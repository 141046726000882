import React from 'react';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import {
  Qas,
  QasInstance,
  QasProductAttributes,
  QasPhotoStandards,
  QasOrganoleptics,
  QasPhotoStandardsItemSchema,
} from '@hooks/api/wizard/types';
import { wizardHook, useUrlParams } from '@hooks';
import { Add as AddIcon, Help } from '@material-ui/icons';

import { deepClone } from '@utils';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import {
  Input,
  Button,
  Table,
  TextArea,
  TableTrashButton,
} from '@components/Common';
import UploadButton from '@components/Common/UploadButtonAdvance';
import {
  QasTables,
  TableInputProps,
  ExpandableKeys,
  CustomCell,
} from '@components/Bussines/Products/ProductForm/Qas/types';
import {
  qasPhotoStandardsInitRow,
  qasOrganolepticsInitRow,
  qasProductAttributesInitRow,
} from '@components/Bussines/Products/ProductForm/Qas/init';
import { Tooltip, IconButton } from '@material-ui/core';

const validationSchema = yup.object({
  product_attributes: yup.array(
    yup.object({
      green: yup.object({
        pic1: yup.mixed().test('pic1', 'error', (val, contxt) => {
          if (contxt.parent.pic2 || contxt.parent.pic3) {
            return true;
          }
          return !!val;
        }),
        pic2: yup.mixed().test('pic2', 'error', (val, contxt) => {
          if (contxt.parent.pic1 || contxt.parent.pic3) {
            return true;
          }
          return !!val;
        }),
        pic3: yup.mixed().test('pic3', 'error', (val, contxt) => {
          if (contxt.parent.pic2 || contxt.parent.pic1) {
            return true;
          }
          return !!val;
        }),
        value: yup.string().ensure().required(),
      }),
      red: yup.object({
        pic1: yup.mixed().test('pic1', 'error', (val, contxt) => {
          if (contxt.parent.pic2 || contxt.parent.pic3) {
            return true;
          }
          return !!val;
        }),
        pic2: yup.mixed().test('pic2', 'error', (val, contxt) => {
          if (contxt.parent.pic1 || contxt.parent.pic3) {
            return true;
          }
          return !!val;
        }),
        pic3: yup.mixed().test('pic3', 'error', (val, contxt) => {
          if (contxt.parent.pic2 || contxt.parent.pic1) {
            return true;
          }
          return !!val;
        }),
        value: yup.string().ensure().required(),
      }),
      status: yup.string().ensure().required(),
    }),
  ),

  photo_standards: yup.array(
    yup.object({
      items: yup.array(
        yup.mixed().test('items', 'error', (val, contxt) => {
          if (contxt.parent[0].file && contxt.parent[0].value) {
            return true;
          }

          if (contxt.parent[1].file && contxt.parent[1].value) {
            return true;
          }

          if (contxt.parent[2].file && contxt.parent[2].value) {
            return true;
          }

          if (contxt.parent[3].file && contxt.parent[3].value) {
            return true;
          }

          return false;
        }),
      ),
      title: yup.string().ensure().required(),
    }),
  ),

  organoleptics: yup.array(
    yup.object({
      green: yup.string().ensure().required(),
      red: yup.string().ensure().required(),
      status: yup.string().ensure().required(),
    }),
  ),
});

type P = {
  share?: boolean;
};
const Products: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const form = useForm<Qas>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();
  const { useQas, useUpdateQasMutation, useValidateQasMutation } = wizardHook;

  const { data: productQas } = useQas(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        const formInitialValue = deepClone(data.data);

        form.reset(formInitialValue);
      },
    },
  );
  const updateQasMutation = useUpdateQasMutation();
  const validateQasMutation = useValidateQasMutation();

  const history = useHistory();

  const onSave = (link: string, data: Qas) => {
    form.clearErrors();
    updateQasMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate = async (data: Qas) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }
      validateQasMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });
    } catch (e) {}
  };

  const onDeleteRow = React.useCallback(
    (rowKey: ExpandableKeys, rowIndex?: number) => {
      const newVals =
        form.getValues()?.[rowKey]?.length > 0 ? form.getValues()[rowKey] : [];

      newVals.splice(rowIndex || 0, 1);

      form.setValue(rowKey, newVals, { shouldDirty: true });
      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
    },
    [productQas?.timeStamp],
  );

  const onAddRow = React.useCallback(
    (
      tableType: QasTables,
      initRow: QasProductAttributes | QasOrganoleptics | QasPhotoStandards,
    ) => {
      const newRows = [...form.getValues()[tableType]];

      newRows.push(initRow);

      form.reset(
        {
          ...form.getValues(),
          [tableType]: newRows,
        },
        {
          keepDirty: true,
          keepIsValid: true,
          keepTouched: true,
          keepErrors: true,
        },
      );
    },
    [productQas?.timeStamp],
  );

  const TableInput = React.useCallback(
    ({
      name,
      dense,
      className = '',
      inputClassName = '',
      disabled,
    }: TableInputProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              dense={dense}
              className={className ?? 'w-full h-6'}
              inputClassName={inputClassName ?? 'text-xs px-2 font-roboto'}
              innerRef={ref}
              value={value ?? ''}
              disabled={disabled}
              {...rest}
            />
          );
        }}
      />
    ),
    [],
  );

  const ProductPhotoStandardsTable = React.useCallback(
    ({ data }: { data: QasPhotoStandards[] }) => {
      return (
        <>
          {data &&
            data?.map((item: QasPhotoStandards, i: number) => (
              <div
                key={item.title}
                className="qas-photo-standards flex bg-white mb-1"
              >
                <div className="flex-none p-2 w-2/6">
                  <div className="flex flex-row">
                    <div className="font-roboto flex flex-row w-full">
                      <div className="w-2.5">
                        {form.getValues(`photo_standards.${+i}.tooltip`) && (
                          <Tooltip
                            title={
                              form.getValues(`photo_standards.${+i}.tooltip`) ||
                              ''
                            }
                          >
                            <IconButton
                              disableRipple
                              className="tooltip p-1 mt-2 h-6"
                            >
                              <Help className="text-sm" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>

                      <TableInput
                        name={`photo_standards.${+i}.title`}
                        disabled={
                          !urlParams?.editMode ||
                          !!form.getValues(`photo_standards.${+i}.tooltip`)
                        }
                        className="w-full"
                        inputClassName="w-full"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`flex w-4/6  ${
                    form?.formState?.errors?.photo_standards?.length &&
                    !!form?.formState?.errors?.photo_standards[i]?.items &&
                    'photo_standards-hasError'
                  }`}
                >
                  {[1, 2, 3, 4].map((j: number) => (
                    <div
                      key={j}
                      className="flex flex-col w-3/12 p-2 photo_standards_item"
                    >
                      <Controller
                        name={`photo_standards.${+i}.items.${+j}.value`}
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          fieldState,
                        }) => (
                          <div className={`${!value && 'empty'}`}>
                            <Input
                              error={!!fieldState?.error}
                              className="w-full h-10"
                              inputClassName="text-xs px-2 font-roboto h-10"
                              dense
                              placeholder="Title ..."
                              value={value}
                              onChange={onChange}
                              disabled={!urlParams?.editMode}
                            />
                          </div>
                        )}
                      />
                      <Controller
                        name={`photo_standards.${+i}.items.${+j}.file`}
                        control={form.control}
                        render={({
                          field: { onChange, value },
                          fieldState,
                        }) => (
                          <div className={`${!value && 'empty'}`}>
                            <UploadButton
                              yupError={!!fieldState?.error}
                              text="Add Pic"
                              textClassName="text-xs"
                              id={`photo_standards.${+i}.items.${+j}.file`}
                              wrapperClassName="flex justify-center items-center h-20 mt-2"
                              imgClassName="w-3/4"
                              className={`w-24 
                            ${
                              !!fieldState?.error &&
                              'border-2 bprder-solid border-red-dark'
                            }
                          `}
                              disabled={!urlParams?.editMode}
                              url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
                              value={value}
                              onChange={onChange}
                              showPreview
                              showAddIcon
                              acceptType="image/*"
                            />
                          </div>
                        )}
                      />
                    </div>
                  ))}
                </div>

                <div className="flex pr-6">
                  <TableTrashButton
                    disabled={!urlParams.editMode || data.length <= 1}
                    onClick={() => onDeleteRow('photo_standards', +i)}
                  />
                </div>
              </div>
            ))}
        </>
      );
    },
    [urlParams.editMode],
  );

  const ProductAttributesTable = React.useCallback(
    ({ data }: { data: QasProductAttributes[] }) => {
      return (
        <>
          {data &&
            data?.map((item: QasProductAttributes, i: number) => (
              <div
                key={`row-${item.status}`}
                className="qas-photo-standards flex bg-white mb-1 w-full"
              >
                <div className="flex-none p-2 row-title">
                  <div className="flex flex-row">
                    <div className="font-roboto flex flex-row w-full">
                      <TableInput
                        disabled={!urlParams?.editMode || !item.userAdded}
                        name={`product_attributes.${+i}.status`}
                        className="w-full"
                        inputClassName="w-full"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex bg-green-light green-width p-2">
                  <div className="flex flex-col w-full">
                    <div className="p-2 flex flex-col w-full bg-green-lighter">
                      <Controller
                        name={`product_attributes.${+i}.green.value`}
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          fieldState,
                        }) => (
                          <TextArea
                            error={!!fieldState.error}
                            className={`rounded-none w-full ${
                              urlParams?.editMode ? 'bg-green-lighter' : ''
                            }`}
                            placeholder="Write here..."
                            multiline
                            rows={4}
                            value={value}
                            onChange={onChange}
                            disabled={!urlParams?.editMode}
                            disabledClassName="bg-green-bright"
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-row justify-around bg-green-lighter mt-2">
                      {([1, 2, 3] as const).map(el => (
                        <div key={`Controller-${el}`} className="w-4/12">
                          <Controller
                            name={`product_attributes.${+i}.green.pic${el}`}
                            control={form.control}
                            render={({
                              field: { onChange, value },
                              fieldState,
                            }) => (
                              <UploadButton
                                yupError={!!fieldState?.error}
                                text="Add Pic"
                                textClassName="text-xs"
                                id={`product_attributes.${+i}.green.pic${el}`}
                                wrapperClassName="flex justify-center items-center h-20 mt-2"
                                imgClassName="w-3/4"
                                className={`w-24 
                                ${
                                  !!fieldState?.error &&
                                  'border-2 bprder-solid border-red-light'
                                }
                              `}
                                disabled={!urlParams?.editMode}
                                url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
                                value={value}
                                onChange={onChange}
                                showPreview
                                showAddIcon
                                acceptType="image/*"
                              />
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex bg-yellow-light amber-width p-2">
                  <div className="flex flex-col w-full">
                    <div className="p-2 flex flex-col w-full bg-yellow-lighter">
                      <Controller
                        name={`product_attributes.${+i}.amber.value`}
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          fieldState,
                        }) => (
                          <TextArea
                            error={!!fieldState.error}
                            className={`rounded-none w-full ${
                              urlParams?.editMode ? 'bg-yellow-lighter' : ''
                            }`}
                            placeholder="Write here..."
                            multiline
                            rows={4}
                            value={value}
                            onChange={onChange}
                            disabled={!urlParams?.editMode}
                            disabledClassName="bg-yellow-bright"
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-row justify-around bg-yellow-lighter mt-2">
                      {([1, 2] as const).map(el => (
                        <div key={`Controller-${el}`} className="w-4/12">
                          <Controller
                            name={`product_attributes.${+i}.amber.pic${el}`}
                            control={form.control}
                            render={({
                              field: { onChange, value },
                              fieldState,
                            }) => (
                              <UploadButton
                                yupError={!!fieldState?.error}
                                text="Add Pic"
                                textClassName="text-xs"
                                id={`product_attributes.${+i}.amber.pic${el}`}
                                wrapperClassName="flex justify-center items-center h-20 mt-2"
                                imgClassName="w-3/4"
                                className={`w-24 
                                ${
                                  !!fieldState?.error &&
                                  'border-2 bprder-solid border-red-light'
                                }
                              `}
                                disabled={!urlParams?.editMode}
                                url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
                                value={value}
                                onChange={onChange}
                                showPreview
                                showAddIcon
                                acceptType="image/*"
                              />
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex bg-red-light red-width p-2">
                  <div className="flex flex-col w-full">
                    <div className="p-2 flex flex-col w-full bg-red-lighter">
                      <Controller
                        name={`product_attributes.${+i}.red.value`}
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          fieldState,
                        }) => (
                          <TextArea
                            error={!!fieldState.error}
                            className={`rounded-none w-full ${
                              urlParams?.editMode ? 'bg-red-lighter' : ''
                            }`}
                            placeholder="Write here..."
                            multiline
                            rows={4}
                            value={value}
                            onChange={onChange}
                            disabled={!urlParams?.editMode}
                            disabledClassName="bg-red-bright"
                          />
                        )}
                      />
                    </div>

                    <div className="flex flex-row justify-around bg-red-lighter mt-2">
                      {([1, 2, 3] as const).map(el => (
                        <div key={`Controller-${el}`} className="w-4/12">
                          <Controller
                            name={`product_attributes.${+i}.red.pic${el}`}
                            control={form.control}
                            render={({
                              field: { onChange, value },
                              fieldState,
                            }) => (
                              <UploadButton
                                yupError={!!fieldState?.error}
                                text="Add Pic"
                                textClassName="text-xs"
                                id={`product_attributes.${+i}.red.pic${el}`}
                                wrapperClassName="flex justify-center items-center h-20 mt-2"
                                imgClassName="w-3/4"
                                className={`w-24 
                                ${
                                  !!fieldState?.error &&
                                  'border-2 bprder-solid border-red-light'
                                }
                              `}
                                disabled={!urlParams?.editMode}
                                url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
                                value={value}
                                onChange={onChange}
                                showPreview
                                showAddIcon
                                acceptType="image/*"
                              />
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex pr-3 pl-3 qas-remove">
                  <TableTrashButton
                    disabled={!urlParams.editMode || data.length <= 1}
                    onClick={() => onDeleteRow('product_attributes', +i)}
                  />
                </div>
              </div>
            ))}
        </>
      );
    },
    [urlParams.editMode],
  );

  // const productAttributesColumns = React.useMemo(
  //   (): {
  //     Header: string;
  //     accessor?: keyof QasInstance;
  //     id: keyof QasInstance | 'action';
  //     className?: string;
  //     width?: number;
  //     bodyCellClassName?: string;
  //     Cell?: undefined | ((cell: CustomCell) => JSX.Element);
  //   }[] => [
  //     {
  //       Header: 'Status',
  //       id: 'status',
  //       accessor: 'status',
  //       width: 210,
  //       Cell: cell => (
  //         <div className="flex flex-col">
  //           <TableInput
  //             name={`product_attributes.${Number(cell.row.id)}.status`}
  //             disabled={!urlParams?.editMode || !cell.row.original.userAdded}
  //           />
  //         </div>
  //       ),
  //     },
  //     {
  //       Header: 'Green',
  //       id: 'green',
  //       accessor: 'green',
  //       width: 320,
  //       bodyCellClassName: 'bg-green-light p-2',
  //       Cell: cell => (
  //         <div className="flex flex-col">
  //           <Controller
  //             name={`product_attributes.${Number(cell.row.id)}.green.value`}
  //             control={form.control}
  //             render={({ field: { value, onChange } }) => (
  //               <TextArea
  //                 className={`rounded-none ${
  //                   urlParams?.editMode ? 'bg-green-lighter' : ''
  //                 }`}
  //                 placeholder="Write here..."
  //                 multiline
  //                 rows={4}
  //                 value={value}
  //                 onChange={onChange}
  //                 disabled={!urlParams?.editMode}
  //                 disabledClassName="bg-green-bright"
  //               />
  //             )}
  //           />
  //           <div className="flex flex-row justify-between">
  //             {([1, 2, 3] as const).map(el => (
  //               <Controller
  //                 name={`product_attributes.${Number(
  //                   cell.row.id,
  //                 )}.green.pic${el}`}
  //                 control={form.control}
  //                 render={({ field: { onChange, value } }) => (
  //                   <UploadButton
  //                     text="Add Pic"
  //                     textClassName="text-xs"
  //                     id={`product_attributes.${Number(
  //                       cell.row.id,
  //                     )}.green.pic${el}`}
  //                     wrapperClassName="flex justify-center items-center h-20 mt-2 px-1"
  //                     className="w-24"
  //                     imgClassName="w-auto h-full"
  //                     disabled={!urlParams?.editMode}
  //                     url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
  //                     value={value}
  //                     onChange={onChange}
  //                     showPreview
  //                     showAddIcon
  //                     acceptType="image/*"
  //                   />
  //                 )}
  //               />
  //             ))}
  //           </div>
  //         </div>
  //       ),
  //     },
  //     {
  //       Header: 'Amber',
  //       id: 'amber',
  //       accessor: 'amber',
  //       width: 210,
  //       bodyCellClassName: 'bg-yellow-light p-2',
  //       Cell: cell => (
  //         <div className="flex flex-col">
  //           <Controller
  //             name={`product_attributes.${Number(cell.row.id)}.amber.value`}
  //             control={form.control}
  //             render={({ field: { value, onChange } }) => (
  //               <TextArea
  //                 className={`rounded-none ${
  //                   urlParams?.editMode ? 'bg-yellow-lighter' : ''
  //                 }`}
  //                 placeholder="Write here..."
  //                 multiline
  //                 rows={4}
  //                 value={value}
  //                 onChange={onChange}
  //                 disabled={!urlParams?.editMode}
  //                 disabledClassName="bg-yellow-bright"
  //               />
  //             )}
  //           />

  //           <div className="flex flex-row justify-between">
  //             {([1, 2] as const).map(el => (
  //               <Controller
  //                 name={`product_attributes.${Number(
  //                   cell.row.id,
  //                 )}.amber.pic${el}`}
  //                 control={form.control}
  //                 render={({ field: { onChange, value } }) => (
  //                   <UploadButton
  //                     text="Add Pic"
  //                     textClassName="text-xs"
  //                     id={`product_attributes.${Number(
  //                       cell.row.id,
  //                     )}.amber.pic${el}`}
  //                     wrapperClassName="flex justify-center items-center h-20 mt-2 px-1"
  //                     className="w-24"
  //                     imgClassName="w-auto h-full"
  //                     disabled={!urlParams?.editMode}
  //                     url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
  //                     value={value}
  //                     onChange={onChange}
  //                     showPreview
  //                     showAddIcon
  //                     acceptType="image/*"
  //                   />
  //                 )}
  //               />
  //             ))}
  //           </div>
  //         </div>
  //       ),
  //     },
  //     {
  //       Header: 'Red',
  //       id: 'red',
  //       accessor: 'red',
  //       width: 320,
  //       bodyCellClassName: 'bg-red-light p-2',
  //       Cell: cell => (
  //         <div className="flex flex-col">
  //           <Controller
  //             name={`product_attributes.${Number(cell.row.id)}.red.value`}
  //             control={form.control}
  //             render={({ field: { value, onChange } }) => (
  //               <TextArea
  //                 className={`rounded-none ${
  //                   urlParams?.editMode ? 'bg-red-lighter' : ''
  //                 }`}
  //                 placeholder="Write here..."
  //                 multiline
  //                 rows={4}
  //                 value={value}
  //                 onChange={onChange}
  //                 disabled={!urlParams?.editMode}
  //                 disabledClassName="bg-red-bright"
  //               />
  //             )}
  //           />

  //           <div className="flex flex-row justify-between">
  //             {([1, 2, 3] as const).map(el => (
  //               <Controller
  //                 name={`product_attributes.${Number(
  //                   cell.row.id,
  //                 )}.red.pic${el}`}
  //                 control={form.control}
  //                 render={({ field: { onChange, value } }) => (
  //                   <UploadButton
  //                     text="Add Pic"
  //                     textClassName="text-xs"
  //                     id={`product_attributes.${Number(
  //                       cell.row.id,
  //                     )}.red.pic${el}`}
  //                     wrapperClassName="flex justify-center items-center h-20 mt-2 px-1"
  //                     className="w-24"
  //                     imgClassName="w-auto h-full"
  //                     disabled={!urlParams?.editMode}
  //                     url={`/products/${urlParams.code}/version/${urlParams.version}/qas/upload`}
  //                     value={value}
  //                     onChange={onChange}
  //                     // showPreview
  //                     showAddIcon
  //                     acceptType="image/*"
  //                   />
  //                 )}
  //               />
  //             ))}
  //           </div>
  //         </div>
  //       ),
  //     },
  //     {
  //       width: 30,
  //       Header: '',
  //       id: 'action',
  //       className: 'text-center',
  //       Cell: cell => (
  //         <TableTrashButton
  //           disabled={!urlParams.editMode}
  //           onClick={() =>
  //             onDeleteRow('product_attributes', Number(cell.row.id))
  //           }
  //         />
  //       ),
  //     },
  //   ],
  //   [],
  // );

  const organolepticColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof QasInstance;
      id: keyof QasInstance | 'action';
      className?: string;
      width?: number;
      bodyCellClassName?: string;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        width: 210,
        Cell: cell => (
          <div className="flex flex-col">
            <TableInput
              name={`organoleptics.${Number(cell.row.id)}.status`}
              disabled={!urlParams?.editMode || !cell.row.original.userAdded}
            />
          </div>
        ),
      },
      {
        Header: 'Green',
        id: 'green',
        accessor: 'green',
        width: 320,
        bodyCellClassName: 'bg-green-light p-2',
        Cell: cell => (
          <div className="p-2 flex flex-col w-full bg-green-lighter">
            <Controller
              name={`organoleptics.${Number(cell.row.id)}.green`}
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextArea
                  error={!!fieldState.error}
                  className={`rounded-none w-full ${
                    urlParams?.editMode ? 'bg-green-lighter' : ''
                  }`}
                  placeholder="Write here..."
                  multiline
                  rows={4}
                  value={value}
                  onChange={onChange}
                  disabled={!urlParams?.editMode}
                  disabledClassName="bg-green-bright"
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'Amber',
        id: 'amber',
        accessor: 'amber',
        width: 210,
        bodyCellClassName: 'bg-yellow-light p-2',
        Cell: cell => (
          <div className="p-2 flex flex-col w-full bg-yellow-lighter">
            <Controller
              name={`organoleptics.${Number(cell.row.id)}.amber`}
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextArea
                  error={!!fieldState.error}
                  className={`rounded-none w-full ${
                    urlParams?.editMode ? 'bg-yellow-lighter' : ''
                  }`}
                  placeholder="Write here..."
                  multiline
                  rows={4}
                  value={value}
                  onChange={onChange}
                  disabled={!urlParams?.editMode}
                  disabledClassName="bg-yellow-bright"
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'Red',
        id: 'red',
        accessor: 'red',
        width: 320,
        bodyCellClassName: 'bg-red-light p-2',
        Cell: cell => (
          <div className="p-2 flex flex-col w-full bg-red-lighter">
            <Controller
              name={`organoleptics.${Number(cell.row.id)}.red`}
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextArea
                  error={!!fieldState.error}
                  className={`rounded-none w-full ${
                    urlParams?.editMode ? 'bg-red-lighter' : ''
                  }`}
                  placeholder="Write here..."
                  multiline
                  rows={4}
                  value={value}
                  onChange={onChange}
                  disabled={!urlParams?.editMode}
                  disabledClassName="bg-red-bright"
                />
              )}
            />
          </div>
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: (cell: any) => (
          <TableTrashButton
            disabled={
              (!urlParams.editMode && cell.row.original.userAdded) ||
              cell.rows.length <= 1
            }
            onClick={() => onDeleteRow('organoleptics', Number(cell.row.id))}
          />
        ),
      },
    ],
    [urlParams.editMode],
  );

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form id="qas" className={errorForm}>
        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Product Attributes
          </h3>

          {/* <Table
            // bodyCellClassName="bg-white border-0 p-2"
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0"
            columns={productAttributesColumns}
            data={form.getValues()?.product_attributes || []}
          /> */}

          {form.getValues().product_attributes?.length ? (
            <ProductAttributesTable
              data={form.getValues().product_attributes || []}
            />
          ) : (
            <div className="flex flex-row justify-center items-center mb-3 mt-6 font-normal text-xl">
              No Data Available
            </div>
          )}

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(
                  QasTables.ProductAttributes,
                  qasProductAttributesInitRow,
                )
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Organoleptic
          </h3>

          <Table
            // bodyCellClassName="bg-white border-0 p-2"
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0"
            columns={organolepticColumns}
            data={form.getValues().organoleptics || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(QasTables.Organoleptics, qasOrganolepticsInitRow)
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Product & Label photos
          </h3>

          {form.getValues().photo_standards?.length ? (
            <ProductPhotoStandardsTable
              data={form.getValues().photo_standards || []}
            />
          ) : (
            <div className="flex flex-row justify-center items-center mb-3 mt-6 font-normal text-xl">
              No Data Available
            </div>
          )}

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(QasTables.PhotoStandards, qasPhotoStandardsInitRow)
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <FormButtonGroup
          share={share}
          pageName="qas"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default Products;
