/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface P {
  value: null | string;
  onChange: (e: string) => void;
  className?: string;
  buttonClassNames?: string;
  type?: 'primary' | 'secondary';
  leftButtonText?: string;
  rightButtonText?: string;
  disabled?: boolean;
  tabIndex?: number;
  error?: boolean;
  warning?: boolean | null;
}

type StyleRelatedProps = Pick<P, 'error'>;

const useStyles = makeStyles((theme: any) => ({
  base: {
    boxSizing: 'border-box',
    border: '1px solid',
    fontFamily: theme.fonts.secondary,

    backgroundColor: theme.palette.common.white,

    '&:first-child': {
      borderRadius: '3px 0 0 3px',
    },

    '&:last-child': {
      borderRadius: '0 3px 3px 0',
    },
  },
  basePrimary: {
    backgroundColor: theme.palette.common.white,

    color: theme.palette.gray.dark,
    borderColor: ({ error }: StyleRelatedProps) =>
      error ? theme.palette.error.light : theme.palette.text.primary,

    ...theme.typography.body2,

    height: theme.spacing(5),
    width: theme.spacing(14),

    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },

  activePrimary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  deActivePrimary: {},

  baseLeftSecondary: {
    backgroundColor: ({ error }: StyleRelatedProps) =>
      error ? '#fdd5dd' : theme.palette.common.white,

    borderColor: ({ error }: StyleRelatedProps) =>
      error ? theme.palette.text.primary : theme.palette.text.primary,

    color: theme.palette.gray.dark,

    ...theme.typography.caption,

    width: theme.spacing(4.375),
    height: theme.spacing(3),
  },
  baseRightSecondary: {
    backgroundColor: ({ error }: StyleRelatedProps) =>
      error ? '#fdd5dd' : theme.palette.common.white,

    borderColor: ({ error }: StyleRelatedProps) =>
      error ? theme.palette.error.light : theme.palette.text.primary,

    color: theme.palette.gray.dark,

    ...theme.typography.caption,

    width: theme.spacing(4.375),
    height: theme.spacing(3),
  },

  leftActiveSecondary: {
    backgroundColor: ({ error }: StyleRelatedProps) =>
      error ? theme.palette.success.main : theme.palette.success.main,

    color: theme.palette.common.white,
    borderWidth: 0,

    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },

  rightActiveSecondary: {
    backgroundColor: ({ error }: StyleRelatedProps) =>
      error ? theme.palette.error.light : theme.palette.error.light,

    color: theme.palette.common.white,

    borderWidth: 0,

    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.common.white,
    },
  },
  leftDeActiveSecondary: {},
  rightDeActiveSecondary: {},
}));

const Switch: React.FC<P> = ({
  value,
  onChange,
  className,
  buttonClassNames,
  leftButtonText = 'Yes',
  rightButtonText = 'No',
  type = 'primary',
  disabled = false,
  tabIndex,
  error,
  warning,
}) => {
  const classes = useStyles({ error });

  return (
    <div
      tabIndex={tabIndex}
      className={clsx(
        'flex flex-row',
        warning && 'border-4 border-yellow-light -ml-1 rounded',

        className,
        error && 'switch-error',
      )}
    >
      <Button
        disabled={disabled}
        className={overrideTailwindClasses(
          clsx(
            classes.base,
            type === 'primary'
              ? classes.basePrimary
              : classes.baseLeftSecondary,
            // eslint-disable-next-line no-nested-ternary
            value === leftButtonText
              ? type === 'primary'
                ? classes.activePrimary
                : classes.leftActiveSecondary
              : '',
            value === rightButtonText
              ? type === 'primary'
                ? classes.deActivePrimary
                : classes.leftDeActiveSecondary
              : '',
            'transition-all',
            buttonClassNames,
            value === rightButtonText && 'bg-white',
          ),
        )}
        onClick={() => onChange(leftButtonText)}
      >
        {leftButtonText}
      </Button>

      <Button
        disabled={disabled}
        className={overrideTailwindClasses(
          clsx(
            classes.base,
            type === 'primary'
              ? classes.basePrimary
              : classes.baseRightSecondary,
            // eslint-disable-next-line no-nested-ternary
            value === rightButtonText
              ? type === 'primary'
                ? classes.activePrimary
                : classes.rightActiveSecondary
              : '',
            value === leftButtonText
              ? type === 'primary'
                ? classes.deActivePrimary
                : classes.rightDeActiveSecondary
              : '',
            'transition-all',
            buttonClassNames,
            value === leftButtonText && 'bg-white',
          ),
        )}
        onClick={() => onChange(rightButtonText)}
      >
        {rightButtonText}
      </Button>
    </div>
  );
};

export default Switch;
