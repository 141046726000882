import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  DeleteSharedLinksParams,
  GetSharedLinksParams,
  ResendSharedLinksParams,
  UseSharedLinksResponseType,
} from '@hooks/api/shared/types';

const getSharedLinksList = async (params: GetSharedLinksParams) => {
  const res: AxiosResponse<UseSharedLinksResponseType> = await axios({
    method: 'get',
    url: `/public_link`,
    params,
  });
  return res.data;
};

const deleteSharedLinks = async (params: DeleteSharedLinksParams) => {
  const res: AxiosResponse = await axios({
    method: 'delete',
    url: `/public_link/${params.publicLink}`,
  });
  return res.data;
};

const resendSharedLinks = async (params: ResendSharedLinksParams) => {
  const res: AxiosResponse = await axios({
    method: 'get',
    url: `/public_link/${params.publicLink}/resend`,
  });
  return res.data;
};

export default {
  getSharedLinksList,
  deleteSharedLinks,
  resendSharedLinks,
};
