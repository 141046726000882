/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';
import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  AutoComplete,
  UploadButtonUploadOnChange,
  CheckBox,
} from '@components/Common';

import { useUrlParams } from '@hooks/utils';

import GeneralHooks from '@hooks/api/general';
import { CustomerProfile } from '@hooks/api/general/types';

const validationSchema = yup.object({
  general: yup.object({
    address: yup.string().required('Address is required'),
    logo: yup.string().required('Logo is required'),
    saq_file: yup.string().required('SAQ file is required'),
    billing_address: yup.string().required('Billing Address is required'),
    billing_name: yup.string().required('Billing name is required'),
    billing_title: yup.string().required('Billing title is required'),
    billing_email: yup
      .string()
      .email('Invalid email format')
      .required('Billing email is required'),
    billing_phone: yup.string().required('Billing phone is required'),
  }),
});

const CompanyProfileComp = () => {
  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
  }>();

  const [user] = useUser();

  const form = useForm<CustomerProfile>({
    resolver: yupResolver(validationSchema),
  });

  const { ...useCurrentUserProfileUtils } = GeneralHooks.useCustomerProfile({
    onSuccess: data => form.reset(data.data),
  });

  const { data: allStatus } = GeneralHooks.useAllStatus();

  const updateCustomerProfileMutation =
    GeneralHooks.useUpdateCustomerProfileMutation();

  const onSubmit: SubmitHandler<CustomerProfile> = data => {
    updateCustomerProfileMutation.mutate(data);
  };

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Certificates' },
              { href: '#', text: 'Certificate Info' },
            ]}
          />

          <div>
            {urlParams.editMode ? (
              <Button
                variant="outlined"
                className="w-28"
                onClick={() => setUrlParams({ editMode: false })}
                size="medium"
                startIcon={<Close />}
                text="Cancel"
              />
            ) : (
              <Button
                className="w-28"
                onClick={() => setUrlParams({ editMode: true })}
                size="medium"
                startIcon={<Edit />}
                text="Edit"
              />
            )}
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              General Info
            </h3>
          </div>

          <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
            {(
              [
                {
                  displayText: 'Customer Reference Number',
                  key: 'general.reference_number',
                  disabled: true,
                },
                {
                  displayText: 'Customer Name',
                  key: 'general.name',
                  required: true,
                  disabled: true,
                },
                {
                  displayText: 'Corresponding Address',
                  key: 'general.address',
                  gridSize: 'col-span-2',
                  required: true,
                },
                {
                  displayText: 'Signatory Name',
                  key: 'general.signatury_name',
                  required: true,
                  disabled: true,
                },
                {
                  displayText: 'Signatory Title',
                  key: 'general.signatury_title',
                  required: true,
                  disabled: true,
                },
                {
                  displayText: 'Signatory Email',
                  key: 'general.signatury_email',
                  required: true,
                  disabled: true,
                },
                {
                  displayText: 'Signatory Phone',
                  key: 'general.signatury_phone',
                  required: true,
                  disabled: true,
                },
                {
                  displayText: 'Technical Contact Name',
                  key: 'general.technical_name',
                },
                {
                  displayText: 'Technical Contact Title',
                  key: 'general.technical_title',
                },
                {
                  displayText: 'Technical Contact Email',
                  key: 'general.technical_email',
                },
                {
                  displayText: 'Technical Contact Phone',
                  key: 'general.technical_phone',
                },
                {
                  displayText: 'Billing Address',
                  key: 'general.billing_address',
                  gridSize: 'col-span-2',
                  required: true,
                },
                {
                  displayText: 'Billing Contact Name',
                  key: 'general.billing_name',
                  required: true,
                },
                {
                  displayText: 'Billing Contact Title',
                  key: 'general.billing_title',
                  required: true,
                },
                {
                  displayText: 'Billing Contact Email',
                  key: 'general.billing_email',
                  required: true,
                },
                {
                  displayText: 'Billing Contact Phone',
                  key: 'general.billing_phone',
                  required: true,
                },
                {
                  displayText: 'Emergency Contact Name',
                  key: 'general.emergency_name',
                },
                {
                  displayText: 'Emergency Contact Title',
                  key: 'general.emergency_title',
                },
                {
                  displayText: 'Emergency Contact Email',
                  key: 'general.emergency_email',
                },
                {
                  displayText: 'Emergency Contact Phone',
                  key: 'general.emergency_phone',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col',
                      'gridSize' in el ? el?.gridSize : 'col-span-1',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useCurrentUserProfileUtils.isLoading}
                          disabled={
                            ('disabled' in el && el.disabled) ||
                            !urlParams?.editMode
                          }
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="general.customer_type"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className={clsx('flex flex-col col-span-1')}>
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Customer Type</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        loading={useCurrentUserProfileUtils.isLoading}
                        disabled={!urlParams?.editMode}
                        value={value}
                        onChange={onChange}
                        items={allStatus?.data.customer_types || []}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Customer Status',
                  type: 'text',
                  key: 'general.status',
                  disabled: true,
                },
                {
                  displayText: 'Customer Website',
                  type: 'text',
                  key: 'general.website',
                },
                {
                  displayText: 'Review Dates',
                  type: 'number',
                  key: 'general.review_dates',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx('flex flex-col col-span-1')}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useCurrentUserProfileUtils.isLoading}
                          disabled={
                            ('disabled' in el && el.disabled) ||
                            !urlParams?.editMode
                          }
                          type={el.type}
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="general.logo"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className={clsx('flex flex-col col-span-1')}>
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Customer Logo*</p>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full">
                      <UploadButtonUploadOnChange
                        text="Upload File"
                        id="general.logo"
                        disabled={!urlParams.editMode}
                        url="/customer/profile/uploadlogo"
                        wrapperClassName="flex justify-center min-w-min my-2"
                        value={value}
                        onChange={val => onChange(val)}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="general.saq_file"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className={clsx('flex flex-col col-span-1')}>
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Upload SAQ*</p>
                    </div>

                    <div className="flex flex-col justify-center items-center w-full">
                      <UploadButtonUploadOnChange
                        text="Upload File"
                        id="general.saq_file"
                        disabled={!urlParams.editMode}
                        url="/customer/profile/upload_saq"
                        wrapperClassName="flex justify-center min-w-min my-2"
                        value={value}
                        onChange={val => onChange(val)}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-8">
        <div className="flex flex-row justify-between items-center">
          <h3 className="text-base text-primary-light font-roboto">Services</h3>
        </div>

        <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
          {(
            [
              {
                displayText: 'Service Type',
                key: 'service.service_type',
              },
              {
                displayText: 'Full Licence Customer',
                key: 'service.full_license_customer',
              },
              {
                displayText: 'Light Licence Customer',
                key: 'service.light_license_customer',
              },
              {
                displayText: 'Spec',
                key: 'service.spec',
              },
              {
                displayText: 'Trial Duration',
                key: 'service.trial_duration',
              },
              {
                displayText: 'Supplier',
                key: 'service.supplier',
              },
              {
                displayText: 'Supplier Users',
                key: 'service.supplier_user',
              },
              {
                displayText: 'Support',
                key: 'service.support',
              },
            ] as const
          ).map(el => (
            <Controller
              key={el.key}
              name={el.key}
              control={form.control}
              render={({ field: { ref, ...rest } }) => (
                <div
                  key={el.key}
                  className=" col-span-1 flex flex-row items-center bg-white rounded shadow-sm"
                >
                  <div>
                    <p className="font-roboto pl-4 w-52">{el.displayText}</p>
                  </div>

                  <div className="flex flex-col w-full">
                    <Input
                      loading={useCurrentUserProfileUtils.isLoading}
                      disabled
                      className="flex-grow"
                      innerRef={ref}
                      {...rest}
                    />
                  </div>
                </div>
              )}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col mt-8">
        <div className="flex flex-row justify-between items-center">
          <h3 className="text-base text-primary-light font-roboto">
            Contract and Pricing
          </h3>
        </div>

        <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
          {(
            [
              {
                displayText: 'VAT Number',
                type: 'text',
                key: 'contract.vat_number',
              },
              {
                displayText: 'Contract Start Date',
                type: 'text',
                key: 'contract.contract_start_date',
              },
              {
                displayText: 'Template Fee',
                type: 'text',
                key: 'contract.template_fee',
              },
              {
                displayText: 'Contract End Date',
                type: 'text',
                key: 'contract.contract_end_date',
              },
              {
                displayText: 'Setup Fee',
                type: 'text',
                key: 'contract.setup_fee',
              },
              {
                displayText: 'Recurring Fee',
                type: 'text',
                key: 'contract.recurring_fee',
              },
              {
                displayText: 'Full Licence',
                type: 'text',
                key: 'contract.full_licence',
              },
              {
                displayText: 'Recurring Duration',
                type: 'text',
                key: 'contract.recurring_duration',
              },
              {
                displayText: 'Light Licence',
                type: 'text',
                key: 'contract.light_licence',
              },
              {
                displayText: 'Supplier Licence',
                type: 'text',
                key: 'contract.supplier_licence',
              },
              {
                displayText: 'Product Licence',
                type: 'text',
                key: 'contract.product_licence',
              },
            ] as const
          ).map(el => (
            <Controller
              key={el.key}
              name={el.key}
              control={form.control}
              render={({ field: { ref, ...rest } }) => (
                <div
                  key={el.key}
                  className=" col-span-1 flex flex-row items-center bg-white rounded shadow-sm"
                >
                  <div>
                    <p className="font-roboto pl-4 w-52">{el.displayText}</p>
                  </div>

                  <div className="flex flex-col w-full">
                    <Input
                      loading={useCurrentUserProfileUtils.isLoading}
                      disabled
                      className="flex-grow"
                      innerRef={ref}
                      {...rest}
                    />
                  </div>
                </div>
              )}
            />
          ))}

          <Controller
            name="contract.renewal"
            control={form.control}
            render={({ field: { ref, value, ...rest } }) => (
              <div className="col-span-1 flex flex-row items-center bg-white rounded shadow-sm">
                <div>
                  <p className="font-roboto pl-4 w-52">Auto Renewal</p>
                </div>

                <div className="flex flex-col w-full">
                  <CheckBox
                    checked={!!value}
                    value={value}
                    disabled
                    className="flex-grow"
                    {...rest}
                  />
                </div>
              </div>
            )}
          />

          <Controller
            name="contract.contract_file"
            control={form.control}
            render={({ field: { value } }) => (
              <div className="min-h-10 col-span-1 flex flex-row items-center bg-white rounded shadow-sm">
                <div>
                  <p className="font-roboto pl-4 w-52">Contract File(s)</p>
                </div>

                <div className="grid grid-cols-5 gap-x-2 justify-center items-center w-full my-2">
                  {(value?.length ? value : []).map(el => (
                    <UploadButtonUploadOnChange
                      text="Upload File"
                      id="contract.contract_file"
                      url=""
                      showViewIconOnly
                      labelClassNames="w-12 py-2 px-4"
                      className="w-12"
                      wrapperClassName="mx-2 flex justify-center w-12"
                      value={el}
                      onChange={() => {}}
                    />
                  ))}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        isLoading={useCurrentUserProfileUtils.isLoading}
        canSave={user?.access_levels.company_profile}
        onSave={form.handleSubmit(onSubmit)}
      />
    </Layout>
  );
};

export default CompanyProfileComp;
