import {
  ShelfLifeInformation,
  StorageTempAndConditions,
} from '@app/hooks/api/wizard/types';

const baseStorageInitRow = {
  comments: '',
  status: '',
  unit: null,
  userAdded: true,
};

const shelfLifeInformationInitRow: ShelfLifeInformation = {
  value: null,
  ...baseStorageInitRow,
};

const storageTempAndConditionsInitRow: StorageTempAndConditions = {
  max_temp: null,
  min_temp: null,
  rh_percent: null,
  storage_condition: null,
  ...baseStorageInitRow,
};

export { shelfLifeInformationInitRow, storageTempAndConditionsInitRow };
