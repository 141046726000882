import { Ingredients_Item } from '@hooks/api/wizard/types';

const treeRow: Ingredients_Item = {
  ingredient_name: {
    name: '',
    code: -1,
  },
  allergen: {
    allergen_id: null,
    allergen_parent_id: null,
    render: '',
    is_allergen: false,
    is_exception_allergen: false,
  },
  qty_in_mixing_bowl: null,
  qty_in_final_product: null,
  percentage_in_final_product: null,
  percentage_in_final_product_manual: null,
  e_number: null,
  function_of_additives: null,
  gmo_status: null,
  country_of_origin: null,
  SupplierName: null,
  quid: false,
  declared: true,
  comments_and_extra_information: '',
  expanded: true,
  children: [],
};

const function_of_additivesList = [
  { name: 'Acid', plural: 'Acids', code: 1 },
  { name: 'Acidity regulator', plural: 'Acidity regulators', code: 2 },
  { name: 'Anti-caking agent', plural: 'Anti-caking agents', code: 3 },
  { name: 'Anti-foaming agent', plural: 'Anti-foaming agents', code: 4 },
  { name: 'Antioxidant', plural: 'Antioxidants', code: 5 },
  { name: 'Bulking agent', plural: 'Bulking agents', code: 6 },
  { name: 'Colour', plural: 'Colours', code: 7 },
  { name: 'Emulsifier', plural: 'Emulsifiers', code: 8 },
  { name: 'Emulsifying salts', plural: 'Emulsifying saltss', code: 9 },
  { name: 'Enzyme', plural: 'Enzymes', code: 10 },
  { name: 'Firming agent', plural: 'Firming agents', code: 11 },
  { name: 'Flavour enhancer', plural: 'Flavour enhancers', code: 12 },
  { name: 'Flour treatment agent', plural: 'Flour treatment agents', code: 13 },
  { name: 'Foaming agent', plural: 'Foaming agents', code: 14 },
  { name: 'Gelling agent', plural: 'Gelling agents', code: 15 },
  { name: 'Glazing agent', plural: 'Glazing agents', code: 16 },
  { name: 'Humectant', plural: 'Humectants', code: 17 },
  { name: 'Modified starch', plural: 'Modified starchs', code: 18 },
  { name: 'Preservative', plural: 'Preservatives', code: 19 },
  { name: 'Processing Aid', plural: 'Processing Aids', code: 20 },
  { name: 'Propellent gas', plural: 'Propellent gass', code: 21 },
  { name: 'Raising agent', plural: 'Raising agents', code: 22 },
  { name: 'Sequestrant', plural: 'Sequestrants', code: 23 },
  { name: 'Stabiliser', plural: 'Stabilisers', code: 24 },
  { name: 'Sweetener', plural: 'Sweeteners', code: 25 },
  { name: 'Thickener', plural: 'Thickeners', code: 26 },
];

const gmo_statusList = [
  { name: 'GM free', code: 1 },
  { name: 'contains GM', code: 2 },
];

const treeCarouselItems = [
  {
    key: 'qty_in_mixing_bowl',
    slug: 'qty_in_mixing_bowl',
    name: 'Qty in Mixing Bowl (g)',
    type: 'number',
    required: false,
    tooltip:
      'Enter weight of each ingredient in mixing bowl (before cooking, baking, Mixing…)',
  },
  {
    key: 'qty_in_final_product',
    slug: 'qty_in_final_product',
    name: 'Qty in Final Product (g)',
    type: 'number',
    disableInSubRow: true,
    required: true,
    tooltip:
      'Enter Weight of each ingredient in finished product (after cooking, Baking, …) (g)',
  },
  {
    key: 'percentage_in_final_product',
    slug: 'percentage_in_final_product',
    name: '',
    type: 'auto',
    required: true,
    disableInSubRow: true,
  },
  {
    key: 'percentage_in_final_product_manual',
    slug: 'percentage_in_final_product_manual',
    name: '% in Final prodct auto - Manual',
    type: 'number',
    disableInSubRow: true,
    required: false,
    tooltip:
      'Enter Percentage of ingredients in finished product (quid) , manually if previous column was not filled',
  },
  {
    key: 'e_number',
    slug: 'e_number',
    name: 'E Number',
    type: 'e_number',
    canAddCustomItem: true,
    required: false,
    tooltip: 'Start typing to select E Numbers from Glossary',
  },
  {
    key: 'function_of_additives',
    slug: 'function_of_additives',
    name: 'Function of additves',
    type: 'autoComplete',
    data: function_of_additivesList,
    canAddCustomItem: true,
    required: false,
    tooltip:
      'For all E numbers, function must be defined, Start typing to select Function from Glossary',
  },
  {
    key: 'gmo_status',
    slug: 'gmo_status',
    name: 'GMO status',
    type: 'autoComplete',
    data: gmo_statusList,
    canAddCustomItem: false,
    required: true,
    tooltip:
      'If ingredint is one of those with concern of being genetically modifed, please mention status',
  },
  {
    key: 'country_of_origin',
    slug: 'country_of_origin',
    name: 'Country Of origin',
    // type: 'text',
    type: 'countryAutoComplete',
    multiple: true,
    required: true,
    // canAddCustomItem: false,
    // data: country_of_originList,
  },
  {
    key: 'SupplierName',
    slug: 'SupplierName',
    name: 'Supplier Name',
    required: false,
    type: 'AutoCompleteMultiple',
    multiple: false,
    data: [],
  },
  {
    key: 'quid',
    slug: 'quid',
    required: false,
    name: 'Quid',
    type: 'checkBox',
    disableInSubRow: true,
  },

  {
    key: 'declared',
    slug: 'declared',
    name: 'Declared',
    type: 'declared',
    required: false,
    tooltip:
      'defult should be x for all and only if function column was choose as Processing aid , x should not be there, user should also be able to remove x where they want',
  },
  {
    key: 'comments_and_extra_information',
    slug: 'comments_and_extra_information',
    required: false,
    name: 'Comments',
    type: 'text',
  },
];

const allergenList = [
  {
    id: 1,
    parent_id: null,
    name: 'Nuts',
    render: '<strong>Nuts</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 2,
    parent_id: 1,
    name: 'Hazelnut',
    render: '<strong>Hazelnut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 3,
    parent_id: 1,
    name: 'Hazelnuts',
    render: '<strong>Hazelnuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 4,
    parent_id: 1,
    name: 'Walnuts',
    render: '<strong>Walnuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 5,
    parent_id: 1,
    name: 'Cashew nuts',
    render: '<strong>Cashew nuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 6,
    parent_id: 1,
    name: 'Pecan nut',
    render: '<strong>Pecan nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 7,
    parent_id: 1,
    name: 'Pecan nuts',
    render: '<strong>Pecan nuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 8,
    parent_id: 1,
    name: 'Pecan',
    render: '<strong>Pecan</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 9,
    parent_id: 1,
    name: 'Pecans',
    render: '<strong>Pecans</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 10,
    parent_id: 1,
    name: 'Pecans nut',
    render: '<strong>Pecans nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 11,
    parent_id: 1,
    name: 'Peacans Nuts',
    render: '<strong>Peacans Nuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 12,
    parent_id: 1,
    name: 'Brazil nut',
    render: '<strong>Brazil nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 13,
    parent_id: 1,
    name: 'Brazil nuts',
    render: '<strong>Brazil nuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 14,
    parent_id: 1,
    name: 'Macadamia nut',
    render: '<strong>Macadamia nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 15,
    parent_id: 1,
    name: 'Queensland nut',
    render: '<strong>Queensland nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 16,
    parent_id: 1,
    name: 'Macadamia',
    render: '<strong>Macadamia</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 17,
    parent_id: 1,
    name: 'Pistachio Nut',
    render: '<strong>Pistachio Nut</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 18,
    parent_id: 1,
    name: 'Pistachio Nuts',
    render: '<strong>Pistachio Nuts</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 19,
    parent_id: 1,
    name: 'Almond',
    render: '<strong>Almond</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 20,
    parent_id: 1,
    name: 'Almonds',
    render: '<strong>Almonds</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 21,
    parent_id: 1,
    name: 'Cashews',
    render: '<strong>Cashews</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 22,
    parent_id: 1,
    name: 'Cashew',
    render: '<strong>Cashew</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 23,
    parent_id: 1,
    name: 'Pistachio',
    render: '<strong>Pistachio</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 24,
    parent_id: 1,
    name: 'Pistachios',
    render: '<strong>Pistachios</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 25,
    parent_id: 1,
    name: 'Pecan',
    render: '<strong>Pecan</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 26,
    parent_id: 1,
    name: 'Pecans',
    render: '<strong>Pecans</strong>',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 27,
    parent_id: null,
    name: 'Peanuts',
    render: '<strong>Peanuts</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 28,
    parent_id: 27,
    name: 'Peanuts',
    render: '<strong>Peanuts</strong>',
    parent_render: '(<strong>Peanuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 29,
    parent_id: 27,
    name: 'Peanut',
    render: '<strong>Peanut</strong>',
    parent_render: '(<strong>Peanuts</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 30,
    parent_id: null,
    name: 'Fish',
    render: '<strong>Fish</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 31,
    parent_id: 30,
    name: 'Abalone',
    render: '<strong>Abalone</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 32,
    parent_id: 30,
    name: 'Anchovies',
    render: '<strong>Anchovies</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 33,
    parent_id: 30,
    name: 'Arctic Char',
    render: '<strong>Arctic Char</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 34,
    parent_id: 30,
    name: 'Barramundi',
    render: '<strong>Barramundi</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 35,
    parent_id: 30,
    name: 'Black Cod',
    render: '<strong>Black Cod</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 36,
    parent_id: 30,
    name: 'Blue Cod',
    render: '<strong>Blue Cod</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 37,
    parent_id: 30,
    name: 'Bombay',
    render: '<strong>Bombay</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 38,
    parent_id: 30,
    name: 'Bonito',
    render: '<strong>Bonito</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 39,
    parent_id: 30,
    name: 'Duck',
    render: '<strong>Duck</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 40,
    parent_id: 30,
    name: 'Bream',
    render: '<strong>Bream</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 41,
    parent_id: 30,
    name: 'Brill',
    render: '<strong>Brill</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 42,
    parent_id: 30,
    name: 'Carp',
    render: '<strong>Carp</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 43,
    parent_id: 30,
    name: 'Cod',
    render: '<strong>Cod</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 44,
    parent_id: 30,
    name: 'Coley',
    render: '<strong>Coley</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 45,
    parent_id: 30,
    name: 'Conger',
    render: '<strong>Conger</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 46,
    parent_id: 30,
    name: 'Eel',
    render: '<strong>Eel</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 47,
    parent_id: 30,
    name: 'Crab Dabs',
    render: '<strong>Crab Dabs</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 48,
    parent_id: 30,
    name: 'Dogfish',
    render: '<strong>Dogfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 49,
    parent_id: 30,
    name: 'Dover',
    render: '<strong>Dover</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 50,
    parent_id: 30,
    name: 'Sole',
    render: '<strong>Sole</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 51,
    parent_id: 30,
    name: 'Yellowfin',
    render: '<strong>Yellowfin</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 52,
    parent_id: 30,
    name: 'Hamachi',
    render: '<strong>Hamachi</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 53,
    parent_id: 30,
    name: 'Unagi',
    render: '<strong>Unagi</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 54,
    parent_id: 30,
    name: 'Tilefish',
    render: '<strong>Tilefish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 55,
    parent_id: 30,
    name: 'Skipjack',
    render: '<strong>Skipjack</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 56,
    parent_id: 30,
    name: 'Somo',
    render: '<strong>Somo</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 57,
    parent_id: 30,
    name: 'Zander',
    render: '<strong>Zander</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 58,
    parent_id: 30,
    name: 'Tobiko',
    render: '<strong>Tobiko</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 59,
    parent_id: 30,
    name: 'masago',
    render: '<strong>masago</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 60,
    parent_id: 30,
    name: 'Grey Mullet',
    render: '<strong>Grey Mullet</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 61,
    parent_id: 30,
    name: 'Escolar',
    render: '<strong>Escolar</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 62,
    parent_id: 30,
    name: 'Gurnard',
    render: '<strong>Gurnard</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 63,
    parent_id: 30,
    name: 'Hake',
    render: '<strong>Hake</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 64,
    parent_id: 30,
    name: 'Halibut',
    render: '<strong>Halibut</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 65,
    parent_id: 30,
    name: 'Haddock',
    render: '<strong>Haddock</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 66,
    parent_id: 30,
    name: 'Herring',
    render: '<strong>Herring</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 67,
    parent_id: 30,
    name: 'John Dory',
    render: '<strong>John Dory</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 68,
    parent_id: 30,
    name: 'Kingfish',
    render: '<strong>Kingfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 69,
    parent_id: 30,
    name: 'Kingklip',
    render: '<strong>Kingklip</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 70,
    parent_id: 30,
    name: 'Langoustine',
    render: '<strong>Langoustine</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 71,
    parent_id: 30,
    name: 'Lemon Sole',
    render: '<strong>Lemon Sole</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 72,
    parent_id: 30,
    name: 'Ling',
    render: '<strong>Ling</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 74,
    parent_id: 30,
    name: 'Mackerel',
    render: '<strong>Mackerel</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 75,
    parent_id: 30,
    name: 'Mahi Mahi',
    render: '<strong>Mahi Mahi</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 76,
    parent_id: 30,
    name: 'Vendace',
    render: '<strong>Vendace</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 77,
    parent_id: 30,
    name: ' Goosenecks',
    render: '<strong>Goosenecks</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 78,
    parent_id: 30,
    name: 'Monkfish',
    render: '<strong>Monkfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 79,
    parent_id: 30,
    name: 'Tuna',
    render: '<strong>Tuna</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 80,
    parent_id: 30,
    name: 'Turbot',
    render: '<strong>Turbot</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 81,
    parent_id: 30,
    name: 'Trout',
    render: '<strong>Trout</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 82,
    parent_id: 30,
    name: 'Pangasius',
    render: '<strong>Pangasius</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 83,
    parent_id: 30,
    name: 'Megrim',
    render: '<strong>Megrim</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 84,
    parent_id: 30,
    name: ' Patagonian Toothfish',
    render: '<strong>Patagonian Toothfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 85,
    parent_id: 30,
    name: 'Pike',
    render: '<strong>Pike</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 86,
    parent_id: 30,
    name: 'Plaice',
    render: '<strong>Plaice</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 87,
    parent_id: 30,
    name: 'Pollack',
    render: '<strong>Pollack</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 88,
    parent_id: 30,
    name: 'Pouting',
    render: '<strong>Pouting</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 89,
    parent_id: 30,
    name: 'Salmon',
    render: '<strong>Salmon</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 90,
    parent_id: 30,
    name: 'Sardines',
    render: '<strong>Sardines</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 91,
    parent_id: 30,
    name: 'Sea Bass',
    render: '<strong>Sea Bass</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 92,
    parent_id: 30,
    name: 'Garfish',
    render: '<strong>Garfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 93,
    parent_id: 30,
    name: 'Tilapia',
    render: '<strong>Tilapia</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 94,
    parent_id: 30,
    name: 'Yellowtail Kingfish',
    render: '<strong>Yellowtail Kingfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 95,
    parent_id: 30,
    name: 'Whelks',
    render: '<strong>Whelks</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 96,
    parent_id: 30,
    name: 'Whitebait',
    render: '<strong>Whitebait</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 97,
    parent_id: 30,
    name: 'Swordfish',
    render: '<strong>Swordfish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 98,
    parent_id: 30,
    name: 'Shark',
    render: '<strong>Shark</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 99,
    parent_id: 30,
    name: 'Sea Urchins',
    render: '<strong>Sea Urchins</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 100,
    parent_id: 30,
    name: 'Whiting',
    render: '<strong>Whiting</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 101,
    parent_id: 30,
    name: 'Winkles',
    render: '<strong>Winkles</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 102,
    parent_id: 30,
    name: 'Flounder',
    render: '<strong>Flounder</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 103,
    parent_id: 30,
    name: 'Witch Sole',
    render: '<strong>Witch Sole</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 104,
    parent_id: 30,
    name: 'Wolf Fish',
    render: '<strong>Wolf Fish</strong>',
    parent_render: '(<strong>Fish</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 105,
    parent_id: null,
    name: 'Egg',
    render: '(<strong>Egg</strong>)',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 106,
    parent_id: 105,
    name: 'Eggs',
    render: '<strong>Eggs</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 107,
    parent_id: 105,
    name: 'Egg',
    render: '<strong>Egg</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 108,
    parent_id: 105,
    name: 'Albumin',
    render: '<strong>Albumin</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 109,
    parent_id: 105,
    name: 'Lysozyme',
    render: '<strong>Lysozyme (Egg)</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 110,
    parent_id: 105,
    name: 'Yolk',
    render: '<strong>Yolk</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 111,
    parent_id: 105,
    name: 'Lecithin',
    render: '<strong>Lecithin</strong>',
    parent_render: '(<strong>Egg</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 112,
    parent_id: null,
    name: 'Soya',
    render: '<strong>Soya</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 113,
    parent_id: 112,
    name: 'Soy',
    render: '<strong>Soy</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 114,
    parent_id: 112,
    name: 'Soya',
    render: '<strong>Soya</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 115,
    parent_id: 112,
    name: 'Soysauce',
    render: '<strong>Soysauce</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 116,
    parent_id: 112,
    name: 'Soya Sauce',
    render: '<strong>Soya</strong> Sauce',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 117,
    parent_id: 112,
    name: 'Soybeans',
    render: '<strong>Soybeans</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 118,
    parent_id: 112,
    name: 'Soyabeans',
    render: '<strong>Soyabeans</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 119,
    parent_id: 112,
    name: 'Soya beans',
    render: '<strong>Soya</strong> beans',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 120,
    parent_id: 112,
    name: 'Soya bean',
    render: '<strong>Soya</strong> bean',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 121,
    parent_id: 112,
    name: 'Soy bean',
    render: '<strong>Soy</strong> bean',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 122,
    parent_id: 112,
    name: 'Soy beans',
    render: '<strong>Soy</strong> beans',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 123,
    parent_id: 112,
    name: 'Edamame',
    render: '<strong>Edamame</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 124,
    parent_id: 112,
    name: 'Edamame beans',
    render: '<strong>Edamame beans</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 125,
    parent_id: 112,
    name: 'Tofu',
    render: '<strong>Tofu</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 126,
    parent_id: 112,
    name: 'Lecithin',
    render: '<strong>Lecithin</strong>',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 127,
    parent_id: null,
    name: 'Milk',
    render: '<strong>Milk</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 128,
    parent_id: 127,
    name: 'Cream',
    render: '<strong>Cream</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 129,
    parent_id: 127,
    name: 'Butter',
    render: '<strong>Butter</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 130,
    parent_id: 127,
    name: 'Lactose',
    render: '<strong>Lactose</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 131,
    parent_id: 127,
    name: 'Lactulose',
    render: '<strong>Lactulose</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 132,
    parent_id: 127,
    name: 'Lactalbumin',
    render: '<strong>Lactalbumin</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 133,
    parent_id: 127,
    name: 'Lactoferrin',
    render: '<strong>Lactoferrin</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 134,
    parent_id: 127,
    name: 'Lactoglobulin',
    render: '<strong>Lactoglobulin</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 135,
    parent_id: 127,
    name: 'Lactoglobulin',
    render: '<strong>Lactoglobulin</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 136,
    parent_id: 127,
    name: 'Cheese',
    render: '<strong>Cheese</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 137,
    parent_id: 127,
    name: 'Casein',
    render: '<strong>Casein</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 138,
    parent_id: 127,
    name: 'Caseinates',
    render: '<strong>Caseinates</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 139,
    parent_id: 127,
    name: 'Caseinate',
    render: '<strong>Caseinate</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 140,
    parent_id: 127,
    name: 'Whey',
    render: '<strong>Whey</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 141,
    parent_id: 127,
    name: 'Yogurt',
    render: '<strong>Yogurt</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 142,
    parent_id: 127,
    name: 'Amasi',
    render: '<strong>Amasi</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 143,
    parent_id: 127,
    name: 'Recalden',
    render: '<strong>Recalden</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 144,
    parent_id: 127,
    name: 'Rennet',
    render: '<strong>Rennet</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 145,
    parent_id: 127,
    name: 'Paneer',
    render: '<strong>Paneer</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 146,
    parent_id: 127,
    name: 'Curd',
    render: '<strong>Curd</strong>',
    parent_render: '(<strong>Milk</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 147,
    parent_id: 1,
    name: 'Almond Milk',
    render: '<strong>Almond</strong> Milk',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 148,
    parent_id: null,
    name: 'Coconut Milk',
    render: 'Coconut Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 149,
    parent_id: 1,
    name: 'Cashew Milk',
    render: '<strong>Cashew</strong> Milk',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 150,
    parent_id: 1,
    name: 'Pistachio Milk',
    render: '<strong>Pistachio</strong> Milk',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 151,
    parent_id: 1,
    name: 'Pistachios Milk',
    render: '<strong>Pistachios</strong> Milk',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 152,
    parent_id: 112,
    name: 'Soya Milk',
    render: '<strong>Soya</strong> Milk',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 153,
    parent_id: 112,
    name: 'Soy Milk',
    render: '<strong>Soy</strong> Milk',
    parent_render: '(<strong>Soya</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 154,
    parent_id: null,
    name: 'Rice Milk',
    render: 'Rice Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 155,
    parent_id: null,
    name: 'Flax Milk',
    render: 'Flax Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 156,
    parent_id: null,
    name: 'Hemp Milk',
    render: 'Hemp Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 157,
    parent_id: 221,
    name: 'Oat Milk',
    render: '<strong>Oat</strong> Milk',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 158,
    parent_id: 221,
    name: 'Spelt milk',
    render: '<strong>Spelt</strong> Milk',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 159,
    parent_id: null,
    name: 'Hazelnut Milk',
    render: '<strong>Hazelnut</strong> Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 160,
    parent_id: 112,
    name: 'Macadamia Milk',
    render: '<strong>Macadamia</strong> Milk',
    parent_render: '(<strong>Nuts</strong>)',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 161,
    parent_id: null,
    name: 'Peanut Milk',
    render: '<strong>Peanut</strong> Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 162,
    parent_id: null,
    name: 'Peanuts Milk',
    render: '<strong>Peanuts</strong> Milk',
    parent_render: '',
    is_exception: 1,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 163,
    parent_id: null,
    name: 'Celery',
    render: '<strong>Celery</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 164,
    parent_id: 163,
    name: 'Celery',
    render: '<strong>Celery</strong>',
    parent_render: '(<strong>Celery</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 165,
    parent_id: 163,
    name: 'Celeriac',
    render: '<strong>Celeriac</strong>',
    parent_render: '(<strong>Celery</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 166,
    parent_id: null,
    name: 'Mustard',
    render: '<strong>Mustard</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 167,
    parent_id: 166,
    name: 'Mustard',
    render: '<strong>Mustard</strong>',
    parent_render: '(<strong>Mustard</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 168,
    parent_id: null,
    name: 'Sesame',
    render: '<strong>Sesame</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 169,
    parent_id: 168,
    name: 'Tahini',
    render: '<strong>Tahini</strong>',
    parent_render: '(<strong>Sesame</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 170,
    parent_id: 168,
    name: 'Sesame',
    render: '<strong>Sesame</strong>',
    parent_render: '(<strong>Sesame</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 171,
    parent_id: null,
    name: 'Sulphur Dioxide',
    render: '<strong>Sulphur Dioxide</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 172,
    parent_id: 171,
    name: 'Sulphur dioxide',
    render: '<strong>Sulphur dioxide</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 173,
    parent_id: 171,
    name: 'SO2',
    render: '<strong>SO2</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 174,
    parent_id: 171,
    name: 'Lemon Juice',
    render: '<strong>Lemon Juice</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 175,
    parent_id: 171,
    name: 'Potassium metabisulphite',
    render: '<strong>Potassium metabisulphite</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 176,
    parent_id: 171,
    name: 'E220',
    render: '<strong>E220</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 177,
    parent_id: 171,
    name: 'E221',
    render: '<strong>E221</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 178,
    parent_id: 171,
    name: 'Sodium sulphite',
    render: '<strong>Sodium sulphite</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 179,
    parent_id: 171,
    name: 'E222',
    render: '<strong>E222</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 180,
    parent_id: 171,
    name: 'E223',
    render: '<strong>E223</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 181,
    parent_id: 171,
    name: 'Sodium metabisulphite',
    render: '<strong>Sodium metabisulphite</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 182,
    parent_id: 171,
    name: 'E224',
    render: '<strong>E224</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 183,
    parent_id: 171,
    name: 'E225',
    render: '<strong>E225</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 184,
    parent_id: 171,
    name: 'E226',
    render: '<strong>E226</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 185,
    parent_id: 171,
    name: 'E227',
    render: '<strong>E227</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 186,
    parent_id: 171,
    name: 'E228',
    render: '<strong>E228</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 187,
    parent_id: 171,
    name: 'Sulphites',
    render: '<strong>Sulphites</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 188,
    parent_id: 171,
    name: 'Sulphite',
    render: '<strong>Sulphite</strong>',
    parent_render: '(<strong>Sulphur Dioxide</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 189,
    parent_id: null,
    name: 'Crustaceans',
    render: '<strong>Crustaceans</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 190,
    parent_id: 189,
    name: 'Prawns',
    render: '<strong>Prawns</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 191,
    parent_id: 189,
    name: 'Prawn',
    render: '<strong>Prawn</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 192,
    parent_id: 189,
    name: 'Crab',
    render: '<strong>Crab</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 193,
    parent_id: 189,
    name: 'Lobster',
    render: '<strong>Lobster</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 194,
    parent_id: 189,
    name: 'Crayfish',
    render: '<strong>Crayfish</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 195,
    parent_id: 189,
    name: 'Ebi',
    render: '<strong>Ebi</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 196,
    parent_id: 189,
    name: 'Langoustine',
    render: '<strong>Langoustine</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 197,
    parent_id: 189,
    name: 'Langoustines',
    render: '<strong>Langoustines</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 198,
    parent_id: 189,
    name: 'Shrimp',
    render: '<strong>Shrimp</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 199,
    parent_id: 189,
    name: 'Shrimps',
    render: '<strong>Shrimps</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 200,
    parent_id: 189,
    name: 'Cockles',
    render: '<strong>Cockles</strong>',
    parent_render: '(<strong>Crustaceans</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 201,
    parent_id: null,
    name: 'Molluscs',
    render: '<strong>Molluscs</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 202,
    parent_id: 201,
    name: 'Mussels',
    render: '<strong>Mussels</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 203,
    parent_id: 201,
    name: 'Mussel',
    render: '<strong>Mussel</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 204,
    parent_id: 201,
    name: 'Whelks',
    render: '<strong>Whelks</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 205,
    parent_id: 201,
    name: 'Oyster',
    render: '<strong>Oyster</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 206,
    parent_id: 201,
    name: 'Oysters',
    render: '<strong>Oysters</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 207,
    parent_id: 201,
    name: 'Snail',
    render: '<strong>Snail</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 208,
    parent_id: 201,
    name: 'Snails',
    render: '<strong>Snails</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 209,
    parent_id: 201,
    name: 'Octopus',
    render: '<strong>Octopus</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 210,
    parent_id: 201,
    name: 'Squid',
    render: '<strong>Squid</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 211,
    parent_id: 201,
    name: 'Scallops',
    render: '<strong>Scallops</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 212,
    parent_id: 201,
    name: 'Scallop',
    render: '<strong>Scallop</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 213,
    parent_id: 201,
    name: 'Shellfish',
    render: '<strong>Shellfish</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 214,
    parent_id: 201,
    name: 'Calamari',
    render: '<strong>Calamari</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 215,
    parent_id: 201,
    name: 'Ink fish',
    render: '<strong>Ink fish</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 216,
    parent_id: 201,
    name: 'Cuttlefish',
    render: '<strong>Cuttlefish</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 217,
    parent_id: 201,
    name: 'Clams',
    render: '<strong>Clams</strong>',
    parent_render: '(<strong>Molluscs</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 218,
    parent_id: null,
    name: 'Lupins',
    render: '<strong>Lupins</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 219,
    parent_id: 218,
    name: 'Lupin',
    render: '<strong>Lupin</strong>',
    parent_render: '(<strong>Lupins</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 220,
    parent_id: 218,
    name: 'Lupins',
    render: '<strong>Lupins</strong>',
    parent_render: '(<strong>Lupins</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 221,
    parent_id: null,
    name: 'Gluten',
    render: '<strong>Gluten</strong>',
    parent_render: '',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 222,
    parent_id: 221,
    name: 'Wheat',
    render: '<strong>Wheat</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 223,
    parent_id: 221,
    name: 'Rye',
    render: '<strong>Rye</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 224,
    parent_id: 221,
    name: 'Barley',
    render: '<strong>Barley</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 225,
    parent_id: 221,
    name: 'Oat',
    render: '<strong>Oat</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 226,
    parent_id: 221,
    name: 'Oats',
    render: '<strong>Oats</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 227,
    parent_id: 221,
    name: 'Spelt',
    render: '<strong>Spelt</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 228,
    parent_id: 221,
    name: 'Bulgur',
    render: '<strong>Bulgur</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 229,
    parent_id: 221,
    name: 'Couscous',
    render: '<strong>Couscous</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 230,
    parent_id: 221,
    name: 'Durum',
    render: '<strong>Durum</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 231,
    parent_id: 221,
    name: 'Khorasan',
    render: '<strong>Khorasan</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 232,
    parent_id: 221,
    name: 'Kamut',
    render: '<strong>Kamut</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
  {
    id: 233,
    parent_id: 221,
    name: 'Gluten',
    render: '<strong>Gluten</strong>',
    parent_render: '(<strong>Gluten</strong>)',
    is_exception: 0,
    is_trashed: 0,
    cardbox_status: 3,
  },
];

export { treeRow, treeCarouselItems, allergenList, function_of_additivesList };
