import { useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import authHooks from '@hooks/api/auth';
import { ResetPasswordParams } from '@hooks/api/auth/types';

import { TextField, Button } from '@components/Common';

import Logo from '@components/Auth/Logo';

import { useUrlParams } from '@hooks/utils';

import RouteMap from '@routes/map';

type ResetPasswordRequiredInputs = Pick<
  ResetPasswordParams,
  'password' | 'password_confirmation'
>;

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const history = useHistory();
  const [urlParams] = useUrlParams<{ token: string; email: string }>();

  const resetPasswordMutation = authHooks.useResetPasswordMutation();

  const initialValues: ResetPasswordRequiredInputs = {
    password: '',
    password_confirmation: '',
  };

  const form = useForm<ResetPasswordRequiredInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<ResetPasswordRequiredInputs> = async data => {
    urlParams.email = urlParams.email.replace(' ', '+');

    try {
      await resetPasswordMutation.mutateAsync({ ...data, ...urlParams });

      history.replace(RouteMap.auth.login);
    } catch {}
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="min-h-screen flex flex-col items-center justify-between bg-white-600"
    >
      <Logo className="mt-24" />

      <h3 className="font-roboto mt-16 text-2xl text-center">
        Welcome to SpecReady
      </h3>

      <div className="flex flex-col justify-center items-center px-8 py-6 w-1/3">
        <Controller
          name="password"
          control={form.control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              type="password"
              className="w-full h-10 border my-5"
              placeholder="Password"
              variant="outlined"
              label="Password"
              id="password"
              innerRef={ref}
              error={!!fieldState?.error?.message}
              helperText={fieldState?.error?.message || ''}
              {...rest}
            />
          )}
        />

        <Controller
          name="password_confirmation"
          control={form.control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              type="password"
              className="w-full h-10 border my-5"
              placeholder="Password Confirmation"
              variant="outlined"
              label="Password Confirmation"
              id="password"
              innerRef={ref}
              error={!!fieldState?.error?.message}
              helperText={fieldState?.error?.message || ''}
              {...rest}
            />
          )}
        />

        <Button
          type="submit"
          className="bg-green mt-5 mb-4 w-60"
          text="Submit"
        />
      </div>

      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div>
    </form>
  );
};

export default ResetPassword;
