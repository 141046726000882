import { AxiosResponse } from 'axios';

import axios from '@lib/api';
import {
  UseCountriesType,
  UseStatusTypes,
  UploadFileParams,
  ShareSAQParams,
  ShareSAQResponse,
  GetCustomerProfileResponse,
  UpdateCustomerProfileParams,
  UseAccessLevelsResponseType,
  GetAccessLevelsParams,
  GetSettingsParams,
  UseSettingsResponseType,
  UseLicenseTypes,
  GetLicenseTypesParams,
} from '@hooks/api/general/types';

const getCountries = async () => {
  const res: AxiosResponse<UseCountriesType> = await axios({
    method: 'get',
    url: '/geo/countries',
  });

  return res.data;
};

const getSettings = async (params: GetSettingsParams) => {
  const res: AxiosResponse<UseSettingsResponseType> = await axios({
    method: 'get',
    url: '/settings',
    params,
  });

  return res.data;
};

const getAllStatus = async () => {
  const res: AxiosResponse<UseStatusTypes> = await axios({
    method: 'get',
    url: '/settings/statuses',
  });

  return res.data;
};

const getLicenseType = async (params: GetLicenseTypesParams) => {
  const res: AxiosResponse<UseLicenseTypes> = await axios({
    method: 'get',
    url: '/settings/license_type',
    params,
  });

  return res.data;
};

const uploadFile = async (params: UploadFileParams) => {
  const { url, file } = params;

  const formData = new FormData();
  formData.append('file', file);

  const res: AxiosResponse<{ data: { file: string } }> = await axios({
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    url,
    data: formData,
  });

  return res.data.data.file;
};

const getSAQ = async () => {
  const res: AxiosResponse<ShareSAQResponse> = await axios({
    method: 'get',
    url: '/settings/saq',
  });

  return res.data;
};

const shareSAQ = async (params: ShareSAQParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: '/settings/shares/saq',
    params,
  });

  return res.data;
};

const getCustomerProfile = async () => {
  const res: AxiosResponse<GetCustomerProfileResponse> = await axios({
    method: 'get',
    url: '/customer/profile',
  });

  return res.data;
};

const updateCustomerProfile = async (data: UpdateCustomerProfileParams) => {
  const res: AxiosResponse<GetCustomerProfileResponse> = await axios({
    method: 'put',
    url: '/customer/profile',
    data,
  });

  return res.data;
};

const getAccessLevels = async (params: GetAccessLevelsParams) => {
  const res: AxiosResponse<UseAccessLevelsResponseType> = await axios({
    method: 'get',
    url: `/user/license_type/${params?.code}/access_level`,
  });

  return res.data;
};

export default {
  getCountries,
  getAllStatus,
  getLicenseType,
  uploadFile,
  getSAQ,
  shareSAQ,
  getCustomerProfile,
  updateCustomerProfile,
  getAccessLevels,
  getSettings,
};
