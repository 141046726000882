import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import subscriptionApis from '@hooks/api/subscription/api';

import { UseSubscriptionType } from '@hooks/api/subscription/types';

const useSubscription = () => {
  const query = useQuery<UseSubscriptionType, AxiosError>(
    ['subscription'],
    subscriptionApis.getProducts,
  );

  return query;
};

export default {
  useSubscription,
};
