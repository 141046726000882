/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  IconButton,
  Collapse,
  Link,
  SvgIconTypeMap,
  Button,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Menu,
  Dashboard,
  LocalShipping,
  Label,
  LocalActivity,
  Policy,
  SettingsApplications,
  KeyboardArrowDown,
  KeyboardArrowLeft,
} from '@material-ui/icons';

import config from '@constants/config';
import { useSidebarState, useUser } from '@contexts';

import { map } from '@routes';

import { Logo } from '@components/Common';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const SIDEBAR_ITEM_HEIGHT = 48;
const SIDEBAR_LOGO_HEIGHT = 64;
const SIDEBAR_ITEM_VERTIVAL_MARGIN = 4;

type BaseSubMenuItem = {
  text: string;
  link: string;
  expanded: boolean;
  access: boolean;
};

type BaseSideBarItem = {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  text: string;
  access: boolean;
  link?: string;
  expanded?: boolean;
  active?: boolean;
  subMenuItems?: Array<{
    text: string;
    access: boolean;
    subMenuItems?: Array<BaseSubMenuItem>;
    link?: string;
    expanded?: boolean;
  }>;
};

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: theme.drawerWidth,
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    overflow: 'overlay',
  },
  drawerOpen: {
    width: theme.drawerWidth,
  },
  drawerClose: {
    width: theme.closedDrawerWidth,
  },
  collapse: {
    '&.MuiCollapse-entered': {
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: -(SIDEBAR_ITEM_HEIGHT / 2 + SIDEBAR_ITEM_VERTIVAL_MARGIN),
        left: 0,
        transform: 'translateY(-50%)',

        borderRadius: '0px 3px 3px 0px',

        height: theme.spacing(6),
        width: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  sideBarLogo: {
    minHeight: SIDEBAR_LOGO_HEIGHT,
    height: SIDEBAR_LOGO_HEIGHT,
  },
  sidebarItem: {
    minHeight: SIDEBAR_ITEM_HEIGHT,
    height: SIDEBAR_ITEM_HEIGHT,
    marginTop: SIDEBAR_ITEM_VERTIVAL_MARGIN,
    marginBottom: SIDEBAR_ITEM_VERTIVAL_MARGIN,
  },
}));

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useSidebarState();
  const location = useLocation();
  const classes = useStyles();
  const [user, , logout] = useUser();

  const sidebarItems: Array<BaseSideBarItem> = React.useMemo(
    () => [
      {
        icon: Dashboard,
        text: 'Dashboard',
        access: user?.access_levels.dashboard_index || false,
        link: map.dashboard,
        active: location.pathname === map.dashboard,
      },
      {
        icon: LocalShipping,
        text: 'Suppliers',
        access:
          !user?.access_levels.is_supplier ||
          user?.access_levels.manufacture_index_single
            ? true
            : false,
        subMenuItems: [
          {
            text: 'Suppliers List',
            access: !user?.access_levels.is_supplier || false,
            link: map.suppliers.suppliersList,
            expanded: location.pathname.includes(map.suppliers.suppliersList),
          },
          {
            text: 'Manufacturers',
            access: user?.access_levels.manufacture_index_single || false,
            link: map.suppliers.manufacturersList,
            expanded: location.pathname.includes(
              map.suppliers.manufacturersList,
            ),
          },
        ],
      },
      {
        icon: Label,
        text: 'Products',
        access: user?.access_levels.product_index_single || false,
        link: map.bussines.products,
        active: location.pathname.includes(map.bussines.products),
      },
      {
        icon: LocalActivity,
        text: 'Certificates',
        access: user?.access_levels.certificate_index_single || false,
        link: map.certificates.certificatesList,
        active: location.pathname.includes(map.certificates.certificatesList),
      },
      {
        icon: Policy,
        text: 'Policies',
        access: user?.access_levels.policy_index_single || false,
        link: map.policies,
        active: location.pathname.includes(map.policies),
      },
      {
        icon: SettingsApplications,
        text: 'Configurations',
        access: true,
        subMenuItems: [
          {
            text: 'Company Profile',
            access: user?.access_levels.company_profile || false,
            link: map.configurations.companyProfile,
            expanded: location.pathname.includes(
              map.configurations.companyProfile,
            ),
          },
          {
            text: 'Users',
            access:
              user?.access_levels.user_index_single_customer ||
              user?.access_levels.user_index_single_supplier
                ? true
                : false,
            link: map.configurations.users.usersList,
            expanded: location.pathname.includes(
              map.configurations.users.usersList,
            ),
          },
          {
            text: 'Subscription',
            access: user?.access_levels.subscription_index || false,
            link: map.configurations.subscription,
            expanded: location.pathname.includes(
              map.configurations.subscription,
            ),
          },
          {
            text: 'Security',
            access:
              user?.access_levels.public_link_index_delete ||
              user?.access_levels.audit_log_index_show
                ? true
                : false,

            subMenuItems: [
              {
                text: 'Shared Links',
                access: user?.access_levels.public_link_index_delete || false,
                link: map.configurations.security.sharedLinks,
                expanded: location.pathname.includes(
                  map.configurations.security.sharedLinks,
                ),
              },
              {
                text: 'Activity Logs',
                access: user?.access_levels.audit_log_index_show || false,
                link: map.configurations.security.activityLogs,
                expanded: location.pathname.includes(
                  map.configurations.security.activityLogs,
                ),
              },
            ],
          },
          {
            text: 'Recycle Bin',
            access:
              user?.access_levels.certificate_trash_restore ||
              user?.access_levels.policy_trash_restore ||
              user?.access_levels.product_trash_restore ||
              user?.access_levels.user_trash_restore_supplier ||
              user?.access_levels.user_trash_restore_customer
                ? true
                : false,
            link: map.configurations.recyclebin,
            expanded: location.pathname.includes(map.configurations.recyclebin),
          },
        ],
      },
    ],
    [location.pathname],
  );

  const [expandedMenu, setExpandedMenu] = React.useState('');
  const [expandedSubMenu, setExpandedSubMenu] = React.useState('');

  React.useEffect(() => {
    const expandedSidebarItem = sidebarItems.find(el => {
      if (typeof el?.expanded === 'boolean') return !!el?.expanded;

      return !!el?.subMenuItems?.find(el2 => {
        if (typeof el2?.expanded === 'boolean') return !!el2?.expanded;

        return !!el2.subMenuItems?.find(el3 => !!el3?.expanded);
      });
    });

    if (
      expandedSidebarItem &&
      'subMenuItems' in expandedSidebarItem &&
      expandedSidebarItem?.subMenuItems?.length
    ) {
      const expandedSubMenuItem = (
        expandedSidebarItem?.subMenuItems || []
      )?.find(el => {
        if (typeof el?.expanded === 'boolean') return !!el?.expanded;

        return !!el.subMenuItems?.find(el2 => !!el2?.expanded);
      });

      if (expandedSubMenuItem) setExpandedSubMenu(expandedSubMenuItem?.text);
    }

    if (expandedSidebarItem) setExpandedMenu(expandedSidebarItem?.text);
  }, [sidebarItems, location.pathname]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, 'overflow-x-hidden', {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen,
      })}
      classes={{
        paper: clsx(
          'transition-all border-0 overflow-x-hidden bg-primary pb-3',
          {
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          },
        ),
      }}
    >
      <div
        className={clsx(
          'flex flex-row justify-between items-center pr-3 mb-2',
          classes.sideBarLogo,
          isSidebarOpen ? 'pl-8' : 'pl-4',
        )}
      >
        <div className="flex flex-row items-center">
          <Logo className="h-20 w-96" color="black" />
        </div>

        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <Menu fontSize="small" className="text-white-600" />
        </IconButton>
      </div>

      {sidebarItems.map(el => (
        <>
          {el.access && (
            <React.Fragment key={el.text}>
              <Button
                key={el.text}
                onClick={() =>
                  setExpandedMenu(expandedMenu === el.text ? '' : el.text)
                }
                className={clsx(
                  'flex flex-row justify-between items-center pr-3 font-normal font-roboto',
                  classes.sidebarItem,
                  isSidebarOpen ? 'pl-8' : 'pl-4',
                )}
              >
                <Link
                  key={el.text}
                  href={el.link}
                  className="flex flex-row items-center"
                >
                  <el.icon
                    onClick={e => e.preventDefault()}
                    className={clsx(
                      'transition-all',
                      el.active ? 'text-red-dark' : 'text-white-400',
                      isSidebarOpen ? 'text-2xl' : 'text-3xl',
                    )}
                  />

                  <h3
                    className={clsx(
                      'text-lg',
                      isSidebarOpen ? 'pl-4' : 'pl-8',
                      el.active ? 'text-red-dark' : 'text-white-600',
                    )}
                  >
                    {el.text}
                  </h3>
                </Link>

                {el.subMenuItems && el.subMenuItems.length > 0 ? (
                  <IconButton
                    onClick={() =>
                      setExpandedMenu(expandedMenu === el.text ? '' : el.text)
                    }
                  >
                    {expandedMenu === el.text ? (
                      <KeyboardArrowDown
                        fontSize="small"
                        className="text-white-600"
                      />
                    ) : (
                      <KeyboardArrowLeft
                        fontSize="small"
                        className="text-white-600"
                      />
                    )}
                  </IconButton>
                ) : null}
              </Button>

              <Collapse
                classes={{ root: classes.collapse }}
                in={isSidebarOpen && el?.text === expandedMenu}
                timeout="auto"
                unmountOnExit
              >
                <div className="flex flex-col pl-10 pr-4">
                  {'subMenuItems' in el
                    ? el?.subMenuItems?.map(el2 => (
                        <>
                          {el2.access && (
                            <React.Fragment key={el2.text}>
                              <Button
                                onClick={() =>
                                  setExpandedSubMenu(
                                    expandedSubMenu === el2.text
                                      ? ''
                                      : el2.text,
                                  )
                                }
                                key={el.text}
                                className={clsx(
                                  'flex flex-row justify-between items-center my-0 py-0',
                                  'font-normal font-roboto',
                                )}
                              >
                                <Link
                                  key={el2.text}
                                  href={el2?.link}
                                  className={clsx(
                                    'py-3 text-white-600 rounded-md font-roboto text-sm h-10 my-1',
                                    el2.expanded && 'text-red-dark',
                                  )}
                                >
                                  {el2.text}
                                </Link>

                                {'subMenuItems' in el2 ? (
                                  <IconButton
                                    onClick={() =>
                                      setExpandedSubMenu(
                                        expandedSubMenu === el2.text
                                          ? ''
                                          : el2.text,
                                      )
                                    }
                                  >
                                    {expandedSubMenu === el2.text ? (
                                      <KeyboardArrowDown
                                        fontSize="small"
                                        className="text-white-600"
                                      />
                                    ) : (
                                      <KeyboardArrowLeft
                                        fontSize="small"
                                        className="text-white-600"
                                      />
                                    )}
                                  </IconButton>
                                ) : null}
                              </Button>

                              <Collapse
                                in={
                                  isSidebarOpen && expandedSubMenu === el2.text
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <div className="flex flex-col pr-4">
                                  {'subMenuItems' in el2
                                    ? (el2?.subMenuItems || [])?.map(el3 => (
                                        <>
                                          {el3.access && (
                                            <div
                                              key={el3.text}
                                              className={clsx(
                                                'flex flex-row justify-between items-center',
                                              )}
                                            >
                                              <Link
                                                key={el3.text}
                                                href={el3.link}
                                                className={clsx(
                                                  'py-3 pl-4 text-white-600 rounded-md font-roboto text-sm h-10 my-1',
                                                  el3.expanded &&
                                                    'text-red-dark',
                                                )}
                                              >
                                                {el3.text}
                                              </Link>
                                            </div>
                                          )}
                                        </>
                                      ))
                                    : null}
                                </div>
                              </Collapse>
                            </React.Fragment>
                          )}
                        </>
                      ))
                    : null}
                </div>
              </Collapse>
            </React.Fragment>
          )}
        </>
      ))}
    </Drawer>
  );
};

export default Sidebar;
