import React, { useState } from 'react';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';

export type Item = {
  name: string;
  code: number;
};

interface P {
  id?: string;
  items: Array<Item>;
  className?: string;
  inputRootClassName?: string;
  inputPlaceholder?: string;
  value: Item | null;
  onChange: (val: Item | null) => void;
  disabled?: boolean;
  freeSolo?: boolean;
  canAddCustomItem?: boolean;
  options?: never;
  renderInput?: never;
  inputProps?: never;
  dense?: boolean;
}

type StyleRelatedProps = Pick<P, 'dense' | 'disabled'>;

const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 0 : 1),

    '& .MuiInputBase-root': {
      backgroundColor: ({ disabled }: { disabled?: boolean }) =>
        disabled ? theme.palette.common.white : theme.palette.gray.light,

      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
  popper: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.light,
    },

    '& .MuiAutocomplete-noOptions': {
      color: theme.palette.primary.light,
    },
  },
  autoComplete: {
    backgroundColor: theme.palette.common.white,
    height: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 3 : 5),

    '& .MuiAutocomplete-inputRoot': {
      height: '100%',
    },

    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,

    '& input': {
      padding: `${theme.spacing(0, 1)} !important`,

      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',

      '&::placeholder': {
        color: theme.palette.gray.main,
      },
    },
  },
}));

const filter = createFilterOptions<Item>();

type CustomAutoComplete = AutocompleteProps<
  Item,
  undefined,
  undefined,
  undefined
>;

const Autocomplete: React.FC<P & Omit<CustomAutoComplete, keyof P>> = ({
  id,
  className,
  inputRootClassName,
  inputPlaceholder = 'Select...',
  items,
  value,
  onChange,
  disabled,
  freeSolo = true,
  canAddCustomItem = true,
  options,
  renderInput,
  dense = false,
  ...rest
}) => {
  const classes = useStyles({ disabled, dense });

  const [localValue, setLocalValue] = React.useState('');

  return (
    <MuiAutocomplete
      disabled={disabled}
      fullWidth
      size="small"
      forcePopupIcon
      getOptionSelected={(option, selectedVal) => {
        if (!option?.code || !selectedVal?.code) return false;

        return option?.code === selectedVal?.code;
      }}
      className={clsx(classes.autoComplete, className)}
      value={value !== undefined ? value : null} // this ensures that the component is always `controlled`
      onChange={(_, newValue, reason) => {
        if (reason === 'clear') onChange(null);
        if (newValue && typeof newValue === 'object') onChange(newValue);

        if (newValue && typeof newValue === 'string') {
          // sometimes when onblur happen the component
          // only gives the item `name` as the new value
          const itemCode = items.find(el => el.name === newValue)?.code;

          onChange({
            name: newValue,
            code: itemCode || itemCode === 0 ? itemCode : -1,
          });
        }
      }}
      classes={{
        popper: clsx(classes.popper),
      }}
      filterOptions={(options2, params) => {
        const filtered = filter(options2, params);

        const doesItemAlreadyExist = !!filtered.find(
          el => el.name.toLowerCase() === (localValue || '').toLowerCase(),
        );
        // Suggest the creation of a new value
        if (canAddCustomItem && localValue !== '' && !doesItemAlreadyExist) {
          filtered.push({
            name: `${localValue}`,
            code: -1,
          });
        }
        console.log('🚀 ~ file: AutoComplete.tsx ~ line 151 ~ params', {
          options2,
          params,
          filtered,
          doesItemAlreadyExist,
          localValue,
        });
        return filtered;
      }}
      selectOnFocus
      autoSelect
      handleHomeEndKeys
      options={items}
      getOptionLabel={option => option?.name || ''}
      // in order to hide the endAdornment Icon when autocomplete is disabled
      // free solo gets enabled when disabled is active
      freeSolo={disabled ? true : freeSolo}
      clearOnBlur={!freeSolo}
      renderInput={params => {
        return (
          <TextField
            {...params}
            classes={{
              root: clsx(classes.inputRoot, inputRootClassName),
            }}
            placeholder={inputPlaceholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              onChange: (e: any) => {
                (params.inputProps as any).onChange(e);
                setLocalValue(e.target.value);
              },
            }}
          />
        );
      }}
      {...rest}
    />
  );
};

export default Autocomplete;
