/* eslint-disable no-unneeded-ternary */
import React from 'react';
import get from 'lodash.get';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  useUrlParams,
  stringify,
  wizardHook as WizardHooks,
  suppliersHook as SupplierHooks,
  manufacturersHook as ManufacturerHooks,
} from '@hooks';

import { MainInfo } from '@hooks/api/wizard/types';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import { Input, AutoComplete } from '@components/Common';

import { map } from '@routes';
import { toast } from 'react-toastify';

type FieldRefType = {
  displayText: string;
  key: keyof MainInfo;
  halfWidth?: boolean;
  disabled?: boolean;
};

const productDetailsFields: Readonly<Array<Readonly<FieldRefType>>> = [
  {
    displayText: 'Product Code',
    key: 'item_code',
    halfWidth: true,
    disabled: true,
  },
  {
    displayText: 'Supplier Product Code',
    key: 'supplier_product_code',
  },
  {
    displayText: 'Product Title',
    key: 'name',
    disabled: true,
  },
  {
    displayText: 'Product Description',
    key: 'description',
  },
  {
    displayText: 'Legal Product Name',
    key: 'legal_name',
  },
  {
    displayText: 'Tariff Number',
    key: 'tariff_number',
  },
  {
    displayText: 'Unit Barcode',
    key: 'unit_barcode',
    halfWidth: true,
  },
  {
    displayText: 'Case Barcode',
    key: 'case_barcode',
    halfWidth: true,
  },
];

const supplierDetailsFields = [
  {
    displayText: 'Type of Business',
    key: 'supplier_type.name',
  },
  {
    displayText: 'Supplier Name',
    key: 'name',
  },
  {
    displayText: 'Country',
    key: 'country.name',
  },
  {
    displayText: 'City',
    key: 'city',
  },
  {
    displayText: 'Address',
    key: 'address',
  },
  {
    displayText: 'Emergency Contact Name',
    key: 'emergency_contact_name',
  },
  {
    displayText: 'Emergency Contact Number',
    key: 'emergency_contact_phone',
  },
  {
    displayText: 'Emergency Contact Email',
    key: 'emergency_contact_email',
  },
] as const;

const manufacturerDetailsFields = [
  {
    displayText: 'Site Name',
    key: 'site_name',
  },
  {
    displayText: 'Country of Manufacturing',
    key: 'country.name',
  },
  {
    displayText: 'City of Manufacturing',
    key: 'city',
  },
  {
    displayText: 'Site Address',
    key: 'site_address',
  },
  {
    displayText: 'Emergency Contact Name',
    key: 'emergency_contact_name',
  },
  {
    displayText: 'Emergency Contact Number',
    key: 'emergency_contact_number',
  },
  {
    displayText: 'Emergency Contact Email',
    key: 'emergency_contact_email',
  },
  {
    displayText: 'Health Marks/ Identification Marks/ EU Approval number',
    key: 'health_mark',
  },
] as const;

const validationSchema = yup.object({
  description: yup.string().ensure().required('Description is required'),
  legal_name: yup.string().ensure().required('Legal Name is required'),
  supplier_product_code: yup
    .string()
    .ensure()
    .required('supplier_product_code is required'),
  manufacture: yup.mixed().test('error', 'Manufacturer is required', value => {
    if (value?.manufacture_code !== undefined) {
      if (value?.manufacture_code !== '' && value?.manufacture_code !== null) {
        return true;
      }
    }
    return false;
  }),
  // unit_barcode: yup.string().ensure().required('Unit Barcode is required'),
  // case_barcode: yup.string().ensure().required('Case Barcode is required'),
});

type P = {
  share?: boolean;
};
const MainInfoComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const history = useHistory();

  const [urlParams, , setUrlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const form = useForm<MainInfo>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const updateMainInfoMutation = WizardHooks.useUpdateMainInfoMutation();
  const validateMainInfoMutation = WizardHooks.useValidateMainInfoMutation();

  const { data: mainInfo, ...mainInfoUtils } = WizardHooks.useProductMainInfo(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        form.reset(data.data);
      },
    },
  );
  // const { data: supplier } = SupplierHooks.useSuppliers({
  //   paginated: false,
  // });

  // const { data: detailedSupplier, ...detailedSupplierUtils } =
  //   SupplierHooks.useDetailedSupplier(
  //     {
  //       code: form.getValues()?.supplier?.supplier_code || '',
  //     },
  //     {
  //       enabled: !!form.getValues()?.supplier?.supplier_code,
  //     },
  //   );
  const { data: manufacturers } = ManufacturerHooks.useManufacturers(
    {
      supplier_code: mainInfo?.data?.supplier?.supplier_code || '',
    },
    {
      enabled: !!mainInfo?.data?.supplier?.supplier_code,
    },
  );
  const { data: detailedManufacturer, ...detailedManufacturerUtils } =
    ManufacturerHooks.useDetailedManufacturer(
      {
        manufactureCode: form.getValues()?.manufacture?.manufacture_code || '',
      },
      {
        enabled: !!form.getValues()?.manufacture?.manufacture_code,
      },
    );

  const formatMainInfoData = (data: MainInfo) => {
    return {
      code: urlParams.code,
      version: urlParams.version,

      legal_name: data.legal_name,

      supplier_code: data.supplier?.supplier_code,
      manufacture_code: data.manufacture?.manufacture_code,

      item_code: data.item_code,
      supplier_product_code: data.supplier_product_code,
      description: data.description,
      tariff_number: data.tariff_number,
      unit_barcode: data.unit_barcode,
      case_barcode: data.case_barcode,
    };
  };

  const onValidate = async (data: MainInfo) => {
    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      const formattedData = formatMainInfoData(data);

      await validateMainInfoMutation.mutateAsync(formattedData);

      form.reset();

      // setUrlParams({ editMode: false });

      // history.push(
      //   `${RoutesMap.bussines.productForm.validation}${history.location.search}`,
      // );
    } catch (e) {}
  };

  const onSave = async (link: string, data: MainInfo) => {
    console.log('🚀 ~ file: index.tsx ~ line 274 ~ onSave ~ link', link);
    form.clearErrors();
    setErrorForm('valid');
    const formattedData = formatMainInfoData(data);

    updateMainInfoMutation.mutateAsync(formattedData, {
      onSuccess: () => {
        form.reset(undefined, {
          keepDirty: false,
        });
        if (link) {
          history.push(link);
        }
      },
    });
  };

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <div className="flex flex-row items-center bg-white mt-6 rounded shadow p-4">
        <img
          className="w-20 h-22 mr-24"
          src={mainInfo?.data.customer.logo || ''}
          alt="customer logo"
        />

        <div className="w-full flex flex-row justify-between items-between">
          <div className="flex flex-col">
            <h4 className="text-primary text-2xl mb-4">
              {mainInfo?.data.customer.name || ''}
            </h4>

            <p className="text-lg text-gray">
              {mainInfo?.data.customer.address || ''}
            </p>
          </div>

          <div className="grid grid-cols-2">
            <p className="col-span-1 text-gray">Version :</p>

            <p className="col-span-1 text-gray">
              {mainInfo?.data.version || ''}
            </p>

            <p className="col-span-1 text-gray">Creation Date :</p>

            <p className="col-span-1 text-gray">
              {mainInfo?.data.creation_date || ''}
            </p>

            <p className="col-span-1 text-gray">Approval Date :</p>

            <p className="col-span-1 text-gray">
              {mainInfo?.data.approve_date || ''}
            </p>

            <p className="col-span-1 text-gray">Revision Date :</p>

            <p className="col-span-1 text-gray">
              {mainInfo?.data.review_date || ''}
            </p>
          </div>
        </div>
      </div>

      <form className={errorForm}>
        <div className="grid grid-cols-4 gap-x-2 gap-y-1">
          <h3 className="col-span-4 font-roboto text-base text-primary-light mb-2 mt-7">
            Product Details
          </h3>

          {productDetailsFields.map(el => (
            <div
              key={el.displayText}
              className={clsx(
                'flex flex-col',
                el?.halfWidth ? 'col-span-2' : 'col-span-4',
              )}
            >
              <div className="flex flex-row items-center bg-white rounded shadow-sm">
                <div>
                  <p className="font-roboto pl-4 w-52">{el.displayText}</p>
                </div>

                <Controller
                  name={el.key}
                  control={form.control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <Input
                      error={
                        !!fieldState?.error && errorForm === 'inValid'
                          ? true
                          : false
                      }
                      loading={mainInfoUtils.isLoading}
                      disabled={!urlParams?.editMode || el.disabled}
                      className="flex-grow"
                      placeholder={el.displayText}
                      innerRef={ref}
                      {...rest}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-4 gap-x-2 gap-y-1">
          <h3 className="col-span-4 font-roboto text-base text-primary-light mb-2 mt-7">
            Supplier Details
          </h3>

          <div className="col-span-2 flex flex-col">
            <div className="flex flex-row items-center bg-white rounded shadow-sm">
              <div>
                <p className="font-roboto pl-4 w-52">Supplier</p>
              </div>

              <Controller
                name="supplier"
                control={form.control}
                render={({ field: { value } }) => (
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    disabled
                    disableClearable
                    id="supplier"
                    items={[
                      {
                        name: value?.name || '',
                        code: value?.supplier_code || '',
                      },
                    ]}
                    value={
                      value
                        ? { name: value.name, code: value.supplier_code }
                        : { code: '', name: '' }
                    }
                    onChange={newVal => {
                      form.setValue(
                        'supplier',
                        newVal
                          ? {
                              ...newVal,
                              supplier_code: String(newVal.code),
                            }
                          : value,
                        { shouldDirty: true },
                      );
                      form.setValue(
                        'manufacture',
                        {
                          site_name: '',
                          manufacture_code: '',
                        },
                        { shouldDirty: true },
                      );

                      form.reset(undefined, {
                        keepValues: true,
                        keepTouched: true,
                        keepDirty: true,
                        keepErrors: true,
                      });
                    }}
                  />
                )}
              />
            </div>
          </div>

          {supplierDetailsFields.map(el => (
            <div
              key={el.displayText}
              className="col-span-4 flex flex-row items-center bg-white rounded shadow-sm"
            >
              <div>
                <p className="font-roboto pl-4 w-52">{el.displayText}</p>
              </div>

              <Controller
                name={`supplier_details.${el.key}`}
                control={form.control}
                render={({ field: { value } }) => (
                  <Input
                    disabled
                    className="flex-grow"
                    value={value}
                    // value={detailedSupplier?.data[el.key]}
                    onChange={() => {}}
                    id={el.key}
                  />
                )}
              />
            </div>
          ))}
        </div>

        <div className="grid grid-cols-4 gap-x-2 gap-y-1">
          <h3 className="col-span-4 font-roboto text-base text-primary-light mb-2 mt-7">
            Manufacturer Details
          </h3>

          <div className="col-span-2 flex flex-col">
            <div className="flex flex-row items-center bg-white rounded shadow-sm">
              <div>
                <p className="font-roboto pl-4 w-52">Manufacturer</p>
              </div>

              <Controller
                name="manufacture"
                control={form.control}
                render={({ field: { value }, fieldState }) => (
                  <AutoComplete
                    error={!!fieldState?.error}
                    freeSolo={false}
                    canAddCustomItem={false}
                    disabled={!urlParams?.editMode}
                    disableClearable
                    id="manufacture"
                    items={
                      manufacturers?.data.map(el => ({
                        name: el.site_name,
                        code: el.manufacture_code,
                      })) || []
                    }
                    value={
                      value?.manufacture_code
                        ? {
                            name: value.site_name,
                            code: value.manufacture_code,
                          }
                        : {
                            name: '',
                            code: '',
                          }
                    }
                    onChange={newVal => {
                      form.setValue(
                        'manufacture',
                        newVal
                          ? {
                              site_name: newVal.name,
                              manufacture_code: String(newVal.code),
                            }
                          : value,
                        { shouldDirty: true },
                      );

                      form.reset(undefined, {
                        keepValues: true,
                        keepTouched: true,
                        keepDirty: true,
                        keepErrors: true,
                      });
                    }}
                  />
                )}
              />
            </div>
          </div>

          {manufacturerDetailsFields.map(el => (
            <div
              key={el.displayText}
              className="col-span-4 flex flex-row items-center bg-white rounded shadow-sm"
            >
              <div>
                <p className="font-roboto pl-4 w-52">{el.displayText}</p>
              </div>

              <Input
                loading={detailedManufacturerUtils.isLoading}
                disabled
                className="flex-grow"
                value={get(detailedManufacturer?.data, el.key)}
                // value={detailedManufacturer?.data[el.key]}
                onChange={() => {}}
                id={el.key}
                name={el.key}
                placeholder={el.displayText}
              />
            </div>
          ))}
        </div>

        <FormButtonGroup
          share={share}
          pageName="main_info"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default MainInfoComp;
