import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { useTable, useExpanded } from 'react-table';
import {
  Table as MiuTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import TableLoading from './TableLoading';

const useStyles = makeStyles(() => ({
  root: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  tablePagination: {
    '& .MuiToolbar-root': {
      '&> .MuiTablePagination-caption:nth-of-type(1) , .MuiTablePagination-selectRoot':
        {
          display: 'none',
        },
    },
  },
}));

const Table = ({
  columns,
  data,
  expanded = false,
  classes = {},
  className = '',
  headerRowClassName = '',
  headerCellClassName = '',
  bodyRowClassName = '',
  bodyCellClassName = '',
  firstRowClassNames = '',
  headerFirstCellClassName = '',
  rowFirstCellClassName = '',
  getSubRows = row => [],
  paginationProps = {},
  loading = false,
  dataUpdatedAt = 1,
  getRowProps = row => {},
  cellMaxWidthIsEqToCellWidth = false,
  ...rest
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // state: { expanded: expandedRow },
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    ...other
  } = useTable(
    {
      columns,
      data,
      getSubRows,
    },
    useExpanded,
  );

  React.useEffect(() => {
    if (isAllRowsExpanded !== expanded) toggleAllRowsExpanded(expanded);
  }, [dataUpdatedAt, expanded, paginationProps.page, isAllRowsExpanded]);

  const internalClasses = useStyles();

  if (loading) {
    return <TableLoading />;
  }

  if (data?.length === 0) {
    return (
      <div className="min-h-inherit flex flex-row justify-center items-center mb-3 mt-6 font-normal text-xl">
        No Data Available
      </div>
    );
  }

  return (
    <>
      <MiuTable
        classes={{ root: internalClasses.root, ...classes }}
        style={{ tableLayout: 'auto' }}
        className={clsx(className)}
        {...getTableProps()}
        size="small"
        {...rest}
      >
        <TableHead className="bg-white-400">
          <TableRow className={headerRowClassName}>
            {other.columns.map((column, i) => {
              return (
                <TableCell
                  className={overrideTailwindClasses(
                    clsx(
                      headerCellClassName,
                      column.className,
                      i === 0 && headerFirstCellClassName,
                    ),
                  )}
                  style={{
                    fontFamily: 'roboto',
                    width: column.width,
                    ...(column.sticky && {
                      position: 'sticky',
                      zIndex: 90,
                      left: 0,
                      boxShadow: '1px 2px 2px 0px #ccc',
                    }),
                  }}
                  variant="head"
                  {...column.getHeaderProps()}
                >
                  {column.filter
                    ? column.render('Filter')
                    : column.render('Header')}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <TableRow
                className={overrideTailwindClasses(
                  clsx(bodyRowClassName, i === 0 && firstRowClassNames),
                )}
                {...row.getRowProps(getRowProps(row))}
              >
                {row.cells.map((cell, cellIndex) => (
                  <TableCell
                    className={overrideTailwindClasses(
                      clsx(
                        bodyCellClassName,
                        cell.column.className,
                        cell.column.bodyCellClassName,
                        cellIndex === 0 && rowFirstCellClassName,
                      ),
                    )}
                    style={{
                      fontFamily: 'roboto',
                      width: cell.column.width,
                      ...(cellMaxWidthIsEqToCellWidth && {
                        maxWidth: cell.column.width,
                        wordBreak: 'break-all',
                      }),
                      ...(cell.column.sticky && {
                        position: 'sticky',
                        zIndex: 90,
                        left: 0,
                        boxShadow: '1px 0px 2px 0px #ccc',
                      }),
                    }}
                    variant="body"
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MiuTable>

      {paginationProps?.pageCount ? (
        <Pagination
          className="mt-2 mb-5 flex flex-row justify-end"
          count={paginationProps.pageCount}
          page={paginationProps.page}
          onChange={(_, page) => paginationProps.onPageChange(page)}
        />
      ) : null}
    </>
  );
};

export default Table;
