import { AxiosResponse } from 'axios';

import axios from '@lib/api';
import {
  UsePoliciesType,
  GetPoliciesParams,
  AddPolicyParams,
  TrashPolicyParams,
} from './types';

const getPolicies = async (params?: GetPoliciesParams) => {
  const res: AxiosResponse<UsePoliciesType> = await axios({
    method: 'get',
    url: '/policies',
    params,
  });

  return res.data;
};

const addPolicy = async (data: AddPolicyParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: '/policies',
    data,
  });

  return res.data;
};

const trashPolicy = async (params: TrashPolicyParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/policies/${params.policy_code}/trash`,
  });

  return res.data;
};

export default {
  getPolicies,
  addPolicy,
  trashPolicy,
};
