import { map } from '@routes';

import { Button } from '@components/Common';

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <h1 className="text-6xl font-bold">404</h1>

      <h4 className="text-2xl">Not Found</h4>

      <div className="flex flex-row justify-around items-center mt-5">
        <Button size="large" text="Go Home" href={map.startingPoing} />
      </div>
    </div>
  );
};

export default NotFound;
