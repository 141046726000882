import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  GetActivityLogParams,
  UseActivityLogResponseType,
} from '@hooks/api/activity/types';

const getActivityLogList = async (params: GetActivityLogParams) => {
  const res: AxiosResponse<UseActivityLogResponseType> = await axios({
    method: 'get',
    url: `/auditlogs`,
    params,
  });
  return res.data;
};

export default {
  getActivityLogList,
};
