import React from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IconButton } from '@material-ui/core';
import { Close, Send } from '@material-ui/icons';

import { Modal, Button, Input, TextArea } from '@components/Common';

import GeneralHooks from '@hooks/api/general';
import { ShareSAQParams } from '@hooks/api/general/types';

interface P {
  open: boolean;
  setOpen: (arg: boolean) => void;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .ensure()
    .required('Email is required')
    .email('Email is not valid'),
  message: yup.string().required('Message is required'),
});

const ShareSAQModal: React.FC<P> = ({ open, setOpen }) => {
  const form = useForm<ShareSAQParams>({
    defaultValues: {
      email: '',
      message: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { data: saq, ...useSAQUtils } = GeneralHooks.useSAQ();

  const shareSAQMutatation = GeneralHooks.useShareSAQMutation();

  const onSubmit: SubmitHandler<ShareSAQParams> = async data => {
    try {
      await shareSAQMutatation.mutateAsync(data);

      setOpen(false);
    } catch (e) {}
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center',
          'bg-white-600 shadow-lg min-w-132',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'h-10 pl-4 min-w-full bg-white-400',
          )}
        >
          <h3 className="font-roboto text-base">Share SAQ File</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto w-1/4">Email</p>

            <Controller
              name="email"
              control={form.control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <div className="flex flex-col w-3/4">
                  <Input className="flex-grow" innerRef={ref} {...rest} />

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto w-1/4">Message</p>

            <Controller
              name="message"
              control={form.control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <div className="flex flex-col w-3/4">
                  <TextArea
                    multiline
                    rows={2}
                    inputClassName="p-2"
                    whiteWrapper
                    innerRef={ref}
                    {...rest}
                  />

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-end items-center min-w-full mt-7">
            <Button
              loading={useSAQUtils.isLoading}
              size="large"
              variant="outlined"
              onClick={async () => {
                try {
                  if (!saq?.data?.[0]) throw new Error('No SAQ file avaliable');

                  const clipboardPermissions =
                    await navigator.permissions.query({
                      name: 'clipboard-write' as PermissionName,
                    });

                  const hasClipboardPermissions =
                    clipboardPermissions.state === 'granted' ||
                    clipboardPermissions.state === 'prompt';

                  if (!hasClipboardPermissions)
                    throw new Error('Clipboard permission Denied');

                  await navigator.clipboard.writeText(saq?.data[0]);

                  toast.success('SAQ link is copied into clipboard');
                } catch (e) {
                  if (e instanceof Error && e?.message) toast.error(e.message);
                }
              }}
              className="border-green text-green mr-2 text-sm"
              text="Copy SAQ link to clipboard"
            />

            <Button
              size="large"
              type="submit"
              onClick={form.handleSubmit(onSubmit)}
              className="bg-green text-sm items-center"
              startIcon={<Send className=" text-base" />}
              text="Send"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ShareSAQModal;
