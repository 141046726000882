/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { Handle, NodeProps, Position } from 'react-flow-renderer';

import { Edit, Delete } from '@material-ui/icons';

import { CustomFlowElementData } from '@components/Bussines/Products/ProductForm/Flow/types';

export const NODE_WIDTH_REM = 10;
export const NODE_WIDTH_PX = NODE_WIDTH_REM * 16;

export const NODE_HEIGHT_REM = 4;
export const NODE_HEIGHT_PX = NODE_HEIGHT_REM * 16;

interface CustomNodeProps extends NodeProps {
  onNodeEdit?: (id: string) => void;
  onNodeDelete?: (id: string) => void;
  data: CustomFlowElementData;
}

const CustomNode: React.FC<CustomNodeProps> = ({
  onNodeEdit,
  onNodeDelete,
  ...node
}) => {
  const [showButtons, setShowButton] = React.useState(false);

  const { data } = node;
  const { nodeType = 'default' } = data;

  return (
    <>
      {nodeType !== 'output' ? (
        <Handle
          type="source"
          position={node.sourcePosition || Position.Bottom}
          className="rounded-lg bg-primary p-1 z-50"
        />
      ) : null}

      <div
        onMouseEnter={() => showButtons === false && setShowButton(true)}
        onMouseLeave={() => showButtons === true && setShowButton(false)}
        className={clsx(
          'flex flex-row items-center justify-between relative',
          'bg-white border-1 pb-2 px-4 rounded',
          'w-40 min-h-16 max-h-32 overflow-auto break-all',
          node.selected ? 'border-primary' : 'border-gray',
        )}
      >
        <div className="mt-2 text-center">{node.data?.label || ''}</div>

        <button
          type="button"
          className={clsx(
            'p-0 bg-transparent border-0 shadow-none',
            'absolute top-0.5 right-0.5',
            showButtons ? 'visible' : 'hidden',
          )}
          onClick={() => onNodeEdit?.(node.id)}
        >
          <Edit className="mx-0 text-xs text-gray" />
        </button>

        <button
          type="button"
          className={clsx(
            'p-0 bg-transparent border-0 shadow-none',
            'transform -translate-y-full absolute top-full right-0.5',
            showButtons ? 'visible' : 'hidden',
          )}
          onClick={() => onNodeDelete?.(node.id)}
        >
          <Delete className="mx-0 text-xs text-gray" />
        </button>
      </div>

      {nodeType !== 'input' ? (
        <Handle
          type="target"
          position={node.targetPosition || Position.Top}
          className="rounded-lg bg-primary p-1 z-50"
        />
      ) : null}
    </>
  );
};

export default CustomNode;
