import { AxiosResponse } from 'axios';

import axios from '@lib/api';

import {
  UseManufacturersParams,
  UseManufacturersResponseType,
  UseFilteredManufacturersParams,
  UseFilteredManufacturersResponseType,
  UseDetailedManufacturersParams,
  UseDetailedManufacturerResponseType,
  SaveManufacturerParams,
  DeleteManufacturerParams,
  UpdateManufacturerParams,
  GetSupplierAsAManufacturerPrams,
  UseSupplierAsManufacturerResponse,
  ApproveOrRejectManufacturerParams,
  SubmitManufacturerParams,
} from './types';

const getManufacturers = async (params?: UseManufacturersParams) => {
  const res: AxiosResponse<UseManufacturersResponseType> = await axios({
    method: 'get',
    url: '/manufactures',
    params,
  });

  return res.data;
};

const getFilteredManufacturers = async (
  params?: UseFilteredManufacturersParams,
) => {
  const res: AxiosResponse<UseFilteredManufacturersResponseType> = await axios({
    method: 'get',
    url: `/manufactures/searchfilter`,
    params,
  });

  return res.data;
};

const getDetailedManufacturer = async (
  params: UseDetailedManufacturersParams,
) => {
  const res: AxiosResponse<UseDetailedManufacturerResponseType> = await axios({
    method: 'get',
    url: `/manufactures/${params.manufactureCode}/show`,
  });

  return res.data;
};

const getSupplierAsManufacturer = async (
  params: GetSupplierAsAManufacturerPrams,
) => {
  const res: AxiosResponse<UseSupplierAsManufacturerResponse> = await axios({
    method: 'get',
    url: `/manufactures/${params.supplierCode}/sameassupplier`,
  });

  return res.data;
};

const saveManufacturer = async (data: SaveManufacturerParams) => {
  const res: AxiosResponse<UseDetailedManufacturerResponseType> = await axios({
    method: 'post',
    url: '/manufactures',
    data,
  });

  return res.data;
};

const submitManufacturer = async (data: SubmitManufacturerParams) => {
  const res: AxiosResponse<UseDetailedManufacturerResponseType> = await axios({
    method: 'post',
    url: '/manufactures/submit',
    data,
  });

  return res.data;
};

const approveOrRejectManufacturer = async (
  params: ApproveOrRejectManufacturerParams,
) => {
  const { manufacture_code, ...data } = params;

  const res: AxiosResponse<UseDetailedManufacturerResponseType> = await axios({
    method: 'put',
    url: `/manufactures/${params.manufacture_code}/status`,
    data,
  });

  return res.data;
};

const deleteManufacturer = async (params: DeleteManufacturerParams) => {
  const res: AxiosResponse = await axios({
    method: 'delete',
    url: `/manufactures/${params.manufacture_code}/soft`,
  });

  return res.data;
};

const updateManufacturer = async (params: UpdateManufacturerParams) => {
  const { manufacture_code, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/manufactures/${manufacture_code}`,
    data,
  });

  return res.data;
};

export default {
  getManufacturers,
  getDetailedManufacturer,
  getFilteredManufacturers,
  saveManufacturer,
  deleteManufacturer,
  updateManufacturer,
  getSupplierAsManufacturer,
  submitManufacturer,
  approveOrRejectManufacturer,
};
