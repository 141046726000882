import React from 'react';
import { Cell } from 'react-table';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler, FieldPath } from 'react-hook-form';
import { Add as AddIcon } from '@material-ui/icons';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import { wizardHook as WizardHooks, useUrlParams } from '@hooks';
import { Attachments, AttachmentInstance } from '@hooks/api/wizard/types';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import {
  Input,
  UploadButtonUploadOnChange as UploadButton,
  Button,
  Table,
  TableTrashButton,
} from '@components/Common';

const validationSchema = yup.object({
  files_collection: yup.array(
    yup.object({
      name: yup.string().ensure().required(),
      description: yup.string().ensure().required(),
      path: yup.string().ensure().required(),
    }),
  ),
});

type P = {
  share?: boolean;
};
const AttachmentsComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const form = useForm<Attachments>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { data: productAttachments, ...productAttachmentsUtils } =
    WizardHooks.useAttachments(
      {
        code: urlParams.code,
        version: urlParams.version,
      },
      {
        enabled: !!urlParams.code && !!urlParams.version,
        onSuccess: data => {
          form.reset(data.data);
        },
      },
    );
  const updateAttachmentsMutation = WizardHooks.useUpdateAttachmentsMutation();
  const validateAttachmentsMutation =
    WizardHooks.useValidateAttachmentsMutation();

  const history = useHistory();

  const onSave = async (link: string, data: Attachments) => {
    form.clearErrors();

    updateAttachmentsMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          if (link) {
            history.push(link);
          }
        },
      },
    );
  };

  const onValidate: SubmitHandler<Attachments> = async data => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      validateAttachmentsMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });
    } catch (e) {}
  };

  const TableInput = React.useCallback(
    ({
      name,
      disabled,
    }: {
      name: FieldPath<Attachments>;
      disabled: boolean;
    }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              disabled={disabled}
              dense
              className="w-full h-6"
              inputClassName="text-xs px-2 font-roboto"
              innerRef={ref}
              value={value || ''}
              {...rest}
            />
          );
        }}
      />
    ),
    [],
  );

  const onDeleteRow = React.useCallback(
    (rowIndex: string) => {
      const newRows = [...form.getValues().files_collection];

      newRows.splice(Number(rowIndex), 1);

      form.reset(
        {
          files_collection: newRows,
        },
        {
          keepDirty: true,
          keepIsValid: true,
          keepTouched: true,
          keepErrors: true,
        },
      );
    },
    [productAttachments?.timeStamp],
  );

  const onAddRow = React.useCallback(() => {
    const newRows = [...form.getValues().files_collection];

    newRows.push({
      name: '',
      description: '',
      path: '',
    });

    form.reset(
      {
        files_collection: newRows,
      },
      {
        keepDirty: true,
        keepIsValid: true,
        keepTouched: true,
        keepErrors: true,
      },
    );
  }, [productAttachments?.timeStamp]);

  const productDetailsColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof AttachmentInstance;
      id: keyof AttachmentInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell) => JSX.Element);
    }> => [
      {
        Header: 'File Name',
        id: 'name',
        accessor: 'name',
        width: 250,
        Cell: cell => (
          <TableInput
            disabled={!urlParams.editMode}
            name={`files_collection.${Number(cell.row.id)}.name`}
          />
        ),
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        width: 650,
        Cell: cell => (
          <TableInput
            disabled={!urlParams.editMode}
            name={`files_collection.${Number(cell.row.id)}.description`}
          />
        ),
      },
      {
        Header: 'File',
        id: 'path',
        accessor: 'path',
        className: 'text-center',
        width: 100,
        Cell: cell => (
          <Controller
            name={`files_collection.${Number(cell.row.id)}.path`}
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <UploadButton
                error={!!fieldState?.error}
                disabled={!urlParams.editMode}
                url={`/products/${urlParams.code}/version/${urlParams.version}/attachments/upload`}
                id={`cell-upload-${cell.row.id}`}
                wrapperClassName="flex justify-center my-2"
                value={value}
                onChange={onChange}
              />
            )}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <TableTrashButton
            disabled={!urlParams.editMode}
            onClick={() => onDeleteRow(cell.row.id)}
          />
        ),
      },
    ],
    [urlParams?.editMode],
  );

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <div className="flex flex-col mt-6">
          <div className="flex flex-row justify-between mt-3">
            <h3 className="font-roboto text-base text-primary-light mb-2">
              Product Details
            </h3>

            <Button
              disabled={!urlParams.editMode}
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => onAddRow()}
              startIcon={<AddIcon />}
              text="New Row"
              size="small"
            />
          </div>

          <Table
            loading={productAttachmentsUtils.isLoading}
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-3"
            columns={productDetailsColumns}
            data={form.getValues().files_collection || []}
          />
        </div>

        <FormButtonGroup
          share={share}
          pageName="attachments"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default AttachmentsComp;
