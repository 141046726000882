import React from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as yup from 'yup';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, Button, DatePicker, AutoComplete } from '@components/Common';

import {
  suppliersHook as SupplierHooks,
  generalHook as GeneralHooks,
} from '@hooks';

type P = {
  values: FilterAttributesValues | null;
  open: boolean;
  setOpen: (value: boolean) => void;
  onFormSubmit: (args: FilterAttributesValues) => void;
};

export interface FilterAttributesValues {
  in_charge: null | {
    name: string;
    code: string;
  };
  supplier: null | {
    name: string;
    code: string;
  };
  approveDate: {
    from: Date | null;
    to: Date | null;
  };
  reviewDate: {
    from: Date | null;
    to: Date | null;
  };
  specificationStatus: null | { name: string; code: string };
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(65),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
  inputWidth: {
    width: theme.spacing(37),
  },
  datePickerWidth: {
    width: theme.spacing(16),
  },
}));

const validationSchema = yup.object({
  supplier: yup.object().optional().nullable(),
  approveDate: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .optional(),
  reviewDate: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .optional(),
  specificationStatus: yup.object().optional().nullable(),
});

const isAtLeastOneFieldCompleted = (obj: FilterAttributesValues) => {
  const formValues = [
    obj?.in_charge?.name,
    obj?.in_charge?.code,
    obj?.supplier?.name,
    obj?.supplier?.code,
    obj.specificationStatus,
    obj.approveDate.from,
    obj.approveDate.to,
    obj.reviewDate.from,
    obj.reviewDate.to,
  ];

  return !!formValues.find(val => Boolean(val));
};

const AttributeFilterModal: React.FC<P> = ({
  values,
  open,
  setOpen,
  onFormSubmit,
}) => {
  const classes = useStyles();

  const { data: allStatuses } = GeneralHooks.useAllStatus();

  const { data: supplierData } = SupplierHooks.useSuppliers(
    { paginated: false },
    {
      staleTime: 1000 * 60 * 60,
    },
  );

  const initialValues = {
    supplier: values?.supplier?.name
      ? {
          name: values?.supplier?.name || '',
          code: values?.supplier?.code || '',
        }
      : null,
    in_charge: values?.in_charge?.name
      ? {
          name: values?.in_charge?.name || '',
          code: values?.in_charge?.code || '',
        }
      : null,
    approveDate: {
      from: values?.approveDate.from || null,
      to: values?.approveDate.to || null,
    },
    reviewDate: {
      from: values?.reviewDate.from || null,
      to: values?.reviewDate.to || null,
    },
    specificationStatus: values?.specificationStatus?.name
      ? {
          name: values?.specificationStatus?.name || '',
          code: values?.specificationStatus?.code || '',
        }
      : null,
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">Attribute Filters</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={formVals => onFormSubmit(formVals)}
        >
          {formik => (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center p-4 min-w-full"
            >
              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Supplier</p>

                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className={clsx(classes.inputWidth)}
                    id="supplier"
                    items={
                      supplierData?.data.map(el => ({
                        name: el.name,
                        code: el.supplier_code,
                      })) || []
                    }
                    value={formik.values.supplier}
                    onChange={newVal =>
                      formik.setFieldValue('supplier', newVal)
                    }
                  />

                  {formik.touched.supplier && formik.errors.supplier ? (
                    <p className="text-red mb-1">{formik.errors.supplier}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Product Manager</p>

                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className={clsx(classes.inputWidth)}
                    id="in_charge"
                    items={[
                      {
                        name: 'Customer',
                        code: 'customer',
                      },
                      {
                        name: 'Supplier',
                        code: 'supplier',
                      },
                    ]}
                    value={formik.values.in_charge}
                    onChange={newVal =>
                      formik.setFieldValue('in_charge', newVal)
                    }
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Approve Date</p>

                <div className="flex flex-row items-center">
                  <DatePicker
                    placeholder="from"
                    className={clsx(classes.datePickerWidth)}
                    wrapperClassName="mr-2"
                    value={formik.values.approveDate.from}
                    onChange={val =>
                      formik.setFieldValue('approveDate.from', val)
                    }
                  />

                  <DatePicker
                    placeholder="to"
                    className={clsx(classes.datePickerWidth)}
                    value={formik.values.approveDate.to}
                    onChange={val =>
                      formik.setFieldValue('approveDate.to', val)
                    }
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Review Date</p>

                <div className="flex flex-row items-center">
                  <DatePicker
                    placeholder="from"
                    className={clsx(classes.datePickerWidth)}
                    wrapperClassName="mr-2"
                    value={formik.values.reviewDate.from}
                    onChange={val =>
                      formik.setFieldValue('reviewDate.from', val)
                    }
                  />

                  <DatePicker
                    placeholder="to"
                    className={clsx(classes.datePickerWidth)}
                    value={formik.values.reviewDate.to}
                    onChange={val => formik.setFieldValue('reviewDate.to', val)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Specification Status</p>

                <AutoComplete
                  freeSolo={false}
                  canAddCustomItem={false}
                  className={clsx(classes.inputWidth)}
                  id="specificationStatus"
                  items={allStatuses?.data.cardbox_statuses || []}
                  value={formik.values.specificationStatus}
                  onChange={newVal =>
                    formik.setFieldValue('specificationStatus', newVal)
                  }
                />
              </div>

              <div className="flex flex-row justify-end items-center min-w-full">
                {isAtLeastOneFieldCompleted(formik.values) ? (
                  <Button
                    // loading={isLoading}
                    type="submit"
                    className="text-green border-green"
                    variant="outlined"
                    startIcon={<Check />}
                    text="Done"
                  />
                ) : null}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AttributeFilterModal;
