/* eslint-disable no-console */
import React from 'react';
import { Cell } from 'react-table';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import {
  Standards,
  StandardInstance,
  StandardsPhysicalAndChemical,
  StandardsMicrobiological,
} from '@hooks/api/wizard/types';
import { wizardHook, generalHook, useUrlParams } from '@hooks';
import { Add as AddIcon } from '@material-ui/icons';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import {
  Input,
  Button,
  Table,
  AutoComplete,
  AutoCompleteItem,
  TableTrashButton,
} from '@components/Common';

type CustomCell = Cell<{ userAdded?: boolean }>;
const validationSchema = yup.object({
  // microbiological: yup.mixed().test('micro_test', 'error', val => {
  //   return false
  // }
  microbiological: yup.array(
    yup.object({
      accept: yup.string().ensure().required(),
      frequency: yup.string().ensure().required(),
      // method: yup.string().ensure().required(),
      micro_test: yup.mixed().test('micro_test', 'error', val => {
        return !!val;
      }),
      reject: yup.string().ensure().required(),
      target: yup.string().ensure().required(),
      units: yup.mixed().test('units', 'error', val => {
        if (val === null) {
          return false;
        }
        if (val?.name === undefined) {
          return false;
        }
        return !!val.name;
      }),
    }),
  ),

  physical: yup.array(
    yup.object({
      test_cre: yup.mixed().test('test_cre', 'error', val => {
        return !!val;
      }),
      units: yup.mixed().test('units', 'error', val => {
        if (val === null) {
          return false;
        }
        if (val?.name === undefined) {
          return false;
        }
        return !!val.name;
      }),

      maximum_reject: yup.string().ensure().required(),
      minimum_alert: yup.string().ensure().required(),
      target_accept: yup.string().ensure().required(),
      frequency: yup.string().ensure().required(),
    }),
  ),

  chemical: yup.array(
    yup.object({
      test_cre: yup.mixed().test('test_cre', 'error', val => {
        return !!val;
      }),
      units: yup.mixed().test('units', 'error', val => {
        if (val === null) {
          return false;
        }
        if (val?.name === undefined) {
          return false;
        }
        return !!val.name;
      }),

      maximum_reject: yup.string().ensure().required(),
      minimum_alert: yup.string().ensure().required(),
      target_accept: yup.string().ensure().required(),
      frequency: yup.string().ensure().required(),
    }),
  ),
});

type P = {
  share?: boolean;
};
const StandardsComponent: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');
  const [deleting, setDeleting] = React.useState(1);

  const form = useForm<any>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();
  const {
    useStandards,
    useUpdateStandardsMutation,
    useValidateStandardsMutation,
  } = wizardHook;
  const { data: allStatus, ...useAllStatusUtils } = generalHook.useAllStatus();

  const { data: productStandards } = useStandards(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => form.reset(data.data),
    },
  );
  const updateStandardsMutation = useUpdateStandardsMutation();
  const validateStandardsMutation = useValidateStandardsMutation();

  const history = useHistory();

  const onSave = async (link: string, data: Standards) => {
    form.clearErrors();
    updateStandardsMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate: SubmitHandler<Standards> = async data => {
    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }
      validateStandardsMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });
    } catch (e) {}
  };

  interface TableInputProps {
    name: FieldPath<Standards>;
    className?: string;
    disabled?: boolean;
    dense?: boolean;
  }

  const TableInput = React.useCallback(
    ({ name, dense = true, className = '', disabled }: TableInputProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              dense={dense}
              className={className ?? 'w-full h-6'}
              inputClassName="text-xs px-2 font-roboto"
              innerRef={ref}
              value={value || ''}
              disabled={disabled}
              {...rest}
            />
          );
        }}
      />
    ),
    [productStandards?.timeStamp],
  );

  interface TableAutoCompleteProps {
    id: string;
    name: FieldPath<Standards>;
    disabled?: boolean;
    freeSolo?: boolean;
    canAddCustomItem?: boolean;
    itemDropDown?: NonNullable<AutoCompleteItem>[];
  }

  const onDeleteRow = (rowKey: any, rowIndex?: number) => {
    const newVals =
      form.getValues()?.[rowKey]?.length > 0 ? form.getValues()[rowKey] : [];

    newVals.splice(rowIndex || 0, 1);

    form.setValue(rowKey, newVals, { shouldDirty: true });
    form.reset(undefined, {
      keepValues: true,
      keepTouched: true,
      keepDirty: true,
      keepErrors: true,
    });
    setDeleting(deleting * 2);
  };

  const TableAutoComplete = React.useCallback(
    (props: TableAutoCompleteProps) => (
      <Controller
        name={props.name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, onChange, ...rest } = field;

          return (
            <AutoComplete
              error={!!fieldState?.error}
              dense
              className="flex justify-center"
              id={props.name}
              items={props.itemDropDown || []}
              value={value}
              disabled={props.disabled}
              disableClearable
              onChange={onChange}
              freeSolo={props.freeSolo}
              canAddCustomItem={props.canAddCustomItem}
              {...rest}
            />
          );
        }}
      />
    ),
    [productStandards?.timeStamp],
  );

  const physicalStandardsColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof StandardInstance;
      id: keyof StandardInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Test / Criteria',
        id: 'test_cre',
        accessor: 'test_cre',
        width: 400,
        Cell: (cell: CustomCell) => (
          <TableAutoComplete
            id={`physical.${+cell.row.id}.test_cre`}
            name={`physical.${+cell.row.id}.test_cre`}
            disabled={!urlParams?.editMode}
            itemDropDown={
              allStatus?.data.physical_standards_test_criteria_dropdown
            }
          />
        ),
      },
      {
        Header: 'Target/Accept',
        id: 'target_accept',
        accessor: 'target_accept',
        Cell: cell => (
          <TableInput
            name={`physical.${+cell.row.id}.target_accept`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Maximum/Reject',
        id: 'maximum_reject',
        accessor: 'maximum_reject',
        Cell: cell => (
          <TableInput
            name={`physical.${+cell.row.id}.maximum_reject`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Minimum/Alert',
        id: 'minimum_alert',
        accessor: 'minimum_alert',
        Cell: cell => (
          <TableInput
            name={`physical.${+cell.row.id}.minimum_alert`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Units',
        id: 'units',
        accessor: 'units',
        Cell: cell => (
          <TableAutoComplete
            id={`physical.${+cell.row.id}.units`}
            name={`physical.${+cell.row.id}.units`}
            disabled={!urlParams?.editMode}
            itemDropDown={allStatus?.data.physical_standards_units_dropdown}
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        accessor: 'frequency',
        Cell: cell => (
          <TableInput
            name={`physical.${+cell.row.id}.frequency`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        Cell: cell => (
          <TableInput
            name={`physical.${+cell.row.id}.comments`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: (cell: any) => (
          <>
            {cell.rows.length <= 1 ? (
              <TableTrashButton
                disabled
                className="opacity-50"
                onClick={() => onDeleteRow('physical', +cell.row.id)}
              />
            ) : (
              <TableTrashButton
                disabled={!urlParams.editMode}
                onClick={() => onDeleteRow('physical', +cell.row.id)}
              />
            )}
          </>
        ),
      },
    ],
    [urlParams?.editMode, deleting, useAllStatusUtils.dataUpdatedAt],
  );

  const chemicalStandardsColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof StandardInstance;
      id: keyof StandardInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Test / Criteria',
        id: 'test_cre',
        accessor: 'test_cre',
        width: 360,
        Cell: cell => (
          <TableAutoComplete
            id={`chemical.${+cell.row.id}.test_cre`}
            name={`chemical.${+cell.row.id}.test_cre`}
            disabled={!urlParams?.editMode}
            itemDropDown={
              allStatus?.data.chemical_standard_test_criteria_dropdown
            }
          />
        ),
      },
      {
        Header: 'Target/Accept',
        id: 'target_accept',
        accessor: 'target_accept',
        Cell: cell => (
          <TableInput
            name={`chemical.${+cell.row.id}.target_accept`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Maximum/Reject',
        id: 'maximum_reject',
        accessor: 'maximum_reject',
        Cell: cell => (
          <TableInput
            name={`chemical.${+cell.row.id}.maximum_reject`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Minimum/Alert',
        id: 'minimum_alert',
        accessor: 'minimum_alert',
        Cell: cell => (
          <TableInput
            name={`chemical.${+cell.row.id}.minimum_alert`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Units',
        id: 'units',
        accessor: 'units',
        Cell: cell => (
          <TableAutoComplete
            id={`chemical.${+cell.row.id}.units`}
            name={`chemical.${+cell.row.id}.units`}
            disabled={!urlParams?.editMode}
            itemDropDown={allStatus?.data.chemical_standards_units_dropdown}
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        accessor: 'frequency',
        Cell: cell => (
          <TableInput
            name={`chemical.${+cell.row.id}.frequency`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        Cell: cell => (
          <TableInput
            name={`chemical.${+cell.row.id}.comments`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: (cell: any) => (
          <>
            {cell.rows.length <= 1 ? (
              <TableTrashButton
                disabled
                className="opacity-50"
                onClick={() => onDeleteRow('chemical', +cell.row.id)}
              />
            ) : (
              <TableTrashButton
                disabled={!urlParams.editMode}
                onClick={() => onDeleteRow('chemical', +cell.row.id)}
              />
            )}
          </>
        ),
      },
    ],
    [urlParams?.editMode, deleting, useAllStatusUtils.dataUpdatedAt],
  );

  const microbiologicalStandardsColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof StandardInstance;
      id: keyof StandardInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Micro Test',
        id: 'micro_test',
        accessor: 'micro_test',
        width: 360,
        Cell: cell => (
          <TableAutoComplete
            id={`microbiological.${+cell.row.id}.micro_test`}
            name={`microbiological.${+cell.row.id}.micro_test`}
            disabled={!cell.row.original.userAdded || !urlParams?.editMode}
            itemDropDown={
              allStatus?.data.microbiological_standards_test_criteria_dropdown
            }
          />
        ),
      },
      {
        Header: 'Target',
        id: 'target',
        accessor: 'target',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.target`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Accept',
        id: 'accept',
        accessor: 'accept',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.accept`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Reject',
        id: 'reject',
        accessor: 'reject',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.reject`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Units',
        id: 'units',
        accessor: 'units',
        Cell: cell => (
          <TableAutoComplete
            id={`microbiological.${+cell.row.id}.units`}
            name={`microbiological.${+cell.row.id}.units`}
            disabled={!urlParams?.editMode}
            itemDropDown={
              allStatus?.data.microbiological_standards_units_dropdown
            }
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: 'Frequency',
        id: 'frequency',
        accessor: 'frequency',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.frequency`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Method',
        id: 'method',
        accessor: 'method',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.method`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        Cell: cell => (
          <TableInput
            name={`microbiological.${+cell.row.id}.comments`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',

        Cell: (cell: any) => (
          <>
            {cell.rows.length <= 1 ? (
              <TableTrashButton
                disabled
                className="opacity-50"
                onClick={() => onDeleteRow('microbiological', +cell.row.id)}
              />
            ) : (
              <TableTrashButton
                disabled={!urlParams.editMode}
                onClick={() => onDeleteRow('microbiological', +cell.row.id)}
              />
            )}
          </>
        ),
      },
    ],
    [urlParams?.editMode, deleting, useAllStatusUtils.dataUpdatedAt],
  );

  enum StandardsTables {
    Physical = 'physical',
    Chemical = 'chemical',
    Microbiological = 'microbiological',
  }

  const baseStandardsInitRow = {
    units: {
      name: '',
      code: 0,
    },
    frequency: '',
    comments: '',
    userAdded: true,
  };

  const physicalAndChemicalInitRow: StandardsPhysicalAndChemical = {
    test_cre: '',
    target_accept: '',
    maximum_reject: '',
    minimum_alert: '',
    ...baseStandardsInitRow,
  };

  const microbiologicalInitRow: StandardsMicrobiological = {
    micro_test: '',
    target: '',
    accept: '',
    reject: '',
    method: '',
    ...baseStandardsInitRow,
  };

  const onAddRow = React.useCallback(
    (
      tableType: StandardsTables,
      initRow: StandardsPhysicalAndChemical | StandardsMicrobiological,
    ) => {
      const newRows = [...form.getValues()[tableType]];

      newRows.push(initRow);

      form.reset(
        {
          ...form.getValues(),
          [tableType]: newRows,
        },
        {
          keepDirty: true,
          keepIsValid: true,
          keepTouched: true,
          keepErrors: true,
        },
      );
    },
    [productStandards?.timeStamp],
  );
  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Microbiological standards
          </h3>

          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-1 flex flex-row justify-between items-center bg-white pl-4 rounded">
              <p className="font-roboto">Laboratory Name</p>

              <TableInput
                name="microbiological_laboratory.laboratory_name"
                className="w-80"
                disabled={!urlParams?.editMode}
                dense={false}
              />
            </div>
            <div className="col-span-1 flex flex-row justify-between items-center bg-white pl-4 rounded">
              <p className="font-roboto">Laboratory Accrediation</p>

              <TableInput
                name="microbiological_laboratory.accreditation"
                className="w-80"
                disabled={!urlParams?.editMode}
                dense={false}
              />
            </div>
          </div>

          <Table
            className="microbiological-table mt-4"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-2 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-2"
            headerFirstCellClassName="pl-4"
            rowFirstCellClassName="pl-2 pr-1"
            columns={microbiologicalStandardsColumns}
            data={form.getValues('microbiological') || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(
                  StandardsTables.Microbiological,
                  microbiologicalInitRow,
                )
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Physical standards
          </h3>

          <Table
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-2 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-2"
            headerFirstCellClassName="pl-4"
            rowFirstCellClassName="pl-2 pr-1"
            columns={physicalStandardsColumns}
            data={form.getValues('physical') || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(StandardsTables.Physical, physicalAndChemicalInitRow)
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Chemical standards
          </h3>

          <Table
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-2 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-2"
            headerFirstCellClassName="pl-4"
            rowFirstCellClassName="pl-2 pr-1"
            columns={chemicalStandardsColumns}
            data={form.getValues('chemical') || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(StandardsTables.Chemical, physicalAndChemicalInitRow)
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <FormButtonGroup
          share={share}
          pageName="standards"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default StandardsComponent;
