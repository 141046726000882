import React from 'react';
import { Cell } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import NewProductModal from '@components/Bussines/Products/NewProductModal';
import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Bussines/Products/AttributeFiltersModal';
import ProductViewModal from '@components/Bussines/Products/ProductViewModal';
import Menu, { MenuItemTypes } from '@components/Bussines/Products/Menu';

import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Switch,
  Table,
  // TrashButton,
  Tooltip,
} from '@components/Common';

import {
  productsHook as ProductHooks,
  usePrevious,
  useDebounce,
  useUrlParams,
  stringify,
} from '@hooks';
import ShareProductModal from '@components/Bussines/Products/ShareProductModal';

import { GetProductsParams } from '@hooks/api/products/types';

import { removeFalsyKeyFromObject, formatDate } from '@utils';

const formatFilterAttributes = (
  obj: FilterAttributesValues,
): GetProductsParams => {
  const dateFields = [
    'from_approve_date',
    'to_approve_date',
    'from_review_date',
    'to_review_date',
  ];

  const formattedValues = {
    supplier_code: obj?.supplier?.code,
    in_charge: obj?.in_charge?.code,
    cardbox_status: obj?.specificationStatus?.code,

    from_approve_date: obj.approveDate.from,
    to_approve_date: obj.approveDate.to,
    from_review_date: obj.reviewDate.from,
    to_review_date: obj.reviewDate.to,
  } as const;

  const truthyValues = removeFalsyKeyFromObject(formattedValues);

  const reFormattedValues = Object.entries(truthyValues).map(([key, value]) => {
    if (dateFields.includes(key)) {
      return [key, formatDate(value as string)];
    }

    return [key, value];
  });

  return Object.fromEntries(reFormattedValues);
};

const ProductsLanding = () => {
  const [isShareProductModalOpen, setIsShareProductModalOpen] =
    React.useState(false);
  const [ShareProductModalData, setShareProductModalData] =
    React.useState<null | {
      name: string;
      code: string;
      vertion: number;
    }>(null);

  const [user] = useUser();

  const history = useHistory();
  const [urlParams, , setUrlParams] = useUrlParams<{
    code: string | undefined;
  }>();

  const [expanded, setExpanded] = React.useState('No');
  // const [showTrashed, setShowTrashed] = React.useState(false);

  const [isNewProductModalOpen, setIsNewProductModalOpen] =
    React.useState(false);
  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [productFiters, setProductFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: productsData, ...useProductsUtils } = ProductHooks.useProducts({
    page: currentPage,
    q: debouncedSearch,
    // is_trashed: showTrashed,
    paginated: true,
    ...(productFiters ? formatFilterAttributes(productFiters) : {}),
  });

  const deleteProdcutMutation = ProductHooks.useDeleteProductMutation();

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: any) => {
      if (actionType === 'View') {
        setUrlParams({
          code: row.original.code,
          version: row.original.version,
        });
      }

      if (actionType === 'Spec View') {
        const newUrlParams = stringify(history.location.search, {
          code: row.original.code,
          version: row.original.version,
        });

        history.push(
          `${RouteMap.bussines.productForm.main_info}?${newUrlParams}`,
        );
      }

      if (actionType === 'Delete') {
        await deleteProdcutMutation.mutateAsync({
          code: row.original.code,
          version: row.original.version,
        });
      }
      if (actionType === 'Share') {
        setIsShareProductModalOpen(true);
        setShareProductModalData({
          code: row.original.code,
          vertion: row.original.version,
          name: row.original.name,
        });
      }
    },
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'counter',
        width: 30,
        Cell: (cell: Cell) => (
          <span>{cell.row.depth > 0 ? '' : cell.row.index + 1}</span>
        ),
      },
      {
        Header: 'Product Code',
        accessor: 'item_code',
      },
      {
        Header: 'Product Name',
        accessor: 'name',
      },
      {
        Header: 'Supplier',
        accessor: 'supplier_name',
      },
      {
        Header: 'Current Version',
        accessor: 'version',
        width: 30,
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacture_name',
      },
      {
        Header: 'Manager',
        accessor: 'in_charge',
      },
      {
        Header: 'Approve Date',
        accessor: 'approve_date',
        id: 'approve_date',
      },
      {
        Header: 'Review Date',
        accessor: 'review_date',
        id: 'review_date',
      },
      {
        Header: 'Specification Status',
        accessor: 'cardbox_status',
      },
      {
        width: 30,
        Header: 'Action',
        id: 'action',
        Cell: (cell: Cell) => (
          <Menu
            menuItems={[
              user?.access_levels.product_create_update ? 'View' : null,
              'Spec View',
              user?.access_levels.product_trash_restore ? 'Delete' : null,
              user?.access_levels.product_share ? 'Share' : null,
              // showTrashed ? 'Restore' : 'Delete',
            ]}
            open={openTableActionMenuId === cell.row.id}
            onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
            onClose={() => setOpenTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onMenuItemClick(actionType, cell.row);

              setOpenTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [
      onMenuItemClick,
      openTableActionMenuId,
      productsData?.timeStamp,
      // showTrashed,
    ],
  );

  const getSubRows = React.useCallback(
    (row: any) => {
      return row.versions?.data?.length > 0 ? row.versions.data : [];
    },
    [productsData?.timeStamp],
  );

  return (
    <Layout>
      {isShareProductModalOpen ? (
        <ShareProductModal
          open={isShareProductModalOpen}
          modalData={ShareProductModalData}
          setOpen={setIsShareProductModalOpen}
        />
      ) : null}

      <NewProductModal
        open={isNewProductModalOpen}
        setOpen={setIsNewProductModalOpen}
      />

      <AttributeFiltersModal
        values={productFiters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setProductFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      {urlParams.code ? (
        <ProductViewModal
          open={!!urlParams.code}
          setOpen={() => setUrlParams({ code: undefined, version: undefined })}
        />
      ) : null}

      <Breadcrumbs
        onClick={() => {}}
        items={[
          { href: '#', text: 'Business' },
          { href: '#', text: 'Product' },
        ]}
      />

      <div className="flex flex-row items-center mt-6">
        <Searchbox
          value={searchValue}
          onSearch={value => setSearchValue(value)}
          onChange={e => setSearchValue(e.target.value)}
          className="mr-4 w-120"
        />

        <Button
          className="mr-3 text-white-600"
          onClick={() => setIsAttributeFiltersModalOpen(true)}
          startIcon={<img src={FilterIcon} alt="filter" />}
          text="Attribute Filters"
        />

        {productFiters ? (
          <Button
            onClick={() => {
              setCurrentPage(1);
              setProductFilters(null);
            }}
            text="Clear Filters"
          />
        ) : null}
      </div>

      <div className="flex flex-row items-center justify-between mt-14">
        <p className="text-base text-primary-light font-roboto">Products</p>

        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center px-4 py-2 bg-white rounded">
            <p className="text-sm font-roboto flex flex-row items-center text-primary-light mr-8">
              Expand Mode
              <Tooltip title="Expand to see all versions" />
            </p>

            <Switch type="secondary" value={expanded} onChange={setExpanded} />
          </div>

          {/* <TrashButton
            className="mx-3"
            active={showTrashed}
            onClick={() => {
              setCurrentPage(1);

              setShowTrashed(prevValue => !prevValue);
            }}
          /> */}

          {user?.access_levels.product_create_update && (
            <Button
              variant="outlined"
              className="border-1 border-primary"
              onClick={() => setIsNewProductModalOpen(true)}
              startIcon={<AddIcon />}
              text="New Product"
            />
          )}
        </div>
      </div>

      <Table
        dataUpdatedAt={useProductsUtils.dataUpdatedAt}
        loading={useProductsUtils.isLoading}
        className="mt-2"
        bodyRowClassName="cursor-pointer rounded bg-white border-t-2 border-b-2 border-white-600"
        headerCellClassName="text-primary-light font-normal font-roboto last-item-center"
        bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
        expanded={expanded === 'Yes'}
        columns={columns}
        data={productsData?.data || []}
        getSubRows={getSubRows}
        getRowProps={row => ({
          onClick: () => onMenuItemClick('Spec View', row),
        })}
        paginationProps={{
          page: currentPage,
          onPageChange: setCurrentPage,
          pageCount: productsData?.meta?.last_page,
        }}
      />
    </Layout>
  );
};

export default ProductsLanding;
