import React from 'react';
import clsx from 'clsx';
import { getBezierPath, getEdgeCenter, EdgeProps } from 'react-flow-renderer';

const foreignObjectSize = 40;

interface CustomEdgeProps extends EdgeProps {
  onDelete?: (id: string) => void;
}

const EdgeButton: React.FC<CustomEdgeProps> = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  id,
  onDelete,
}) => {
  const [showButtons, setShowButton] = React.useState(false);

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <g
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <path
        id={id}
        className={clsx(
          'react-flow__edge-path-selector',
          'cursor-pointer bg-transparent text-transparent fill-current stroke-current stroke-30',
          'z-20',
        )}
        fillRule="evenodd"
        d={edgePath}
      />

      <path
        id={id}
        className={clsx(
          'react-flow__edge-path text-gray',
          'cursor-pointer stroke-current stroke-1',
          'z-10',
        )}
        fillRule="evenodd"
        d={edgePath}
      />

      {showButtons ? (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <body className="flex justify-center items-center h-10 w-10 bg-transparent z-50">
            <button
              type="button"
              className="bg-primary border-white w-5 h-5 rounded-full text-white"
              onClick={event => {
                event.stopPropagation();
                if (onDelete) onDelete(id);
              }}
            >
              ×
            </button>
          </body>
        </foreignObject>
      ) : null}
    </g>
  );
};

export default EdgeButton;
