import GeneralHooks from '@hooks/api/general';
import AuthHooks from '@hooks/api/auth';
import { useHistory } from 'react-router-dom';

import { Button } from '@components/Common';

import Logo from '@components/Auth/Logo';
import { ExitToApp } from '@material-ui/icons';
import Cookies from 'js-cookie';
import RouteMap from '@routes/map';

const TermsAndConditions = () => {
  const history = useHistory();

  const { data: settings } = GeneralHooks.useSettings({
    type: 'terms_and_condition',
  });

  const acceptTermsAndConditionsMutation =
    AuthHooks.useAcceptTermsAndConditionsMutation();

  const termsAndConditions =
    settings?.data[settings?.data?.length - 1]?.meta_description || '';

  return (
    <form className="min-h-screen flex flex-col items-center justify-between bg-white-600">
      <Logo className="mt-24" />

      <Button
        className=" px-4 absolute top-10 right-10"
        onClick={e => {
          Cookies.remove('public_token');
          history.push(RouteMap.auth.login);
        }}
      >
        <ExitToApp className="text-white text-lg" />

        <span className="ml-4 text-sm font-roboto text-white">Log out</span>
      </Button>

      <div className="flex flex-col justify-center items-center px-8 py-6 w-full">
        <div className="flex flex-col w-full">
          <div className="w-full bg-white-400 px-4 py-2 rounded">
            <h3 className="font-roboto text-base">Terms and Conditions</h3>
          </div>

          <div className="w-full bg-white mt-1 p-4 rounded">
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: termsAndConditions,
              }}
              className="font-roboto text-black text-sm whitespace-pre-line"
            />
          </div>
        </div>

        <Button
          className="bg-green mt-5 mb-4 ml-auto w-22"
          text="Accept"
          onClick={() => acceptTermsAndConditionsMutation.mutate()}
        />
      </div>

      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div>
    </form>
  );
};

export default TermsAndConditions;
