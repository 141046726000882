import React from 'react';
import { AxiosError } from 'axios';
import 'date-fns';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from '@app/reportWebVitals';

import { Loader } from '@components/Common';
import errorHandler, { errorData } from '@utils/errorHandler';

import { SidebarStateProvider, UserProvider } from '@contexts';

import theme from '@app/theme';

import { Router } from '@routes';

// Load css
import 'react-toastify/dist/ReactToastify.css';
import 'draft-js/dist/Draft.css';
import '@assets/styles/index.css';
import '@assets/styles/custom.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      retry: false,
      refetchOnWindowFocus: false,
      // when upload window opens the webiste loses focus and therefore when upload window closess
      // some of the queries will refetch and app flow will be ruined !!!
      onError: err => errorHandler(err as AxiosError<errorData>),
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <SidebarStateProvider>
              <CssBaseline />

              <Loader />

              <Router />

              <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                closeOnClick={false}
              />
            </SidebarStateProvider>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
