import type {} from '@material-ui/lab/themeAugmentation';
import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    drawerWidth: number;
    closedDrawerWidth: number;
    appBarHeight: number;
    fonts: {
      primary: string;
      secondary: string;
    };
  }

  interface ThemeOptions {
    drawerWidth: number;
    closedDrawerWidth: number;
    appBarHeight: number;
    fonts: {
      primary: string;
      secondary: string;
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gray: {
      light: string;
      main: string;
      dark: string;
      darker?: string;
    };
  }

  interface PaletteOptions {
    gray: {
      light: string;
      main: string;
      dark: string;
      darker?: string;
    };
  }
}

// A custom theme for this app
const theme = createTheme({
  fonts: {
    primary: 'Poppins',
    secondary: 'Roboto',
  },
  typography: {
    fontFamily: 'Poppins , Roboto',
  },
  palette: {
    primary: {
      light: '#3B495F',
      main: '#15233C',
    },
    secondary: {
      main: '#E3E5E8',
      dark: '#686868',
    },
    error: {
      light: '#E45572',
      main: '#e54b6a',
      dark: '#EF5350',
    },
    success: {
      light: '#EAF5EA',
      main: '#208D9A',
      dark: '#66BB6A',
    },
    background: {
      default: '#F5F6FA',
      paper: '#F5F6FA',
    },
    text: {
      primary: '#162641',
      secondary: '#F5F6FA',
    },
    gray: {
      light: '#F9F9F9',
      main: '#C4C4C4',
      dark: '#898989',
    },
  },
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        minWidth: 'auto',
      },
      outlinedPrimary: {
        color: '#162641',
        borderColor: '#162641',
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  drawerWidth: 310,
  closedDrawerWidth: 70,
  appBarHeight: 64,
  spacing: factorOf8 => `${factorOf8 / 2}rem`,
});

export default theme;
