/* eslint-disable no-nested-ternary */
import get from 'lodash.get';
import { UnpackNestedValue, FieldPath } from 'react-hook-form';

import { Nutrition, NutritionMineralsSchema } from '@hooks/api/wizard/types';

import {
  EggType,
  EggColor,
  NutritionType,
  AllWatchHandlerParams,
} from '@components/Bussines/Products/ProductForm/Nutrition/types';

const roundingCalculator = (value: number | string, rowTitle: string) => {
  const keepOneDecimal = (x: number) => x.toFixed(1);

  const doesTitleMatchFromArray = (arr: Array<string>) => {
    const formattedArray = arr.map(el => el.toLowerCase().trim());
    const formattedTitle = rowTitle
      .toLowerCase()
      .replace(/\(([^)]+)\)/g, '') // this regex finds anything between paranthesis
      // (including the paranthesis themselves)
      // like (g) / (mg) / (aaaaaaaaaaaaaaa111111111111)
      .trim();

    return !!formattedArray.find(el => el === formattedTitle);
  };

  switch (true) {
    case doesTitleMatchFromArray([
      'fat',
      'carbohydrate',
      'of which sugar',
      'protein',
      'fibre',
      'of which polyols',
      'of which starch',
    ]):
      return value >= 10
        ? keepOneDecimal(Math.round(+value))
        : +value <= 0.5
        ? '<0.5'
        : keepOneDecimal(Math.round(+value * 10) / 10);

    case doesTitleMatchFromArray([
      'of which saturates',
      'of which mono-unsaturated',
      'of which polyunsaturated',
    ]):
      return value >= 10
        ? keepOneDecimal(Math.round(+value))
        : +value <= 0.1
        ? '<0.1'
        : keepOneDecimal(Math.round(+value * 10) / 10);

    case doesTitleMatchFromArray(['sodium']):
      return value >= 1
        ? keepOneDecimal(Math.round(+value * 10) / 10)
        : +value <= 0.005
        ? '<0.005'
        : Math.round(+value * 100) / 100;

    case doesTitleMatchFromArray(['salt']):
      return value >= 1
        ? keepOneDecimal(Math.round(+value * 10) / 10)
        : +value <= 0.0125
        ? '<0.01'
        : Math.round(+value * 100) / 100;

    default: {
      if (!value.toString().includes('.')) return Number(value).toFixed(1);

      // the bellow approch fixed the issue with `toFixed(1)` function rounding down some of the values
      // for example if value = 0.19 then this `Number(value).toFixed(1)` will produce 0.2
      // and we do not want that
      const [whole, decimal] = value.toString().split('.');

      return `${whole}.${decimal[0]}`;
    }
  }
};

const eggColorCalculator = (
  per_100g: number | null,
  type: EggType = EggType.Energy,
  nutritionType: NutritionType = NutritionType.Food,
  portionSize: number,
) => {
  if (!per_100g) return EggColor.White;

  if (Number.isNaN(per_100g) || per_100g < 0) return EggColor.White;

  if (type === EggType.Energy) return EggColor.White;

  const FOOD_PORTION_SIZE_THRESHOLD = 100;
  const DRINK_PORTION_SIZE_THRESHOLD = 150;

  const LIMITS = {
    underPortionLimit: {
      [EggType.Fat]: {
        [NutritionType.Food]: [3, 17.5],
        [NutritionType.Drink]: [1.5, 8.75],
      },
      [EggType.Saturates]: {
        [NutritionType.Food]: [1.5, 5],
        [NutritionType.Drink]: [0.75, 2.5],
      },
      [EggType.Sugars]: {
        [NutritionType.Food]: [5, 22.5],
        [NutritionType.Drink]: [2.5, 11.25],
      },
      [EggType.Salt]: {
        [NutritionType.Food]: [0.3, 1.5],
        [NutritionType.Drink]: [0.3, 0.75],
      },
    },
    overPortionLimit: {
      [EggType.Fat]: {
        [NutritionType.Food]: 21,
        [NutritionType.Drink]: 10.5,
      },
      [EggType.Saturates]: {
        [NutritionType.Food]: 6,
        [NutritionType.Drink]: 3,
      },
      [EggType.Sugars]: {
        [NutritionType.Food]: 27,
        [NutritionType.Drink]: 13.5,
      },
      [EggType.Salt]: {
        [NutritionType.Food]: 1.8,
        [NutritionType.Drink]: 0.9,
      },
    },
  } as const;

  const portionSizeLimit =
    nutritionType === NutritionType.Food
      ? FOOD_PORTION_SIZE_THRESHOLD
      : DRINK_PORTION_SIZE_THRESHOLD;

  const per_serving = (per_100g * portionSize) / 100;

  if (
    portionSize > portionSizeLimit &&
    per_serving > LIMITS.overPortionLimit[type][nutritionType]
  ) {
    return EggColor.Red;
  }

  // rule : if (portionSize <= portionSizeLimit) then refrenceValue will be equal to `per_100g`
  // but if (portionSize > portionSizeLimit) refrenceValue will be equal to `per_serving` which get's calculated
  // this way (per_100g * portionSize) / 100

  const [greenRange, amberRange] =
    LIMITS.underPortionLimit[type][nutritionType];

  const color =
    per_100g <= greenRange
      ? EggColor.Green
      : per_100g <= amberRange
      ? EggColor.Amber
      : EggColor.Red;

  return color;
};

const rowColor = (index: number, eggColors: any, depth: number): EggColor => {
  if (depth > 0) return EggColor.White;

  switch (index) {
    case 2:
      return eggColors.fat;
    case 3:
      return eggColors.saturates;
    case 5:
      return eggColors.sugars;
    case 8:
      return eggColors.salt;
    default:
      return EggColor.White;
  }
};

const numberNormalize = (
  num: number,
  decimalCount = 2,
  roundToWholeNumber = true,
) => {
  if (Number.isNaN(num) || !Number.isFinite(num)) return 0;

  let X = (Math.round(num * 100) / 100).toFixed(decimalCount);

  if (roundToWholeNumber) {
    X = X.replace(/\.?0+$/, '');
  }

  return +X;
};

const isSubRow = (name: string) => name.toLowerCase().includes('subrows');

const fetchRowTitle = (
  formValues: UnpackNestedValue<Nutrition>,
  name: string,
) => {
  const rowIndex = name?.split('.')[1];

  const targetRow = formValues.typical_values[+rowIndex];

  if (!isSubRow(name)) return targetRow.title;

  const subRowIndex = name?.split('.')[3];

  return formValues.typical_values[+rowIndex].subrows[+subRowIndex].title;
};

const ensureNumber = (Y: number | string | null | undefined) => Number(Y);

const keepOneDecimal = (value: number) => Math.round(value * 10) / 10;

const calculateAndSetEnergy = (
  formValues: UnpackNestedValue<Nutrition>,
  { autoCalculateEnergy }: { autoCalculateEnergy: boolean },
) => {
  let sumKJ = 0;
  let sumKcal = 0;

  const servingSize = ensureNumber(formValues?.serving_size);
  const refrenceIntake = ensureNumber(
    get(formValues, 'typical_values.0.reference_intake'),
  );

  const KjPer100g = formValues?.typical_values?.[0]?.per_100g;
  const KcalPer100g = formValues?.typical_values?.[1]?.per_100g;

  if (autoCalculateEnergy) {
    for (let i = 2; i < formValues?.typical_values.length; i += 1) {
      const targetRow: any = get(formValues, `typical_values.${i}`);

      sumKJ +=
        ensureNumber(targetRow?.per_100g) *
        ensureNumber(targetRow?.conservation_factor_for_kj);

      sumKcal +=
        ensureNumber(targetRow?.per_100g) *
        ensureNumber(targetRow?.conservation_factors_for_kcal);

      // eslint-disable-next-line @typescript-eslint/no-loop-func
      targetRow?.subrows?.forEach((el: any) => {
        sumKJ +=
          ensureNumber(el?.per_100g) *
          ensureNumber(el?.conservation_factor_for_kj);

        sumKcal +=
          ensureNumber(el?.per_100g) *
          ensureNumber(el?.conservation_factors_for_kcal);
      });
    }
  } else {
    sumKJ = KjPer100g || 0;
    sumKcal = KcalPer100g || 0;
  }

  const resultFormTypicalValues = [...formValues.typical_values];

  resultFormTypicalValues.splice(0, 1, {
    ...resultFormTypicalValues[0],
    per_100g: sumKJ ? Math.round(sumKJ * 100) / 100 : null,
    per_100g_rounded: sumKJ ? Number(sumKJ).toFixed(0) : null,
    per_serving: sumKJ ? keepOneDecimal((sumKJ * servingSize) / 100) : null,
    ri_per_100: sumKJ ? Math.round((sumKJ / refrenceIntake) * 100) : null,
    ri_per_serving: sumKJ
      ? Math.round(((sumKJ * servingSize) / 100 / refrenceIntake) * 100)
      : null,
  });

  resultFormTypicalValues.splice(1, 1, {
    ...resultFormTypicalValues[1],
    per_100g: sumKcal ? Math.round(sumKcal * 100) / 100 : null,
    per_100g_rounded: sumKcal ? Number(sumKcal).toFixed(0) : null,
    per_serving: sumKcal ? keepOneDecimal((sumKcal * servingSize) / 100) : null,
    ri_per_100: sumKcal ? Math.round((sumKcal / refrenceIntake) * 100) : null,
    ri_per_serving: sumKcal
      ? Math.round(((sumKcal * servingSize) / 100 / refrenceIntake) * 100)
      : null,
  });

  return resultFormTypicalValues;
};

const allWatchHandler = ({
  form,
  type,
  name,
  value,
  setIsEnergyReadOnly,
}: AllWatchHandlerParams) => {
  if (!type) {
    setIsEnergyReadOnly(!!value.auto_calculate_kj_kcal);
  }

  const servingSize = ensureNumber(value?.serving_size);

  const setMineralOrVitaminValue = (
    dataGroupKey: 'minerals' | 'vitamins',
    index: number,
  ) => {
    const BASE_KEY: `${typeof dataGroupKey}.${number}` = `${dataGroupKey}.${+index}`;

    const { per_100g_ml } = value[dataGroupKey][+index];

    const RiForAdult = value[dataGroupKey][+index].ri_for_adult ?? 0;
    // this one should be treated as a CONSTANT in calculations
    // & should always have a NUMBER value , hence the `?? 0`

    const perServingValue = ((Number(per_100g_ml) ?? 0) * servingSize) / 100;

    form.setValue(
      `${BASE_KEY}.per_serving`,
      per_100g_ml ? keepOneDecimal(perServingValue) : null,
    );

    form.setValue(
      `${BASE_KEY}.ri_per_100g_ml`,
      per_100g_ml ? Math.round((Number(per_100g_ml) / RiForAdult) * 100) : null,
    );

    form.setValue(
      `${BASE_KEY}.ri_per_serving`,
      per_100g_ml ? Math.round((perServingValue / RiForAdult) * 100) : null,
    );
  };

  const isValueUsefull = (val: unknown) =>
    !(val === '' || val === null || val === undefined);

  if (type === 'change' && name && !Number.isNaN(+get(value, name))) {
    const rowIndex = +name?.split('.')[1];

    switch (true) {
      case name?.startsWith('typical_values.'): {
        if (Number.isFinite(rowIndex)) {
          const WHOLE_INDEX = isSubRow(name)
            ? `${+rowIndex}.subrows.${+name?.split('.')[3]}`
            : `${+rowIndex}`;

          const per_100g = ensureNumber(
            get(value, `typical_values.${WHOLE_INDEX}.per_100g`),
          );
          const refrenceIntake = ensureNumber(
            get(value, `typical_values.${WHOLE_INDEX}.reference_intake`),
          );

          const rowTitle = fetchRowTitle(value, name);

          const per_100g_rounded = roundingCalculator(per_100g, rowTitle);

          const per_serving = numberNormalize((per_100g * servingSize) / 100);
          const ri_per_100 = numberNormalize(
            Math.round((per_100g / refrenceIntake) * 100),
          );
          const ri_per_serving = numberNormalize(
            Math.round(((per_100g * servingSize) / 100 / refrenceIntake) * 100),
          );

          const setFormTypicalValue = (
            subKey: string,
            newValue: any,
            options: { checkPer100g?: boolean },
          ) => {
            const formattedNewValue = options?.checkPer100g
              ? isValueUsefull(
                  get(value, `typical_values.${WHOLE_INDEX}.per_100g`),
                )
                ? newValue
                : null
              : newValue;

            form.setValue(
              `typical_values.${WHOLE_INDEX}.${subKey}` as FieldPath<Nutrition>,
              formattedNewValue,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          };

          setFormTypicalValue('per_100g_rounded', per_100g_rounded, {
            checkPer100g: true,
          });
          setFormTypicalValue('per_serving', keepOneDecimal(per_serving), {
            checkPer100g: true,
          });
          setFormTypicalValue('ri_per_100', ri_per_100, {
            checkPer100g: true,
          });
          setFormTypicalValue('ri_per_serving', ri_per_serving, {
            checkPer100g: true,
          });

          // if (rowIndex >= 2) {
          const typicalValuesWithCalculatedEnergyValues = calculateAndSetEnergy(
            value,
            {
              autoCalculateEnergy: value?.auto_calculate_kj_kcal,
            },
          );

          form.setValue(
            'typical_values',
            typicalValuesWithCalculatedEnergyValues,
          );
          // }
        }

        return null;
      }

      case name?.startsWith('auto_calculate_kj_kcal'): {
        if (!!value?.auto_calculate_kj_kcal && value?.typical_values?.length) {
          const typicalValuesWithCalculatedEnergyValues = calculateAndSetEnergy(
            value,
            {
              autoCalculateEnergy: value?.auto_calculate_kj_kcal,
            },
          );

          form.setValue(
            'typical_values',
            typicalValuesWithCalculatedEnergyValues,
          );
        }

        return setIsEnergyReadOnly(!!value?.auto_calculate_kj_kcal);
      }

      case name?.startsWith('serving_size'): {
        if (value?.typical_values?.length) {
          for (
            let index = 0;
            index < value?.typical_values.length;
            index += 1
          ) {
            const { per_100g, reference_intake } = value.typical_values[index];

            const per_serving = numberNormalize(
              keepOneDecimal((ensureNumber(per_100g) * servingSize) / 100),
            );
            const ri_per_serving = numberNormalize(
              Math.round(
                ((ensureNumber(per_100g) * servingSize) /
                  100 /
                  ensureNumber(reference_intake)) *
                  100,
              ),
            );

            form.setValue(
              `typical_values.${index}.per_serving`,
              isValueUsefull(per_100g) ? per_serving : null,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
            form.setValue(
              `typical_values.${index}.ri_per_serving`,
              isValueUsefull(per_100g) ? ri_per_serving : null,

              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          }
        }

        if (value.vitamins?.length) {
          for (let index = 0; index < value?.vitamins.length; index += 1) {
            setMineralOrVitaminValue('vitamins', index);
          }
        }

        if (value.minerals?.length) {
          for (let index = 0; index < value?.minerals.length; index += 1) {
            setMineralOrVitaminValue('minerals', index);
          }
        }

        return null;
      }

      case name.startsWith('minerals.') || name.startsWith('vitamins.'): {
        const [dataGroupKey, index] = name?.split('.') as [
          'minerals' | 'vitamins',
          string,
        ];

        setMineralOrVitaminValue(dataGroupKey, +index);

        return null;
      }

      default:
        return null;
    }
  }

  return null;
};

const handleBgColorTailwind = (color?: string) => {
  switch (color) {
    case 'green':
      return 'bg-green-light';
    case 'yellow':
      return 'bg-yellow-light';
    case 'red':
      return 'bg-red-light';
    case 'white':
      return 'bg-white';
    default:
      return 'bg-white';
  }
};

const handleTextColorTailwind = (color?: string) => {
  switch (color) {
    case 'green':
      return 'text-green-500 bg-green-lighter';
    case 'yellow':
      return 'text-yellow-500 bg-yellow-lighter';
    case 'red':
      return 'text-red-500 bg-red-lighter';
    case 'white':
      return 'text-gray bg-gray-100';
    default:
      return 'text-white bg-gray-100';
  }
};

const sampleMineralRow: NutritionMineralsSchema = {
  title: '',
  declare: false,
  per_100g_ml: null,
  per_serving: null,
  ri_for_adult: 0, // this one should always have a NUMBER value , since it's used in calculations
  ri_per_100g_ml: null,
  ri_per_serving: null,
  unit_text: '',
};

const sampleVitaminsRow: NutritionMineralsSchema = {
  title: '',
  declare: false,
  per_100g_ml: null,
  per_serving: null,
  ri_for_adult: 0, // this one should always have a NUMBER value , since it's used in calculations
  ri_per_100g_ml: null,
  ri_per_serving: null,
  unit_text: '',
};

export {
  eggColorCalculator,
  rowColor,
  numberNormalize,
  allWatchHandler,
  handleBgColorTailwind,
  handleTextColorTailwind,
  sampleMineralRow,
  sampleVitaminsRow,
};
