import React from 'react';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import PolicyHooks from '@hooks/api/policies';
import { AddPolicyParams } from '@hooks/api/policies/types';

import {
  Modal,
  Input,
  Button,
  Switch,
  UploadButtonUploadOnChange as UploadButton,
} from '@components/Common';

interface P {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationSchema = yup.object({
  title: yup.string().required('Policy title is required'),
  file: yup.string().required('Policy file is required'),
});

const NewPolicyModalComp: React.FC<P> = ({ open, setOpen }) => {
  const form = useForm<AddPolicyParams>({
    defaultValues: {
      title: '',
      acknowledgement_required: false,
      file: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const addPolicyMutation = PolicyHooks.useAddPolicyMutation();

  const onSubmit: SubmitHandler<AddPolicyParams> = async data => {
    try {
      await addPolicyMutation.mutateAsync(data);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center',
          'bg-white-600 shadow-lg min-w-120',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            'bg-white-400 h-10',
          )}
        >
          <h3 className="font-roboto text-base">New Policy</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col items-center p-4 min-w-full"
        >
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Policy Title</p>

            <Controller
              name="title"
              control={form.control}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <div className="flex flex-col">
                  <Input
                    className="w-56"
                    placeholder="Title"
                    innerRef={ref}
                    {...rest}
                  />

                  {fieldState.error?.message ? (
                    <p className="text-red my-1">{fieldState.error?.message}</p>
                  ) : null}
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-between items-center min-w-full my-2">
            <p className="font-roboto">Acknowledgement Required</p>

            <Controller
              name="acknowledgement_required"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <Switch
                  className="w-56 justify-center"
                  type="secondary"
                  value={value ? 'Yes' : 'No'}
                  onChange={newVal => onChange(newVal.toLowerCase() === 'yes')}
                />
              )}
            />
          </div>

          <div className="flex flex-row justify-between items-center min-w-full my-4">
            <p className="font-roboto">Upload File</p>

            <Controller
              name="file"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col text-center items-center">
                  <UploadButton
                    wrapperClassName="w-56 text-center"
                    id="unique-id"
                    url="/policies/upload"
                    value={value}
                    onChange={onChange}
                  />

                  {fieldState.error?.message ? (
                    <p className="text-red my-1">{fieldState.error?.message}</p>
                  ) : null}
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-end items-center min-w-full mt-2">
            <Button
              type="submit"
              className="text-green border-green"
              variant="outlined"
              startIcon={<Check />}
              text="Save"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewPolicyModalComp;
