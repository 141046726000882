import React from 'react';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
} from '@components/Common';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Suppliers/Manufacturers/ManufacturersList/AttributeFiltersModal';
import Menu, {
  MenuItemTypes,
} from '@components/Suppliers/Manufacturers/ManufacturersList/Menu';

import ManufacturerHooks from '@hooks/api/manufacturers';
import {
  UseFilteredManufacturersParams,
  DetailedManufacturer,
} from '@hooks/api/manufacturers/types';

import { usePrevious, useDebounce } from '@hooks/utils';

import { formatDate } from '@utils';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): UseFilteredManufacturersParams => {
  const formattedValues = {
    ...(filterValues?.specificationStatus && {
      cardbox_status: filterValues?.specificationStatus?.code,
    }),

    ...(filterValues?.approveDate?.from && {
      from_approve_date: formatDate(filterValues.approveDate.from),
    }),

    ...(filterValues?.approveDate?.to && {
      to_approve_date: formatDate(filterValues.approveDate.to),
    }),
  };

  return formattedValues;
};

const ManufacturersListComp = () => {
  const history = useHistory();
  const [user] = useUser();

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [manufacturerFilters, setManufacturerFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  const [selectedManufacturerCode, setSelectedManufacturerCode] =
    React.useState('');

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: manufacturersData, ...useFilteredManufacturersUtils } =
    ManufacturerHooks.useFilteredManufacturers({
      page: currentPage,
      q: debouncedSearch,
      ...(manufacturerFilters
        ? formatFilterAttributes(manufacturerFilters)
        : {}),
    });

  const deleteManufacturerMutation =
    ManufacturerHooks.useDeleteManufacturerMutation();

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<DetailedManufacturer>) => {
      if (actionType === 'View') {
        history.push(
          `${RouteMap.suppliers.manufacturerInfo.replace(
            ':manufacturerCode?',
            row.original.manufacture_code,
          )}`,
        );
      }

      if (actionType === 'Delete') {
        setSelectedManufacturerCode(row.original.manufacture_code);
      }
    },
    [],
  );

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof DetailedManufacturer | 'counter' | 'action';
      id: keyof DetailedManufacturer | 'counter' | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<DetailedManufacturer>) => JSX.Element);
    }> => [
      {
        Header: 'Manufacturer',
        accessor: 'site_name',
        id: 'site_name',
        width: 228,
      },
      {
        Header: 'Supplier',
        accessor: 'supplier_name',
        id: 'supplier_name',
        width: 241,
      },
      {
        Header: 'Status',
        accessor: 'cardbox_status',
        id: 'cardbox_status',
        width: 90,
      },
      {
        Header: 'Approval Date',
        accessor: 'approval_date',
        id: 'approval_date',
        width: 130,
      },
      {
        Header: 'Manufacturer Country',
        accessor: 'country',
        id: 'country',
        width: 260,
        Cell: cell => <span>{cell.value?.name}</span>,
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={
              user?.access_levels.manufacture_soft_delete
                ? ['View', 'Delete']
                : ['View']
            }
            open={openTableActionMenuId === cell.row.id}
            onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
            onClose={() => setOpenTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onMenuItemClick(actionType, cell.row);

              setOpenTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [
      onMenuItemClick,
      openTableActionMenuId,
      useFilteredManufacturersUtils.dataUpdatedAt,
    ],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={!!selectedManufacturerCode}
        setOpen={() => setSelectedManufacturerCode('')}
        onConfirm={async () => {
          try {
            await deleteManufacturerMutation.mutateAsync({
              manufacture_code: selectedManufacturerCode,
            });
          } catch (error) {
          } finally {
            setSelectedManufacturerCode('');
          }
        }}
      />

      <AttributeFiltersModal
        values={manufacturerFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setManufacturerFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Supplier' },
            { href: '#', text: 'Manufacturer List' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {manufacturerFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setManufacturerFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <p className="text-base text-primary-light font-roboto">
            Manufacturers List
          </p>

          {user?.access_levels.manufacture_create_update && (
            <div className="flex flex-row items-center">
              <Button
                variant="outlined"
                className="border-1 border-primary"
                onClick={() =>
                  history.push(
                    `${RouteMap.suppliers.manufacturerInfo.replace(
                      ':manufacturerCode?',
                      '',
                    )}?editMode=true`,
                  )
                }
                startIcon={<AddIcon />}
                text="Add New"
              />
            </div>
          )}
        </div>

        <Table
          loading={useFilteredManufacturersUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto last-item-center"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          getRowProps={row => ({
            onClick: () =>
              history.push(
                `${RouteMap.suppliers.manufacturerInfo.replace(
                  ':manufacturerCode?',
                  row.original.manufacture_code,
                )}`,
              ),
          })}
          columns={columns}
          data={manufacturersData?.data || []}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: manufacturersData?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default ManufacturersListComp;
