import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Logo_Green from '@assets/images/logo-green.svg';
import Logo_Black from '@assets/images/logo-black.svg';

const LOGOS = {
  green: Logo_Green,
  black: Logo_Black,
} as const;

const useStyles = makeStyles((theme: any) => ({
  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

type P = {
  className?: string;
  color?: 'green' | 'black';
};

const Logo: React.FC<P> = ({ className = '', color = 'green' }) => {
  const classes = useStyles();

  return (
    <img
      src={LOGOS[color]}
      alt="logo"
      className={clsx([classes.logo, className])}
    />
  );
};

export default Logo;
