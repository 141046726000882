import React, { useEffect } from 'react';
import { useForm, Controller, FieldPath } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import { Divider, IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, Button, TextArea, CheckBox } from '@components/Common';
import { ProductSpecTabsInstance } from '@app/hooks/api/wizard/types';

interface submitModalForm {
  nutritional: boolean;
  ingredients: boolean;
  intgraty: boolean;
  allergen: boolean;
}

type P = {
  header: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  submit: () => void;
  warnings?: Array<ProductSpecTabsInstance>;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(65),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const validationSchema = yup.object({
  message: yup.string().required('Product Name is required'),
});

const NewProductModal: React.FC<P> = ({
  open,
  setOpen,
  header,
  submit,
  warnings,
}) => {
  const classes = useStyles();

  const form = useForm<submitModalForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (warnings) {
      form.reset({
        nutritional: warnings[4].color !== 'warning' || false,
        ingredients: warnings[1].color !== 'warning' || false,
        intgraty: warnings[2].color !== 'warning' || false,
        allergen: warnings[3].color !== 'warning' || false,
      });
    }
  }, [warnings]);

  const cancel = async () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async () => {
    submit();
    form.reset();
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">{header}</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className=" justify-between items-center min-w-full mb-2">
            <h4 className="font-roboto mb-4">Please confirm:</h4>

            {warnings && (
              <>
                {warnings[1].color === 'warning' && (
                  <>
                    <div
                      className={`flex justify-between items-center px-3 py-2 bb- ${
                        !form.watch('ingredients') && 'bg-yellow-lighter'
                      } `}
                    >
                      <div className="mr-2">
                        Product contains unauthorised E number (mention chosen E
                        number)
                      </div>
                      <Controller
                        name="ingredients"
                        control={form.control}
                        render={({ field: { value, onChange } }) => (
                          <CheckBox
                            checked={!!value}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>
                    <Divider className="my-1" />
                  </>
                )}
                {warnings[2].color === 'warning' && (
                  <>
                    <div
                      className={`flex justify-between items-center px-3 py-2 bb- ${
                        !form.watch('intgraty') && 'bg-yellow-lighter'
                      } `}
                    >
                      <div className="mr-2">
                        Products contains Genetically modified ingredients
                      </div>
                      <Controller
                        name="intgraty"
                        control={form.control}
                        render={({ field: { value, onChange } }) => (
                          <CheckBox
                            checked={!!value}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>
                    <Divider className="my-1" />
                  </>
                )}
                {warnings[3].color === 'warning' && (
                  <>
                    <div
                      className={`flex justify-between items-center px-3 py-2 bb- ${
                        !form.watch('allergen') && 'bg-yellow-lighter'
                      } `}
                    >
                      <div className="mr-2">
                        Product contain(s) undeclared/declared allergen(s) which
                        is chosen by user manually
                      </div>
                      <Controller
                        name="allergen"
                        control={form.control}
                        render={({ field: { value, onChange } }) => (
                          <CheckBox
                            checked={!!value}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>
                    <Divider className="my-1" />
                  </>
                )}
                {warnings[4].color === 'warning' && (
                  <div
                    className={`flex justify-between items-center px-3 py-2 bb- ${
                      !form.watch('nutritional') && 'bg-yellow-lighter'
                    } `}
                  >
                    <div className="mr-2">
                      Product salt level, exceeds selected target salt value
                    </div>
                    <Controller
                      name="nutritional"
                      control={form.control}
                      render={({ field: { value, onChange } }) => (
                        <CheckBox
                          checked={!!value}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="flex flex-row justify-end items-center min-w-full mt-2">
            <div className="flex flex-row">
              <Button
                variant="text"
                text="Cancel"
                onClick={() => cancel()}
                className="mr-2 text-sm font-roboto text-gray-800"
              />

              <Button
                size="medium"
                className="bg-green"
                text="Confirm"
                disabled={
                  !form.watch('nutritional') ||
                  !form.watch('ingredients') ||
                  !form.watch('intgraty') ||
                  !form.watch('allergen')
                }
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
