import { AxiosResponse } from 'axios';

import axios from '@lib/api';
import {
  GetProductsParams,
  GetDetailedProductsParams,
  // GetFilteredProductsParams,
  AddProductParams,
  UpdateProductParams,
  DeleteProductParams,
  RestoreProductParams,
  UseProductsType,
  UseProductType,
  ShareProductParams,
  NewVersionProductParams,
  ReportParams,
} from './types';

const getProducts = async (params?: GetProductsParams) => {
  const res: AxiosResponse<UseProductsType> = await axios({
    method: 'get',
    url: '/products',
    params,
  });

  return res.data;
};

const getDetailedProduct = async (params: GetDetailedProductsParams) => {
  const res: AxiosResponse<UseProductType> = await axios({
    method: 'get',
    url: `/products/${params.code}/version/${params.version}`,
  });

  return res.data;
};

// const getFilteredProducts = async (params?: GetFilteredProductsParams) => {
//   const res: AxiosResponse<UseProductsType> = await axios({
//     method: 'get',
//     url: '/products/filter',
//     params,
//   });

//   return res.data;
// };

const addProduct = async (params: AddProductParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: '/products',
    data: params,
  });

  return res.data;
};

const newVersionProduct = async (params: NewVersionProductParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: `/products/${params.code}/newversion/${params.version}`,
  });

  return res.data;
};

const updateProdcut = async (params: UpdateProductParams) => {
  const { code, version, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${params.code}/version/${params.version}`,
    data,
  });

  return res.data;
};

const deleteProdcut = async (params: DeleteProductParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${params.code}/version/${params.version}/trash`,
  });

  return res.data;
};

const restoreProdcut = async (params: RestoreProductParams) => {
  const res: AxiosResponse = await axios({
    method: 'get',
    url: `/products/${params.code}/version/${params.version}/restore`,
  });

  return res.data;
};

const shareProduct = async (params: ShareProductParams) => {
  const { code, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'post',
    url: `/products/${code}/share`,
    data,
  });

  return res.data;
};

const report = async (params: ReportParams) => {
  const { code, version, spec, isFull } = params;

  if (spec) {
    const res: AxiosResponse = await axios({
      method: 'get',
      responseType: 'blob',
      url: `/products/${code}/version/${version}/report?spec=${spec}&isfull=${isFull}`,
    });

    return res.data;
  }
  const res: AxiosResponse = await axios({
    method: 'get',
    responseType: 'blob',
    url: `/products/${code}/version/${version}/report?isfull=${isFull}`,
  });

  return res.data;
};

export default {
  getProducts,
  getDetailedProduct,
  // getFilteredProducts,
  shareProduct,
  addProduct,
  newVersionProduct,
  updateProdcut,
  deleteProdcut,
  restoreProdcut,
  report,
};
