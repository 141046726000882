import React from 'react';
import { useForm, Controller, FieldPath } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, Button, TextArea } from '@components/Common';

interface rejectModalForm {
  message: string;
}

type P = {
  header: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  submit: (data: rejectModalForm) => void;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(65),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const validationSchema = yup.object({
  message: yup.string().required('Message is required'),
});

const NewProductModal: React.FC<P> = ({ open, setOpen, header, submit }) => {
  const classes = useStyles();

  const form = useForm<rejectModalForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const cancel = async () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (data: rejectModalForm) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        return;
      }

      submit(data);
      form.reset();
      setOpen(false);
    } catch (e) {}
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">{header}</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className=" justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Message*</p>

            <div className=" w-full">
              <Controller
                name="message"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <TextArea
                      className="h-full w-full"
                      inputClassName="h-full w-full"
                      placeholder="Message ..."
                      multiline
                      rows={4}
                      value={value}
                      onChange={onChange}
                    />
                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-end items-center min-w-full mt-2">
            <div className="flex flex-row">
              <Button
                variant="text"
                text="Cancel"
                onClick={() => cancel()}
                className="mr-2 text-sm font-roboto text-gray-800"
              />

              <Button
                size="medium"
                className="bg-green"
                text="Confirm"
                onClick={() => onSubmit(form.getValues())}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
