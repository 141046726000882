import React, { useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { Divider } from '@material-ui/core';

import { Layout } from '@components/Common';

import { Stepper } from '@components/Bussines/Products/ProductForm';

type P = {
  children: React.ReactNode;
  shouldShowTransitionPrompt?: boolean;
  share?: boolean;
};

const CompositeLayout: React.FC<P> = ({
  children,
  shouldShowTransitionPrompt = false,
  share = false,
}) => {
  const location = useLocation();

  const promptMessage =
    'You have unsaved changes, are you sure you want to leave?';

  React.useEffect(() => {
    if (shouldShowTransitionPrompt) {
      // eslint-disable-next-line consistent-return
      window.onbeforeunload = event => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      };
    } else {
      window.onbeforeunload = () => {};
    }
  }, [shouldShowTransitionPrompt]);

  return (
    <Layout share={share}>
      <Prompt
        message={nextLocation => {
          // navigation prompt should only happen when pathname is about to change
          // not on urlParams change or location.search change
          if (
            nextLocation.pathname !== location.pathname &&
            shouldShowTransitionPrompt
          )
            return promptMessage;

          return true;
        }}
      />

      <Stepper className="mt-10 mb-4" share={share} />
      <Divider />

      {children}
    </Layout>
  );
};

export default CompositeLayout;
