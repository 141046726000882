import { useQuery, UseQueryOptions, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import generalApis from '@hooks/api/general/api';
import {
  UseAccessLevelsResponseType,
  UseStatusTypes,
  ShareSAQResponse,
  GetCustomerProfileResponse,
  UseCountriesType,
  GetAccessLevelsParams,
  GetSettingsParams,
  UseSettingsResponseType,
  UseLicenseTypes,
  GetLicenseTypesParams,
} from '@hooks/api/general/types';

import errorHandler from '@utils/errorHandler';

const KEYS = {
  country: ['country'],
  status: ['status'],
  license: ['license'],
  saq: ['saq'],
  customerProfile: ['customer-profile'],
  accessLevels: ['accessLevels'],
  settings: ['settings'],
} as const;

const useCountries = (
  options?: UseQueryOptions<UseCountriesType, AxiosError>,
) => {
  const query = useQuery<UseCountriesType, AxiosError>(
    [...KEYS.country],
    () => generalApis.getCountries(),
    { cacheTime: Infinity, staleTime: Infinity, ...options },
  );

  return query;
};

const useAllStatus = (
  options?: UseQueryOptions<UseStatusTypes, AxiosError>,
) => {
  const query = useQuery<UseStatusTypes, AxiosError>(
    [...KEYS.status],
    () => generalApis.getAllStatus(),
    { cacheTime: Infinity, staleTime: Infinity, ...options },
  );

  return query;
};

const useLicenseType = (
  params: GetLicenseTypesParams,
  options?: UseQueryOptions<UseLicenseTypes, AxiosError>,
) => {
  const query = useQuery<UseLicenseTypes, AxiosError>(
    [...KEYS.license],
    () => generalApis.getLicenseType(params),
    { cacheTime: Infinity, staleTime: Infinity, ...options },
  );

  return query;
};

const useUploadMutation = () => {
  const mutation = useMutation(generalApis.uploadFile, {
    onError: errorHandler,
  });

  return mutation;
};

const useSAQ = (options?: UseQueryOptions<ShareSAQResponse, AxiosError>) => {
  const query = useQuery<ShareSAQResponse, AxiosError>(
    [...KEYS.saq],
    () => generalApis.getSAQ(),
    options,
  );

  return query;
};

const useShareSAQMutation = () => {
  const mutation = useMutation(generalApis.shareSAQ, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useCustomerProfile = (
  options?: UseQueryOptions<GetCustomerProfileResponse, AxiosError>,
) => {
  const query = useQuery<GetCustomerProfileResponse, AxiosError>(
    [...KEYS.customerProfile],
    () => generalApis.getCustomerProfile(),
    options,
  );

  return query;
};

const useUpdateCustomerProfileMutation = () => {
  const mutation = useMutation(generalApis.updateCustomerProfile, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useAllAccessLevels = (
  params: GetAccessLevelsParams,
  options?: UseQueryOptions<UseAccessLevelsResponseType, AxiosError>,
) => {
  const query = useQuery<UseAccessLevelsResponseType, AxiosError>(
    [...KEYS.accessLevels, params.code],
    () => generalApis.getAccessLevels(params),
    options,
  );

  return query;
};

const useSettings = (
  params: GetSettingsParams,
  options?: UseQueryOptions<UseSettingsResponseType, AxiosError>,
) => {
  const query = useQuery<UseSettingsResponseType, AxiosError>(
    [...KEYS.settings, params.type],
    () => generalApis.getSettings(params),
    options,
  );

  return query;
};

export default {
  useCountries,
  useAllStatus,
  useLicenseType,
  useUploadMutation,
  useSAQ,
  useShareSAQMutation,
  useCustomerProfile,
  useUpdateCustomerProfileMutation,
  useAllAccessLevels,
  useSettings,
};
