/* eslint-disable no-nested-ternary */
import React from 'react';
import { useForm, Controller, FieldPath, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Add } from '@material-ui/icons';

import {
  useUrlParams,
  generalHook as GeneralHooks,
  wizardHook as WizardHooks,
} from '@hooks';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import Row from '@components/Bussines/Products/ProductForm/Integrity/Row';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import {
  AutoComplete,
  AutoCompleteMultiple,
  AutoCompleteMultipleChoiseItem,
  Switch,
  Button,
  TextArea,
  Input,
  CountryAutoComplete,
  UploadButtonUploadOnChange as UploadButton,
} from '@components/Common';

import { Integrity } from '@hooks/api/wizard/types';
import { Country } from '@hooks/api/generalTypes';

import { deepClone } from '@utils';

import {
  ROW_SAMPLES,
  DEFAULT_EXPAND_STATE,
  ExpandableKeys,
  ExpandableValuesUnion,
} from '@components/Bussines/Products/ProductForm/Integrity/utils';

const hasExpandStateChanged = (
  data: Integrity | undefined,
  currentExpandState: typeof DEFAULT_EXPAND_STATE,
) => {
  if (!data) return false;

  const originalExpandState = data.expanded_state;

  return (
    JSON.stringify(originalExpandState) !== JSON.stringify(currentExpandState)
  );
};

type RowKeys = ExpandableKeys | 'additional_tables';

const Questions = [
  'Product or subcomponents contain Palm Oil? (includes Palm Oil and Palm Kernel Oil)',
  'Product or subcomponents contain Soya?',
  'Product or subcomponents contain Egg?',
  'Product or subcomponents contain any GM materials?',
  'Product or subcomponents contain Meat',
  'Product or subcomponents contain Fish or Seafood',
  'Product or subcomponents contain Artificial Colours?',
  'Product or subcomponents contain Artificial Flavourings?',
  'Product or subcomponents contain Artificial Preservatives?',
  'Product or subcomponents contain Artificial Additives?',
  'Product been Treated by Irradiation',
] as const;

const customValidator = (index: number) => {
  return yup
    .mixed()
    .test(
      'expanded_state',
      `you need to specify if ${Questions[index].toLowerCase()}`,
      val => typeof val === 'boolean',
    );
};

const inside = (name: string, type: string, valType = 'string') => {
  return yup.mixed().test(name, 'error', (val, contxt: any) => {
    if (
      contxt.from[contxt.from.length - 1].value.expanded_state[type] === false
    ) {
      return true;
    }

    switch (valType) {
      case 'string':
        return !!val;
      case 'country':
        return !!val.length;
      case 'AutoCompleteMultiple':
        return !!val.length;
      case 'switch':
        return typeof val === 'boolean';
      case 'RowAutoComplete':
        if (val !== null) {
          if (val.length !== 0 && val.name !== null) {
            return true;
          }
        }
        return false;
      default:
        return !!val;
    }
  });
};

const validationSchema = yup.object({
  expanded_state: yup.object({
    palm: customValidator(0),
    egg: customValidator(1),
    gm: customValidator(2),
    meat: customValidator(3),
    seafood: customValidator(4),
    colours: customValidator(5),
    flavourings: customValidator(6),
    preservatives: customValidator(7),
    additives: customValidator(8),
    irradiation: customValidator(9),
    soya: customValidator(10),
  }),

  irradiation: yup.array().of(
    yup.object().shape({
      explain: inside('explain', 'irradiation'),
    }),
  ),

  additives: yup.array().of(
    yup.object().shape({
      name: inside('name', 'additives'),
      e_number_name: inside('e_number_name', 'additives'),
      percent: inside('percent', 'additives'),
      country_of_origin: inside('country_of_origin', 'additives', 'country'),
      source_of_material: inside('source_of_material', 'additives'),
    }),
  ),

  additional_tables: yup.array().of(
    yup.object().shape({
      title: yup.mixed().test('title', 'error', val => !!val),
      value: yup.mixed().test('value', 'error', val => !!val),
    }),
  ),

  palm: yup.array().of(
    yup.object().shape({
      ingredient_name: inside('ingredient_name', 'palm'),
      percent: inside('percent', 'palm'),
      country_of_origin: inside('country_of_origin', 'palm', 'country'),
      certificate: yup
        .mixed()
        .test('certificate', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state.palm ===
            false
          ) {
            return true;
          }

          if (contxt.parent.certified === false) {
            return true;
          }
          return !!val;
        }),
      certified: inside('certified', 'palm', 'switch'),
      accreditation_status: yup
        .mixed()
        .test('accreditation_status', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state.palm ===
            false
          ) {
            return true;
          }
          if (!contxt.parent.certified) {
            return true;
          }
          if (val !== null) {
            if (val.length !== 0 && val.name !== null) {
              return true;
            }
          }
          return false;
        }),
    }),
  ),
  soya: yup.array().of(
    yup.object().shape({
      ingredient_name: inside('ingredient_name', 'soya'),
      percent: inside('percent', 'soya'),
      country_of_origin: inside('country_of_origin', 'soya', 'country'),
      certificate: yup
        .mixed()
        .test('certificate', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state.soya ===
            false
          ) {
            return true;
          }

          if (contxt.parent.certified === false) {
            return true;
          }
          return !!val;
        }),
      certified: inside('certified', 'soya', 'switch'),
      accreditation_status: yup
        .mixed()
        .test('accreditation_status', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state.soya ===
            false
          ) {
            return true;
          }

          if (contxt.parent.certified === false) {
            return true;
          }
          return !!val;
        }),
    }),
  ),

  egg: yup.array().of(
    yup.object().shape({
      ingredient_name: inside('ingredient_name', 'egg'),
      is_pasteurized: inside('is_pasteurized', 'egg', 'switch'),
      country_of_origin: inside('country_of_origin', 'egg', 'country'),
      // welfare: inside('welfare', 'egg'),
      // certificate: inside('certificate', 'egg'),
    }),
  ),

  gm: yup.array().of(
    yup.object().shape({
      ingredient_name: inside('ingredient_name', 'gm'),
      percent: inside('percent', 'gm'),
      country_of_origin: inside('country_of_origin', 'gm', 'country'),
    }),
  ),

  seafood: yup.array().of(
    yup.object().shape({
      capture_method: inside('capture_method', 'seafood', 'RowAutoComplete'),
      species_latin_name: inside('species_latin_name', 'seafood'),
      caught_method: yup
        .mixed()
        .test('caught_method', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from?.length - 1]?.value?.expanded_state
              .seafood === false ||
            contxt.from[contxt.from?.length - 1]?.value?.expanded_state
              .seafood === null
          ) {
            return true;
          }
          if (contxt.parent.capture_method?.name === 'Farmed') {
            return true;
          }
          return !!val;
        }),
      caught_area_faq_number: yup
        .mixed()
        .test('caught_area_faq_number', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1]?.value?.expanded_state
              .seafood === false ||
            contxt.from[contxt.from.length - 1]?.value?.expanded_state
              .seafood === null
          ) {
            return true;
          }
          if (contxt.parent?.capture_method?.name === 'Farmed') {
            return true;
          }
          return !!val;
        }),
      health_mark: inside('health_mark', 'seafood'),
      // welfare: yup.mixed().test('welfare', 'error', (val, contxt: any) => {
      //   if (
      //     contxt.from[contxt.from.length - 1]?.value?.expanded_state.seafood ===
      //       false ||
      //     contxt.from[contxt.from.length - 1]?.value?.expanded_state.seafood ===
      //       null
      //   ) {
      //     return true;
      //   }
      //   if (contxt.parent?.capture_method?.name === 'Farmed') {
      //     return true;
      //   }
      //   return !!val;
      // }),
      // min_fish_content: yup
      //   .mixed()
      //   .test('min_fish_content', 'error', (val, contxt: any) => {
      //     if (
      //       contxt.from[contxt.from.length - 1]?.value?.expanded_state
      //         .seafood === false ||
      //       contxt.from[contxt.from.length - 1]?.value?.expanded_state
      //         .seafood === null
      //     ) {
      //       return true;
      //     }
      //     if (contxt.parent?.capture_method?.name === 'Farmed') {
      //       return true;
      //     }
      //     return !!val;
      //   }),
    }),
  ),

  colours: yup.array().of(
    yup.object().shape({
      name: inside('name', 'colours'),
      e_number_name: inside('e_number_name', 'colours'),
      percent: inside('percent', 'colours'),
      country_of_origin: inside('country_of_origin', 'colours', 'country'),
      source_of_material: inside('source_of_material', 'colours'),
    }),
  ),

  flavourings: yup.array().of(
    yup.object().shape({
      name: inside('name', 'flavourings'),
      e_number_name: inside('e_number_name', 'flavourings'),
      percent: inside('percent', 'flavourings'),
      country_of_origin: inside('country_of_origin', 'flavourings', 'country'),
      source_of_material: inside('source_of_material', 'flavourings'),
    }),
  ),

  preservatives: yup.array().of(
    yup.object().shape({
      name: inside('name', 'preservatives'),
      e_number_name: inside('e_number_name', 'preservatives'),
      percent: inside('percent', 'preservatives'),
      country_of_origin: inside(
        'country_of_origin',
        'preservatives',
        'country',
      ),
      source_of_material: inside('source_of_material', 'preservatives'),
    }),
  ),

  meat: yup.array().of(
    yup.object().shape({
      meat_type: yup.object().shape({
        meat_species:
          // inside('meat_species', 'meat', 'AutoCompleteMultiple'),
          yup
            .mixed()
            .test('accreditation_status', 'error', (val, contxt: any) => {
              if (
                contxt.from[contxt.from?.length - 1]?.value?.expanded_state
                  .meat === false ||
                contxt.from[contxt.from?.length - 1]?.value?.expanded_state
                  .meat === null
              ) {
                return true;
              }
              if (val !== null) {
                if (val.length !== 0 && val.name !== null && val.name !== '') {
                  return true;
                }
              }
              return false;
            }),
        min_meat_percent: inside('min_meat_percent', 'meat'),
        meat_content: inside('meat_content', 'meat'),
        min_visual_lean: inside('min_visual_lean', 'meat'),
      }),
      packing_site_details: yup.object().shape({
        name_and_address: inside('name_and_address', 'meat'),
        health_mark: inside('health_mark', 'meat'),
        country: inside('country', 'meat', 'country'),
        // welfare: inside('welfare', 'meat', 'AutoCompleteMultiple'),
      }),
      slaughterhouse_details: yup.object().shape({
        name_and_address: inside('name_and_address', 'meat'),
        health_mark: inside('health_mark', 'meat'),
        country: inside('country', 'meat', 'country'),
        // welfare: inside('welfare', 'meat', 'AutoCompleteMultiple'),
      }),
      farm_details: yup.object().shape({
        name_and_address: inside('name_and_address', 'meat'),
        health_mark: inside('health_mark', 'meat'),
        country: inside('country', 'meat', 'country'),
        // welfare: inside('welfare', 'meat', 'AutoCompleteMultiple'),
      }),
    }),
  ),
});

type P = {
  share?: boolean;
};
const IntegrityComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const history = useHistory();
  const FormSteps = Object.values(map.bussines.productForm);
  const currentIndex = FormSteps.findIndex(el =>
    el.includes(history.location.pathname),
  );
  const nextPageLink = FormSteps[currentIndex + 1]
    ? `${FormSteps[currentIndex + 1]}${history.location.search}`
    : '';
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const form = useForm<Integrity>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });
  const formState = useFormState({
    control: form.control,
    name: 'expanded_state',
  });

  const expandedState = form.getValues('expanded_state');

  const { data: allStatus } = GeneralHooks.useAllStatus();
  const { data: originalIntegrity } = WizardHooks.useIntegrity(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        form.reset(data.data);
      },
    },
  );

  const updateIntegrityMutatation = WizardHooks.useUpdateIntegrityMutation();
  const validateIntegrityMutatation =
    WizardHooks.useValidateIntegrityMutation();

  const formatIntegirtyData = React.useCallback(
    (data: Integrity) => {
      const { errors, warnings, cardbox_status, ...cleanValues } = data;

      // if the row has been expanded , we set the value to an empty array (reseting value)
      Object.entries(expandedState).forEach((el: any) => {
        const [key, value]: [ExpandableKeys, unknown] = el;

        if (!value) cleanValues[key] = [];
      });

      return cleanValues;
    },
    [JSON.stringify(expandedState)],
  );

  const onValidate = async (data: Integrity) => {
    console.log('🚀 ~ file: index.tsx ~ line 473 ~ onValidate ~ data', data);
    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      // const formattedData = formatIntegirtyData(data);

      validateIntegrityMutatation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });
    } catch (e) {}
  };

  const onSave = (link: string, data: Integrity) => {
    // const formattedData = formatIntegirtyData(data);
    form.clearErrors();
    updateIntegrityMutatation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onAddRow = React.useCallback((rowKey: RowKeys) => {
    const oldVals = form.getValues()[rowKey];
    const newRow = deepClone({ ...ROW_SAMPLES[rowKey] });

    const newVals = [...oldVals, newRow] as ExpandableValuesUnion;

    form.setValue(rowKey, newVals, {
      shouldDirty: true,
    });

    form.reset(undefined, {
      keepValues: true,
      keepTouched: true,
      keepDirty: true,
      keepErrors: true,
    });
  }, []);

  const onDeleteRow = React.useCallback(
    (rowKey: RowKeys, rowIndex?: number) => {
      const newVals =
        form.getValues()?.[rowKey]?.length > 0 ? form.getValues()[rowKey] : [];

      // additional tables don't have multplie rows ,
      // hence  the ( || 0 )
      newVals.splice(rowIndex || 0, 1);

      form.setValue(rowKey, newVals, { shouldDirty: true });

      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
    },
    [],
  );

  const onExpandChange = React.useCallback(
    (key: ExpandableKeys, val: boolean) => {
      const isRowEmpty = form.getValues()?.[key]?.length === 0;

      form.setValue(`expanded_state.${key}`, val, {
        shouldDirty: true,
        shouldValidate: true,
      });

      if (isRowEmpty && val) {
        const newVal = [
          deepClone({ ...ROW_SAMPLES[key] }),
        ] as ExpandableValuesUnion;
        form.setValue(key, newVal, { shouldDirty: true });
      }

      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
    },
    [],
  );

  const fetchRowData = React.useCallback((key: RowKeys) => {
    const data = form?.getValues()?.[key]?.length ? form.getValues()[key] : [];

    return data || [];
  }, []);

  const RowTextArea = React.useCallback(
    ({ name, disabled }: { name: FieldPath<Integrity>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange, onBlur }, fieldState }) => (
          <>
            <TextArea
              error={!!fieldState?.error}
              className="h-full"
              inputClassName="h-full"
              disabled={disabled}
              placeholder="Write here..."
              multiline
              rows={4}
              value={typeof value === 'string' ? value : ''}
              onChange={onChange}
              onBlur={onBlur}
            />
          </>
        )}
      />
    ),
    [],
  );
  const RowNumber = React.useCallback(
    ({ name, disabled }: { name: FieldPath<Integrity>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange, onBlur }, fieldState }) => (
          <>
            <Input
              error={!!fieldState?.error}
              className="h-full"
              inputClassName="h-full"
              disabled={disabled}
              placeholder="Write number here..."
              value={typeof value === 'string' ? value : ''}
              onChange={onChange}
              onBlur={onBlur}
              type="number"
            />
          </>
        )}
      />
    ),
    [],
  );

  const RowCountryAutoComplete = React.useCallback(
    ({ name, disabled }: { name: FieldPath<Integrity>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange, onBlur }, fieldState }) => (
          <CountryAutoComplete
            error={!!fieldState?.error}
            disabled={disabled}
            multiple
            className="flex justify-center"
            value={value as Country | Country[]}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
    ),
    [],
  );

  const RowUploadButton = React.useCallback(
    ({ name, disabled }: { name: FieldPath<Integrity>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <UploadButton
            error={!!fieldState?.error}
            url={`/products/${urlParams.code}/version/${urlParams.version}/integrities/upload`}
            disabled={disabled}
            id={name}
            wrapperClassName="flex justify-center mt-4"
            value={value ? String(value) : null}
            onChange={onChange}
          />
        )}
      />
    ),
    [urlParams.code, urlParams.version],
  );

  const RowSwitch = React.useCallback(
    ({ name, disabled }: { name: FieldPath<Integrity>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <Switch
            error={!!fieldState?.error}
            disabled={disabled}
            className="justify-center mt-4"
            type="secondary"
            value={typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null}
            onChange={newVal => onChange(newVal === 'Yes')}
          />
        )}
      />
    ),
    [],
  );

  const RowAutoComplete = React.useCallback(
    ({
      name,
      options,
      disabled,
    }: {
      name: FieldPath<Integrity>;
      options: Array<AutoCompleteMultipleChoiseItem>;
      disabled: boolean;
    }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <AutoComplete
            error={!!fieldState?.error}
            className="flex justify-center mt-4"
            disabled={disabled}
            id={name}
            items={options}
            value={value as AutoCompleteMultipleChoiseItem}
            onChange={onChange}
          />
        )}
      />
    ),
    [],
  );

  const RowAutoCompleteMultiple = React.useCallback(
    ({
      name,
      options,
      disabled,
    }: {
      name: FieldPath<Integrity>;
      options: Array<AutoCompleteMultipleChoiseItem>;
      disabled: boolean;
    }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <AutoCompleteMultiple
            error={!!fieldState?.error}
            className="flex justify-center mt-4"
            disabled={disabled}
            id={name}
            items={options}
            value={value as AutoCompleteMultipleChoiseItem[]}
            onChange={onChange}
          />
        )}
      />
    ),
    [],
  );

  return (
    <Layout
      shouldShowTransitionPrompt={
        form.formState.isDirty ||
        hasExpandStateChanged(originalIntegrity?.data, expandedState)
      }
      share={share}
    >
      <form className={errorForm}>
        <Row
          error={!!formState.errors?.expanded_state?.palm}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.palm}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.palm}
          onExpandChange={val => onExpandChange('palm', !!val)}
          onAddRow={() => onAddRow('palm')}
          onDeleteRow={rowIndex => onDeleteRow('palm', rowIndex)}
          question={Questions[0]}
          data={fetchRowData('palm').map((_, rowIndex) => [
            {
              header: 'In which ingredient?',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`palm.${rowIndex}.ingredient_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`palm.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`palm.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Derived from certified Palm Oil?',
              jsx: (
                <>
                  <Controller
                    name={`palm.${rowIndex}.certified`}
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <>
                        <Switch
                          error={!!fieldState?.error}
                          disabled={!urlParams?.editMode}
                          className="justify-center mb-5"
                          type="secondary"
                          value={
                            typeof value === 'boolean'
                              ? value
                                ? 'Yes'
                                : 'No'
                              : null
                          }
                          onChange={newVal => onChange(newVal === 'Yes')}
                        />

                        <h4 className="font-roboto">
                          Select Accreditation Status
                        </h4>
                      </>
                    )}
                  />
                  <Controller
                    name={`palm.${rowIndex}.accreditation_status`}
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <AutoComplete
                        error={!!fieldState?.error}
                        className="flex justify-center"
                        disabled={
                          !form.watch(`palm.${rowIndex}.certified`) ||
                          !urlParams?.editMode
                        }
                        id={`palm.${rowIndex}.accreditation_status`}
                        items={allStatus?.data.accreditation_statuses || []}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </>
              ),
            },
            {
              header: 'Upload the certificate',
              tooltip: 'Upload relevant certificate',
              jsx: (
                <RowUploadButton
                  disabled={!urlParams?.editMode}
                  name={`palm.${rowIndex}.certificate`}
                />
              ),
            },
          ])}
        />
        <Row
          error={!!formState.errors?.expanded_state?.soya}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.soya}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.soya}
          onExpandChange={val => onExpandChange('soya', !!val)}
          onAddRow={() => onAddRow('soya')}
          onDeleteRow={rowIndex => onDeleteRow('soya', rowIndex)}
          question={Questions[1]}
          data={fetchRowData('soya').map((_, rowIndex) => [
            {
              header: 'In which ingredient?',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`soya.${rowIndex}.ingredient_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`soya.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`soya.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Derived from certified soya?',
              jsx: (
                <>
                  <Controller
                    name={`soya.${rowIndex}.certified`}
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <>
                        <Switch
                          error={!!fieldState?.error}
                          disabled={!urlParams?.editMode}
                          className="justify-center mb-5"
                          type="secondary"
                          value={
                            typeof value === 'boolean'
                              ? value
                                ? 'Yes'
                                : 'No'
                              : null
                          }
                          onChange={newVal => onChange(newVal === 'Yes')}
                        />

                        <h4 className="font-roboto">Accreditation type</h4>
                      </>
                    )}
                  />
                  {form.watch(`soya.${rowIndex}.certified`) && (
                    <RowTextArea
                      disabled={
                        !form.watch(`soya.${rowIndex}.certified`) ||
                        !urlParams?.editMode
                      }
                      name={`soya.${rowIndex}.accreditation_status`}
                    />
                  )}
                </>
              ),
            },
            {
              header: 'Upload the certificate',
              tooltip: 'Upload relevant certificate',
              jsx: (
                <RowUploadButton
                  disabled={!urlParams?.editMode}
                  name={`soya.${rowIndex}.certificate`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.egg}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.egg}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.egg}
          onExpandChange={val => onExpandChange('egg', val)}
          onAddRow={() => onAddRow('egg')}
          onDeleteRow={rowIndex => onDeleteRow('egg', rowIndex)}
          question="Product or subcomponents contain Egg?"
          data={fetchRowData('egg').map((_, rowIndex) => [
            {
              header: 'In which ingredient?',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.ingredient_name`}
                />
              ),
            },
            {
              header: 'Is pasteurised egg used?',
              jsx: (
                <RowSwitch
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.is_pasteurized`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Welfare Standard/Certification',
              tooltip:
                'Free range, Lion egg certified, Freedom Food or any other welfare or sustainability certificates should mention here',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.welfare`}
                />
              ),
            },
            {
              header: 'Upload the certificate',
              tooltip: 'Upload relevant certificate',
              jsx: (
                <RowUploadButton
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.certificate`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`egg.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <div className="GM">
          <Row
            error={!!formState.errors?.expanded_state?.gm}
            canExpand={urlParams?.editMode}
            canAddRow={urlParams?.editMode && !!expandedState?.gm}
            canDeleteRow={urlParams?.editMode}
            headerTooltip="If product contains any genetically modified ingredients, this section must be completed"
            className="mb-6"
            expanded={expandedState?.gm}
            onExpandChange={val => onExpandChange('gm', val)}
            onAddRow={() => onAddRow('gm')}
            onDeleteRow={rowIndex => onDeleteRow('gm', rowIndex)}
            question="Product or subcomponents contain any GM materials?"
            data={fetchRowData('gm').map((_, rowIndex) => [
              {
                header: 'In which ingredient?',
                jsx: (
                  <RowTextArea
                    disabled={!urlParams?.editMode}
                    name={`gm.${rowIndex}.ingredient_name`}
                  />
                ),
              },
              {
                header: '% in Ingredient',
                jsx: (
                  <RowNumber
                    disabled={!urlParams?.editMode}
                    name={`gm.${rowIndex}.percent`}
                  />
                ),
              },
              {
                header: 'Country of Origin',
                jsx: (
                  <RowCountryAutoComplete
                    disabled={!urlParams?.editMode}
                    name={`gm.${rowIndex}.country_of_origin`}
                  />
                ),
              },
              {
                header: 'Date & Details',
                className: 'hidden',
                jsx: (
                  <RowTextArea
                    disabled={!urlParams?.editMode}
                    name={`gm.${rowIndex}.data_and_details`}
                  />
                ),
              },
              {
                header: 'Comments',
                className: 'col-span-2',
                jsx: (
                  <RowTextArea
                    disabled={!urlParams?.editMode}
                    name={`gm.${rowIndex}.comments`}
                  />
                ),
              },
            ])}
          />
        </div>

        <Row
          error={!!formState.errors?.expanded_state?.meat}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.meat}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.meat}
          onExpandChange={val => onExpandChange('meat', val)}
          onAddRow={() => onAddRow('meat')}
          onDeleteRow={rowIndex => onDeleteRow('meat', rowIndex)}
          tabs
          tabsError={formState?.errors?.meat}
          question="Product or subcomponents contain Meat"
          data={fetchRowData('meat').map((_, rowIndex) => [
            {
              meat_type: [
                {
                  header: 'Meat Species',
                  jsx: (
                    <RowAutoComplete
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.meat_type.meat_species`}
                      options={allStatus?.data.meat_species || []}
                    />
                  ),
                },
                {
                  header: 'Min meat content %',
                  jsx: (
                    <RowNumber
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.meat_type.min_meat_percent`}
                    />
                  ),
                },
                {
                  header: 'Meat content g/100g',
                  jsx: (
                    <RowNumber
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.meat_type.meat_content`}
                    />
                  ),
                },
                {
                  header: 'Min Visual Lean %',
                  tooltip: 'e.g., enter 80 for 80vl or 80/20',
                  jsx: (
                    <RowNumber
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.meat_type.min_visual_lean`}
                    />
                  ),
                },
              ],
              packing_site_details: [
                {
                  header: 'Name and Address',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.packing_site_details.name_and_address`}
                    />
                  ),
                },
                {
                  header:
                    'Health Marks/ Identification Marks/ EU Approval number',
                  tooltip: `Health Mark/ Identification Mark/ EU Approval
                     number for Farm, Slaughterhouse ,Processing and Packing site must to be mentioned here`,
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.packing_site_details.health_mark`}
                    />
                  ),
                },
                {
                  header: 'Country',
                  jsx: (
                    <RowCountryAutoComplete
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.packing_site_details.country`}
                    />
                  ),
                },
                {
                  header: 'Welfare Standard/Certification',
                  jsx: (
                    <RowAutoCompleteMultiple
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.packing_site_details.welfare`}
                      options={allStatus?.data.meat_welfare_standards || []}
                    />
                  ),
                },
                // {
                //   header: 'Welfare Standard/Certification',
                //   jsx: (
                //     <RowAutoComplete
                //       disabled={!urlParams?.editMode}
                //       name={`meat.${rowIndex}.packing_site_details.welfare`}
                //       options={allStatus?.data.meat_welfare_standards || []}
                //     />
                //   ),
                // },
                {
                  header: 'Comments',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.packing_site_details.comments`}
                    />
                  ),
                },
              ],
              slaughterhouse_details: [
                {
                  header: 'Name and Address',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.slaughterhouse_details.name_and_address`}
                    />
                  ),
                },
                {
                  header:
                    'Health Marks/ Identification Marks/ EU Approval number',
                  tooltip: `Health Mark/ Identification Mark/ EU Approval
                     number for Farm, Slaughterhouse ,Processing and Packing site must to be mentioned here`,
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.slaughterhouse_details.health_mark`}
                    />
                  ),
                },
                {
                  header: 'Country',
                  jsx: (
                    <RowCountryAutoComplete
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.slaughterhouse_details.country`}
                    />
                  ),
                },
                {
                  header: 'Welfare Standard/Certification',
                  jsx: (
                    <RowAutoCompleteMultiple
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.slaughterhouse_details.welfare`}
                      options={allStatus?.data.meat_welfare_standards || []}
                    />
                  ),
                },
                {
                  header: 'Comments',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.slaughterhouse_details.comments`}
                    />
                  ),
                },
              ],
              farm_details: [
                {
                  header: 'Name and Address',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.farm_details.name_and_address`}
                    />
                  ),
                },
                {
                  header:
                    'Health Marks/ Identification Marks/ EU Approval number',
                  tooltip: `Health Mark/ Identification Mark/ EU Approval
                     number for Farm, Slaughterhouse ,Processing and Packing site must to be mentioned here`,
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.farm_details.health_mark`}
                    />
                  ),
                },
                {
                  header: 'Country',
                  jsx: (
                    <RowCountryAutoComplete
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.farm_details.country`}
                    />
                  ),
                },
                {
                  header: 'Welfare Standard/Certification',
                  jsx: (
                    <RowAutoCompleteMultiple
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.farm_details.welfare`}
                      options={allStatus?.data.meat_welfare_standards || []}
                    />
                  ),
                },
                {
                  header: 'Comments',
                  jsx: (
                    <RowTextArea
                      disabled={!urlParams?.editMode}
                      name={`meat.${rowIndex}.farm_details.comments`}
                    />
                  ),
                },
              ],
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.seafood}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.seafood}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.seafood}
          onExpandChange={val => onExpandChange('seafood', val)}
          onAddRow={() => onAddRow('seafood')}
          onDeleteRow={rowIndex => onDeleteRow('seafood', rowIndex)}
          question="Product or subcomponents contain Fish or Seafood"
          rowGridCount={9}
          data={fetchRowData('seafood').map((_, rowIndex) => [
            {
              header: 'Capture Method',
              className: 'col-span-2',
              jsx: (
                <RowAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.capture_method`}
                  options={allStatus?.data.seafood_capture_methods || []}
                />
              ),
            },
            {
              header: 'Species / Latin Name',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.species_latin_name`}
                />
              ),
            },
            {
              header: 'Caught Method',
              tooltip:
                'Fish catching Method should be mentioned here e.g., pole & line, Purse seine…',
              jsx: (
                <Controller
                  name={`seafood.${rowIndex}.caught_method`}
                  control={form.control}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <TextArea
                      error={!!fieldState?.error}
                      className={`h-full ${
                        form.watch(`seafood.${rowIndex}.capture_method`)
                          ?.name !==
                          allStatus?.data.seafood_capture_methods[0].name &&
                        'opacity-0'
                      }`}
                      inputClassName="h-full"
                      disabled={
                        !urlParams?.editMode ||
                        form.watch(`seafood.${rowIndex}.capture_method`)
                          ?.name !==
                          allStatus?.data.seafood_capture_methods[0].name
                      }
                      placeholder="Write here..."
                      multiline
                      rows={4}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              ),
            },
            {
              header: 'Caught area & FAO Number',
              tooltip:
                'Define catching area e.g., Maldives, Indian Ocean, FAO 89',
              jsx: (
                <Controller
                  name={`seafood.${rowIndex}.caught_area_faq_number`}
                  control={form.control}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <TextArea
                      error={!!fieldState?.error}
                      className={`h-full ${
                        form.watch(`seafood.${rowIndex}.capture_method`)
                          ?.name !==
                          allStatus?.data.seafood_capture_methods[0].name &&
                        'opacity-0'
                      }`}
                      inputClassName="h-full"
                      disabled={
                        !urlParams?.editMode ||
                        form.watch(`seafood.${rowIndex}.capture_method`)
                          ?.name !==
                          allStatus?.data.seafood_capture_methods[0].name
                      }
                      placeholder="Write here..."
                      multiline
                      rows={4}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              ),
            },
            {
              header: 'Health Mark',
              tooltip: `Health Mark/ Identification Mark/ EU Approval number for processing and
               manufacturing site must to be mentioned here`,
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.health_mark`}
                />
              ),
            },
            {
              header: 'Welfare or  Environmental Standard/Certification',
              tooltip: `Any welfare or sustainability certificates or 
                standards should be listed here such as ASC, MSC…`,
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.welfare`}
                />
              ),
            },
            // {
            //   header: 'Free of Parasite',
            //   jsx: (
            //     <RowTextArea
            //       disabled={!urlParams?.editMode}
            //       name={`seafood.${rowIndex}.free_of_parasite`}
            //     />
            //   ),
            // },
            {
              header: 'Min fish Content',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.min_fish_content`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`seafood.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.colours}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.colours}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.colours}
          onExpandChange={val => onExpandChange('colours', val)}
          onAddRow={() => onAddRow('colours')}
          onDeleteRow={rowIndex => onDeleteRow('colours', rowIndex)}
          question="Product or subcomponents contain Artificial Colours?"
          data={fetchRowData('colours').map((_, rowIndex) => [
            {
              header: 'Name',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.name`}
                />
              ),
            },
            {
              header: 'E Number',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.e_number_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Source of Material',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.source_of_material`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`colours.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.flavourings}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.flavourings}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.flavourings}
          onExpandChange={val => onExpandChange('flavourings', val)}
          onAddRow={() => onAddRow('flavourings')}
          onDeleteRow={rowIndex => onDeleteRow('flavourings', rowIndex)}
          question="Product or subcomponents contain Artificial Flavourings?"
          data={fetchRowData('flavourings').map((_, rowIndex) => [
            {
              header: 'Name',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.name`}
                />
              ),
            },
            {
              header: 'E Number',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.e_number_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Source of Material',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.source_of_material`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`flavourings.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.preservatives}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.preservatives}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.preservatives}
          onExpandChange={val => onExpandChange('preservatives', val)}
          onAddRow={() => onAddRow('preservatives')}
          onDeleteRow={rowIndex => onDeleteRow('preservatives', rowIndex)}
          question="Product or subcomponents contain Artificial Preservatives?"
          data={fetchRowData('preservatives').map((_, rowIndex) => [
            {
              header: 'Name',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.name`}
                />
              ),
            },
            {
              header: 'E Number',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.e_number_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Source of Material',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.source_of_material`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`preservatives.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.additives}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.additives}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.additives}
          onExpandChange={val => onExpandChange('additives', val)}
          onAddRow={() => onAddRow('additives')}
          onDeleteRow={rowIndex => onDeleteRow('additives', rowIndex)}
          question="Product or subcomponents contain Artificial Additives?"
          data={fetchRowData('additives').map((_, rowIndex) => [
            {
              header: 'Name',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.name`}
                />
              ),
            },
            {
              header: 'E Number',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.e_number_name`}
                />
              ),
            },
            {
              header: '% in Ingredient',
              jsx: (
                <RowNumber
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.percent`}
                />
              ),
            },
            {
              header: 'Country of Origin',
              jsx: (
                <RowCountryAutoComplete
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.country_of_origin`}
                />
              ),
            },
            {
              header: 'Source of Material',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.source_of_material`}
                />
              ),
            },
            {
              header: 'Comments',
              jsx: (
                <RowTextArea
                  disabled={!urlParams?.editMode}
                  name={`additives.${rowIndex}.comments`}
                />
              ),
            },
          ])}
        />

        <Row
          error={!!formState.errors?.expanded_state?.irradiation}
          canExpand={urlParams?.editMode}
          canAddRow={urlParams?.editMode && !!expandedState?.irradiation}
          canDeleteRow={urlParams?.editMode}
          className="mb-6"
          expanded={expandedState?.irradiation}
          onExpandChange={val => onExpandChange('irradiation', val)}
          onAddRow={() => onAddRow('irradiation')}
          onDeleteRow={rowIndex => onDeleteRow('irradiation', rowIndex)}
          question="Product been Treated by Irradiation"
          columnClassName="pt-0 pb-0"
          columnDividerClassName="mb-0"
          data={fetchRowData('irradiation').map((_, rowIndex) => [
            {
              jsx: (
                <Controller
                  name={`irradiation.${rowIndex}.explain`}
                  control={form.control}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <Input
                      error={!!fieldState?.error}
                      className="w-full"
                      disabled={!urlParams?.editMode}
                      placeholder="Please explain..."
                      innerRef={ref}
                      {...rest}
                    />
                  )}
                />
              ),
            },
          ])}
        />

        {fetchRowData('additional_tables').map((_, rowIndex) => (
          <Row
            key={`additional_tables.${String(rowIndex)}`}
            canExpand={urlParams?.editMode}
            canAddRow={urlParams?.editMode}
            canDeleteRow={urlParams?.editMode}
            expanded
            className="mb-6"
            onDeleteRow={() => onDeleteRow('additional_tables', 0)}
            headerRowClassName="px-2"
            headerWrapperClassName="mb-0"
            customHeaderComponent={
              <Controller
                name={`additional_tables.${rowIndex}.title`}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Input
                    error={!!fieldState?.error}
                    disabled={!urlParams?.editMode}
                    className="h-6 w-5/12"
                    inputClassName="px-2 text-xs"
                    dense
                    placeholder="Write Title..."
                    innerRef={ref}
                    {...rest}
                  />
                )}
              />
            }
            customCollapseComponent={
              <Controller
                name={`additional_tables.${rowIndex}.value`}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <Input
                    error={!!fieldState?.error}
                    className="w-full"
                    inputClassName="text-sm"
                    disabled={!urlParams?.editMode}
                    placeholder="Please explain..."
                    innerRef={ref}
                    {...rest}
                  />
                )}
              />
            }
          />
        ))}

        <Button
          disabled={!urlParams?.editMode}
          className="flex ml-auto mt-2"
          color="primary"
          size="medium"
          startIcon={<Add />}
          text="New Table"
          variant="outlined"
          onClick={() => onAddRow('additional_tables')}
        />

        <FormButtonGroup
          share={share}
          pageName="integrities"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default IntegrityComp;
