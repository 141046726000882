import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  LoginParams,
  PublicLoginParams,
  LoginResponse,
  PublicLoginResponse,
  ForgotPasswordParams,
  ResetPasswordParams,
  UserUserPoliciesResponse,
  GetUserSupplierAndCertificateResponse,
  SubmitUserSupplierAndCertificateParams,
} from '@hooks/api/auth/types';

const login = async (params: LoginParams) => {
  const res: AxiosResponse<LoginResponse> = await axios({
    method: 'post',
    url: '/user/login',
    params,
  });

  return res.data;
};
const publicLogin = async (params: PublicLoginParams) => {
  const res: AxiosResponse<PublicLoginResponse> = await axios({
    method: 'get',
    url: '/public-token',
    params,
  });

  return res.data;
};

const forgotPassword = async (params: ForgotPasswordParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: '/forgot-password',
    params,
  });

  return res.data;
};

const resetPassword = async (params: ResetPasswordParams) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: '/update-password',
    params,
  });

  return res.data;
};

const acceptTermsAndConditions = async () => {
  const res: AxiosResponse<LoginResponse> = await axios({
    method: 'post',
    url: '/user/terms-condition',
  });

  return res.data;
};

const getUserPolicies = async () => {
  const res: AxiosResponse<UserUserPoliciesResponse> = await axios({
    method: 'get',
    url: '/user/policies',
  });

  return res.data;
};

const acceptPolicies = async () => {
  const res: AxiosResponse<LoginResponse> = await axios({
    method: 'post',
    url: '/user/policies',
  });

  return res.data;
};

const getUserSupplierAndCertificate = async () => {
  const res: AxiosResponse<GetUserSupplierAndCertificateResponse> = await axios(
    {
      method: 'get',
      url: '/user/supplier',
    },
  );

  return res.data;
};

const submitUserSupplierAndCertificate = async (
  data: SubmitUserSupplierAndCertificateParams,
) => {
  const res: AxiosResponse = await axios({
    method: 'post',
    url: `/user/supplier/${data.supplier.supplier_code}/certificate/${data.certificate.certificate_code}/submit`,
    data,
  });

  return res.data;
};

const saveUserSupplierAndCertificate = async (
  data: SubmitUserSupplierAndCertificateParams,
) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/user/supplier/${data.supplier.supplier_code}/certificate/${data.certificate.certificate_code}`,
    data,
  });

  return res.data;
};

export default {
  login,
  publicLogin,
  forgotPassword,
  resetPassword,
  acceptTermsAndConditions,
  getUserPolicies,
  acceptPolicies,
  getUserSupplierAndCertificate,
  submitUserSupplierAndCertificate,
  saveUserSupplierAndCertificate,
};
