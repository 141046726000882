import React, { useEffect } from 'react';
import { useForm, Controller, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, Input, Button } from '@components/Common';
import { layerModalValues } from './types';

type P = {
  header: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  submit: (value: layerModalValues) => void;
  initialValues?: layerModalValues;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(65),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const validationSchema = yup.object({
  name: yup.string().required('Product Name is required'),
  // product_grams: yup.string().required('Product Oroduct Grams is required'),
  // product_percentage: yup
  //   .string()
  //   .required('Product Product Percentage is required'),
});

const NewProductModal: React.FC<P> = ({
  open,
  setOpen,
  header,
  submit,
  initialValues = {
    name: '',
    product_grams: null,
    product_percentage: null,
  },
}) => {
  const classes = useStyles();

  const fieldRef: Readonly<
    Array<{
      displayText: string;
      key: keyof layerModalValues;
    }>
  > = [
    {
      displayText: 'Name',
      key: 'name',
    },
    {
      displayText: 'Total gram of Product',
      key: 'product_grams',
    },
    {
      displayText: 'Percentage of Product',
      key: 'product_percentage',
    },
  ] as const;

  const form = useForm<layerModalValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    fieldRef.map(el =>
      form.setValue(el.key, initialValues[el.key], { shouldDirty: true }),
    );
  }, [initialValues]);

  const onSubmit = async (data: layerModalValues) => {
    const isFormValid = await form.trigger();

    if (!isFormValid) {
      return;
    }

    submit(data);
    form.reset();
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">{header}</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form
          className="flex flex-col items-center p-4 min-w-full"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {fieldRef.map(el => (
            <div
              className="flex flex-row justify-between items-center min-w-full mb-2"
              key={el.key}
            >
              <p className="font-roboto">{el.displayText}</p>

              <div className="flex flex-col">
                <Controller
                  name={el.key}
                  control={form.control}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <Input
                      error={!!fieldState?.error}
                      id={el.key}
                      className="w-56"
                      value={value}
                      placeholder={el.displayText}
                      onChange={val => onChange(val)}
                    />
                  )}
                />
              </div>
            </div>
          ))}

          <div className="flex flex-row justify-end items-center min-w-full mt-2">
            <Button
              type="submit"
              className="text-green border-green"
              variant="outlined"
              startIcon={<Check />}
              text="Done"
              onClick={() => onSubmit(form.getValues())}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
