import React from 'react';
import clsx from 'clsx';
import {
  Popper,
  PopperProps,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@components/Common';

import DotsIcons from '@assets/images/icons/3-dots.svg';

export type MenuItemTypes =
  | 'View'
  | 'Spec View'
  | 'Delete'
  | 'Restore'
  | 'Share'
  | null;

const useStyles = makeStyles((theme: any) => ({
  menuItem: {
    ...theme.typography.caption,
    fontFamily: theme.fonts.secondary,
  },
}));

type P = {
  className?: string;
  onClose: () => void;
  onTriggerClick: () => void;
  onMenuItemClick: (value: MenuItemTypes) => void;
  children?: never;
  menuItems?: Array<MenuItemTypes>;
};

const Menu: React.FC<P & Omit<PopperProps, keyof P>> = ({
  open,
  onClose,
  onTriggerClick,
  onMenuItemClick,
  menuItems,
}) => {
  const classes = useStyles();
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        variant="text"
        innerRef={menuAnchorRef}
        className="w-full h-full"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          onTriggerClick();
        }}
      >
        <img src={DotsIcons} alt="3 dots" />
      </Button>

      <Popper
        open={open}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className="z-20 bg-white shadow-lg rounded"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {(menuItems || ['View', 'Spec View', 'Delete']).map(text => (
                    <>
                      {text !== null && (
                        <MenuItem
                          key={text}
                          className={clsx(classes.menuItem)}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            onMenuItemClick(text as MenuItemTypes);
                          }}
                        >
                          {text}
                        </MenuItem>
                      )}
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Menu;
