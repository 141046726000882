/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
import React from 'react';
import { FieldPath } from 'react-hook-form';
import { Cell, Row } from 'react-table';
import { FileCopyOutlined } from '@material-ui/icons';

import { toast } from 'react-toastify';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
} from '@components/Common';

import { formatDate } from '@utils';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Notification/AttributeFiltersModal';

import NotificationHooks from '@hooks/api/notification';

import { GetNotificationParams } from '@hooks/api/notification/types';

import { usePrevious, useDebounce } from '@hooks/utils';
import { Badge, Link } from '@material-ui/core';
import { notification } from '@app/hooks/api/notification/types';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): GetNotificationParams => {
  const formattedValues = {
    status: filterValues?.status?.code,
  };

  return formattedValues;
};

const Notification = () => {
  const [selectedNotification, setSelectedNotification] = React.useState<{
    publicLink: string;
  }>({ publicLink: '' });

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);

  const [userFilters, setUserFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  const deleteNotification = NotificationHooks.useDeleteNotificationMutations();

  const resend = React.useCallback(async (row: Row<notification>) => {
    alert('resend');
  }, []);

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: usersList, ...useUsersListUtils } =
    NotificationHooks.useNotificationList({
      page: currentPage,
      q: debouncedSearch,
      ...(userFilters ? formatFilterAttributes(userFilters) : {}),
    });

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<notification> | 'action';
      id: FieldPath<notification> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<notification>) => JSX.Element);
    }> => [
      {
        Header: 'Link Address',
        accessor: 'notification_text',
        id: 'notification_text',
        width: 580,
        Cell: cell => (
          <div>
            {cell.row.original.notification_status === 'new' ? (
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                variant="dot"
                color="error"
              >
                {cell.row.original.notification_text}
              </Badge>
            ) : (
              <>{cell.row.original.notification_text}</>
            )}
          </div>
        ),
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <>
            {cell.row.original.notification_status === 'new' && (
              <Button
                className="cursor-pointer"
                variant="text"
                onClick={async () => {
                  try {
                    await deleteNotification.mutateAsync({
                      notification_code: cell.row.original.notification_code,
                    });
                  } catch (error) {}
                }}
              >
                Ignore
              </Button>
            )}
          </>
        ),
      },
    ],
    [useUsersListUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <AttributeFiltersModal
        values={userFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setUserFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Configurations' },
            { href: '#', text: 'Notification' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {userFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setUserFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <p className="text-base text-primary-light font-roboto">
            Notification
          </p>
        </div>
        <Table
          loading={useUsersListUtils.isLoading}
          className="mt-2"
          bodyRowClassName="rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={usersList?.data || []}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: usersList?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default Notification;
