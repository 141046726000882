import { Cell } from 'react-table';
import { FieldPath } from 'react-hook-form';
import { AutoCompleteItem } from '@components/Common';

import { Storage } from '@hooks/api/wizard/types';

export enum StorageTables {
  ShelfLifeInformationTable = 'shelf_life_information',
  StorageTempAndConditionsTable = 'storage_temp_and_conditions',
}

export type CustomCell = Cell<{ userAdded?: boolean }>;

export interface TableSwitchProps {
  name: FieldPath<Storage>;
  disabled?: boolean;
}

export interface TableInputProps {
  name: FieldPath<Storage>;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  dense?: boolean;
  type?: string;
}

export interface TableAutoCompleteProps {
  name: FieldPath<Storage>;
  disabled?: boolean;
  canAddCustomItem?: boolean;
  freeSolo?: boolean;
  itemDropDown?: NonNullable<AutoCompleteItem>[];
}
