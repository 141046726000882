import React, { useState, useEffect } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import RouteMap from '@routes/map';

import authHooks from '@hooks/api/auth';
import { LoginParams } from '@hooks/api/auth/types';

import { useUrlParams } from '@hooks';

const Login = () => {
  const history = useHistory();
  const [isTokenFound, setTokenFound] = useState(false);

  const logniMutation = authHooks.useLoginMutation();
  const routeParams = useRouteMatch<{ token: string }>();

  useEffect(() => {
    const newData = {
      token: routeParams.params.token,
    };
    logniMutation.mutate(newData);
  }, []);

  return <>LOGIN AS</>;
};

export default Login;
