import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';

export type Item = {
  name: string;
  code: string | number;
};

type P = {
  options?: never;
  multiple?: boolean;
  value: TValue;
  onChange: (arg: TValue) => void;
  id?: string;
  items: Array<Item>;
  className?: string;
  inputPlaceholder?: string;
  disabled?: boolean;
  freeSolo?: boolean;
  canAddCustomItem?: boolean;
  renderInput?: never;
  inputProps?: never;
  error?: boolean;
};

type StyleRelatedProps = Pick<P, 'disabled' | 'error'>;

const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    height: 'fit-content',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: theme.spacing(1),

    '& .MuiInputBase-root': {
      backgroundColor: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.common.white : theme.palette.gray.light,

      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ error }: StyleRelatedProps) =>
        error ? theme.palette.error.dark : theme.palette.gray.light,
    },
  },
  autoComplete: {
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(5),

    '& .MuiAutocomplete-inputRoot': {
      minHeight: theme.spacing(3),
    },

    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,

    '& input': {
      ...theme.typography.body2,
      fontFamily: theme.fonts.secondary,
      fontWeight: theme.typography.fontWeightRegular,

      color: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

      padding: `${theme.spacing(0, 1)} !important`,

      '&::placeholder': {
        color: theme.palette.gray.main,
      },
    },
  },
  popper: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.light,
    },

    '& .MuiAutocomplete-noOptions': {
      color: theme.palette.primary.light,
    },
  },
  groupLabel: {
    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,
  },
  option: {
    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type TValue = Array<Item> | null;

const filter = createFilterOptions<Item>();

const Autocomplete: React.FC<
  P & Omit<AutocompleteProps<Item, true, false, true>, keyof P>
> = ({
  multiple = true,
  id,
  className,
  inputPlaceholder = 'Select...',
  items,
  value,
  onChange,
  disabled,
  freeSolo = true,
  error = false,
  canAddCustomItem = true,
  options,
  renderInput,
  ...rest
}) => {
  const classes = useStyles({ disabled, error });

  return (
    <MuiAutocomplete
      popupIcon={disabled ? null : <ArrowDropDown />}
      freeSolo={freeSolo}
      disabled={disabled}
      multiple={multiple}
      options={items || []}
      fullWidth
      autoHighlight
      getOptionLabel={option => option?.name || ''}
      className={clsx(classes.autoComplete, 'transition-all', className)}
      getOptionSelected={(option, currentValue) => {
        if (!currentValue) return false;

        return currentValue.code === option.code;
      }}
      filterOptions={(allItems, params) => {
        const filtered = filter(allItems, params);

        const negativeCodes = (value || [])
          .filter(el => Number(el.code) < 0)
          .map(el => Number(el.code));

        if (negativeCodes.length === 0) {
          negativeCodes.push(0);
        }

        const min = Math.min(...negativeCodes);

        // generating new negative number for new item code
        const newCode = min - 1;

        // Suggest the creation of a new value
        if (canAddCustomItem && params.inputValue !== '') {
          filtered.push({
            name: `${params.inputValue}`,
            code: newCode,
          });
        }

        return filtered;
      }}
      value={value || []}
      classes={{
        popper: clsx(classes.popper),
        groupLabel: classes.groupLabel,
        option: classes.option,
      }}
      onChange={(_, newValue) => onChange(newValue as TValue)}
      renderInput={params => (
        <TextField
          {...params}
          classes={{
            root: classes.inputRoot,
          }}
          placeholder={inputPlaceholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
