/* eslint-disable react/no-array-index-key */
import React from 'react';
import clsx from 'clsx';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Tooltip, TableLoading } from '@components/Common';

export const FIRST_COLUMN: Readonly<
  Array<{ label: string; tooltip?: string }>
> = [
  { label: 'Component Description' },
  { label: 'Material' },
  { label: 'Colour' },
  { label: 'Height (mm)' },
  { label: 'Width (mm)' },
  { label: 'Length (mm)' },
  { label: 'Diameter (mm)' },
  { label: 'Gauge or Thickeness (μm)' },
  { label: 'Component Weight (g)' },
  { label: 'Supplier name' },
  {
    label: 'Country of Origin',
    tooltip:
      'Define country of origin where packaging material is made and manufactured in',
  },
  {
    label: 'Used recycled material content (%)',
    tooltip:
      'If the packaging item has been manufactured using recycled material, specify this as percentage',
  },
  { label: 'Component Recyclable?' },
  {
    label: 'Packaging material biodegradable?',
    tooltip:
      'Biodegradable refers to the ability of materials to break down and return to nature',
  },
  { label: 'Method of closure' },
  { label: 'Food Contact certified' },
  { label: 'Upload food contact certificate' },
  { label: 'Comments' },
];

export type TVerticalTableCellParams = { colIndex: number; tabIndex: number };

interface P {
  rowCount: number;
  columnCount: number;
  loading?: boolean;
  columnLoading?: boolean;
  HeaderCell: (arg: { colIndex: number }) => JSX.Element;
  column: Array<{
    Cell: (cell: TVerticalTableCellParams) => JSX.Element;
  }>;
}

const useStyles = makeStyles((theme: any) => ({
  tableWrap: {
    display: 'block',
    'max-width': '100%',
    'overflow-x': 'auto',

    paddingBottom: 12,

    '&::-webkit-scrollbar': {
      height: 11,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.palette.gray.main,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.error.light,
      borderRadius: 10,
    },
  },
}));

const VerticalTable: React.FC<P> = ({
  rowCount,
  columnCount,
  HeaderCell,
  column,
  loading,
  columnLoading,
}) => {
  const classes = useStyles();

  if (loading) {
    return <TableLoading />;
  }

  if (columnCount === 0) {
    return (
      <div className="flex flex-row justify-center items-center mb-3 mt-6 font-normal text-xl">
        No Data Available
      </div>
    );
  }

  return (
    <div className={clsx(classes.tableWrap)}>
      <table className="inline-table table-auto border-separate border-spacing-0 min-w-full max-w-full relative">
        <thead>
          <tr className="table-row h-10 border-t-0 border-b-0">
            <td
              className={clsx(
                'table-cell align-middle px-4 py-2 min-w-56 w-56 sticky left-0 z-20 bg-white-400',
                'border-r-4 border-white-400 border-t-0 border-b-0',
              )}
            >
              Status
            </td>

            {Array(columnCount)
              .fill('')
              .map((_, colIndex) => (
                <td
                  key={`header-cell-${colIndex}`}
                  className={clsx(
                    'table-cell align-middle px-4 py-2 min-w-56 w-56 z-10 bg-white-400',
                    'border-white-400 border-t-0 border-b-0',
                    colIndex > 0 ? 'border-l-4' : '',
                  )}
                >
                  <div className="flex flex-row items-center">
                    <HeaderCell colIndex={colIndex} />

                    <Tooltip title="Please Choose type of packaging from drop down" />
                  </div>
                </td>
              ))}
          </tr>
        </thead>

        <tbody>
          {Array(rowCount)
            .fill('')
            .map((_1, rowIndex) => (
              <tr
                key={`body-row-${rowIndex}`}
                className=" h-10 table-row border-t-0 border-b-0"
              >
                <td
                  className={clsx(
                    'table-cell px-4 py-2 sticky left-0 z-20 bg-white',
                    'border-r-4 border-white-600 border-t-0 border-b-0',
                  )}
                >
                  {FIRST_COLUMN[rowIndex]?.label || ''}

                  {FIRST_COLUMN[rowIndex]?.tooltip ? (
                    <Tooltip title={FIRST_COLUMN[rowIndex]?.tooltip || ''} />
                  ) : null}
                </td>

                {columnLoading === false ? (
                  <>
                    {Array(columnCount)
                      .fill('')
                      .map((_2, colIndex) => (
                        <td
                          // contentEditable
                          key={`body-cell-${rowIndex}-${colIndex}`}
                          className={clsx(
                            'table-cell px-4 py-2 z-10 bg-white',
                            'border-white-600 border-t-0 border-b-0',
                            colIndex > 0 ? 'border-l-4' : '',
                          )}
                        >
                          {column?.[rowIndex]?.Cell?.({
                            colIndex,
                            tabIndex:
                              Math.abs(rowCount - (rowIndex + 1 + rowCount)) +
                              rowCount * (colIndex + 1),
                          })}
                        </td>
                      ))}
                  </>
                ) : (
                  <>
                    {Array(columnCount)
                      .fill('')
                      .map((_2, colIndex) => (
                        <td
                          // contentEditable
                          key={`body-cell-afterloadin-${rowIndex}-${colIndex}`}
                          className={clsx(
                            'table-cell px-4 py-2 z-10 bg-white',
                            'border-white-600 border-t-0 border-b-0',
                            colIndex > 0 ? 'border-l-4' : '',
                          )}
                        >
                          {column?.[rowIndex]?.Cell?.({
                            colIndex,
                            tabIndex:
                              Math.abs(rowCount - (rowIndex + 1 + rowCount)) +
                              rowCount * (colIndex + 1),
                          })}
                        </td>
                      ))}
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default VerticalTable;
