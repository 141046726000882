import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close, Check, Create, Add as AddIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Menu, { MenuItemTypes } from '@components/Bussines/Products/Menu';

import {
  Modal,
  Input,
  AutoComplete,
  AutoCompleteItem,
  Button,
  Table,
  Switch,
} from '@components/Common';

import {
  productsHook as ProductHooks,
  suppliersHook as SupplierHooks,
  useUrlParams,
  stringify,
} from '@hooks';

import { map as RouteMap } from '@routes';

type P = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

interface Values {
  name: string;
  item_code: string;
  supplier_name: string;
  currentVersion: string;
}

const useStyles = makeStyles((theme: any) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
  inputWidth: {
    width: theme.spacing(40),
  },
  datePickerWidth: {
    width: theme.spacing(16),
  },
  tableRoot: {
    width: theme.spacing(72),
  },
  root: {
    minWidth: theme.spacing(72),
  },
}));

const validationSchema = yup.object({
  name: yup.string().required('Product Name is required'),
  item_code: yup.string().required('Product Code is required'),
  customer_name: yup.string().required('Customer Code is required'),
  current_version: yup.number().required('Customer Code is required'),
});

const NewProductModal: React.FC<P> = ({ open, setOpen }) => {
  const history = useHistory();
  const [urlParams] = useUrlParams<{ code: string; version: string }>();

  const newVersionproduct = ProductHooks.useNewVersionProductMutation();

  const classes = useStyles();
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');
  const [editable, setEditable] = React.useState(false);

  const form = useForm<any>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { data: detailedProduct } = ProductHooks.useDetailedProduct(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: open && !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        form.reset(data.data);
      },
    },
  );

  const updateProductMutation = ProductHooks.useUpdateProductMutation();

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: any) => {
      if (actionType === 'Spec View') {
        const newUrlParams = stringify(history.location.search, {
          code: row.original.code,
          version: row.original.version,
        });

        history.push(
          `${RouteMap.bussines.productForm.main_info}?${newUrlParams}`,
        );
      }
    },
    [],
  );

  const columns = [
    {
      Header: 'Version',
      accessor: 'version',
      width: 30,
    },
    {
      Header: 'Manufacturer',
      accessor: 'manufacture_name',
    },
    {
      Header: 'Approve Date',
      id: 'approve_date',
      accessor: 'approve_date',
    },
    {
      Header: 'Review Date',
      id: 'review_date',
      accessor: 'review_date',
    },
    {
      Header: 'Status',
      accessor: 'cardbox_status',
    },
    {
      Header: 'Action',
      id: 'action',
      width: 30,
      Cell: ({ row }: any) => (
        <Menu
          menuItems={['Spec View']}
          open={openTableActionMenuId === row.id}
          onTriggerClick={() => setOpenTableActionMenuId(row.id)}
          onClose={() => setOpenTableActionMenuId('')}
          onMenuItemClick={actionType => onMenuItemClick(actionType, row)}
        />
      ),
    },
  ];

  const onSave = async (data: any) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) return;
      await updateProductMutation.mutateAsync(data);
      form.reset();
      setOpen(false);
    } catch (e) {}
  };

  const getSubRows = React.useCallback(
    (row: any) => {
      return row.versions?.data?.length > 0 ? row.versions.data : [];
    },
    [detailedProduct?.timeStamp],
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          classes.root,
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">Product View</h3>

          <div className="flex flex-row items-center">
            {!editable && (
              <IconButton onClick={() => setEditable(true)}>
                <Create className="text-primary-light" />
              </IconButton>
            )}

            <IconButton onClick={() => setOpen(false)}>
              <Close className="text-4xl text-red" />
            </IconButton>
          </div>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Product Name</p>

            <div className="flex flex-col">
              <Controller
                name="name"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <Input
                      disabled={!editable}
                      className={clsx(classes.inputWidth)}
                      value={value}
                      onChange={onChange}
                      placeholder="Product Name"
                    />
                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Product Code</p>

            <div className="flex flex-col">
              <Controller
                name="item_code"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <Input
                      disabled={!editable}
                      className={clsx(classes.inputWidth)}
                      value={value}
                      onChange={onChange}
                      placeholder="Product Code"
                    />
                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Supplier</p>

            <div className="flex flex-col">
              <Controller
                name="customer_name"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <Input
                      disabled
                      className={clsx(classes.inputWidth)}
                      value={value}
                      onChange={onChange}
                      placeholder="customer name"
                    />
                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Current Version</p>

            <div className="flex flex-col">
              <Controller
                name="current_version"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <AutoComplete
                      freeSolo={false}
                      canAddCustomItem={false}
                      disabled={!editable}
                      className={clsx(classes.inputWidth)}
                      disableClearable
                      items={
                        detailedProduct?.data?.versions?.data?.map(el => ({
                          name: el.version.toString(),
                          code: el.version,
                        })) || []
                      }
                      value={
                        value
                          ? { name: value.toString(), code: value }
                          : { name: '1', code: 1 }
                      }
                      onChange={val => {
                        onChange(val?.code);
                      }}
                    />

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Product Manager</p>

            <div className="flex flex-col">
              <Controller
                name="in_charge"
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <Switch
                      leftButtonText="Customer"
                      disabled={!editable}
                      rightButtonText="Supplier"
                      value={value}
                      onChange={val => {
                        onChange(val);
                      }}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="flex justify-between w-full">
            <h4 className="text-base font-roboto mt-4 mr-auto font-medium">
              Other Versions
            </h4>
            <Button
              variant="outlined"
              size="small"
              className="border-1 border-primary"
              onClick={async () => {
                try {
                  await newVersionproduct.mutateAsync({
                    code: urlParams.code,
                    version:
                      detailedProduct?.data.versions.data[
                        detailedProduct?.data.versions.data.length - 1
                      ].version.toString() || '1',
                  });
                  setOpen(false);
                } catch (error) {}
              }}
              startIcon={<AddIcon />}
              text="New Version"
            />
          </div>
          <Table
            className="mb-2 mt-2"
            bodyRowClassName="rounded bg-white border-t-4 border-b-4 border-white-600"
            classes={{
              root: classes.tableRoot,
            }}
            columns={columns}
            data={
              detailedProduct?.data.versions?.data.length
                ? detailedProduct?.data.versions.data
                : []
            }
            size="small"
            getSubRows={getSubRows}
          />

          {editable ? (
            <div className="flex flex-row justify-end items-center min-w-full mt-2">
              <Button
                className="text-gray-800 mr-4"
                variant="text"
                text="Cancel"
                onClick={() => setEditable(false)}
              />

              <Button
                className="text-green border-green"
                startIcon={<Check />}
                variant="outlined"
                text="Done"
                onClick={() => onSave(form.getValues())}
              />
            </div>
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
