import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import errorHandler from '@utils/errorHandler';

import manufacturerApis from '@hooks/api/manufacturers/api';
import {
  UseManufacturersParams,
  UseManufacturersResponseType,
  UseFilteredManufacturersParams,
  UseFilteredManufacturersResponseType,
  UseDetailedManufacturersParams,
  UseDetailedManufacturerResponseType,
  GetSupplierAsAManufacturerPrams,
  UseSupplierAsManufacturerResponse,
} from './types';

const KEYS = {
  manufacturers: ['manufacturers'],
  detailedManufacturer: ['manufacturers', 'details'],
  filteredManufacturer: ['manufacturers', 'filter'],
} as const;

const useManufacturers = (
  params?: UseManufacturersParams,
  options?: UseQueryOptions<UseManufacturersResponseType, AxiosError>,
) => {
  const query = useQuery<UseManufacturersResponseType, AxiosError>(
    [...KEYS.manufacturers, params],
    () => manufacturerApis.getManufacturers(params),
    {
      keepPreviousData: params?.paginated,
      ...options,
    },
  );

  return query;
};

const useFilteredManufacturers = (
  params?: UseFilteredManufacturersParams,
  options?: UseQueryOptions<UseFilteredManufacturersResponseType, AxiosError>,
) => {
  const query = useQuery<UseFilteredManufacturersResponseType, AxiosError>(
    [...KEYS.filteredManufacturer, params],
    () => manufacturerApis.getFilteredManufacturers(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );

  return query;
};

const useDetailedManufacturer = (
  params: UseDetailedManufacturersParams,
  options?: UseQueryOptions<UseDetailedManufacturerResponseType, AxiosError>,
) => {
  const query = useQuery<UseDetailedManufacturerResponseType, AxiosError>(
    [...KEYS.detailedManufacturer, params],
    () => manufacturerApis.getDetailedManufacturer(params),
    options,
  );

  return query;
};

const useSupplierAsManufacturer = (
  params: GetSupplierAsAManufacturerPrams,
  options?: UseQueryOptions<UseSupplierAsManufacturerResponse, AxiosError>,
) => {
  const query = useQuery<UseSupplierAsManufacturerResponse, AxiosError>(
    [...KEYS.detailedManufacturer, params],
    () => manufacturerApis.getSupplierAsManufacturer(params),
    options,
  );

  return query;
};

const useSaveManufacturerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.saveManufacturer, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.manufacturers);
    },
  });

  return mutation;
};

const useSubmitManufacturerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.submitManufacturer, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.manufacturers);
    },
  });

  return mutation;
};

const useApproveOrRejectManufacturerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.approveOrRejectManufacturer, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.manufacturers);
    },
  });

  return mutation;
};

const useUpdateManufacturerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.updateManufacturer, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.manufacturers);
    },
  });

  return mutation;
};

const useDeleteManufacturerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.deleteManufacturer, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.manufacturers);
    },
  });

  return mutation;
};

export default {
  useManufacturers,
  useDetailedManufacturer,
  useFilteredManufacturers,
  useSaveManufacturerMutation,
  useUpdateManufacturerMutation,
  useSupplierAsManufacturer,
  useDeleteManufacturerMutation,
  useSubmitManufacturerMutation,
  useApproveOrRejectManufacturerMutation,
};
