import React from 'react';

import {
  Tooltip as MuiTooltip,
  IconButton,
  TooltipProps,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';

interface Props {
  children?: React.ReactNode;
}

const Tooltip: React.FC<Props & Omit<TooltipProps, keyof Props>> = ({
  title,
  children,
}) => (
  <MuiTooltip title={title}>
    <IconButton className="p-1 h-6">
      <Help className="text-sm" />
    </IconButton>
  </MuiTooltip>
);

export default Tooltip;
