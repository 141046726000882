/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Cell, Row } from 'react-table';

import { useForm, Controller, UnpackNestedValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';

import { phoneRegex, isApproved, isPendigForApproval } from '@utils';
import RouteMap from '@routes/map';
import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  AutoComplete,
  AutoCompleteItem,
  FooterButtonGroup,
  Table,
  CountryAutoComplete,
  DeletePromptModal,
  RejectModal,
} from '@components/Common';

import { useUrlParams } from '@hooks/utils';

import GeneralHooks from '@hooks/api/general';
import { Country } from '@hooks/api/generalTypes';

import SupplierHooks from '@hooks/api/suppliers';
import { Supplier } from '@hooks/api/suppliers/types';

import CertificateTable from '@components/Suppliers/SupplierInfo/CertificateTable';
import ManufactureTable from '@components/Suppliers/SupplierInfo/ManufactureTable';

type FieldRefType = {
  displayText: string;
  key: keyof Supplier;
  halfWidth?: boolean;
  disabled?: boolean;
  type?: 'input' | 'autocomplete' | 'country-autocomplete';
  inputType?: string;
  required?: boolean;
};

const initialValues: Omit<Supplier, 'health_mark' | 'logo' | 'supplier_code'> =
  {
    name: '',
    supplier_item_code: '',
    approval_date: '',
    approved_by: '',
    cardbox_status: null,
    supplier_type: null,
    country: null,
    city: '',
    address: '',
    email: '',
    phone: '',
    emergency_contact_name: '',
    emergency_contact_email: '',
    emergency_contact_phone: '',
    website: '',
    number_of_sites: null,
    number_of_staffs: null,
  };

const productDetailsFields: Readonly<Array<FieldRefType>> = [
  {
    displayText: 'Name',
    key: 'name',
    required: true,
  },
  {
    displayText: 'Supplier Code',
    key: 'supplier_item_code',
  },
  {
    displayText: 'Approval Data',
    key: 'approval_date',
    disabled: true,
  },
  {
    displayText: 'Approved By',
    key: 'approved_by',
    disabled: true,
  },
  {
    displayText: 'Status',
    key: 'cardbox_status',
    disabled: true,
  },
  {
    displayText: 'Supplier Type',
    key: 'supplier_type',
    type: 'autocomplete',
  },
  {
    displayText: 'Country',
    key: 'country',
    type: 'country-autocomplete',
    required: true,
  },
  {
    displayText: 'City',
    key: 'city',
    required: true,
  },
  {
    displayText: 'Address',
    key: 'address',
    halfWidth: false,
    required: true,
  },
  {
    displayText: 'Email',
    key: 'email',
    required: true,
  },
  {
    displayText: 'Phone',
    key: 'phone',
    inputType: 'number',
    required: true,
  },
  {
    displayText: 'Emergency contact Name',
    key: 'emergency_contact_name',
    required: true,
  },
  {
    displayText: 'Emergency contact Email',
    key: 'emergency_contact_email',
    required: true,
  },
  {
    displayText: 'Emergency contact Phone',
    key: 'emergency_contact_phone',
    required: true,
    inputType: 'number',
  },
  {
    displayText: 'Website',
    key: 'website',
  },
  {
    displayText: 'Number of sites',
    key: 'number_of_sites',
    inputType: 'number',
  },
  {
    displayText: 'Number of staff',
    key: 'number_of_staffs',
    inputType: 'number',
  },
];

const validationSchema = yup.object({
  name: yup.string().ensure().required('Name is required'),
  country: yup
    .mixed()
    .required('Country is required')
    .test('country', 'Country is required', val => !!val),
  city: yup.string().ensure().required('City is required'),
  address: yup.string().ensure().required('Address is required'),
  phone: yup
    .string()
    .ensure()
    .required('Phone is required')
    .test('what-ever', 'Phone number is not valid', val => {
      if (val === '') return true;

      return phoneRegex.test(val);
    }),
  email: yup
    .string()
    .ensure()
    .required('Email is required')
    .email('Email is not valid'),
  emergency_contact_name: yup
    .string()
    .ensure()
    .required('Emergency contact name is required'),
  emergency_contact_phone: yup
    .string()
    .ensure()
    .required('Emergency contact phone is required')
    .matches(phoneRegex, 'Phone number is not valid'),
  emergency_contact_email: yup
    .string()
    .ensure()
    .required('Emergency contact email is required')
    .email('Emergency contact email is not valid'),
});

const SuppliersInfoComp = () => {
  const history = useHistory();

  const [urlParams, , setUrlParams] = useUrlParams<{ editMode?: boolean }>();
  const routeParams = useRouteMatch<{ supplierCode?: string }>();
  const [user] = useUser();

  const [isDeleteSupplierModalOpen, setIsDeleteSupplierModalOpen] =
    React.useState(false);

  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);

  const { data: allStatus } = GeneralHooks.useAllStatus();

  const form = useForm<Supplier>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { data: detailedSupplier, ...useDetailedSupplierUtils } =
    SupplierHooks.useDetailedSupplier(
      {
        code: routeParams.params.supplierCode || '',
      },
      {
        enabled: !!routeParams?.params?.supplierCode,
        onSuccess: data => form.reset(data.data),
      },
    );

  const deleteSupplierMutation = SupplierHooks.useDeleteSupplierMutation();
  const updateSupplierMutation = SupplierHooks.useUpdateSupplierMutation();
  const saveSupplierMutation = SupplierHooks.useSaveSupplierMutation();
  const submitSupplierMutation = SupplierHooks.useSubmitSupplierMutation();
  const approveOrRejectSupplierMutation =
    SupplierHooks.useApproveOrRejectSupplierMutation();

  const formatSupplierData = (data: UnpackNestedValue<Supplier>) => {
    const { country, ...goodData } = data;

    return {
      ...goodData,
      country_id: country?.id || null,
    };
  };

  const onSave: (data: UnpackNestedValue<Supplier>) => void = async data => {
    form.clearErrors();

    try {
      // when 'Save' is clicked only supplier name field is validated , not all the fields
      const isFormValid = await form.trigger('name');

      if (!isFormValid) return;

      const formattedData = formatSupplierData(data);

      if (routeParams.params?.supplierCode) {
        await updateSupplierMutation.mutateAsync({
          ...formattedData,
          supplier_code: routeParams.params.supplierCode,
        });
      } else {
        const newSupplier = await saveSupplierMutation.mutateAsync(
          formattedData,
        );

        if (newSupplier) {
          history.replace(
            `${RouteMap.suppliers.supplierInfo.replace(
              ':supplierCode?',
              newSupplier.data.supplier_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (error) {}
  };

  const onSubmit: (data: UnpackNestedValue<Supplier>) => void = async data => {
    form.clearErrors();

    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) return;

      const formattedData = formatSupplierData(data);

      if (routeParams.params?.supplierCode) {
        await submitSupplierMutation.mutateAsync({
          ...formattedData,
          supplier_code: routeParams.params.supplierCode,
        });
      } else {
        const newSupplier = await submitSupplierMutation.mutateAsync(
          formattedData,
        );

        if (newSupplier) {
          history.replace(
            `${RouteMap.suppliers.supplierInfo.replace(
              ':supplierCode?',
              newSupplier.data.supplier_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (e) {}
  };

  const isStatusPendigForApproval = isPendigForApproval(
    detailedSupplier?.data.cardbox_status,
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={isDeleteSupplierModalOpen}
        setOpen={() => setIsDeleteSupplierModalOpen(false)}
        onConfirm={async () => {
          try {
            await deleteSupplierMutation.mutateAsync({
              supplier_code: routeParams.params.supplierCode || '',
            });

            history.replace(RouteMap.suppliers.suppliersList);
          } catch (error) {
          } finally {
            setIsDeleteSupplierModalOpen(false);
          }
        }}
      />

      <RejectModal
        header="Reject Message"
        open={rejectModalOpen}
        setOpen={setRejectModalOpen}
        submit={data =>
          approveOrRejectSupplierMutation.mutate({
            supplier_code: routeParams.params?.supplierCode || '',
            status: 'reject',
            message: data.message,
          })
        }
      />

      <div className="flex flex-row justify-between items-center">
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Supplier' },
            { href: '#', text: 'Supplier List' },
            { href: '#', text: 'Supplier Info' },
          ]}
        />

        {(user?.access_levels.supplier_create_update ||
          user?.access_levels.supplier_soft_delete ||
          user?.access_levels.supplier_approve) && (
          <div>
            {detailedSupplier?.data.cardbox_status === 'Archived' ? (
              <>
                {user?.access_levels.supplier_approve && (
                  <Button
                    variant="outlined"
                    className="w-28 mr-2 text-red-dark border-red-dark"
                    onClick={() => {
                      approveOrRejectSupplierMutation.mutate({
                        supplier_code: routeParams.params?.supplierCode || '',
                        status: 'draft',
                      });
                    }}
                    size="medium"
                    text="Active"
                  />
                )}
              </>
            ) : (
              <>
                {routeParams.params.supplierCode && (
                  <Button
                    variant="outlined"
                    className="w-28 mr-2 text-red-dark border-red-dark"
                    onClick={() => {
                      approveOrRejectSupplierMutation.mutate(
                        {
                          supplier_code: routeParams.params?.supplierCode || '',
                          status: 'archive',
                        },
                        {
                          onSuccess: () => {
                            setUrlParams({ editMode: false });
                          },
                        },
                      );
                    }}
                    size="medium"
                    text="Archive"
                  />
                )}
              </>
            )}

            {detailedSupplier?.data.cardbox_status !== 'Archived' && (
              <>
                {/* {user?.access_levels.supplier_soft_delete && (
                  <>
                    {urlParams.editMode && routeParams.params?.supplierCode ? (
                      <>
                        <Button
                          variant="outlined"
                          className="w-28 mr-2 text-red-dark border-red-dark"
                          onClick={() => setIsDeleteSupplierModalOpen(true)}
                          size="medium"
                          text="Delete"
                        />
                      </>
                    ) : null}
                  </>
                )} */}

                {urlParams.editMode ? (
                  <Button
                    variant="outlined"
                    className="w-28"
                    onClick={() => setUrlParams({ editMode: false })}
                    size="medium"
                    startIcon={<Close />}
                    text="Cancel"
                  />
                ) : (
                  <>
                    <Button
                      className="w-28"
                      onClick={() => setUrlParams({ editMode: true })}
                      size="medium"
                      startIcon={<Edit />}
                      text="Edit"
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <form>
        <div className="flex flex-col mt-8">
          <h3 className="text-base text-primary-light font-roboto">
            Supplier Info
          </h3>

          <div className="grid grid-cols-4 gap-x-2 gap-y-1 mt-2">
            {productDetailsFields.map(el => (
              <Controller
                key={el.displayText}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.displayText}
                    className={clsx(
                      'flex flex-col',
                      el?.halfWidth === false ? 'col-span-4' : 'col-span-2',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        {el.type === 'autocomplete' ? (
                          <AutoComplete
                            loading={useDetailedSupplierUtils.isLoading}
                            disabled={!urlParams?.editMode || el.disabled}
                            value={(value as AutoCompleteItem) || null}
                            items={allStatus?.data.supplier_types || []}
                            {...rest}
                          />
                        ) : el.type === 'country-autocomplete' ? (
                          <CountryAutoComplete
                            disableClearable
                            loading={useDetailedSupplierUtils.isLoading}
                            disabled={!urlParams?.editMode}
                            value={(value as Country) || null}
                            onChange={rest.onChange}
                          />
                        ) : (
                          <Input
                            loading={useDetailedSupplierUtils.isLoading}
                            disabled={!urlParams?.editMode || el.disabled}
                            className="flex-grow"
                            placeholder={el.displayText}
                            value={value || ''}
                            innerRef={ref}
                            type={el.inputType || 'text'}
                            {...rest}
                          />
                        )}
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}
          </div>
        </div>

        {user?.access_levels.manufacture_index_single && (
          <ManufactureTable
            supplier_code={detailedSupplier?.data.supplier_code}
            supplier_name={detailedSupplier?.data.name}
            cardbox_status={detailedSupplier?.data.cardbox_status}
          />
        )}

        {user?.access_levels.certificate_index_single && (
          <CertificateTable
            supplier_code={detailedSupplier?.data.supplier_code}
            supplier_name={detailedSupplier?.data.name}
          />
        )}

        {detailedSupplier?.data.cardbox_status === 'Archived' ? (
          <FooterButtonGroup className="my-10" editMode={false} />
        ) : (
          <FooterButtonGroup
            className="my-10"
            editMode={urlParams.editMode || false}
            isLoading={useDetailedSupplierUtils.isLoading}
            canApprove={user?.access_levels.supplier_approve}
            canReject={user?.access_levels.supplier_approve}
            canSave={user?.access_levels.supplier_create_update}
            canSubmit={user?.access_levels.supplier_submit}
            {...(urlParams.editMode
              ? isStatusPendigForApproval
                ? {
                    onApprove: () =>
                      approveOrRejectSupplierMutation.mutate(
                        {
                          supplier_code: routeParams.params?.supplierCode || '',
                          status: 'approve',
                        },
                        {
                          onSuccess: () => setUrlParams({ editMode: false }),
                        },
                      ),
                    onReject: () => {
                      setRejectModalOpen(true);
                    },
                    onSave: () => onSave(form.getValues()),
                  }
                : {
                    onSubmit: () => onSubmit(form.getValues()),
                    onSave: () => onSave(form.getValues()),
                  }
              : {})}
          />
        )}
      </form>
    </Layout>
  );
};

export default SuppliersInfoComp;
