import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { Input as MuiInput, InputProps } from '@material-ui/core';

interface P {
  value?: string | number;
  whiteWrapper?: boolean;
  inputClassName?: string;
  disabledClassName?: string;
  error?: boolean;
}

const TextArea: React.FC<P & Omit<InputProps, keyof P>> = ({
  whiteWrapper = false,
  error = false,
  className,
  inputClassName,
  disabled,
  disabledClassName,
  ...rest
}) => {
  return (
    <MuiInput
      disabled={disabled}
      className={className}
      classes={{
        root: overrideTailwindClasses(
          clsx(
            'p-2 rounded transition-all',
            whiteWrapper ? 'bg-white' : 'bg-gray-100',
            disabled ? disabledClassName ?? 'bg-inherit' : '',
            error ? 'border-1 border-red-light' : 'border-1 border-transparent',
          ),
        ),
        input: overrideTailwindClasses(
          clsx(
            'placeholder-gray-400 text-sm font-roboto',
            whiteWrapper ? 'bg-gray-100 p-4' : 'bg-inherit p-0',
            disabled ? 'bg-white text-gray-400' : 'bg-gray-100 text-gray',
            inputClassName,
          ),
        ),
      }}
      {...rest}
    />
  );
};

export default TextArea;
