import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import {
  Input as MuiInput,
  InputProps as MuiInputProps,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface P {
  inputRootClassName?: string;
  inputClassName?: string;
  dense?: boolean;
  centeredInputText?: boolean;
  fontSize?: number;
  loading?: boolean;
  disableDisabledColor?: boolean;
}

export type InputProps = P & Omit<MuiInputProps, keyof P>;

const Input: React.FC<InputProps> = ({
  className,
  inputRootClassName,
  inputClassName,
  disabled,
  dense = false,
  loading = false,
  disableDisabledColor = false,
  value,
  ...rest
}) => {
  return (
    <div
      className={overrideTailwindClasses(
        clsx(
          'bg-white transition-all',
          dense ? 'p-0 h-6' : 'p-2 h-10',
          className,
        ),
      )}
    >
      {loading ? (
        <Skeleton animation="wave" />
      ) : (
        <MuiInput
          disabled={disabled}
          classes={{
            input: overrideTailwindClasses(
              clsx(
                'transition-all w-full font-inherit text-left p-0 placeholder-gray-400',
                inputRootClassName,
              ),
            ),
            error: clsx('transition-all border-1 border-red-light'),
            // input: classes.input,
          }}
          className={overrideTailwindClasses(
            clsx(
              'font-roboto font-normal min-w-full h-full text-sm',
              disabled ? 'bg-white' : 'bg-gray-100',
              disabled && !disableDisabledColor ? 'text-gray-400' : 'text-gray',
              dense ? 'p-0' : 'px-2 py-1',
              inputClassName,
            ),
          )}
          value={value !== undefined && value !== null ? value : ''} // this ensures that the component is always `controlled`
          {...rest}
          inputProps={{
            ...rest.inputProps,
            onWheel: e => (e.target as any).blur(),
            onKeyDown: e => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              } else if (rest.inputProps?.onKeyDown) {
                rest.inputProps.onKeyDown(e);
              }
            },
          }}
        />
      )}
    </div>
  );
};

export default Input;
