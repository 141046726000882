import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';

import { toast } from 'react-toastify';

import sharedLinksApis from '@hooks/api/shared/api';
import errorHandler from '@utils/errorHandler';

import {
  GetSharedLinksParams,
  UseSharedLinksResponseType,
  DeleteSharedLinksParams,
} from '@hooks/api/shared/types';

const KEYS = {
  sharedLinksList: ['sharedLinks', 'list'],
} as const;

const useSharedLinksList = (
  params: GetSharedLinksParams,
  options?: UseQueryOptions<UseSharedLinksResponseType, AxiosError>,
) => {
  const query = useQuery<UseSharedLinksResponseType, AxiosError>(
    [...KEYS.sharedLinksList, params],
    () => sharedLinksApis.getSharedLinksList(params),
    options,
  );

  return query;
};

const useDeleteSharedLinksMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(sharedLinksApis.deleteSharedLinks, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('sharedLinks');
    },
  });

  return mutation;
};

const useResendSharedLinksMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(sharedLinksApis.resendSharedLinks, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('sharedLinks');
    },
  });

  return mutation;
};

export default {
  useSharedLinksList,
  useDeleteSharedLinksMutations,
  useResendSharedLinksMutations,
};
