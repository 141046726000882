import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import productApis from '@hooks/api/products/api';
import {
  GetProductsParams,
  GetDetailedProductsParams,
  // GetFilteredProductsParams,
  AddProductParams,
  UpdateProductParams,
  DeleteProductParams,
  RestoreProductParams,
  UseProductsType,
  UseProductType,
  NewVersionProductParams,
} from '@hooks/api/products/types';

import errorHandler from '@utils/errorHandler';

const useProducts = (
  params?: GetProductsParams,
  options?: UseQueryOptions<UseProductsType, AxiosError>,
) => {
  const query = useQuery<UseProductsType, AxiosError>(
    ['products', params],
    () => productApis.getProducts(params),
    {
      keepPreviousData: params?.paginated,
      ...options,
    },
  );

  return query;
};

const useDetailedProduct = (
  params: GetDetailedProductsParams,
  options?: UseQueryOptions<UseProductType, AxiosError>,
) => {
  const query = useQuery<UseProductType, AxiosError>(
    ['products', params],
    () => productApis.getDetailedProduct(params),
    options,
  );

  return query;
};

// const useFilteredProducts = (
//   params?: GetFilteredProductsParams,
//   options?: UseQueryOptions<UseProductsType, AxiosError>,
// ) => {
//   const query = useQuery<UseProductsType, AxiosError>(
//     ['products', params],
//     () => productApis.getFilteredProducts(params),
//     options,
//   );

//   return query;
// };

const useAddProductMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(productApis.addProduct, {
    onMutate: async (variables: AddProductParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('products');
    },
  });

  return mutation;
};

const useNewVersionProductMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(productApis.newVersionProduct, {
    onMutate: async (variables: NewVersionProductParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('products');
    },
  });

  return mutation;
};

const useUpdateProductMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(productApis.updateProdcut, {
    onMutate: async (variables: UpdateProductParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('products');
    },
  });

  return mutation;
};

const useDeleteProductMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(productApis.deleteProdcut, {
    onMutate: async (variables: DeleteProductParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('products');
    },
  });

  return mutation;
};

const useRestoreProductMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(productApis.restoreProdcut, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('products');
    },
  });

  return mutation;
};

const useShareProductMutation = () => {
  const mutation = useMutation(productApis.shareProduct, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useReportMutation = () => {
  const mutation = useMutation(productApis.report, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

export default {
  useProducts,
  useDetailedProduct,
  // useFilteredProducts,
  useNewVersionProductMutation,
  useAddProductMutation,
  useShareProductMutation,
  useReportMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useRestoreProductMutations,
};
