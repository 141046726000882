/* eslint-disable no-nested-ternary */
import React from 'react';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';
import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  DeletePromptModal,
  Switch,
  UploadButtonUploadOnChange,
  AutoComplete,
} from '@components/Common';

import RouteMap from '@routes/map';

import { useUrlParams } from '@hooks/utils';

import SupplierHooks from '@hooks/api/suppliers';

import GeneralHooks from '@hooks/api/general';

import UserHooks from '@hooks/api/user';
import { UserInfo } from '@hooks/api/user/types';
import clsx from 'clsx';

const initialValues: UserInfo = {
  contact_number: '',
  email: '',
  is_commercial_contact: false,
  is_emergency_contact: false,
  is_technical_contact: false,
  supplier: null,
  job_title: '',
  name: '',
  roles_permissions: null,
  status: '',
  license_type: null,
  username: '',
  picture: '',
};

type DropDownItem = null | {
  name: string;
  code: string | number;
};

const UserInfoComp = () => {
  const history = useHistory();
  const [user] = useUser();

  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
    React.useState(false);

  const routeParams = useRouteMatch<{ username?: string }>();

  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
    type: string;
  }>();

  const validationSchema = yup.object({
    name: yup.string().ensure().required('Name is required'),
    email: yup.string().ensure().email('Email is not valid'),
    job_title: yup.string().ensure().required('Job title is required'),
    contact_number: yup
      .string()
      .ensure()
      .required('Contact number is required'),
    roles_permissions: yup.mixed().required('Access level is required'),
    license_type: yup.mixed().required('License type is required'),
    supplier: yup.mixed().test('supplier', 'supplier is required', val => {
      if (val) {
        return true;
      }
      if (urlParams.type === 'Supplier_User') {
        return false;
      }
      return true;
    }),
  });

  const form = useForm<UserInfo>({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { ...useUserInfoUtils } = UserHooks.useUserInfo(
    {
      username: routeParams.params.username || '',
    },
    {
      enabled: !!routeParams.params.username,
      onSuccess: data => form.reset(data.data),
    },
  );

  const resendWelcomeEmailMutation = UserHooks.useResendWelcomeEmailMutation();

  let allLicenseType: Array<{ name: string; code: string }> = [
    {
      name: 'Full',
      code: 'full_license',
    },
    {
      name: 'Light',
      code: 'light_license',
    },
  ];

  if (urlParams.type === 'Supplier_User' || form.getValues('supplier')) {
    allLicenseType = [
      {
        name: 'Supplier',
        code: 'supplier',
      },
    ];
  }

  const { data: allAccessLevels, ...useAllAccessLevels } =
    GeneralHooks.useAllAccessLevels(
      {
        code: form.watch('license_type')?.code
          ? (form.watch('license_type')?.code || '').toString()
          : '',
      },
      {
        enabled:
          !!form.watch('license_type') &&
          !Number.isNaN(Number(form.watch('license_type')?.code).toString()),
      },
    );

  const { data: suppliersList, ...useSuppliersUtils } =
    SupplierHooks.useSuppliers({
      paginated: false,
      cardbox_status: true,
    });

  const createUserProfileMutation = UserHooks.useCreateUserMutation();
  const updateUserMutation = UserHooks.useUpdateUserMutation();
  const deleteUserMutation = UserHooks.useDeleteUserMutation();
  const changeUserStatusMutation = UserHooks.useChangeUserStatusMutation();

  const onSubmit: SubmitHandler<UserInfo> = async data => {
    const formattedData = {
      ...data,
      license_type: data.license_type || null,

      supplier_code: (data.supplier?.code || '').toString(),
      access_level: Number.isNaN(data.roles_permissions?.code)
        ? null
        : Number(data.roles_permissions?.code),
    };

    if (routeParams?.params?.username) {
      console.log(
        '🚀 ~ file: index.tsx ~ line 168 ~ UserInfoComp ~ formattedData',
        formattedData,
      );
      updateUserMutation.mutate(formattedData);
    } else {
      try {
        const newUser = await createUserProfileMutation.mutateAsync(
          formattedData,
        );

        if (newUser) {
          history.replace(
            `${RouteMap.configurations.users.usersInfo.replace(
              ':username?',
              newUser.data.username,
            )}?editMode=true`,
          );
        }
      } catch (error) {}
    }
  };

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={isDeleteUserModalOpen}
        setOpen={() => setIsDeleteUserModalOpen(false)}
        onConfirm={async () => {
          try {
            if (routeParams.params.username) {
              await deleteUserMutation.mutateAsync({
                username: routeParams.params.username,
              });

              history.replace(RouteMap.configurations.users.usersList);
            }
          } catch (e) {}
        }}
      />

      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Users' },
              { href: '#', text: 'Users Form' },
            ]}
          />

          <div>
            {(user?.access_levels.user_create_update_supplier ||
              user?.access_levels.user_create_update_customer) && (
              <>
                {routeParams.params?.username ? (
                  <Button
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      if (routeParams?.params?.username) {
                        resendWelcomeEmailMutation.mutate({
                          username: routeParams.params.username,
                        });
                      }
                    }}
                    size="medium"
                    text="Resend Welcome Email"
                  />
                ) : null}
              </>
            )}

            {urlParams.editMode && routeParams.params?.username ? (
              <>
                {/* <Button
                  variant="outlined"
                  className="mr-2 text-red-dark border-red-dark"
                  onClick={() => setIsDeleteUserModalOpen(true)}
                  size="medium"
                  text="Delete"
                /> */}

                {user?.access_levels.user_change_status && (
                  <Button
                    variant="outlined"
                    className="mr-2 text-red-dark border-red-dark"
                    onClick={() => {
                      if (routeParams?.params?.username) {
                        changeUserStatusMutation.mutate({
                          username: routeParams.params.username,
                          status:
                            form.watch('status') === 'active'
                              ? 'suspend'
                              : 'active',
                        });
                      }
                    }}
                    size="medium"
                    text={
                      form.watch('status') === 'active' ? 'Suspend' : 'Activate'
                    }
                  />
                )}
              </>
            ) : null}

            {(user?.access_levels.user_create_update_supplier ||
              user?.access_levels.user_create_update_customer) && (
              <>
                {urlParams.editMode ? (
                  <Button
                    variant="outlined"
                    className="w-28"
                    onClick={() => setUrlParams({ editMode: false })}
                    size="medium"
                    startIcon={<Close />}
                    text="Cancel"
                  />
                ) : (
                  <Button
                    className="w-28"
                    onClick={() => setUrlParams({ editMode: true })}
                    size="medium"
                    startIcon={<Edit />}
                    text="Edit"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              User Form
            </h3>
          </div>

          <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
            {(
              [
                {
                  displayText: 'Full Name',
                  key: 'name',
                  required: true,
                },
                {
                  displayText: 'Email',
                  key: 'email',
                  required: true,
                  readonly: true,
                },
                {
                  displayText: 'Status',
                  key: 'status',
                  disabled: true,
                },
                {
                  displayText: 'Job Title',
                  key: 'job_title',
                  required: true,
                },
                {
                  displayText: 'Contact Number',
                  key: 'contact_number',
                  required: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useUserInfoUtils.isLoading}
                          disabled={
                            ('disabled' in el && el.disabled) ||
                            ('readonly' in el &&
                              el.readonly &&
                              !!routeParams.params?.username) ||
                            !urlParams?.editMode
                          }
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="supplier"
              control={form.control}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState,
              }) => (
                <div
                  className={clsx(
                    'flex flex-col col-span-1',
                    urlParams.type === 'Supplier_User' ||
                      form.getValues('supplier')
                      ? ''
                      : 'invisible',
                  )}
                >
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Supplier</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        noOptionsText="No matching supplier is found, please check the supplier record is created"
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={useSuppliersUtils.isLoading}
                        disabled={
                          !urlParams?.editMode || !!routeParams.params?.username
                        }
                        value={value || null}
                        items={(suppliersList?.data || []).map(el2 => ({
                          name: el2.name,
                          code: el2.supplier_code,
                        }))}
                        onChange={newVal => onChange(newVal)}
                        {...rest}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="license_type"
              control={form.control}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState,
              }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Licence Type</p>
                    </div>
                    <div className="flex flex-col w-full">
                      <AutoComplete
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={useSuppliersUtils.isLoading}
                        disabled={!urlParams?.editMode}
                        items={allLicenseType || []}
                        value={value}
                        onChange={val => {
                          form.setValue('roles_permissions', null);
                          onChange(val);
                        }}
                        {...rest}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="roles_permissions"
              control={form.control}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState,
              }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Access Level</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={
                          useSuppliersUtils.isLoading &&
                          useAllAccessLevels.isLoading
                        }
                        disabled={!urlParams?.editMode}
                        items={allAccessLevels?.data || []}
                        value={value}
                        onChange={val => {
                          onChange(val);
                        }}
                        {...rest}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Emergency Contact',
                  key: 'is_emergency_contact',
                },
                {
                  displayText: 'Technical Contact',
                  key: 'is_technical_contact',
                },
                {
                  displayText: 'Commercial Contact',
                  key: 'is_commercial_contact',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({
                  field: { onChange, value, ...rest },
                  fieldState,
                }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Switch
                          disabled={!urlParams?.editMode}
                          type="secondary"
                          className="justify-center my-2"
                          value={
                            typeof value === 'boolean'
                              ? value
                                ? 'Yes'
                                : 'No'
                              : null
                          }
                          onChange={newVal =>
                            onChange(newVal.toLowerCase() === 'yes')
                          }
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="picture"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Picture</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <UploadButtonUploadOnChange
                        url="/user/upload-picture-user"
                        disabled={!urlParams?.editMode}
                        id="picture"
                        wrapperClassName="flex justify-center my-2"
                        value={value ? String(value) : ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        isLoading={useSuppliersUtils.isLoading}
        canSave={
          user?.access_levels.user_create_update_supplier ||
          user?.access_levels.user_create_update_customer
        }
        {...(urlParams.editMode
          ? {
              onSave: form.handleSubmit(onSubmit),
            }
          : {})}
      />
    </Layout>
  );
};

export default UserInfoComp;
