import React from 'react';
import clsx from 'clsx';
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  EditorProps,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ReactComponent as BoldIcon } from '@assets/images/icons/text-editor/bold.svg';
import { ReactComponent as ItalicIcon } from '@assets/images/icons/text-editor/italic.svg';
import { ReactComponent as UnderlineIcon } from '@assets/images/icons/text-editor/underline.svg';
import { ReactComponent as PasteIcon } from '@assets/images/icons/text-editor/paste.svg';
import { ReactComponent as CopyIcon } from '@assets/images/icons/text-editor/copy.svg';
import { ReactComponent as AddIcon } from '@assets/images/icons/text-editor/add.svg';
import { ReactComponent as UndoIcon } from '@assets/images/icons/text-editor/undo.svg';
import { ReactComponent as SaveIcon } from '@assets/images/icons/text-editor/save.svg';
import { ReactComponent as ClearIcon } from '@assets/images/icons/text-editor/clear.svg';

type ToolbarActions =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'paste'
  | 'copy'
  | 'add'
  | 'save'
  | 'clear'
  | 'autofill';
interface P {
  value?: string; // should probably be string of html
  onChange: (value: string) => void;
  onSave?: () => void;
  onUndo?: () => void;
  height?: number;
  editorState?: never; // will be handled internaly
  whiteWrapper?: boolean;
  header?: string;
  toolbar?: Array<ToolbarActions>;
  className?: string;
  error?: boolean;
}

const ICONS = {
  bold: BoldIcon,
  italic: ItalicIcon,
  underline: UnderlineIcon,
  paste: PasteIcon,
  copy: CopyIcon,
  add: AddIcon,
  autofill: UndoIcon,
  save: SaveIcon,
  clear: ClearIcon,
} as const;

const TOOLBAR_HEIGHT = 5;

type StyleRelatedProps = Pick<P, 'toolbar' | 'whiteWrapper' | 'height'>;

const useStyles = makeStyles((theme: any) => ({
  header: {
    height: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(0.5),
  },
  'editor-wrapper': {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),

    '& .DraftEditor-root': {
      backgroundColor: ({ whiteWrapper }: StyleRelatedProps) =>
        whiteWrapper ? theme.palette.gray.light : theme.palette.common.white,
      height: ({ height, toolbar }: StyleRelatedProps) =>
        toolbar
          ? theme.spacing(height || TOOLBAR_HEIGHT - TOOLBAR_HEIGHT)
          : theme.spacing(height || TOOLBAR_HEIGHT),

      overflowY: 'auto',

      '& .public-DraftEditorPlaceholder-root': {
        padding: theme.spacing(2, 2, 0, 2),
      },

      '& .DraftEditor-editorContainer': {
        // padding: theme.spacing(1),

        '& .public-DraftEditor-content': {
          padding: theme.spacing(2, 2, 0, 2),
        },
      },
    },
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    height: theme.spacing(TOOLBAR_HEIGHT),
    backgroundColor: ({ whiteWrapper }: StyleRelatedProps) =>
      whiteWrapper ? theme.palette.gray.light : theme.palette.common.white,
  },
  iconActive: {
    color: theme.palette.secondary.dark,
  },
  iconInactive: {
    color: theme.palette.secondary.main,
  },
}));

const FetchIcon = ({ icon }: { icon: ToolbarActions }) => {
  const ICON = ICONS[icon];

  return <ICON className="fill-current" />;
};

const makeEditorStateFromHtml = (htmlValue: string) => {
  const blocksFromHTML = convertFromHTML(htmlValue);

  const contentFromBlocks = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );

  const state = EditorState.createWithContent(contentFromBlocks);

  return state;
};

const TextEditor: React.FC<P & Omit<EditorProps, keyof P>> = ({
  whiteWrapper = false,
  header,
  toolbar,
  value,
  height = 12,
  onChange,
  onSave,
  onUndo,
  className,
  readOnly,
  error,
  ...rest
}) => {
  const classes = useStyles({ toolbar, whiteWrapper, height });

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  );

  React.useEffect(() => {
    if (value && value !== stateToHTML(editorState.getCurrentContent())) {
      const newEditorState = makeEditorStateFromHtml(value);

      const newStateAndSelection = EditorState.moveFocusToEnd(newEditorState);

      setEditorState(newStateAndSelection);
    }
  }, [value]);

  const onToolbarClick = (type: ToolbarActions) => {
    if (type === 'bold' || type === 'italic' || type === 'underline') {
      setEditorState(
        RichUtils.toggleInlineStyle(editorState, type.toUpperCase()),
      );
    }

    if (type === 'autofill') {
      // if (onUndo?.()) {
      //   const newState = EditorState.autofill(editorState);
      //   setEditorState(newState);
      //   onChange(stateToHTML(newState.getCurrentContent()));
      // } else {
      onUndo?.();
      // }
    }

    if (type === 'copy') {
      navigator.permissions
        .query({ name: 'clipboard-write' as PermissionName })
        .then(result => {
          if (result.state === 'granted' || result.state === 'prompt') {
            // const editorPlainText = editorState
            //   .getCurrentContent()
            //   .getPlainText();

            const editorHtmlValue = stateToHTML(
              editorState.getCurrentContent(),
            );

            navigator.clipboard.writeText(editorHtmlValue);
          }
        });
    }

    if (type === 'paste') {
      navigator.permissions
        .query({ name: 'clipboard-read' as PermissionName })
        .then(result => {
          if (result.state === 'granted' || result.state === 'prompt') {
            navigator.clipboard.readText().then(clipboardValue => {
              const newEditorState = makeEditorStateFromHtml(clipboardValue);

              setEditorState(newEditorState);
            });
          }
        });
    }

    if (type === 'clear') {
      setEditorState(EditorState.createEmpty());
    }

    if (type === 'save') {
      onSave?.();
    }
  };

  const editorInlineStyles = editorState.getCurrentInlineStyle();

  const handleReturn = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }

    return 'not-handled';
  };

  return (
    <>
      {header ? (
        <div
          className={clsx(
            classes.header,
            'flex items-center px-4 text-sm font-roboto font-normal rounded',
          )}
        >
          {header}
        </div>
      ) : null}

      <div
        className={clsx(
          classes['editor-wrapper'],
          'shadow w-full',
          className,
          error && 'border-1 border-solid border-red-light',
        )}
      >
        <Editor
          readOnly={readOnly}
          handleReturn={handleReturn}
          editorState={editorState}
          onChange={val => {
            setEditorState(val);
          }}
          onBlur={() => {
            const htmlValue = stateToHTML(editorState.getCurrentContent());

            if (
              value !== editorState.getCurrentContent().getPlainText() &&
              value !== stateToHTML(editorState.getCurrentContent())
            ) {
              onChange(htmlValue);
            }
          }}
          {...rest}
        />

        {toolbar && toolbar.length > 0 ? (
          <div
            className={clsx(
              classes.toolbar,
              'flex flex-row justify-between items-center',
            )}
          >
            <div className="flex flex-row items-center">
              {toolbar
                .filter(
                  el => el === 'bold' || el === 'italic' || el === 'underline',
                )
                .map(el => (
                  <Tooltip key={el} title={el}>
                    <IconButton
                      disabled={readOnly}
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onToolbarClick(el);
                      }}
                      className={clsx(
                        editorInlineStyles.has(el.toUpperCase())
                          ? classes.iconActive
                          : classes.iconInactive,
                      )}
                    >
                      <FetchIcon icon={el} />
                    </IconButton>
                  </Tooltip>
                ))}
            </div>

            <div className="flex flex-row items-center">
              {toolbar
                .filter(
                  el =>
                    el === 'paste' ||
                    el === 'copy' ||
                    el === 'add' ||
                    el === 'save' ||
                    el === 'clear' ||
                    el === 'autofill',
                )
                .map(el => (
                  <Tooltip key={el} title={el}>
                    <IconButton
                      disabled={readOnly}
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        onToolbarClick(el);
                      }}
                      className={clsx(classes.iconActive)}
                    >
                      <FetchIcon icon={el} />
                    </IconButton>
                  </Tooltip>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TextEditor;
