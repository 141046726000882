import React from 'react';
import { useForm, Controller, FieldPath } from 'react-hook-form';

import * as yup from 'yup';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Modal, Switch, Select, Button } from '@components/Common';

import { deleteLayerModalValues } from './types';

type P = {
  header: string;
  open: boolean;
  option: any;
  setOpen: (value: boolean) => void;
  submit: (value: deleteLayerModalValues) => void;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(65),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const validationSchema = yup.object({
  productName: yup.string().required('Product Name is required'),
  productCode: yup.string().required('Product Code is required'),
});

const NewProductModal: React.FC<P> = ({
  open,
  setOpen,
  header,
  submit,
  option,
}) => {
  const classes = useStyles();
  const [initialConfirm, setInitialConfirm] = React.useState(false);

  const form = useForm<deleteLayerModalValues>();

  const cancel = async () => {
    form.reset();
    setInitialConfirm(false);
  };

  const onSubmit = async (data: deleteLayerModalValues) => {
    submit(data);
    form.reset();
    setInitialConfirm(false);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">{header}</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-center items-center min-w-full mb-2">
            {/* <p className="font-roboto">What to do</p> */}
            <div className="flex flex-col">
              <Controller
                name="whatToDo"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    leftButtonText="Delete"
                    rightButtonText="Merge"
                    value={value}
                    onChange={val => onChange(val)}
                  />
                )}
              />
            </div>
          </div>

          {form.watch('whatToDo') === 'Merge' ? (
            <div className="flex flex-row justify-between items-center min-w-full mb-2">
              <p className="font-roboto">Merge to</p>
              <Controller
                name="mergeTo"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    wrapperClassName="w-56"
                    labelId="mergeTo-select-label"
                    id="mergeTo"
                    menuItems={option}
                    placeholder="Select Layer"
                    value={value}
                    onChange={val => onChange(val)}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="flex flex-row justify-between items-center min-w-full mt-2">
            {initialConfirm ? (
              <>
                <p>Are you sure you want to {form.watch('whatToDo')}</p>
                <div className="flex flex-row">
                  <Button
                    variant="text"
                    text="Cancel"
                    onClick={() => cancel()}
                    className="mr-2 text-sm font-roboto text-gray-800"
                  />

                  <Button
                    size="medium"
                    className="bg-green"
                    text="Confirm"
                    onClick={() => onSubmit(form.getValues())}
                  />
                </div>
              </>
            ) : (
              <Button
                type="submit"
                className="text-green border-green"
                variant="outlined"
                startIcon={<Check />}
                text="Done"
                onClick={() => setInitialConfirm(true)}
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
