import { AxiosResponse } from 'axios';

import axios from '@lib/api';

import {
  GetCertificatesParams,
  UseCertificatesResponse,
  GetDetailedCertificateParams,
  UseDetailedCertificateResponse,
  DeleteCertificateParams,
  SaveCertificateParams,
  EditCertificateParams,
  ApproveOrRejectCertificateParams,
  SubmitCertificateParams,
} from './types';

const getCertificates = async (params?: GetCertificatesParams) => {
  const res: AxiosResponse<UseCertificatesResponse> = await axios({
    method: 'get',
    url: '/certificates',
    params,
  });

  return res.data;
};

const getDetailedCertificate = async (params: GetDetailedCertificateParams) => {
  const { certificateCode, ...data } = params;

  const res: AxiosResponse<UseDetailedCertificateResponse> = await axios({
    method: 'get',
    url: `/certificates/${certificateCode}/show`,
    data,
  });

  return res.data;
};

const deleteCertificate = async (params: DeleteCertificateParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/certificates/${params.certificateCode}/trash`,
  });

  return res.data;
};

const saveCertificate = async (data: SaveCertificateParams) => {
  const res: AxiosResponse<UseDetailedCertificateResponse> = await axios({
    method: 'post',
    url: '/certificates',
    data,
  });

  return res.data;
};

const submitCertificate = async (data: SubmitCertificateParams) => {
  const res: AxiosResponse<UseDetailedCertificateResponse> = await axios({
    method: 'post',
    url: '/certificates/submit',
    data,
  });

  return res.data;
};

const approveOrRejectCertificate = async (
  params: ApproveOrRejectCertificateParams,
) => {
  const res: AxiosResponse<UseDetailedCertificateResponse> = await axios({
    method: 'put',
    url: `/certificates/${params.certificateCode}/status`,
    params,
  });

  return res.data;
};

const updateCertificate = async (params: EditCertificateParams) => {
  const { certificateCode, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/certificates/${certificateCode}/update`,
    data,
  });

  return res.data;
};

export default {
  getCertificates,
  deleteCertificate,
  saveCertificate,
  updateCertificate,
  getDetailedCertificate,
  submitCertificate,
  approveOrRejectCertificate,
};
