import React from 'react';
import clsx from 'clsx';
import { Modal as MuiModal, Fade, Backdrop } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useSidebarState } from '@contexts';

type P = {
  children: React.ReactElement<any, any>;
  open: boolean;
  setOpen: (value: boolean) => void;
  className?: string;
};

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: isSidebarOpen =>
      isSidebarOpen ? theme.drawerWidth : theme.closedDrawerWidth,
    marginTop: theme.appBarHeight,
  },
  backdrop: {
    backdropFilter: 'blur(2px)',
    // filter: 'opacity(50%)',
    marginLeft: isSidebarOpen =>
      isSidebarOpen ? theme.drawerWidth : theme.closedDrawerWidth,
    marginTop: theme.appBarHeight,
  },
}));

const Modal: React.FC<P> = ({ children, open, setOpen, className }) => {
  const [isSidebarOpen] = useSidebarState();

  const classes = useStyles(isSidebarOpen);

  return (
    <MuiModal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={clsx(classes.modal, 'c-modal-open', className)}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Fade in={open}>{children}</Fade>
    </MuiModal>
  );
};

export default Modal;
