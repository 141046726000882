import React from 'react';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';

import {
  handleBgColorTailwind,
  handleTextColorTailwind,
} from '@components/Bussines/Products/ProductForm/Nutrition/helper';

interface P {
  color: string;
  footerComp: {
    value: string | number | null;
  };
  headerComp:
    | { value: string | number | null }
    | { values: Array<string | number | null> };
  header: string;
}

const EggComp: React.FC<P> = ({ color, header, headerComp, footerComp }) => {
  const HeadeComp = () => {
    if ('values' in headerComp) {
      return (
        <>
          {headerComp.values.map((el, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${el}--${i}`}
              className={clsx(
                'h-8 mx-3 text-ceter mt-2 flex flex-row justify-center items-center text-center text-lg w-30',
                i === 0 ? '' : 'mb-4',
                handleTextColorTailwind(color),
              )}
            >
              <p className="break-all h-8 leading-8 text-ellipsis  overflow-hidden">
                {el || ''}
              </p>

              <span>{el ? `${i === 0 ? ' KJ' : ' Kcal'}` : ''}</span>
            </div>
          ))}
        </>
      );
    }

    return (
      <div
        className={clsx(
          'h-12 mx-3 text-ceter mt-5 mb-7 flex flex-row justify-center items-center text-center text-lg w-30',
          handleTextColorTailwind(color),
        )}
      >
        <p className="break-all h-12 leading-12 text-ellipsis  overflow-hidden">
          {headerComp.value !== null ? (
            <>{Number(headerComp.value) >= 0.1 ? headerComp.value : '<0.1'}</>
          ) : (
            ''
          )}
        </p>

        <span>{headerComp.value !== null ? 'g' : ''}</span>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        'w-36 flex flex-col text-center py-12',
        'border-2 border-primary rounded-90px',
        handleBgColorTailwind(color),
      )}
    >
      <h3 className="font-poppins text-black text-2xl leading-9 h-9">
        {header}
      </h3>

      <HeadeComp />

      <Divider className="bg-primary w-full h-0.5 mb-4" />

      <div
        className={clsx(
          ' flex flex-row justify-center items-center text-center text-lg w-30 h-12 mx-3 text-ceter',
          handleTextColorTailwind(color),
        )}
      >
        <p className="break-all h-12 leading-12 text-ellipsis  overflow-hidden">
          {footerComp.value !== null ? (
            <>{Number(footerComp.value >= 0.1) ? footerComp.value : '<0.1'}</>
          ) : (
            ''
          )}
        </p>

        <span>{footerComp.value !== null ? '%' : ''}</span>
      </div>
    </div>
  );
};

export default EggComp;
