import React from 'react';
import Badge from '@material-ui/core/Badge';

import { toast } from 'react-toastify';

import {
  IconButton,
  Popper,
  Grow,
  Divider,
  ClickAwayListener,
} from '@material-ui/core';

import NotificationHooks from '@hooks/api/notification';

import { Notifications, Close } from '@material-ui/icons';

import RouteMap from '@routes/map';

import { Button } from '@components/Common';

import { onMessageListener } from '../../firebase.js';

const Layout: React.FC = () => {
  const { data: notificationList, refetch } =
    NotificationHooks.useNotificationList({
      page: 1,
      q: '',
      status: 1,
    });

  onMessageListener()
    .then(payload => {
      console.log('🚀 ~ file: Layout.tsx ~ line 78 ~ payload', payload);
      if (payload.notification?.body) {
        console.log(
          '🚀 ~ file: Layout.tsx ~ line 81 ~ payload.notification?.body.toString()',
          payload.notification?.body.toString(),
        );
        toast(payload.notification?.body.toString());
        refetch();
      } else {
        toast(payload.data?.body.toString());
      }
    })
    .catch(err => console.log('failed: ', err));

  const [open, setOpen] = React.useState(false);
  const [openNoitification, setOpenNoitification] = React.useState(false);
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);

  const deleteNotification = NotificationHooks.useDeleteNotificationMutations();

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="text"
        innerRef={menuAnchorRef}
        onClick={() => setOpenNoitification(!open)}
        className="mr-4"
      >
        <Badge color="error" badgeContent={notificationList?.meta?.total}>
          <Notifications color="secondary" />
        </Badge>
      </Button>

      <Popper
        open={openNoitification}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className="z-20 bg-white shadow-lg rounded"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <div>
              <ClickAwayListener
                onClickAway={() => setOpenNoitification(false)}
              >
                <div className="flex flex-col py-4 shadow w-120">
                  <h6 className="px-4">Notifications</h6>
                  <Divider className="my-4" />

                  <ul className="px-4 h-40 overflow-auto">
                    {notificationList?.data.map(notification => (
                      <li className="w-full flex">
                        <div className="w-full pt-3">
                          {notification.notification_text}
                        </div>
                        <IconButton
                          className="ml-4"
                          onClick={async () => {
                            try {
                              await deleteNotification.mutateAsync({
                                notification_code:
                                  notification.notification_code,
                              });
                            } catch (error) {}
                          }}
                        >
                          <Close className="" />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                  <div className="text-right px-4 mt-4">
                    <a href={RouteMap.notification}>See All</a>
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Layout;
