import React, { useEffect, useState } from 'react';
import { Add as AddIcon, Edit, Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { useUrlParams } from '@hooks';

import {
  Units,
  ingredients_materials,
  Ingredients_Item,
} from '@hooks/api/wizard/types';

import { treeRow } from '@components/Bussines/Products/ProductForm/Ingredients/utils';
import {
  layerModalValues,
  deleteLayerModalValues,
} from '@components/Bussines/Products/ProductForm/Ingredients/types';

import LayerModal from './LayerModal';
import DeleteLayerModal from './DeleteLayerModal';
import Table from './Table';

import Button from '../../../../Common/Button';

type P = {
  error: any;
  value: Array<ingredients_materials>;
  onChange: (value: Array<ingredients_materials>) => void;
};

const Layer: React.FC<P> = ({ error, value, onChange }) => {
  // console.log('error', error);
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const [isLayerModalOpen, setIsLayerModalOpen] = React.useState(false);
  const [isEditLayerModalOpen, setIsEditLayerModalOpen] = React.useState(false);
  const [isDeleteLayerModalOpen, setIsDeleteLayerModalOpen] =
    React.useState(false);

  const treesData = value;

  const [activeTable, setActiveTable] = useState(0);

  const changeTab = (index: number) => {
    setActiveTable(index);
  };

  const isCurrentLayer = () =>
    (treesData[0]?.name === null || treesData[0]?.name === '') &&
    treesData.length === 1;

  const newLayerHandling = (data: layerModalValues) => {
    if (isCurrentLayer()) {
      const newData = [...treesData];
      newData[0].name = data.name;
      newData[0].product_grams = data.product_grams;
      newData[0].product_percentage = data.product_percentage;
      onChange(newData);
    } else {
      const newData = [...treesData];
      newData.push({
        id: Math.floor(Math.random() * 10000),
        name: data.name,
        product_grams: data.product_grams,
        product_percentage: data.product_percentage,
        tree_data: [treeRow],
        total_qty_in_mixing_bowl: 0,
        total_qty_in_final_product: 0,
        total_percentage_in_final_product_manual: 0,
      });
      onChange(newData);
      setActiveTable(treesData.length);
    }
  };

  const editLayerHandling = (data: layerModalValues) => {
    const newData = [...treesData];

    newData[activeTable].name = data.name;
    newData[activeTable].product_grams = data.product_grams;
    newData[activeTable].product_percentage = data.product_percentage;

    onChange(newData);
  };

  const deleteLayerHandling = (data: deleteLayerModalValues) => {
    const indexDeleted = activeTable;

    // if user select merge
    const newData = [...treesData];
    if (data.whatToDo === 'Merge') {
      newData[data.mergeTo].tree_data.push(...treesData[activeTable].tree_data);
      newData.splice(indexDeleted, 1);
      setActiveTable(data.mergeTo);
    } else if (treesData.length === 1) {
      // if user select delete all layer
      newData[0].name = '';
      newData[0].product_grams = null;
      newData[0].product_percentage = null;
    } else {
      newData.splice(indexDeleted, 1);
      setActiveTable(0);
    }
    onChange(newData);
  };

  // React.useEffect(() => {
  //   if (activeTable > treesData.length) {
  //     setActiveTable(0);
  //   }
  // }, [activeTable]);

  const option = treesData
    .map((tree_data, index) => ({
      displayText: tree_data.name,
      value: index,
    }))
    .filter((tree_data, index) => index !== activeTable);

  return (
    <>
      <LayerModal
        header={isCurrentLayer() ? 'Current Layer' : 'New Layer'}
        open={isLayerModalOpen}
        setOpen={setIsLayerModalOpen}
        submit={newLayerHandling}
      />
      {isEditLayerModalOpen ? (
        <LayerModal
          header={treesData[activeTable].name}
          open={isEditLayerModalOpen}
          setOpen={setIsEditLayerModalOpen}
          initialValues={{
            name: treesData[activeTable].name,
            product_grams: treesData[activeTable].product_grams,
            product_percentage: treesData[activeTable].product_percentage,
          }}
          submit={editLayerHandling}
        />
      ) : null}
      {isDeleteLayerModalOpen ? (
        <DeleteLayerModal
          header={treesData[activeTable]?.name}
          open={isDeleteLayerModalOpen}
          setOpen={setIsDeleteLayerModalOpen}
          option={option}
          submit={deleteLayerHandling}
        />
      ) : null}
      <div className="flex justify-between mb-4 mt-6 z-10 relative">
        <h2 className="text-center text-xl text-primary ">Ingredients</h2>
        <Button
          variant="outlined"
          className="border-1 border-primary h-9"
          onClick={() => setIsLayerModalOpen(true)}
          startIcon={<AddIcon />}
          disabled={!urlParams.editMode}
          text="New Layer"
        />
      </div>

      {treesData.length > 1 && (
        <>
          {!isCurrentLayer() && (
            <div className="tabs">
              {treesData.map((table, index) => (
                <div
                  className={`tab-parent 
                ${
                  error?.error !== undefined &&
                  error.error[index] &&
                  'inValidLayer'
                }`}
                  key={`layer-${table.id}`}
                >
                  {activeTable === index ? (
                    <div
                      className={`tab ${activeTable === index && 'details'} `}
                    >
                      <span className="item name">{table.name}</span>
                      <span className="item details-item product_grams">
                        Total gram of Product: {table.product_grams}
                      </span>
                      <span className="item details-item product_percentage">
                        % of Product: {table.product_percentage}
                      </span>
                      <span className="item details-item edit-close">
                        <IconButton
                          onClick={() => setIsEditLayerModalOpen(true)}
                          className="p-0"
                          disabled={!urlParams.editMode}
                        >
                          <Edit className="" />
                        </IconButton>
                        <IconButton
                          onClick={() => setIsDeleteLayerModalOpen(true)}
                          className="p-0"
                          disabled={!urlParams.editMode}
                        >
                          <Close className="" />
                        </IconButton>
                      </span>
                    </div>
                  ) : (
                    <Button
                      onClick={() => changeTab(index)}
                      className={`tab ${activeTable === index && 'details'}`}
                      key={`layer-${table.id}`}
                    >
                      <span className="item name">{table.name}</span>
                    </Button>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {treesData.map((tree_data, index) => (
        <div
          key={`table-${tree_data.id}`}
          className={activeTable === index ? '' : 'hidden'}
        >
          <Table treesData={treesData} setTreesData={onChange} index={index} />
        </div>
      ))}
    </>
  );
};

export default Layer;
