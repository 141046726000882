import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  WizardApiBaseParams,
  UpdateMainInfoParams,
  UseProductMainInfo,
  UseProductIntegrity,
  UseProductNutrition,
  UpdateNutritionParams,
  UpdateIngredientsParams,
  UpdateIntegrityParams,
  UseProductAllergens,
  UpdateAllergensParams,
  UseProductCCP,
  UpdateCCPParams,
  UseProductStandards,
  UpdateStandardsParams,
  UseProductAttachments,
  UpdateAttachmentsParams,
  UseProductWeight,
  UpdateWeightParams,
  UseProductStorage,
  UpdateStorageParams,
  UseProductIngredients,
  UseENumbersType,
  UseSaltTargets,
  UseProductQas,
  UpdateQasParams,
  UseCustomDeclaration,
  UseAllergenListType,
  CustomDeclaration,
  UseProductValidations,
  UpdateValidationsParams,
  UseProductSpecTabs,
  StatusProductParams,
  UseStatusProductResponseType,
} from '@hooks/api/wizard/types';

const getMainInfo = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductMainInfo> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/maininfo`,
  });

  return res.data;
};

const updateMainInfo = async (params: UpdateMainInfoParams) => {
  const { code, version, ...rest } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/maininfo`,
    data: rest,
  });

  return res.data;
};

const validateMainInfo = async (params: UpdateMainInfoParams) => {
  const { code, version, ...rest } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/maininfo/validate`,
    data: rest,
  });

  return res.data;
};

const getIngredients = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductIngredients> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/ingredients`,
  });

  // const newData = { ...res.data };

  let custom_declarations = '';

  if (res.data.data.custom_declarations) {
    (
      res.data.data.custom_declarations as unknown as Array<CustomDeclaration>
    ).forEach((item: CustomDeclaration) => {
      custom_declarations += `<strong>${item.changed_by}</strong> . `;
      custom_declarations += item.changed_at;
      custom_declarations += '<br/>';
      custom_declarations += item.cause_of_change;
      custom_declarations += '<br/>';
    });
  }

  res.data.data.custom_declarations = custom_declarations;

  return res.data;
};

const getENumbers = async () => {
  const res: AxiosResponse<UseENumbersType> = await axios({
    method: 'get',
    url: '/enumbers',
  });
  return res.data;
};

const getAllergenList = async () => {
  const res: AxiosResponse<UseAllergenListType> = await axios({
    method: 'get',
    url: '/allergens',
  });

  return res.data;
};

const getCustomDeclaration = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseCustomDeclaration> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/ingredients/custom_declaration`,
  });
  return res.data;
};

const updateIngredients = async (params: UpdateIngredientsParams) => {
  const { code, version, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/ingredients`,
    data,
  });

  return res.data;
};

const validateIngredients = async (params: UpdateIngredientsParams) => {
  const { code, version, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/ingredients/validate`,
    data,
  });

  return res.data;
};

const getIntegrity = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductIntegrity> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/integrities`,
  });

  return res.data;
};

const updateIntegrity = async (params: UpdateIntegrityParams) => {
  const { code, version, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/integrities`,
    data,
  });

  return res.data;
};

const validateIntegrity = async (params: UpdateIntegrityParams) => {
  const { code, version, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/integrities/validate`,
    data,
  });

  return res.data;
};

const getAllergens = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductAllergens> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/allergens`,
  });

  const newData = { ...res.data };

  console.log(
    '🚀 ~ file: api.ts ~ line 201 ~ getAllergens ~ newData.data.substances.length',
    newData.data.substances.length,
  );
  // for (let i = 0; i < 14; i += i) {
  //   if (
  //     newData.data.substances[i].comments === null &&
  //     newData.data.substances_automatic[i].comments !== null
  //   ) {
  //     newData.data.substances[i].comments =
  //       newData.data.substances_automatic[i].comments;
  //   }
  // }

  return newData;
};

const updateAllergens = async (params: UpdateAllergensParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/allergens`,
    data,
  });

  return res.data;
};

const validateAllergens = async (params: UpdateAllergensParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/allergens/validate`,
    data,
  });

  return res.data;
};

const getStandards = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductStandards> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/standards`,
  });

  return res.data;
};

const updateStandards = async (params: UpdateStandardsParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/standards`,
    data,
  });

  return res.data;
};

const validateStandards = async (params: UpdateStandardsParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/standards/validate`,
    data,
  });

  return res.data;
};

const getNutrition = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductNutrition> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/nutritions`,
  });

  return res.data;
};

const updateNutrition = async (params: UpdateNutritionParams) => {
  const { code, version, ...data } = params;
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/nutritions`,
    data,
  });

  return res.data;
};

const validateNutrition = async (params: UpdateNutritionParams) => {
  const { code, version, ...data } = params;
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/nutritions/validate`,
    data,
  });

  return res.data;
};

const getAttachments = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductAttachments> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/attachments`,
  });

  return res.data;
};

const updateAttachments = async (params: UpdateAttachmentsParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/attachments`,
    data,
  });

  return res.data;
};

const validateAttachments = async (params: UpdateAttachmentsParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/attachments/validate`,
    data,
  });

  return res.data;
};

const getCCP = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductCCP> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/ccps`,
  });

  return res.data;
};

const updateCCP = async (params: UpdateCCPParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/ccps`,
    data,
  });

  return res.data;
};

const validateCCP = async (params: UpdateCCPParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/ccps/validate`,
    data,
  });

  return res.data;
};

const getWeight = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductWeight> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/packaging`,
  });

  return res.data;
};

const updateWeight = async (params: UpdateWeightParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/packaging`,
    data,
  });

  return res.data;
};

const validateWeight = async (params: UpdateWeightParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/packaging/validate`,
    data,
  });

  return res.data;
};

const getStorage = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductStorage> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/storages`,
  });

  return res.data;
};

const updateStorage = async (params: UpdateStorageParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/storages`,
    data,
  });

  return res.data;
};

const validateStorage = async (params: UpdateStorageParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/storages/validate`,
    data,
  });

  return res.data;
};

const getSaltTargets = async () => {
  const res: AxiosResponse<UseSaltTargets> = await axios({
    method: 'get',
    url: '/salttargets',
  });

  return res.data;
};

const getQas = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductQas> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/qas`,
  });

  return res.data;
};

const updateQas = async (params: UpdateQasParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/qas`,
    data,
  });

  return res.data;
};

const validateQas = async (params: UpdateQasParams) => {
  const { code, version, errors, warnings, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/qas/validate`,
    data,
  });

  return res.data;
};

const getValidations = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductValidations> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/validate`,
  });

  return res.data;
};

const updateValidations = async (params: UpdateValidationsParams) => {
  const { code, version } = params;

  const res: AxiosResponse = await axios({
    method: 'post',
    url: `/products/${code}/version/${version}/validate`,
  });

  return res.data;
};

const getProductSpecTabs = async (params: WizardApiBaseParams) => {
  const { code, version } = params;

  const res: AxiosResponse<UseProductSpecTabs> = await axios({
    method: 'get',
    url: `/products/${code}/version/${version}/validate/tabs`,
  });

  return res.data;
};

const statusProduct = async (params: StatusProductParams) => {
  const { code, version, ...data } = params;
  const res: AxiosResponse<UseStatusProductResponseType> = await axios({
    method: 'put',
    url: `/products/${code}/version/${version}/status`,
    data,
  });

  return res.data;
};

export default {
  getMainInfo,
  updateMainInfo,
  validateMainInfo,
  getIngredients,
  updateIngredients,
  getCustomDeclaration,
  getENumbers,
  getAllergenList,
  getIntegrity,
  updateIntegrity,
  getAllergens,
  updateAllergens,
  getNutrition,
  updateNutrition,
  getStandards,
  updateStandards,
  getAttachments,
  updateAttachments,
  getCCP,
  updateCCP,
  getWeight,
  updateWeight,
  getStorage,
  updateStorage,
  getSaltTargets,
  getQas,
  updateQas,
  getValidations,
  validateIntegrity,
  validateStandards,
  validateAttachments,
  validateWeight,
  validateQas,
  validateIngredients,
  validateAllergens,
  validateCCP,
  validateNutrition,
  validateStorage,
  updateValidations,
  getProductSpecTabs,
  statusProduct,
};
