/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { Cell } from 'react-table';
import { overrideTailwindClasses } from 'tailwind-override';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import { Add as AddIcon } from '@material-ui/icons';
import { wizardHook, generalHook, useUrlParams } from '@hooks';
import { deepClone, numberValidator, switchValidator } from '@utils';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import {
  Input,
  TextArea,
  Button,
  Switch,
  Table,
  AutoComplete,
  TableTrashButton,
  AutoCompleteItem,
  AutoCompleteMultiple,
  AutoCompleteMultipleChoiseItem,
} from '@components/Common';

import {
  Storage,
  StorageInstance,
  ShelfLifeInformation,
  StorageTempAndConditions,
} from '@app/hooks/api/wizard/types';

import {
  TableInputProps,
  TableAutoCompleteProps,
  TableSwitchProps,
  StorageTables,
} from '@components/Bussines/Products/ProductForm/Storage/types';

import {
  shelfLifeInformationInitRow,
  storageTempAndConditionsInitRow,
} from '@components/Bussines/Products/ProductForm/Storage/init';

const validationSchema = yup.object({
  shelf_life_information: yup.array(
    yup.object({
      status: yup.string().required(),
      value: yup.mixed().test('value', 'error', numberValidator),
      unit: yup.mixed().test('unit', 'error', val => !!val),
    }),
  ),
  storage_temp_and_conditions: yup.array(
    yup.object({
      status: yup.string().required(),
      storage_condition: yup.mixed().test('storage_condition', 'error', val => {
        if (val?.name) {
          return true;
        }
        return false;
      }),
      unit: yup.mixed().test('unit', 'error', val => !!val),
      min_temp: yup.mixed().test('min_temp', 'error', numberValidator),
      max_temp: yup.mixed().test('max_temp', 'error', numberValidator),
    }),
  ),
  serving_info: yup.array(
    yup.object({
      yes_no: yup.mixed().test('yes_no', 'error', switchValidator),
    }),
  ),
  serving_cooking_preparation: yup
    .string()
    .ensure()
    .required('serving_cooking_preparation is required'),

  packing_conditions: yup.array(
    yup.object({
      yes_no: yup.mixed().test('yes_no', 'error', switchValidator),
    }),
  ),
  durability_and_coding: yup.array(
    yup.object({
      value: yup.mixed().test('yes_no', 'error', val => {
        if (Array.isArray(val) && val?.length === 0) return false;

        return val !== undefined && val !== null && val !== '';
      }),
    }),
  ),
});

type P = {
  share?: boolean;
};
const ProductStorageComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');
  const [deleteing, setDeleteing] = React.useState(1);

  const form = useForm<Storage>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const { data: allStatus, ...useAllStatusUtils } = generalHook.useAllStatus();

  const { data: productStorage } = wizardHook.useStorage(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        const formInitialValue = deepClone(data.data);

        form.reset(formInitialValue);
      },
    },
  );

  const updateStorageMutation = wizardHook.useUpdateStorageMutation();
  const validateStorageMutation = wizardHook.useValidateStorageMutation();

  const history = useHistory();

  const onSave = (link: string, data: Storage) => {
    form.clearErrors();
    updateStorageMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate = async (data: Storage) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }
      validateStorageMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });
    } catch (e) {}
  };
  const onDeleteRow = (
    rowKey: 'shelf_life_information' | 'storage_temp_and_conditions',
    rowIndex?: number,
  ) => {
    const newVals =
      form.getValues()?.[rowKey]?.length > 0 ? form.getValues()[rowKey] : [];

    newVals.splice(rowIndex || 0, 1);

    form.setValue(rowKey, newVals, { shouldDirty: true });
    form.reset(undefined, {
      keepValues: true,
      keepTouched: true,
      keepDirty: true,
      keepErrors: true,
    });

    setDeleteing(deleteing * 2);
  };

  const TableInput = React.useCallback(
    ({
      name,
      dense,
      className = '',
      inputClassName = '',
      placeholder = '',
      disabled,
    }: TableInputProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              placeholder={placeholder}
              dense={dense}
              className={className ?? 'w-full h-6'}
              inputClassName={overrideTailwindClasses(
                clsx('text-xs px-2 font-roboto', inputClassName),
              )}
              innerRef={ref}
              value={value ?? ''}
              disabled={disabled}
              {...rest}
            />
          );
        }}
      />
    ),
    [productStorage?.timeStamp],
  );

  const TableNumber = React.useCallback(
    ({
      name,
      dense,
      className = '',
      inputClassName = '',
      placeholder = '',
      disabled,
    }: TableInputProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              placeholder={placeholder}
              dense={dense}
              className={className ?? 'w-full h-6'}
              inputClassName={overrideTailwindClasses(
                clsx('text-xs px-2 font-roboto', inputClassName),
              )}
              innerRef={ref}
              type="number"
              value={value ?? ''}
              disabled={disabled}
              {...rest}
            />
          );
        }}
      />
    ),
    [productStorage?.timeStamp],
  );

  const TableSwitch = React.useCallback(
    ({ name, disabled }: TableSwitchProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <Switch
            error={!!fieldState?.error}
            className="px-4"
            disabled={disabled}
            type="secondary"
            value={typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null}
            onChange={newVal => onChange(newVal.toLowerCase() === 'yes')}
          />
        )}
      />
    ),
    [productStorage?.timeStamp],
  );

  const TableAutoComplete = React.useCallback(
    (props: TableAutoCompleteProps) => (
      <Controller
        name={props.name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <AutoComplete
            error={!!fieldState?.error}
            className="flex justify-center"
            items={props.itemDropDown || []}
            value={value as AutoCompleteItem}
            disabled={props.disabled}
            onChange={onChange}
            freeSolo={props.freeSolo}
            canAddCustomItem={props.canAddCustomItem}
          />
        )}
      />
    ),
    [productStorage?.timeStamp, useAllStatusUtils.dataUpdatedAt],
  );

  const onAddRow = React.useCallback(
    (
      tableType: StorageTables,
      initRow: ShelfLifeInformation | StorageTempAndConditions,
    ) => {
      const newRows = [...form.getValues()[tableType]];

      newRows.push(initRow);

      form.reset(
        {
          ...form.getValues(),
          [tableType]: newRows,
        },
        {
          keepDirty: true,
          keepIsValid: true,
          keepTouched: true,
          keepErrors: true,
        },
      );
    },
    [productStorage?.timeStamp],
  );

  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      // the bellow code absolutly suck
      if (type === 'change' && name?.endsWith('storage_condition')) {
        const id = name?.split('.')[1];
        const currentValueName = (form.getValues(name) as any)?.code;

        form.setValue(
          `storage_temp_and_conditions.${Number(id)}.min_temp`,
          allStatus?.data.storage_temp_condition_dropdown?.[currentValueName]
            ?.min_temp || 0,
        );
        form.setValue(
          `storage_temp_and_conditions.${Number(id)}.max_temp`,
          allStatus?.data.storage_temp_condition_dropdown?.[currentValueName]
            ?.max_temp || 0,
        );

        if (allStatus?.data?.storage_unit_condition_dropdown?.[0])
          form.setValue(
            `storage_temp_and_conditions.${Number(id)}.unit`,
            allStatus?.data?.storage_unit_condition_dropdown?.[0],
          );

        return null;
      }
      return null;
    });

    return () => subscription.unsubscribe();
  }, [form.watch, allStatus?.data.storage_temp_condition_dropdown]);

  const shelfLifeColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof StorageInstance;
      id: keyof StorageInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell<ShelfLifeInformation>) => JSX.Element);
    }[] => [
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        width: 300,
        Cell: cell => (
          <TableInput
            inputClassName="text-sm text-primary font-roboto"
            name={`shelf_life_information.${+cell.row.id}.status`}
            disabled={!urlParams?.editMode || cell.row.original.is_main}
          />
        ),
      },
      {
        Header: 'Value',
        id: 'value',
        accessor: 'value',
        width: 210,
        Cell: cell => (
          <TableNumber
            name={`shelf_life_information.${+cell.row.id}.value`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Unit',
        id: 'unit',
        accessor: 'unit',
        width: 170,
        Cell: cell => (
          <TableAutoComplete
            name={`shelf_life_information.${+cell.row.id}.unit`}
            disabled={!urlParams?.editMode}
            itemDropDown={allStatus?.data.storage_shelf_life_units_dropdown}
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        width: 255,
        Cell: cell => (
          <TableInput
            name={`shelf_life_information.${+cell.row.id}.comments`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <TableTrashButton
            disabled={!urlParams.editMode || cell.row.original.is_main}
            onClick={() => onDeleteRow('shelf_life_information', +cell.row.id)}
          />
        ),
      },
    ],
    [urlParams?.editMode, deleteing, useAllStatusUtils.dataUpdatedAt],
  );

  const storageTempColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof StorageInstance;
      id: keyof StorageInstance | 'action';
      className?: string;
      width?: number;
      Cell?:
        | undefined
        | ((cell: Cell<StorageTempAndConditions>) => JSX.Element);
    }[] => [
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        width: 300,
        Cell: cell => (
          <TableInput
            dense={cell.row.original.is_main}
            inputClassName="text-sm text-primary font-roboto"
            name={`storage_temp_and_conditions.${+cell.row.id}.status`}
            disabled={!urlParams?.editMode || cell.row.original.is_main}
          />
        ),
      },
      {
        Header: 'Storage Condition',
        id: 'storage_condition',
        accessor: 'storage_condition',
        width: 130,
        Cell: cell => (
          <Controller
            name={`storage_temp_and_conditions.${+cell.row
              .id}.storage_condition`}
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <AutoComplete
                error={!!fieldState?.error}
                className="flex justify-center"
                items={allStatus?.data?.storage_temp_condition_dropdown || []}
                value={value}
                disabled={!urlParams?.editMode}
                onChange={newVal => {
                  const statusItem =
                    allStatus?.data?.storage_temp_condition_dropdown.find(
                      el => el.code === newVal?.code,
                    );

                  onChange({
                    ...newVal,
                    min_temp: statusItem?.min_temp || 0,
                    max_temp: statusItem?.max_temp || 0,
                  });
                }}
              />
            )}
          />
        ),
      },
      {
        Header: 'Min Temp',
        id: 'min_temp',
        accessor: 'min_temp',
        width: 80,
        Cell: cell => (
          <TableNumber
            name={`storage_temp_and_conditions.${+cell.row.id}.min_temp`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Max Temp',
        id: 'max_temp',
        accessor: 'max_temp',
        width: 80,
        Cell: cell => (
          <TableNumber
            name={`storage_temp_and_conditions.${+cell.row.id}.max_temp`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Unit',
        id: 'unit',
        accessor: 'unit',
        width: 80,
        Cell: cell => (
          <TableAutoComplete
            name={`storage_temp_and_conditions.${+cell.row.id}.unit`}
            disabled={!urlParams?.editMode}
            itemDropDown={allStatus?.data.storage_unit_condition_dropdown}
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: '%RH ?',
        id: 'rh_percent',
        accessor: 'rh_percent',
        width: 80,
        Cell: cell => (
          <TableNumber
            name={`storage_temp_and_conditions.${+cell.row.id}.rh_percent`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        width: 250,
        Cell: cell => (
          <TableInput
            name={`storage_temp_and_conditions.${+cell.row.id}.comments`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <TableTrashButton
            disabled={!urlParams.editMode || cell.row.original.is_main}
            onClick={() =>
              onDeleteRow('storage_temp_and_conditions', +cell.row.id)
            }
          />
        ),
      },
    ],
    [urlParams?.editMode, deleteing, useAllStatusUtils.dataUpdatedAt],
  );

  const durabilityInputDetails = [
    {
      type: 'autocomplete-multiple',
      options: allStatus?.data.storage_date_coding_dropdown,
    },
    {
      type: 'autocomplete-multiple',
      options: allStatus?.data.ccp_location_of_durability_dropdown,
    },
    {
      type: 'autocomplete',
      options: allStatus?.data.ccp_format_of_date_coding,
    },
    {
      type: 'input',
      options: [],
    },
    {
      type: 'switch',
      options: [],
    },
    {
      type: 'switch',
      options: [],
    },
  ];

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Product Shelf Life Information*
          </h3>

          <Table
            className="mt-2 border-collapse"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-1 py-0 h-10"
            columns={shelfLifeColumns}
            data={form.getValues().shelf_life_information || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(
                  StorageTables.ShelfLifeInformationTable,
                  shelfLifeInformationInitRow,
                )
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Storage Temperature and Conditions*
          </h3>

          <Table
            className="mt-2 border-collapse"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-1 py-0 h-10"
            columns={storageTempColumns}
            data={form.getValues().storage_temp_and_conditions || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                onAddRow(
                  StorageTables.StorageTempAndConditionsTable,
                  storageTempAndConditionsInitRow,
                )
              }
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Serving, Cooking & Preparation Instructions*
          </h3>

          {productStorage?.data?.serving_info?.map((item, index) => (
            <div
              key={item.title}
              className="grid grid-cols-9 gap-x-2 bg-white items-center px-4 mb-1"
            >
              <div className="col-span-4 font-roboto">{item.title}</div>

              <Controller
                name={`serving_info.${+index}`}
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <div className="col-span-1">
                      <Switch
                        error={!!fieldState?.error}
                        className="px-4"
                        disabled={!urlParams?.editMode}
                        type="secondary"
                        value={
                          typeof value?.yes_no === 'boolean'
                            ? value?.yes_no
                              ? 'Yes'
                              : 'No'
                            : null
                        }
                        onChange={newVal =>
                          onChange({
                            ...value,
                            yes_no: newVal.toLowerCase() === 'yes',
                          })
                        }
                      />
                    </div>

                    <div className="col-span-4">
                      <Input
                        placeholder="Comment"
                        inputClassName="text-xs px-2 font-roboto"
                        disabled={!urlParams?.editMode || !value?.yes_no}
                        value={value?.comments || ''}
                        onChange={e =>
                          onChange({
                            ...value,
                            comments: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
              />
            </div>
          ))}

          <Controller
            name="serving_cooking_preparation"
            control={form.control}
            render={({ field, fieldState }) => {
              const { ref, value, ...rest } = field;

              return (
                <TextArea
                  error={!!fieldState?.error}
                  whiteWrapper
                  className="my-2"
                  placeholder="* Define..."
                  multiline
                  rows={4}
                  value={value}
                  {...rest}
                />
              );
            }}
          />
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            General Packaging Information
          </h3>

          {productStorage?.data?.packing_conditions?.map((item, index) => (
            <div
              key={item.title}
              className="grid grid-cols-9 gap-x-2 bg-white items-center px-4 mb-1"
            >
              <div className="col-span-4 font-roboto">{item.title} *</div>

              <Controller
                name={`packing_conditions.${+index}`}
                control={form.control}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <div className="col-span-1">
                      <Switch
                        error={!!fieldState?.error}
                        className="px-4"
                        disabled={!urlParams?.editMode}
                        type="secondary"
                        value={
                          typeof value?.yes_no === 'boolean'
                            ? value?.yes_no
                              ? 'Yes'
                              : 'No'
                            : null
                        }
                        onChange={newVal =>
                          onChange({
                            ...value,
                            yes_no: newVal.toLowerCase() === 'yes',
                          })
                        }
                      />
                    </div>

                    <div className="col-span-4">
                      <Input
                        placeholder="Comment"
                        inputClassName="text-xs px-2 font-roboto"
                        disabled={!urlParams?.editMode || !value?.yes_no}
                        value={value?.comment || ''}
                        onChange={e =>
                          onChange({
                            ...value,
                            comment: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Durability & Date Coding information*
          </h3>

          {productStorage?.data?.durability_and_coding?.map((item, index) => (
            <div
              key={item.title}
              className="grid grid-cols-9 gap-x-2 bg-white items-center px-4 mb-1"
            >
              <div className="col-span-4 font-roboto">{item.title}</div>

              <div className="col-span-2 w-full flex flex-row justify-center">
                {durabilityInputDetails[index].type ===
                'autocomplete-multiple' ? (
                  <Controller
                    name={`durability_and_coding.${+index}.value`}
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState }) => (
                      <AutoCompleteMultiple
                        error={!!fieldState?.error}
                        className="flexx justify-centerx w-full"
                        items={durabilityInputDetails?.[index]?.options || []}
                        value={value as AutoCompleteMultipleChoiseItem[]}
                        disabled={!urlParams?.editMode}
                        onChange={onChange}
                      />
                    )}
                  />
                ) : durabilityInputDetails[index].type === 'autocomplete' ? (
                  <TableAutoComplete
                    name={`durability_and_coding.${+index}.value`}
                    disabled={!urlParams?.editMode}
                    itemDropDown={
                      durabilityInputDetails?.[index]?.options || []
                    }
                  />
                ) : durabilityInputDetails[index].type === 'input' ? (
                  <TableInput
                    name={`durability_and_coding.${+index}.value`}
                    disabled={!urlParams?.editMode}
                    className="w-full"
                  />
                ) : (
                  <TableSwitch
                    name={`durability_and_coding.${+index}.value`}
                    disabled={!urlParams?.editMode}
                  />
                )}
              </div>

              <div className="col-span-3">
                <TableInput
                  placeholder="Comments"
                  name={`durability_and_coding.${+index}.comments`}
                  disabled={!urlParams?.editMode}
                />
              </div>
            </div>
          ))}
        </div>

        <FormButtonGroup
          share={share}
          pageName="storage"
          onSave={link => onSave(link, form.getValues())}
          onValidate={() => onValidate(form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default ProductStorageComp;
