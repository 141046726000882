import React from 'react';
import clsx from 'clsx';

import { ExportButton, Button } from '@components/Common';

interface P {
  editMode: boolean;
  className?: string;
  onSubmit?: () => void;
  onSave?: () => void;
  onApprove?: () => void;
  onReject?: () => void;
  canSubmit?: boolean;
  canSave?: boolean;
  canApprove?: boolean;
  canReject?: boolean;
  hasExport?: boolean;
  isLoading?: boolean;
}

const FooterButtonGroup: React.FC<P> = ({
  className,
  onSubmit,
  onSave,
  onApprove,
  onReject,
  editMode,
  canSubmit = false,
  canSave = false,
  canApprove = false,
  canReject = false,
  hasExport = true,
  isLoading = false,
}) => (
  <div
    className={clsx('flex flex-row justify-between items-center', className)}
  >
    <div />
    <div className="flex flex-row justify-center items-center">
      {canReject && (
        <>
          {onReject ? (
            <Button
              loading={isLoading}
              size="large"
              variant="text"
              color="inherit"
              className="bg-gray-600 text-red-dark mr-2 text-sm min-w-22 w-22"
              onClick={() => onReject()}
            >
              Reject
            </Button>
          ) : null}
        </>
      )}

      {canApprove && (
        <>
          {onApprove ? (
            <Button
              loading={isLoading}
              size="large"
              className="min-w-22 w-22 bg-green mr-2 text-sm items-center"
              onClick={() => onApprove()}
            >
              Approve
            </Button>
          ) : null}
        </>
      )}

      {editMode && (
        <>
          {canSubmit && (
            <>
              {onSubmit ? (
                <Button
                  loading={isLoading}
                  size="large"
                  variant="outlined"
                  color="inherit"
                  className="border-green text-green mr-2 text-sm min-w-22 w-22"
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button>
              ) : null}
            </>
          )}

          {canSave && (
            <>
              {onSave ? (
                <Button
                  loading={isLoading}
                  size="large"
                  className="min-w-22 w-22 bg-green text-sm items-center"
                  onClick={() => onSave()}
                >
                  Save
                </Button>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  </div>
);

export default FooterButtonGroup;
