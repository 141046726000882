import { AxiosResponse } from 'axios';

import axios from '@lib/api';
import { UseSubscriptionType } from '@hooks/api/subscription/types';

const getProducts = async () => {
  const res: AxiosResponse<UseSubscriptionType> = await axios({
    method: 'get',
    url: '/subscription',
  });

  return res.data;
};

export default {
  getProducts,
};
