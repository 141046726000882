/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  DeletePromptModal,
  Switch,
  UploadButtonUploadOnChange,
} from '@components/Common';

import { useUrlParams } from '@hooks/utils';

import UserHooks from '@hooks/api/user';
import { UserProfile } from '@hooks/api/user/types';

const initialValues: UserProfile = {
  contact_number: '',
  email: '',
  is_commercial_contact: null,
  is_emergency_contact: null,
  is_technical_contact: null,
  job_title: '',
  name: '',
  roles_permissions: null,
  status: '',
  license_type: null,
  username: '',
  picture: '',
};

const validationSchema = yup.object({
  job_title: yup.string().ensure().required('Job title is required'),
  contact_number: yup.string().ensure().required('Contact number is required'),
});

const ProfileComp = () => {
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
    React.useState(false);

  const [verification, setVerification] = React.useState('');
  const [verificationRequested, SetVerificationRequested] =
    React.useState(false);

  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
  }>();

  const form = useForm<UserProfile>({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { ...useCurrentUserProfileUtils } = UserHooks.useCurrentUserProfile({
    onSuccess: data => form.reset(data.data),
  });

  const updateUserProfileMutation = UserHooks.useUpdateUserProfileMutation();
  const deleteUserMutation = UserHooks.useDeleteUserMutation();

  // const { data: detailedManufacturer, ...detailedManufacturerUtils } =
  //   ManufacturerHooks.useDetailedManufacturer(
  //     {
  //       manufactureCode: form.getValues()?.manufacture?.manufacture_code || '',
  //     },
  //     {
  //       enabled: !!form.getValues()?.manufacture?.manufacture_code,
  //     },
  //   );

  const { data: userVerificationMutation } = UserHooks.useVerification({
    enabled: verificationRequested,
  });

  const onSubmit: SubmitHandler<UserProfile> = data => {
    updateUserProfileMutation.mutate(data);
  };

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={isDeleteUserModalOpen}
        setOpen={() => setIsDeleteUserModalOpen(false)}
        onConfirm={() => {
          deleteUserMutation.mutate({
            username: form.getValues('username'),
          });
        }}
      />

      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Certificates' },
              { href: '#', text: 'Profile' },
            ]}
          />

          <div>
            <span className="mx-8">
              <Link to="/profile/change-password">Change Password</Link>
            </span>

            {urlParams.editMode ? (
              <Button
                variant="outlined"
                className="w-28"
                onClick={() => setUrlParams({ editMode: false })}
                size="medium"
                startIcon={<Close />}
                text="Cancel"
              />
            ) : (
              <Button
                className="w-28"
                onClick={() => setUrlParams({ editMode: true })}
                size="medium"
                startIcon={<Edit />}
                text="Edit"
              />
            )}
            <>
              {userVerificationMutation ? (
                <Button
                  className="w-28 ml-2"
                  disabled
                  size="medium"
                  text={userVerificationMutation?.data.code.toString()}
                />
              ) : (
                <Button
                  className="w-28 ml-2"
                  onClick={() => {
                    SetVerificationRequested(true);
                    setTimeout(() => {
                      SetVerificationRequested(false);
                    }, 300000);
                  }}
                  size="medium"
                  text="Verification"
                />
              )}
            </>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              Profile
            </h3>
          </div>

          <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
            {(
              [
                {
                  displayText: 'Full Name',
                  key: 'name',
                  disabled: true,
                },
                {
                  displayText: 'Email',
                  key: 'email',
                  disabled: true,
                },
                {
                  displayText: 'Status',
                  key: 'status',
                  disabled: true,
                },
                {
                  displayText: 'Job Title',
                  key: 'job_title',
                  disabled: false,
                },
                {
                  displayText: 'Contact Number',
                  key: 'contact_number',
                  disabled: false,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useCurrentUserProfileUtils.isLoading}
                          disabled={el?.disabled || !urlParams?.editMode}
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {'supplier' in form.getValues() ? (
              <Controller
                name="supplier"
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div className="col-span-1 flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Supplier</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <Input
                        loading={useCurrentUserProfileUtils.isLoading}
                        disabled
                        className="flex-grow"
                        innerRef={ref}
                        value={value?.name}
                      />
                    </div>
                  </div>
                )}
              />
            ) : null}

            {(
              [
                {
                  displayText: 'Licence Type',
                  key: 'license_type',
                  disabled: true,
                },
                {
                  displayText: 'Access Level',
                  key: 'roles_permissions',
                  disabled: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useCurrentUserProfileUtils.isLoading}
                          disabled={el?.disabled || !urlParams?.editMode}
                          className="flex-grow"
                          innerRef={ref}
                          value={value?.name}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Emergency Contact',
                  key: 'is_emergency_contact',
                },
                {
                  displayText: 'Technical Contact',
                  key: 'is_technical_contact',
                },
                {
                  displayText: 'Commercial Contact',
                  key: 'is_commercial_contact',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({
                  field: { onChange, value, ...rest },
                  fieldState,
                }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Switch
                          disabled
                          type="secondary"
                          className="justify-center my-2"
                          value={
                            typeof value === 'boolean'
                              ? value
                                ? 'Yes'
                                : 'No'
                              : null
                          }
                          onChange={newVal =>
                            onChange(newVal.toLowerCase() === 'yes')
                          }
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="picture"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">
                        Upload Profile pic
                      </p>
                    </div>

                    <div className="flex flex-col w-full">
                      <UploadButtonUploadOnChange
                        url="/user/profile/picture"
                        disabled={!urlParams?.editMode}
                        id="picture"
                        wrapperClassName="flex justify-center my-2"
                        value={value ? String(value) : ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        isLoading={useCurrentUserProfileUtils.isLoading}
        canSave={urlParams.editMode}
        onSave={form.handleSubmit(onSubmit)}
      />
    </Layout>
  );
};

export default ProfileComp;
