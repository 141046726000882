import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  UseUserProfileResponseType,
  UseUserSettingResponseType,
  UseUserInfoResponseType,
  UpdateUserProfileParams,
  UpdateUserProfileChangePasswordParams,
  UpdateUserSettingParams,
  DeleteUserParams,
  GetUsersListParams,
  UseUsersListResponseType,
  GetUserInfoParams,
  UpdateUserParams,
  CreateUserParams,
  ResendWelcomeEmailParams,
  ChangeUserStatusParams,
  UseUserVerificationResponseType,
} from '@hooks/api/user/types';

const fetchUserProfile = async () => {
  const res: AxiosResponse<UseUserProfileResponseType> = await axios({
    method: 'get',
    url: '/user/profile',
  });

  return res.data;
};
const getVerification = async () => {
  const res: AxiosResponse<UseUserVerificationResponseType> = await axios({
    method: 'get',
    url: '/user/profile/verification_code',
  });

  return res.data;
};

const updateUserProfile = async (data: UpdateUserProfileParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: '/user/profile',
    data,
  });

  return res.data;
};

const updateUserProfileChangePassword = async (
  data: UpdateUserProfileChangePasswordParams,
) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: '/user/profile/update_password',
    data,
  });

  return res.data;
};

const fetchUserSetting = async () => {
  const res: AxiosResponse<UseUserSettingResponseType> = await axios({
    method: 'get',
    url: '/user/setting',
  });

  return res.data;
};

const updateUserSetting = async (data: UpdateUserSettingParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: '/user/setting',
    data,
  });

  return res.data;
};

const deleteUser = async (params: DeleteUserParams) => {
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/user/${params.username}/trash`,
  });

  return { data: res.data, username: params.username };
};

const getUsersList = async (params?: GetUsersListParams) => {
  const res: AxiosResponse<UseUsersListResponseType> = await axios({
    method: 'get',
    url: '/user',
    params,
  });

  return res.data;
};

const createUser = async (data: CreateUserParams) => {
  const res: AxiosResponse<UseUserProfileResponseType> = await axios({
    method: 'post',
    url: `/user`,
    data,
  });

  return res.data;
};

const getUserInfo = async (params: GetUserInfoParams) => {
  const res: AxiosResponse<UseUserInfoResponseType> = await axios({
    method: 'get',
    url: `/user/${params.username}/show`,
  });

  return res.data;
};

const updateUser = async (params: UpdateUserParams) => {
  const { username, ...data } = params;

  const res: AxiosResponse<UseUserProfileResponseType> = await axios({
    method: 'put',
    url: `/user/${username}/update`,
    data,
  });

  return res.data;
};

const resendWelcomeEmail = async (params: ResendWelcomeEmailParams) => {
  const res: AxiosResponse = await axios({
    method: 'get',
    url: `/user/${params.username}/resend-email`,
  });

  return res.data;
};

const changeUserStatus = async (params: ChangeUserStatusParams) => {
  const res: AxiosResponse = await axios({
    method: 'get',
    url: `/user/${params.username}/status`,
    params,
  });

  return res.data;
};

export default {
  fetchUserProfile,
  updateUserProfileChangePassword,
  updateUserProfile,
  fetchUserSetting,
  getVerification,
  updateUserSetting,
  deleteUser,
  createUser,
  getUsersList,
  getUserInfo,
  updateUser,
  resendWelcomeEmail,
  changeUserStatus,
};
