/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
import React from 'react';
import { FieldPath } from 'react-hook-form';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { FileCopyOutlined } from '@material-ui/icons';

import { toast } from 'react-toastify';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
  AutoComplete,
} from '@components/Common';

import { formatDate } from '@utils';

import Menu, {
  MenuItemTypes,
} from '@components/Configurations/security/sharedLinks/Menu';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Configurations/security/sharedLinks/AttributeFiltersModal';

import SharedLinksHooks from '@hooks/api/shared';

import { GetSharedLinksParams } from '@hooks/api/shared/types';

import { usePrevious, useDebounce } from '@hooks/utils';
import { Link } from '@material-ui/core';
import { sharedLinks } from '@app/hooks/api/shared/types';
import config from '@app/constants/config';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): GetSharedLinksParams => {
  const formattedValues = {
    ...(filterValues?.expired_at?.from && {
      from_expired_at: formatDate(filterValues.expired_at.from),
    }),

    ...(filterValues?.expired_at?.to && {
      to_expired_at: formatDate(filterValues.expired_at.to),
    }),
  };

  return formattedValues;
};

const SharedLinks = () => {
  const history = useHistory();

  const [selectedSharedLinks, setSelectedSharedLinks] = React.useState<{
    publicLink: string;
  }>({ publicLink: '' });

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [userFilters, setUserFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  const deleteSharedLinks = SharedLinksHooks.useDeleteSharedLinksMutations();
  const resendSharedLinks = SharedLinksHooks.useResendSharedLinksMutations();

  const resend = React.useCallback(async (row: Row<sharedLinks>) => {
    await resendSharedLinks.mutateAsync({
      publicLink: row.original.public_token,
    });
  }, []);

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<sharedLinks>) => {
      if (actionType === 'Resend') {
        resend(row);
      }

      if (actionType === 'Delete') {
        // const regex = /\w+$/g;
        // const found = row.original.public_token.match(regex) || '';

        setSelectedSharedLinks({
          publicLink: row.original.public_token,
        });
      }
    },
    [],
  );

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: usersList, ...useUsersListUtils } =
    SharedLinksHooks.useSharedLinksList({
      page: currentPage,
      q: debouncedSearch,
      ...(userFilters ? formatFilterAttributes(userFilters) : {}),
    });

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<sharedLinks> | 'action';
      id: FieldPath<sharedLinks> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<sharedLinks>) => JSX.Element);
    }> => [
      {
        Header: 'Link Address',
        accessor: 'public_token',
        id: 'public_token',
        width: 580,
        Cell: (cell: any) => (
          <div
            className="flex"
            onClick={async () => {
              try {
                const clipboardPermissions = await navigator.permissions.query({
                  name: 'clipboard-write' as PermissionName,
                });

                const hasClipboardPermissions =
                  clipboardPermissions.state === 'granted' ||
                  clipboardPermissions.state === 'prompt';

                if (!hasClipboardPermissions)
                  throw new Error('Clipboard permission Denied');

                await navigator.clipboard.writeText(
                  `${config.baseUrl}/auth/share/${cell.row.original.public_token}`,
                );

                toast.success('Share link is copied into clipboard');
              } catch (e) {
                if (e instanceof Error && e?.message) toast.error(e.message);
              }
            }}
          >
            <div className="overflow-hidden text-ellipsis w-74 whitespace-nowrap">
              {config.baseUrl}/auth/share/{cell.row.original.public_token}
            </div>
            <FileCopyOutlined className="ml-2 text-black-500 text-lg" />
          </div>
        ),
      },
      {
        Header: 'Shared By',
        accessor: 'create_by',
        id: 'create_by',
        width: 220,
      },
      {
        Header: 'Shared With',
        accessor: 'receiver',
        id: 'receiver',
        width: 220,
      },
      {
        Header: 'Shared Date',
        accessor: 'created_at',
        id: 'created_at',
        width: 120,
      },
      {
        Header: 'Expiry Date',
        accessor: 'expired_at',
        id: 'expired_at',
        width: 120,
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={['Resend', 'Delete']}
            open={openTableActionMenuId === cell.row.id}
            onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
            onClose={() => setOpenTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onMenuItemClick(actionType, cell.row);

              setOpenTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [openTableActionMenuId, useUsersListUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={!!selectedSharedLinks.publicLink}
        setOpen={() => setSelectedSharedLinks({ publicLink: '' })}
        onConfirm={async () => {
          try {
            await deleteSharedLinks.mutateAsync({
              publicLink: selectedSharedLinks.publicLink,
            });
          } catch (error) {
          } finally {
            setSelectedSharedLinks({ publicLink: '' });
          }
        }}
      />

      <AttributeFiltersModal
        values={userFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setUserFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Configurations' },
            { href: '#', text: 'Shared Links' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {userFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setUserFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <p className="text-base text-primary-light font-roboto">
            Shared Links
          </p>
        </div>
        <Table
          loading={useUsersListUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={usersList?.data || []}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: usersList?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default SharedLinks;
