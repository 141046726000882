import React from 'react';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import { Modal, Button, DatePicker, AutoComplete } from '@components/Common';

import GeneralHooks from '@hooks/api/general';
import { Status } from '@hooks/api/generalTypes';

export interface FilterAttributesValues {
  supplier: null | Status['supplier_types'][number];
  approveDate: {
    from: Date | null;
    to: Date | null;
  };
  specificationStatus: null | Status['cardbox_statuses'][number];
}

type P = {
  values: FilterAttributesValues | null;
  open: boolean;
  setOpen: (value: boolean) => void;
  onFormSubmit: (args: FilterAttributesValues) => void;
};

const AttributeFilterModal: React.FC<P> = ({
  values,
  open,
  setOpen,
  onFormSubmit,
}) => {
  const form = useForm<FilterAttributesValues>({
    defaultValues: {
      supplier: null,
      approveDate: {
        from: null,
        to: null,
      },
      specificationStatus: null,
    },
  });

  React.useEffect(() => {
    form.reset({
      supplier: values?.supplier
        ? {
            name: values?.supplier.name ?? '',
            code: values?.supplier.code ?? 0,
          }
        : null,
      approveDate: {
        from: values?.approveDate.from ?? null,
        to: values?.approveDate.to ?? null,
      },
      specificationStatus: values?.specificationStatus
        ? {
            name: values?.specificationStatus.name ?? '',
            code: values?.specificationStatus.code ?? 0,
          }
        : null,
    });
  }, [JSON.stringify(values)]);

  const onSubmit: SubmitHandler<FilterAttributesValues> = data => {
    onFormSubmit(data);
  };

  const { data: allStatuses } = GeneralHooks.useAllStatus();

  const isAtLeastOneFieldCompleted = (obj: FilterAttributesValues) => {
    const formValues = [
      obj?.supplier,
      obj.specificationStatus,
      obj.approveDate.from,
      obj.approveDate.to,
    ];

    return !!formValues.find(el => el !== null);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center',
          'bg-white-600 shadow-lg min-w-132',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'h-10 pl-4 min-w-full bg-white-400',
          )}
        >
          <h3 className="font-roboto text-base">Attribute Filters</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Approve Date</p>

            <div className="flex flex-row items-center">
              <Controller
                name="approveDate.from"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="from"
                      className="w-32"
                      wrapperClassName="mr-2"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />

              <Controller
                name="approveDate.to"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="to"
                      className="w-32"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Supplier Type</p>

            <Controller
              name="supplier"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className="w-74"
                    items={allStatuses?.data.supplier_types || []}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Specification Status</p>

            <Controller
              name="specificationStatus"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className="w-74"
                    items={allStatuses?.data.cardbox_statuses || []}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-row justify-end items-center min-w-full">
            {isAtLeastOneFieldCompleted(form.watch()) ? (
              <Button
                type="submit"
                onClick={form.handleSubmit(onSubmit)}
                className="text-green border-green"
                variant="outlined"
                startIcon={<Check />}
                text="Done"
              />
            ) : null}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AttributeFilterModal;
