import { AxiosResponse } from 'axios';

import axios from '@lib/api';
import { UseDashboardType } from '@hooks/api/dashboard/types';

const getProducts = async () => {
  const res: AxiosResponse<UseDashboardType> = await axios({
    method: 'get',
    url: '/dashboard/home',
  });

  return res.data;
};

export default {
  getProducts,
};
