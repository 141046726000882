import React from 'react';

import {
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { ArrowDropUp, ArrowRight } from '@material-ui/icons';

import ExportIcon from '@assets/images/icons/export.svg';
import PrintIcon from '@assets/images/icons/print.svg';
import PdfIcon from '@assets/images/icons/pdf.svg';
import ExcelIcon from '@assets/images/icons/excel.svg';

import { Button } from '@components/Common';
import { useUrlParams } from '@hooks';
import ProductHooks from '@hooks/api/products';

interface P {
  className?: string;
  pageName?: string;
  children?: React.ReactNode;
}

const ExportButton: React.FC<P> = ({ className, pageName }) => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [subMenuAnchor, setSubMenuAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const [selectedExportMenuItem, setSelectedExportMenuItem] =
    React.useState('');

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode?: boolean;
  }>();

  const reportMutation = ProductHooks.useReportMutation();

  const reportHandeler = (type: string) => {
    if (selectedExportMenuItem === 'This Page') {
      if (type === 'as PDF') {
        reportMutation.mutateAsync(
          {
            code: urlParams.code,
            version: urlParams.version,
            spec: pageName,
            isFull: false,
          },
          {
            onSuccess: res => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            },
          },
        );
      }
    } else if (selectedExportMenuItem === 'Full Spec') {
      if (type === 'as PDF') {
        reportMutation.mutateAsync(
          {
            code: urlParams.code,
            version: urlParams.version,
            isFull: true,
          },
          {
            onSuccess: res => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            },
          },
        );
      }
    } else if (selectedExportMenuItem === 'Short Spec') {
      if (type === 'as PDF') {
        reportMutation.mutateAsync(
          {
            code: urlParams.code,
            version: urlParams.version,
            isFull: false,
          },
          {
            onSuccess: res => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            },
          },
        );
      }
    }
  };

  return (
    <div className={className}>
      <Button
        className="text-primary-light text-sm font-medium w-32"
        text="Export"
        variant="outlined"
        startIcon={<img src={ExportIcon} alt="export" />}
        endIcon={<ArrowDropUp />}
        onClick={e => {
          setMenuAnchor(e.currentTarget);
          // setSelectedExportSubMenuItem('');
          // setIsExportMenuOpen(!isExportMenuOpen);
        }}
      />

      <Popper
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        role={undefined}
        transition
        disablePortal
        placement="top"
        className="z-20 bg-white border-1 border-primary-light rounded -top-1"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <ClickAwayListener onClickAway={() => setMenuAnchor(null)}>
              <MenuList
                className="w-32"
                autoFocusItem={!!menuAnchor}
                id="menu-list-grow"
              >
                {['Full Spec', 'Short Spec', 'This Page'].map(el => (
                  <MenuItem
                    component="button"
                    key={el}
                    className="flex flex-row items-center justify-between text-sm font-normal font-roboto w-full"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                    ) => {
                      setSelectedExportMenuItem(el);

                      if (selectedExportMenuItem === el && subMenuAnchor) {
                        setSubMenuAnchor(null);
                        return;
                      }

                      setSubMenuAnchor(e.currentTarget);
                    }}
                  >
                    {el}
                    <ArrowRight />
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      <Popper
        open={!!subMenuAnchor}
        anchorEl={subMenuAnchor}
        role={undefined}
        transition
        disablePortal
        placement="right"
        className="z-20 bg-white border-1 border-primary-light rounded left-1"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener
              onClickAway={() => {
                if (!menuAnchor) {
                  setSubMenuAnchor(null);
                }
              }}
            >
              <MenuList
                className="w-32"
                autoFocusItem={!!subMenuAnchor}
                id="menu-list-grow"
              >
                {[
                  // {
                  //   text: 'as Print',
                  //   icon: PrintIcon,
                  // },
                  // {
                  //   text: 'as Excel',
                  //   icon: ExcelIcon,
                  // },
                  {
                    text: 'as PDF',
                    icon: PdfIcon,
                  },
                ].map(el => (
                  <MenuItem
                    key={el.text}
                    className="text-sm font-normal font-roboto"
                    onClick={() => {
                      setMenuAnchor(null);
                      setSubMenuAnchor(null);
                      reportHandeler(el.text);
                    }}
                  >
                    <img className="w-4 h-4 mr-3" src={el.icon} alt={el.text} />

                    {el.text}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ExportButton;
