/* eslint-disable no-nested-ternary */
import React from 'react';
import { Cell, Row } from 'react-table';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { useUrlParams } from '@hooks';

import * as MyUtils from '@utils';

import WizardHooks from '@hooks/api/wizard';
import {
  UseProductValidations,
  ValidationInstance,
} from '@hooks/api/wizard/types';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import { Button, CheckBox, Table } from '@components/Common';

type P = {
  share?: boolean;
};
const ValidationsComp: React.FC<P> = ({ share }) => {
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const form = useForm<UseProductValidations>();

  const { data: productValidations, ...productValidationsUtils } =
    WizardHooks.useValidations(
      {
        code: urlParams.code,
        version: urlParams.version,
      },
      {
        // enabled: false,
        enabled: !!urlParams.code && !!urlParams.version,
        onSuccess: data => form.reset(data),
      },
    );
  const updateValidationsMutation = WizardHooks.useUpdateValidationsMutation();

  // console.log({ productValidations });

  const onSubmit: SubmitHandler<UseProductValidations> = data => {
    updateValidationsMutation.mutate({
      code: urlParams.code,
      version: urlParams.version,
      // validation_items: data.data,
    });
  };

  const productDetailsColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof ValidationInstance;
      id: keyof ValidationInstance;
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell<ValidationInstance>) => JSX.Element);
    }> => [
      {
        Header: 'Page',
        id: 'page',
        accessor: 'page',
        width: 200,
        Cell: cell => <span>{MyUtils.pascalize(cell.value)}</span>,
      },
      {
        Header: 'Field',
        id: 'field_name',
        accessor: 'field_name',
        width: 250,
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        width: 400,
      },
      // {
      //   width: 80,
      //   Header: 'Accept',
      //   id: 'accept',
      //   className: 'text-center py-0',
      //   Cell: cell => {
      //     if (cell.row.original.type === 'error') return <></>;

      //     return (
      //       <Controller
      //         name={`data.${Number(cell.row.id)}.accept`}
      //         control={form.control}
      //         render={({ field: { value, onChange } }) => (
      //           <CheckBox
      //             disabled={!urlParams.editMode}
      //             checked={!!value}
      //             onChange={onChange}
      //             value={value}
      //           />
      //         )}
      //       />
      //     );
      //   },
      // },
    ],
    [urlParams?.editMode],
  );

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form>
        <div className="flex flex-col mt-6">
          <div className="flex flex-row justify-between mt-3">
            <h3 className="font-roboto text-base text-primary-light mb-2">
              Validation Summary
            </h3>
          </div>

          <Table
            loading={productValidationsUtils.isLoading}
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-4 border-b-4 border-white-600"
            bodyCellClassName="text-primary border-0 px-4"
            getRowProps={(row: Row<UseProductValidations['data'][number]>) => ({
              className: clsx(
                'h-10 rounded border-t-4 border-b-4 border-white-600',
                row.original.type === 'error'
                  ? 'bg-red-lighter'
                  : row.original.type === 'warning' && row.original.accept
                  ? 'bg-white'
                  : 'bg-yellow-lighter',
              ),
            })}
            columns={productDetailsColumns}
            data={form?.getValues()?.data || []}
          />
        </div>

        <div className="text-right mt-4">
          <Button
            className={clsx(
              'min-w-60 w-60 bg-green',
              'mr-2 text-sm min-w-22 w-22',
            )}
            onClick={() => onSubmit(form.getValues())}
          >
            Acknowledge and Submit
          </Button>
        </div>
      </form>
    </Layout>
  );
};

export default ValidationsComp;
