import React from 'react';
import clsx from 'clsx';
import { Select as MuiSelect, SelectProps, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

type MenuItemType = {
  displayText: string;
  value: string;
};
interface P {
  menuItems: Array<MenuItemType>;
  wrapperClassName?: string;
  dense?: boolean;
  fontSize?: number;
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),

    '& .MuiInput-root': {
      minWidth: '100%',

      '& input': {
        height: theme.spacing(3),
      },
    },

    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.gray.light,
    },
  },
  root: {
    color: ({ value }: any) =>
      value ? theme.palette.secondary.dark : theme.palette.gray.main,
    backgroundColor: ({ disabled }: any) =>
      disabled ? theme.palette.common.white : theme.palette.gray.light,

    fontFamily: theme.fonts.secondary,
    fontSize: ({ fontSize }) => theme.typography.pxToRem(fontSize),
    fontWeight: theme.typography.fontWeightRegular,

    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: '100%',
    height: theme.spacing(3),

    display: 'flex',
    alignItems: 'center',

    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.dark,
  },
}));

const Select: React.FC<P & Omit<SelectProps, keyof P>> = ({
  wrapperClassName,
  className,
  menuItems,
  disabled,
  placeholder = 'Search...',
  dense,
  fontSize = 14,
  ...nativeProps
}) => {
  const classes = useStyles({ fontSize, disabled, value: nativeProps.value });

  return (
    <div
      className={clsx(
        classes.wrapper,
        'flex flex-row items-center',
        dense ? 'p-0' : 'p-2',
        wrapperClassName,
      )}
    >
      <MuiSelect
        disabled={disabled}
        displayEmpty
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
        className={className}
        IconComponent={KeyboardArrowDown}
        {...nativeProps}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>

        {menuItems.map(el => (
          <MenuItem key={el.displayText} value={el.value}>
            {el.displayText}
          </MenuItem>
        ))}
      </MuiSelect>
    </div>
  );
};

export default Select;
