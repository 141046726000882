import React from 'react';
import Cookies from 'js-cookie';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useUser } from '@contexts';

import map from '@routes/map';

type P = RouteProps & { authenticationRoute?: boolean };

const ShareRoute: React.FC<P> = props => {
  const token = Cookies.get('public_token');

  // if token doesn't exist , user is not authenticated
  if (!token) {
    return <Redirect to={map.auth.login} />;
  }

  // Logged in and verified
  return <Route exact {...props} />;
};

export default ShareRoute;
