/* eslint-disable no-nested-ternary */
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Edit, Close } from '@material-ui/icons';

import {
  Layout,
  Breadcrumbs,
  Button,
  FooterButtonGroup,
  Switch,
} from '@components/Common';

import { useUrlParams } from '@hooks/utils';

import UserHooks from '@hooks/api/user';
import { UserSetting } from '@hooks/api/user/types';

const initialValues: UserSetting = {
  notification: 'Active',
};

const ProfileComp = () => {
  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
  }>();

  const form = useForm<UserSetting>({
    defaultValues: initialValues,
  });

  const { ...useCurrentUserSettingUtils } = UserHooks.useCurrentUserSetting({
    onSuccess: data => form.reset(data.data),
  });

  const updateUserSettingMutation = UserHooks.useUpdateUserSettingMutation();

  const onSubmit: SubmitHandler<UserSetting> = data => {
    updateUserSettingMutation.mutate(data);
  };

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[{ href: '#', text: 'Setting' }]}
          />

          <div>
            {urlParams.editMode ? (
              <Button
                variant="outlined"
                className="w-28"
                onClick={() => setUrlParams({ editMode: false })}
                size="medium"
                startIcon={<Close />}
                text="Cancel"
              />
            ) : (
              <Button
                className="w-28"
                onClick={() => setUrlParams({ editMode: true })}
                size="medium"
                startIcon={<Edit />}
                text="Edit"
              />
            )}
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              Setting
            </h3>
          </div>

          <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
            <div className="flex flex-row items-center bg-white rounded shadow-sm">
              <div>
                <p className="font-roboto pl-4 w-52"> Notification </p>
              </div>

              <div className="flex flex-col w-full">
                <Controller
                  name="notification"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      buttonClassNames="w-16"
                      disabled={!urlParams?.editMode}
                      className="mr-2 wide"
                      leftButtonText="Active"
                      type="secondary"
                      rightButtonText="Deactivate"
                      value={value === 'Active' ? 'Active' : 'Deactivate'}
                      onChange={onChange}
                    />
                  )}
                />{' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        isLoading={updateUserSettingMutation.isLoading}
        canSave
        onSave={form.handleSubmit(onSubmit)}
      />
    </Layout>
  );
};

export default ProfileComp;
