import clsx from 'clsx';
// import { useHistory } from 'react-router-dom';
import * as DateUtils from 'date-fns';

import { Link } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import RouteMap from '@routes/map';

import {
  phoneRegex,
  isPendigForApproval,
  convertToIso,
  formatDate,
} from '@utils';

import GeneralHooks from '@hooks/api/general';

import AuthHooks from '@hooks/api/auth';
import { UserSupplierAndCertificate } from '@hooks/api/auth/types';

import {
  Button,
  Input,
  AutoComplete,
  DatePicker,
  TextArea,
  UploadButtonUploadOnChange,
  CountryAutoComplete,
} from '@components/Common';

import Logo from '@components/Auth/Logo';
import Cookies from 'js-cookie';
import { ExitToApp } from '@material-ui/icons';

const validationSchema = yup.object({
  supplier: yup.object({
    country: yup
      .mixed()
      .required('Country is required')
      .test('country', 'Country is required', val => !!val),
    city: yup.string().ensure().required('City is required'),
    address: yup.string().ensure().required('Address is required'),
    phone: yup
      .string()
      .ensure()
      .required('Phone number is required')
      .test('what-ever', 'Phone number is not valid', val => {
        if (val === '') return true;

        return phoneRegex.test(val);
      }),
    email: yup
      .string()
      .ensure()
      .required('Email is required')
      .email('Email is not valid'),
    emergency_contact_name: yup
      .string()
      .ensure()
      .required('Emergency contact name is required'),
    emergency_contact_phone: yup
      .string()
      .ensure()
      .required('Emergency contact phone is required')
      .matches(phoneRegex, 'Phone number is not valid'),
    emergency_contact_email: yup
      .string()
      .ensure()
      .required('Emergency contact email is required')
      .email('Emergency contact email is not valid'),
  }),
  certificate: yup.object({
    certificate_type: yup
      .mixed()
      .required('Certificate type is required')
      .test('certificate_type', 'Certificate type is required', val => !!val),
    expiry_date: yup
      .string()
      .test(
        'expiry_date',
        'Expiry date Cannot be set before issue date',
        (val, contxt) => {
          if (!val || !contxt.parent.issue_date) return true;

          return !DateUtils.isBefore(
            convertToIso(val),
            convertToIso(contxt.parent.issue_date),
          );
        },
      )
      .required('Expiry date is required'),
    upload_certificate: yup
      .string()
      .required('Certificate attachment is required'),
  }),
});

const SupplierAndCertificate = () => {
  const history = useHistory();

  const form = useForm<UserSupplierAndCertificate>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { data: allStatus } = GeneralHooks.useAllStatus();

  const useUserSupplierAndCertificateUtils =
    AuthHooks.useUserSupplierAndCertificate({
      onSuccess: data => {
        if (data.data.supplier.cardbox_status === 'Approved') {
          history.push(RouteMap.dashboard);
        }
        form.reset(data.data);
      },
    });

  const isSaqSelected = !!form.watch('certificate.certificate_type')?.is_saq;

  const submitUserSupplierAndCertificateMutation =
    AuthHooks.useSubmitUserSupplierAndCertificateMutation();
  const saveUserSupplierAndCertificateMutation =
    AuthHooks.useSaveUserSupplierAndCertificateMutation();

  const formatFormValues = (data: UserSupplierAndCertificate) => {
    const formattedData = {
      ...data,
      certificate: {
        ...data.certificate,
        issue_date: formatDate(data.certificate.issue_date || ''),
        expiry_date: formatDate(data.certificate.expiry_date || ''),
      },
    };

    return formattedData;
  };

  const onFormSubmit = async (data: UserSupplierAndCertificate) => {
    try {
      const isFormValid = await form.trigger();

      if (isFormValid) {
        const formattedValues = formatFormValues(data);

        await submitUserSupplierAndCertificateMutation.mutateAsync(
          formattedValues,
        );
      }
    } catch (e) {}
  };

  const isStatusPendigForApproval = isPendigForApproval(
    form.getValues('supplier.cardbox_status'),
  );

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-white-600">
      <Logo className="mt-24" />

      <Button
        className=" px-4 absolute top-10 right-10"
        onClick={e => {
          Cookies.remove('public_token');
          history.push(RouteMap.auth.login);
        }}
      >
        <ExitToApp className="text-white text-lg" />

        <span className="ml-4 text-sm font-roboto text-white">Log out</span>
      </Button>

      <div className="w-full px-8 mt-20">
        <div className="flex flex-col justify-center items-center">
          <div className="bg-white-400 w-full px-4 py-2 rounded">
            <h3 className="font-roboto text-base">General Information</h3>
          </div>

          <div className="grid grid-cols-3 py-2 gap-2 w-full">
            {(
              [
                {
                  displayText: 'Name',
                  key: 'supplier.name',
                  disabled: true,
                },
                {
                  displayText: 'Phone',
                  key: 'supplier.phone',
                },
                {
                  displayText: 'Email',
                  key: 'supplier.email',
                },
                {
                  displayText: 'Emergency contact Name',
                  key: 'supplier.emergency_contact_name',
                  required: true,
                },
                {
                  displayText: 'Emergency contact Email',
                  key: 'supplier.emergency_contact_email',
                  required: true,
                },
                {
                  displayText: 'Emergency contact Phone',
                  key: 'supplier.emergency_contact_phone',
                  required: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.displayText}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.displayText}
                    className={clsx('flex flex-col bg-white col-span-1')}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useUserSupplierAndCertificateUtils.isLoading}
                          disabled={
                            ('disabled' in el && !!el.disabled) ||
                            isStatusPendigForApproval
                          }
                          className="flex-grow"
                          placeholder={el.displayText}
                          value={value || ''}
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="supplier.supplier_type"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className={clsx('flex flex-col col-span-1')}>
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Supplier type</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        disabled={isStatusPendigForApproval}
                        loading={useUserSupplierAndCertificateUtils.isLoading}
                        value={value}
                        items={allStatus?.data?.supplier_types || []}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Number of sites',
                  key: 'supplier.number_of_sites',
                  inputType: 'number',
                },
                {
                  displayText: 'Number of staff',
                  key: 'supplier.number_of_staffs',
                  inputType: 'number',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.displayText}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.displayText}
                    className={clsx('flex flex-col bg-white col-span-1')}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          disabled={isStatusPendigForApproval}
                          loading={useUserSupplierAndCertificateUtils.isLoading}
                          className="flex-grow"
                          placeholder={el.displayText}
                          value={value || ''}
                          innerRef={ref}
                          type={'inputType' in el ? el.inputType : 'text'}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="supplier.country"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className={clsx('flex flex-col col-span-1')}>
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Country*</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <CountryAutoComplete
                        disabled={isStatusPendigForApproval}
                        disableClearable
                        loading={useUserSupplierAndCertificateUtils.isLoading}
                        value={value || null}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Website',
                  key: 'supplier.website',
                },
                {
                  displayText: 'City',
                  key: 'supplier.city',
                  required: true,
                },
                {
                  displayText: 'Address',
                  key: 'supplier.address',
                  gridSize: 'col-span-3',
                  required: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.displayText}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.displayText}
                    className={clsx(
                      'flex flex-col bg-white',
                      'gridSize' in el ? el.gridSize : 'col-span-1',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          disabled={isStatusPendigForApproval}
                          loading={useUserSupplierAndCertificateUtils.isLoading}
                          className="flex-grow"
                          placeholder={el.displayText}
                          value={value || ''}
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center mt-8">
          <div className="bg-white-400 w-full px-4 py-2 rounded">
            <h3 className="font-roboto text-base">General Information</h3>
          </div>

          <div className="grid grid-cols-3 py-2 gap-2 w-full">
            <Controller
              name="certificate.certificate_type"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Certificate Type*</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        disabled={isStatusPendigForApproval}
                        loading={useUserSupplierAndCertificateUtils.isLoading}
                        freeSolo={false}
                        canAddCustomItem={false}
                        value={value}
                        groupBy={option => option?.cert_type?.toString()}
                        items={
                          allStatus?.data?.certifications_category_dropdown ||
                          []
                        }
                        onChange={newVal => onChange(newVal)}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Certificate number',
                  key: 'certificate.certification_number',
                },
                {
                  displayText: 'Certification body',
                  key: 'certificate.certification_body',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-1',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          disabled={isStatusPendigForApproval}
                          loading={useUserSupplierAndCertificateUtils.isLoading}
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="certificate.grade"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div
                  className={clsx(
                    'flex flex-col col-span-1',
                    isSaqSelected ? 'hidden' : '',
                  )}
                >
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Grade</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        disabled={isStatusPendigForApproval}
                        loading={useUserSupplierAndCertificateUtils.isLoading}
                        value={value}
                        items={
                          allStatus?.data?.certifications_grade_dropdown || []
                        }
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Issue date',
                  key: 'certificate.issue_date',
                },
                {
                  displayText: 'Expiry date',
                  key: 'certificate.expiry_date',
                  required: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState,
                }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <DatePicker
                          disabled={isStatusPendigForApproval}
                          className="w-full"
                          value={value ? convertToIso(value.toString()) : null}
                          onChange={val => {
                            onChange(val);

                            form.trigger([
                              'certificate.issue_date',
                              'certificate.expiry_date',
                            ]);
                          }}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Scope',
                  key: 'certificate.scope',
                },
                {
                  displayText: 'Extensions',
                  key: 'certificate.extensions',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-3',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <TextArea
                          disabled={isStatusPendigForApproval}
                          multiline
                          rows={2}
                          inputClassName="p-2"
                          whiteWrapper
                          value={value ? value.toString() : ''}
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Number of critical NC(s)',
                  key: 'certificate.number_of_critical_nc',
                },
                {
                  displayText: 'Number of minor NC(s)',
                  key: 'certificate.number_of_minor_nc',
                },
                {
                  displayText: 'Number of major NC(s)',
                  key: 'certificate.number_of_major_nc',
                },
                {
                  displayText: 'Number of observation(s)',
                  key: 'certificate.number_of_observations',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-1',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          disabled={isStatusPendigForApproval}
                          loading={useUserSupplierAndCertificateUtils.isLoading}
                          className="flex-grow"
                          innerRef={ref}
                          type="number"
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="certificate.upload_certificate"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1 justify-start">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">
                        Upload Certificate*
                      </p>
                    </div>

                    <div className="flex flex-col w-full">
                      <UploadButtonUploadOnChange
                        disabled={isStatusPendigForApproval}
                        url="/user/supplier/upload"
                        id="upload_certificate"
                        wrapperClassName="flex justify-center my-2"
                        value={value ? String(value) : ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>

        <Controller
          name="supplier.saq_file"
          control={form.control}
          render={({ field: { value } }) => (
            <Button
              variant="outlined"
              className="mt-3"
              onClick={() => {}}
              size="medium"
              text=""
            >
              <a download href={value} target="_blank" rel="noreferrer">
                Download SAQ
              </a>
            </Button>
          )}
        />
      </div>

      <div className="w-full flex flex-row items-center justify-between p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />

        <div className="flex flex-row">
          {isStatusPendigForApproval ? null : (
            <>
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                className="border-green text-green mr-2 text-sm min-w-22 w-22"
                onClick={() => onFormSubmit(form.getValues())}
              >
                Submit
              </Button>

              <Button
                size="large"
                className="min-w-22 w-22 bg-green text-sm items-center"
                onClick={() =>
                  saveUserSupplierAndCertificateMutation.mutate(
                    formatFormValues(form.getValues()),
                  )
                }
              >
                Save
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupplierAndCertificate;
