/* eslint-disable react/no-array-index-key */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { Collapse, Divider, Tabs, Tab } from '@material-ui/core';

import { PalletisngInfoInstance } from '@hooks/api/wizard/types';

import { Switch, Dialog } from '@components/Common';

type DataType = {
  header?: string;
  className?: string;
  jsx: React.ReactNode;
};

type DataWithTabs = Record<keyof PalletisngInfoInstance, Array<DataType>>;

type P = {
  className?: string;
  expanded: boolean;
  onExpandChange?: (arg: boolean) => void;

  columnClassName?: string;
  columnHeaderClassName?: string;

  headerWrapperClassName?: string;
  headerRowClassName?: string;

  customCollapseComponent?: React.ReactNode;
  customHeaderComponent?: React.ReactNode;

  canExpand?: boolean;
  expandedForm: React.ReactNode;

  data?: null | DataWithTabs;
};

const Column = ({
  column,
  hidden = false,
  columnHeaderClassName = '',
  columnClassName = '',
  ...rest
}: {
  column: DataType;
  columnHeaderClassName?: string;
  columnDividerClassName?: string;
  columnClassName?: string;
  hidden?: boolean;
}) => (
  <div
    {...rest}
    className={overrideTailwindClasses(
      clsx(
        'flex flex-col bg-white shadow px-2 pb-2',
        'rounded-b pt-3',
        hidden && 'hidden',
        columnClassName,
        column.className,
      ),
    )}
  >
    {column?.header ? (
      <div className="items-center mb-4">
        <h4
          className={clsx(
            'font-roboto break-words inline-flex text-primary-light text-xs2',
            columnHeaderClassName,
          )}
        >
          {column.header}
        </h4>
      </div>
    ) : null}

    {column?.jsx || ''}
  </div>
);

type TabsKeys = keyof PalletisngInfoInstance;

const TABS: Readonly<
  Array<{
    key: TabsKeys;
    label: string;
    gridColsCount: 5 | 3 | 2;
  }>
> = [
  {
    key: 'pallet_general_info',
    label: 'Pallet General Information',
    gridColsCount: 3,
  },
  {
    key: 'pallet_details',
    label: 'Pallet Details',
    gridColsCount: 5,
  },
  {
    key: 'loaded_pallet_assembled',
    label: 'Loaded Pallet ( assembled ) Details',
    gridColsCount: 2,
  },
];

const Row: React.FC<P> = ({
  data,
  customHeaderComponent,
  customCollapseComponent,
  columnClassName,
  columnHeaderClassName,
  headerWrapperClassName,
  headerRowClassName,
  className,
  expanded,
  expandedForm,
  onExpandChange,
  canExpand,
}) => {
  const [tabValue, setTabValue] = React.useState<TabsKeys>(TABS[0].key);
  const [isExpandDialogOpen, setIsExpandDialogOpen] = React.useState(false);

  return (
    <div className={className}>
      <Dialog
        confirmButtonText="Confirm"
        rejectButtonText="Close"
        title="Are you sure ?"
        contentText={`Selecting "Confirm" will erase all the row data ,
         if you Save/Submit current changes`}
        open={isExpandDialogOpen}
        onClose={() => setIsExpandDialogOpen(false)}
        onConfirm={() => {
          onExpandChange?.(!expanded);
          setIsExpandDialogOpen(false);
        }}
        onReject={() => setIsExpandDialogOpen(false)}
      />

      <div
        className={overrideTailwindClasses(
          clsx(
            'flex flex-col mb-2',
            headerWrapperClassName,
            `tabStatus-${!expanded}`,
          ),
        )}
      >
        <div
          className={overrideTailwindClasses(
            clsx(
              'flex flex-row items-center rounded bg-white-400 py-2 px-4',
              headerRowClassName,
            ),
          )}
        >
          {customHeaderComponent || (
            <>
              <div className="flex flex-row items-center w-6/12">
                <h3 className="text-sm font-roboto text-primary-light">
                  Product sold by pallet?
                </h3>
              </div>

              <div className="flex flex-row items-center flex-grow justify-between">
                {onExpandChange ? <>{expandedForm || ''}</> : null}
              </div>
            </>
          )}
        </div>

        <Tabs
          classes={{
            root: 'min-h-unset h-10',
            flexContainer: 'min-h-unset h-10',
            indicator: 'bg-primary',
          }}
          value={tabValue}
          onChange={(_, newVal) => setTabValue(newVal)}
          aria-label="simple tabs example"
        >
          {TABS.map((el, i) => (
            <Tab
              key={`simple-tab-${i}`}
              disabled={!expanded}
              classes={{
                root: 'transition-all min-h-unset h-10 normal-case font-roboto text-sm text-gray',
                selected:
                  'text-primary-light border-b-1 border-primary-light bg-white-400',
              }}
              value={el.key}
              label={<span>{el.label}</span>}
              id={`simple-tab-${i}`}
              aria-controls={`simple-tabpanel-${i}`}
              className="font-roboto px-4 min-w-min max-w-full"
            />
          ))}
        </Tabs>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {!customCollapseComponent && !data ? (
          <div className="flex flex-row justify-center items-center my-4">
            <p className="font-roboto text-primary-light text-sm">No Content</p>
          </div>
        ) : null}

        {data ? (
          <>
            <Divider className="mb-2 bg-gray-400" />

            <div className="flex flex-row items-stretch">
              <div
                className={clsx(
                  'grid gap-x-2 w-full',
                  `grid-cols-${
                    (TABS.find(tab => tab.key === tabValue) || TABS[0])
                      .gridColsCount
                  }`,
                )}
              >
                {data[tabValue].map((column, colIndex) => (
                  <Column
                    columnClassName={columnClassName}
                    key={`column-${colIndex}-${column.header}`}
                    columnHeaderClassName={columnHeaderClassName}
                    hidden={false}
                    // hidden={tabValue !== currentTab.key}
                    column={column}
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}
      </Collapse>
    </div>
  );
};

export default Row;
