import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import config from '@constants/config';

import { map } from '@routes';

export interface errorData {
  data?: {
    errors: {
      [key: string]: Array<string>;
    };
  };
  message: string;
  status: string;
  timeStamp: number;
}

const errorHandler = (error: AxiosError<errorData>) => {
  if (config.isDevEnv && error) {
    console.log('onError', {
      url: error?.config?.url,
      error,
      config: error?.config,
      response: error?.response,
    });

    try {
      console.log('onError', {
        ...(error?.config?.data && { data: JSON.parse(error?.config?.data) }),
      });
    } catch (e) {}
  }

  if (error.response?.status === 401 || error.response?.status === 403) {
    Cookies.remove('token');

    if (window.location.pathname !== map.auth.login)
      window.location.replace(map.auth.login);
  }

  if (error.response?.data?.data?.errors) {
    const errorArr = Object.values(error.response.data.data.errors).flat();

    errorArr.forEach(errMsg => {
      toast.error(errMsg);
    });
  } else {
    // without the roast id , toast gets shoot twice for some reason
    toast.error(error.response?.data.message, {
      toastId: String(error.response?.data.timeStamp),
    });
  }

  if (!error.response && error.isAxiosError && error.message) {
    toast.error(error.message);
  }
};

export default errorHandler;
