/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { useForm, Controller, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import { Cell } from 'react-table';

import { Add as AddIcon } from '@material-ui/icons';

import { useUrlParams } from '@hooks';
import {
  deepClone,
  numberValidator,
  switchValidator,
  arrayValidator,
} from '@utils';

import {
  Input,
  UploadButtonUploadOnChange as UploadButton,
  Button,
  Switch,
  CountryAutoComplete,
  AutoComplete,
  AutoCompleteItem,
  Table,
  Tooltip,
  TableTrashButton,
} from '@components/Common';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import VerticalTable, {
  TVerticalTableCellParams,
  FIRST_COLUMN,
} from '@components/Bussines/Products/ProductForm/Weight/VerticalTable';
import MiniTable from '@components/Bussines/Products/ProductForm/Weight/MiniTable';
import TabRow from '@components/Bussines/Products/ProductForm/Weight/TabRow';

import WeightUtils from '@components/Bussines/Products/ProductForm/Weight/utils';

import WizardHooks from '@hooks/api/wizard';
import GeneralHooks from '@hooks/api/general';
import {
  Weight,
  WeightControlInstance,
  ManufactureDetailInstance,
  RecycleIconDetailsInstance,
} from '@hooks/api/wizard/types';

const COLUMN_KEYS_IN_ORDER: Array<keyof ManufactureDetailInstance['details']> =
  [
    'component_description',
    'material',
    'colour',
    'height_mm',
    'width_mm',
    'length_mm',
    'diameter_mm',
    'gauge_of_thickness_micro',
    'component_of_weight_g',
    'supplier_name',
    'country_of_origin',
    'used_recycled_material_content_percent',
    'component_recyclable',
    'packaging_material_biodegradable',
    'method_of_closure',
    'food_contact_certified',
    'upload_food_contact_certificate',
    'comments',
  ];

interface ControlledFormElementParams {
  name: FieldPath<Weight>;
  disabled: boolean;
  dense?: boolean;
  className?: string;
  placeholder?: string;
  type?: string;
  tabIndex?: number;
}

const weightControlValidator = () => {
  return yup.object({
    value: yup.mixed().test('value', 'error', numberValidator),
    // tolerance: yup.mixed().test('tolerance', 'error', numberValidator),
    unit: yup.mixed().test('unit', 'error', val => !!val),
  });
};

const validationSchema = yup.object({
  manufacture_details: yup.array(
    yup.object({
      details: yup.object({
        component_description: yup.string().ensure().required(),
        colour: yup.mixed().test('colour', 'error', val => !!val),
        material: yup.mixed().test('material', 'error', val => !!val),
        used_recycled_material_content_percent: yup
          .mixed()
          .test(
            'used_recycled_material_content_percent',
            'error',
            numberValidator,
          ),
        height_mm: yup.mixed().test('height_mm', 'error', numberValidator),
        width_mm: yup.mixed().test('width_mm', 'error', numberValidator),
        length_mm: yup.mixed().test('length_mm', 'error', numberValidator),
        component_of_weight_g: yup
          .mixed()
          .test('component_of_weight_g', 'error', numberValidator),
        country_of_origin: yup
          .mixed()
          .test('country_of_origin', 'error', arrayValidator),
        method_of_closure: yup
          .mixed()
          .test('method_of_closure', 'error', val => !!val),
        component_recyclable: yup
          .mixed()
          .test('component_recyclable', 'error', switchValidator),
        food_contact_certified: yup
          .mixed()
          .test('food_contact_certified', 'error', switchValidator),
        packaging_material_biodegradable: yup
          .mixed()
          .test('packaging_material_biodegradable', 'error', switchValidator),
      }),
      packaging_type: yup.mixed().test('packaging_type', 'error', val => !!val),
    }),
  ),

  expanded_state: yup.object({
    product_sold_by_pallet: yup
      .mixed()
      .test('expanded_state', `error`, val => typeof val === 'boolean'),
  }),

  weight_control: yup.object({
    unit_net_weight: weightControlValidator(),
    unit_gross_weight: weightControlValidator(),
    case_net_weight: weightControlValidator(),
    case_gross_weight: weightControlValidator(),
    number_of_units_in_outer_case: yup
      .mixed()
      .test('number_of_units_in_outer_case', 'error', numberValidator),

    // specific_gravity: yup.object({
    //   value: yup.string().ensure().required('specific_gravity is required'),
    //   measuring_unit: yup.mixed().test('measuring_unit', 'error', val => !!val),
    // }),
    method_of_weight_control: yup.object({
      // value: yup.mixed().test('value', 'error', numberValidator),
      // unit: yup.mixed().test('unit', 'error', val => !!val),
      method: yup.mixed().test('method', 'error', val => {
        return !!val;
      }),
      t1_weight: yup.mixed().test('method', 'error', (val, contxt) => {
        if (contxt.parent.method?.t1 === undefined) {
          return true;
        }
        return !!val;
      }),
      t2_weight: yup.mixed().test('method', 'error', (val, contxt) => {
        if (contxt.parent.method?.t2 === undefined) {
          return true;
        }
        return !!val;
      }),
    }),
  }),
  palletisng_info: yup.array(
    yup.object({
      pallet_general_info: yup.object({
        pallet_material: yup
          .mixed()
          .test('pallet_material', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            return !!val;
          }),

        is_there_a_pallet_label: yup
          .mixed()
          .test('is_there_a_pallet_label', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }
            return typeof val === 'boolean';
          }),

        method_of_pallet_security: yup
          .mixed()
          .test('method_of_pallet_security', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            return !!val;
          }),
      }),

      pallet_details: yup.object({
        pallet_type: yup
          .mixed()
          .test('pallet_type', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            return !!val;
          }),
        height_mm: yup
          .mixed()
          .test('height_mm', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            if (val === 0) return true;

            if (val === null || val === undefined || val === '') return false;

            return Number.isFinite(Number(val));
          }),
        length_mm: yup
          .mixed()
          .test('length_mm', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            if (val === 0) return true;

            if (val === null || val === undefined || val === '') return false;

            return Number.isFinite(Number(val));
          }),
        weight_g: yup.mixed().test('weight_g', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state
              .product_sold_by_pallet === false
          ) {
            return true;
          }

          if (val === 0) return true;

          if (val === null || val === undefined || val === '') return false;

          return Number.isFinite(Number(val));
        }),
        width_mm: yup.mixed().test('width_mm', 'error', (val, contxt: any) => {
          if (
            contxt.from[contxt.from.length - 1].value.expanded_state
              .product_sold_by_pallet === false
          ) {
            return true;
          }

          if (val === 0) return true;

          if (val === null || val === undefined || val === '') return false;

          return Number.isFinite(Number(val));
        }),
      }),

      loaded_pallet_assembled: yup.object({
        assembled_pallet_height_mm: yup
          .mixed()
          .test('assembled_pallet_height_mm', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            if (val === 0) return true;

            if (val === null || val === undefined || val === '') return false;

            return Number.isFinite(Number(val));
          }),
        max_pallet_weight_kg: yup
          .mixed()
          .test('max_pallet_weight_kg', 'error', (val, contxt: any) => {
            if (
              contxt.from[contxt.from.length - 1].value.expanded_state
                .product_sold_by_pallet === false
            ) {
              return true;
            }

            if (val === 0) return true;

            if (val === null || val === undefined || val === '') return false;

            return Number.isFinite(Number(val));
          }),
      }),
    }),
  ),
  pallet_configuration: yup.object({
    number_of_cases_per_layer: yup
      .mixed()
      .test('number_of_cases_per_layer', 'error', (val, contxt: any) => {
        if (
          !contxt.from[contxt.from.length - 1].value.expanded_state
            .product_sold_by_pallet
        ) {
          return true;
        }
        if (val === 0) return true;

        if (val === null || val === undefined || val === '') return false;

        return Number.isFinite(Number(val));
      }),

    number_of_layers_per_pallet: yup
      .mixed()
      .test('number_of_layers_per_pallet', 'error', (val, contxt: any) => {
        if (
          !contxt.from[contxt.from.length - 1].value.expanded_state
            .product_sold_by_pallet
        ) {
          return true;
        }
        if (val === 0) return true;

        if (val === null || val === undefined || val === '') return false;

        return Number.isFinite(Number(val));
      }),
    number_of_units_per_pack: yup
      .mixed()
      .test('number_of_units_per_pack', 'error', numberValidator),

    number_of_packs_per_case: yup
      .mixed()
      .test('number_of_packs_per_case', 'error', numberValidator),
  }),
});

type P = {
  share?: boolean;
};
const WeightAndPackagingComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');
  const [loading, setLoading] = React.useState(false);

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode?: boolean;
  }>();

  const form = useForm<Weight>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const { data: productWeight, ...useProductWeightUtils } =
    WizardHooks.useWeight(
      {
        code: urlParams.code,
        version: urlParams.version,
      },
      {
        enabled: !!urlParams.code && !!urlParams.version,
        onSuccess: data => {
          form.reset(data.data);
        },
      },
    );
  const updateWeightMutation = WizardHooks.useUpdateWeightMutation();
  const validateWeightMutation = WizardHooks.useValidateWeightMutation();
  const { data: allStatus, ...allStatusHookUtils } =
    GeneralHooks.useAllStatus();

  const history = useHistory();

  const onSave = (link: string, data: Weight) => {
    form.clearErrors();
    const newData = { ...data };

    // // if the row has been expanded , we set the value to an empty array (reseting value)
    // if (!isPalletisingInformationExpanded) {
    //   newData.palletisng_info = [];
    // }

    updateWeightMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...newData,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate = async (data: Weight) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      const newData = { ...data };

      // if the row has been expanded , we set the value to an empty array (reseting value)
      // if (!isPalletisingInformationExpanded) {
      //   newData.palletisng_info = [];
      // }

      validateWeightMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...newData,
      });
    } catch (e) {}
  };

  const addManufacturerDetailColumn = () => {
    const firstCol = deepClone({
      ...WeightUtils.manufactureDetailInstance,
    });

    form.setValue(
      'manufacture_details',
      [...form.getValues().manufacture_details, firstCol],
      { shouldDirty: true },
    );

    form.reset(undefined, {
      keepValues: true,
      keepTouched: true,
      keepDirty: true,
      keepErrors: true,
    });
  };

  const removeManufacturerDetailColumn = React.useCallback(
    (colIndex: number) => {
      setLoading(true);
      const oldVals = [...form.getValues().manufacture_details];

      oldVals.splice(colIndex, 1);

      form.setValue('manufacture_details', oldVals, { shouldDirty: true });

      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
      setTimeout(() => {
        setLoading(false);
      }, 100);
    },
    [productWeight?.timeStamp],
  );

  const FormInput = React.useCallback(
    ({
      name,
      disabled,
      dense = true,
      className,
      placeholder,
      type,
      tabIndex,
    }: ControlledFormElementParams) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { ref, value, ...rest }, fieldState }) => (
          <Input
            error={!!fieldState.error}
            disabled={disabled}
            className={clsx('w-full', dense ? '' : 'p-0', className)}
            inputClassName="text-sm px-2 font-roboto"
            dense={dense}
            placeholder={placeholder}
            type={type || 'text'}
            innerRef={ref}
            autoComplete="new-password"
            inputProps={{ tabIndex }}
            value={value}
            {...rest}
          />
        )}
      />
    ),
    [],
  );

  const FormAutoComplete = React.useCallback(
    ({
      name,
      options,
      disabled,
      dense = true,
      canAddCustomItem,
      freeSolo,
      placeholder,
      tabIndex,
    }: ControlledFormElementParams & {
      canAddCustomItem?: boolean;
      freeSolo?: boolean;
      tabIndex?: number;
      options: Array<NonNullable<AutoCompleteItem>>;
    }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <AutoComplete
            error={!!fieldState.error}
            dense={dense}
            className="flex justify-center"
            inputRootClassName={dense ? '' : 'p-0'}
            disabled={disabled}
            id={name}
            items={options}
            value={value as AutoCompleteItem}
            onChange={onChange}
            canAddCustomItem={canAddCustomItem}
            freeSolo={freeSolo}
            inputPlaceholder={placeholder}
            tabIndex={tabIndex}
          />
        )}
      />
    ),
    [],
  );

  const FormSwitch = React.useCallback(
    ({ name, disabled, tabIndex }: ControlledFormElementParams) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, onChange }, fieldState }) => (
          <Switch
            error={!!fieldState.error}
            disabled={disabled}
            className="justify-center my-2"
            type="secondary"
            value={typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null}
            onChange={val => onChange(val.toLowerCase() === 'yes')}
            tabIndex={tabIndex}
          />
        )}
      />
    ),
    [],
  );

  const CUSTOM_COLUMN: Array<{
    Cell: (cell: TVerticalTableCellParams) => JSX.Element;
  }> = React.useMemo(
    () => [
      {
        Cell: cell => (
          <FormInput
            disabled={!urlParams?.editMode}
            name={`manufacture_details.${cell.colIndex}.details.component_description`}
            placeholder={FIRST_COLUMN[0].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormAutoComplete
            options={allStatus?.data.packaging_material_dropdown || []}
            name={`manufacture_details.${cell.colIndex}.details.material`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[1].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            name={`manufacture_details.${cell.colIndex}.details.colour`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[2].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.height_mm`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[3].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.width_mm`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[4].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.length_mm`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[5].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.diameter_mm`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[6].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.gauge_of_thickness_micro`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[7].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            type="number"
            name={`manufacture_details.${cell.colIndex}.details.component_of_weight_g`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[8].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            name={`manufacture_details.${cell.colIndex}.details.supplier_name`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[9].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <Controller
            name={`manufacture_details.${cell.colIndex}.details.country_of_origin`}
            control={form.control}
            render={({ field: { value, onChange, onBlur }, fieldState }) => (
              <CountryAutoComplete
                error={!!fieldState.error}
                dense
                disabled={!urlParams?.editMode}
                multiple
                className="flex justify-center "
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputPlaceholder={FIRST_COLUMN[10].label}
                tabIndex={cell.tabIndex}
              />
            )}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            disabled={!urlParams?.editMode}
            name={`manufacture_details.${cell.colIndex}.details.used_recycled_material_content_percent`}
            placeholder={FIRST_COLUMN[11].label}
            tabIndex={cell.tabIndex}
            type="number"
          />
        ),
      },
      {
        Cell: cell => (
          <FormSwitch
            name={`manufacture_details.${cell.colIndex}.details.component_recyclable`}
            disabled={!urlParams?.editMode}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormSwitch
            name={`manufacture_details.${cell.colIndex}.details.packaging_material_biodegradable`}
            disabled={!urlParams?.editMode}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormAutoComplete
            options={allStatus?.data.packaging_method_of_closure_dropdown || []}
            name={`manufacture_details.${cell.colIndex}.details.method_of_closure`}
            disabled={!urlParams?.editMode}
            placeholder={FIRST_COLUMN[14].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <FormSwitch
            name={`manufacture_details.${cell.colIndex}.details.food_contact_certified`}
            disabled={!urlParams?.editMode}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <Controller
            name={`manufacture_details.${cell.colIndex}.details.upload_food_contact_certificate`}
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <UploadButton
                disabled={
                  !urlParams?.editMode ||
                  !form.watch(
                    `manufacture_details.${cell.colIndex}.details.food_contact_certified`,
                  )
                }
                id={`manufacture_details.${cell.colIndex}.details.upload_food_contact_certificate`}
                url={`/products/${urlParams.code}/version/${urlParams.version}/packaging/upload`}
                wrapperClassName="flex justify-center my-2"
                onChange={onChange}
                value={value}
                tabIndex={cell.tabIndex}
              />
            )}
          />
        ),
      },
      {
        Cell: cell => (
          <FormInput
            disabled={!urlParams?.editMode}
            name={`manufacture_details.${cell.colIndex}.details.comments`}
            placeholder={FIRST_COLUMN[17].label}
            tabIndex={cell.tabIndex}
          />
        ),
      },
      {
        Cell: cell => (
          <>
            {form.getValues().manufacture_details.length <= 1 ? (
              <TableTrashButton
                className="w-full"
                disabled
                onClick={() => removeManufacturerDetailColumn(cell.colIndex)}
                tabIndex={cell.tabIndex}
              />
            ) : (
              <TableTrashButton
                className="w-full"
                disabled={!urlParams.editMode}
                onClick={() => removeManufacturerDetailColumn(cell.colIndex)}
                tabIndex={cell.tabIndex}
              />
            )}
          </>
        ),
      },
    ],
    [urlParams.editMode, allStatusHookUtils.dataUpdatedAt],
  );

  const WeightRowComp = React.useCallback(
    (props: {
      keyStringBase: keyof Pick<
        WeightControlInstance,
        | 'case_gross_weight'
        | 'case_net_weight'
        | 'unit_drained_weight'
        | 'unit_gross_weight'
        | 'unit_net_weight'
      >;
    }) => {
      const StatusByKey = {
        case_gross_weight: {
          label: 'Case Gross Weight',
          tooltip: '',
        },
        case_net_weight: {
          label: 'Case Net Weight',
          tooltip: "Full case Weight without packaging material's weight",
        },
        unit_drained_weight: {
          label: 'Unit Drained Weight',
          tooltip:
            'Define product weight after draining, when product contains drainable liquid',
        },
        unit_gross_weight: {
          label: 'Unit Gross Weight',
          tooltip: "Full case weight including packaging material's weight",
        },
        unit_net_weight: { label: 'Unit Net Weight', tooltip: '' },
      } as const;

      return (
        <>
          <td className="table-cell px-4 py-2">
            <div className="flex flex-row items-center w-44">
              <p className="font-roboto text-primary-light text-sm">
                {StatusByKey[props.keyStringBase]?.label}
              </p>

              {StatusByKey[props.keyStringBase]?.tooltip ? (
                <Tooltip
                  title={StatusByKey[props.keyStringBase]?.tooltip || ''}
                />
              ) : null}
            </div>
          </td>

          {(
            [
              { key: 'value', type: 'number' },
              { key: 'tolerance', type: 'text' },
            ] as const
          ).map(el => (
            <td key={el.key} className="table-cell px-2 py-2">
              <FormInput
                name={`weight_control.${props.keyStringBase}.${el.key}`}
                disabled={!urlParams.editMode}
                type={'type' in el ? el.type : ''}
              />
            </td>
          ))}

          <td className="table-cell px-2 py-2 w-32">
            <FormAutoComplete
              options={allStatus?.data.packaging_unit_dropdown || []}
              name={`weight_control.${props.keyStringBase}.unit`}
              disabled={!urlParams.editMode}
              canAddCustomItem={false}
              freeSolo={false}
            />
          </td>
        </>
      );
    },
    [
      urlParams.editMode,
      allStatusHookUtils.dataUpdatedAt,
      useProductWeightUtils.dataUpdatedAt,
    ],
  );

  const MethodWeightRowComp = React.useCallback(() => {
    return (
      <>
        <td className="table-cell align-middle px-4 py-2">
          <p className="w-44 font-roboto text-primary-light text-sm">
            Method of weight control
          </p>
        </td>

        <td className="table-cell align-middle px-2 py-2 w-72">
          <FormAutoComplete
            options={
              allStatus?.data.packaging_method_of_weight_control_dropdown || []
            }
            name="weight_control.method_of_weight_control.method"
            disabled={!urlParams.editMode}
          />
        </td>
        <td className="table-cell align-middle px-2 py-2">
          <FormInput
            type="number"
            name="weight_control.method_of_weight_control.t1_weight"
            disabled={!urlParams.editMode}
          />
        </td>
        <td className="table-cell align-middle px-2 py-2">
          <FormInput
            type="number"
            name="weight_control.method_of_weight_control.t2_weight"
            disabled={!urlParams.editMode}
          />
        </td>
      </>
    );
  }, [
    urlParams.editMode,
    allStatusHookUtils.dataUpdatedAt,
    useProductWeightUtils.dataUpdatedAt,
  ]);

  const MethodWeightRowCompwithoutTWeight = React.useCallback(() => {
    return (
      <>
        <td className="table-cell align-middle px-4 py-2">
          <p className="w-44 font-roboto text-primary-light text-sm">
            Method of weight control
          </p>
        </td>

        <td className="table-cell align-middle px-2 py-2 w-72">
          <FormAutoComplete
            options={
              allStatus?.data.packaging_method_of_weight_control_dropdown || []
            }
            name="weight_control.method_of_weight_control.method"
            disabled={!urlParams.editMode}
          />
        </td>
        <td className="table-cell align-middle px-2 py-2 w-72">
          <div className="w-full" />
        </td>
        <td className="table-cell align-middle px-2 py-2 w-72">
          <div className="w-full" />
        </td>
      </>
    );
  }, [
    urlParams.editMode,
    allStatusHookUtils.dataUpdatedAt,
    useProductWeightUtils.dataUpdatedAt,
  ]);

  const NumberUnitsOuterCaseRowComp = React.useCallback(() => {
    return (
      <>
        <td className="table-cell align-middle px-4 py-2">
          <p className="w-44 font-roboto text-primary-light text-sm">
            Number of units in outer case (box)
          </p>
        </td>

        <td className="table-cell align-middle px-2 py-2">
          <FormInput
            name="weight_control.number_of_units_in_outer_case"
            disabled={!urlParams.editMode}
            type="number"
          />
        </td>
      </>
    );
  }, [
    urlParams.editMode,
    allStatusHookUtils.dataUpdatedAt,
    useProductWeightUtils.dataUpdatedAt,
  ]);

  const MentionSpecificGravityRowComp = React.useCallback(() => {
    return (
      <>
        <td className="table-cell align-middle px-4 py-2">
          <p className="w-44 font-roboto text-primary-light text-sm">
            If liquid, please mention specific gravity
          </p>
        </td>

        <td className="table-cell align-middle px-2 py-2">
          <FormInput
            name="weight_control.specific_gravity.value"
            disabled={!urlParams.editMode}
          />
        </td>

        <td className="table-cell align-middle px-2 py-2 w-48">
          <FormAutoComplete
            options={
              allStatus?.data
                ?.packaging_specific_gravity_measuring_units_dropdown || []
            }
            name="weight_control.specific_gravity.measuring_unit"
            disabled={!urlParams.editMode}
            canAddCustomItem={false}
            freeSolo={false}
          />
        </td>
      </>
    );
  }, [
    urlParams.editMode,
    allStatusHookUtils.dataUpdatedAt,
    useProductWeightUtils.dataUpdatedAt,
  ]);

  const ManufactureDetailsTableHeadeCell = ({
    colIndex,
  }: {
    colIndex: number;
  }) => (
    <FormAutoComplete
      options={allStatus?.data.packaging_packagings_type_dropdown || []}
      disabled={!urlParams?.editMode}
      name={`manufacture_details.${colIndex}.packaging_type`}
    />
  );

  const onRecylingIconsAddRow = () => {
    const newRows = [...form.getValues().recycling_icon_details];

    newRows.push(deepClone({ ...WeightUtils.recyclingIconDetailInstance }));

    form.setValue('recycling_icon_details', newRows, { shouldDirty: true });

    form.reset(undefined, {
      keepValues: true,
      keepDirty: true,
      keepIsValid: true,
      keepTouched: true,
      keepErrors: true,
    });
  };

  const onRecylingIconsDeleteRow = (rowIndex: string) => {
    const newRows = [...form.getValues().recycling_icon_details];
    newRows.splice(Number(rowIndex), 1);
    form.setValue('recycling_icon_details', newRows, { shouldDirty: true });
    form.reset(undefined, {
      keepValues: true,
      keepDirty: true,
      keepIsValid: true,
      keepTouched: true,
      keepErrors: true,
    });
  };

  const recyclingIconsDetailsColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof RecycleIconDetailsInstance;
      id: keyof RecycleIconDetailsInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell) => JSX.Element);
    }> => [
      {
        Header: 'Text',
        id: 'text',
        accessor: 'text',
        width: 367,
        Cell: cell => (
          <FormAutoComplete
            options={
              allStatus?.data.packaging_recycling_icon_details_dropdown || []
            }
            disabled={!urlParams.editMode}
            name={`recycling_icon_details.${Number(cell.row.id)}.text`}
          />
        ),
      },
      {
        Header: 'Supporting Text',
        id: 'supporting_text',
        accessor: 'supporting_text',
        width: 469,
        Cell: cell => (
          <FormInput
            disabled={!urlParams.editMode}
            name={`recycling_icon_details.${Number(
              cell.row.id,
            )}.supporting_text`}
          />
        ),
      },
      {
        Header: 'Used on Pack',
        id: 'used_on_pack',
        accessor: 'used_on_pack',
        width: 192,
        Cell: cell => (
          <FormAutoComplete
            options={allStatus?.data.packaging_used_on_pack_dropdown || []}
            disabled={!urlParams.editMode}
            name={`recycling_icon_details.${Number(cell.row.id)}.used_on_pack`}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <TableTrashButton
            disabled={!urlParams.editMode}
            onClick={() => onRecylingIconsDeleteRow(cell.row.id)}
          />
        ),
      },
    ],
    [
      urlParams?.editMode,
      JSON.stringify(form.getValues().recycling_icon_details),
      allStatusHookUtils.dataUpdatedAt,
    ],
  );

  const onExpandChange = (val: boolean) => {
    const isRowEmpty = form.getValues()?.expanded_state.product_sold_by_pallet;

    if (isRowEmpty && val) {
      const newVal = [deepClone({ ...WeightUtils.palletisngInfoInstance })];
      form.setValue('palletisng_info', newVal, { shouldDirty: true });

      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
    }
  };

  const isAverageWeightControlChosen =
    form.watch('weight_control.method_of_weight_control.method')?.name ===
    allStatus?.data?.packaging_method_of_weight_control_dropdown?.[0]?.name;

  const hasPalletisingInfoSwitchValueChanged =
    productWeight?.data?.palletisng_info?.length;

  const totalHandeler = () => {
    const total_cases_per_pallet =
      (Number(form.watch('pallet_configuration.number_of_cases_per_layer')) ||
        0) *
      (Number(form.watch('pallet_configuration.number_of_layers_per_pallet')) ||
        0);

    const total_units_per_pallet =
      (Number(form.watch('pallet_configuration.number_of_units_per_pack')) ||
        0) * total_cases_per_pallet;

    form.setValue(
      'pallet_configuration.total_cases_per_pallet',
      total_cases_per_pallet,
      {
        shouldDirty: true,
      },
    );
    form.setValue(
      'pallet_configuration.total_units_per_pallet',
      total_units_per_pallet,
      {
        shouldDirty: true,
      },
    );

    // const total_units_per_pallet =
    //   Number(
    //     form.watch(
    //       'pallet_configuration.number_of_layers_per_pallet',
    //     ) || 0,
    //   ) * total_cases_per_pallet;

    // if (el.key === 'total_cases_per_pallet') {
    //   form.setValue(
    //     'pallet_configuration.total_cases_per_pallet',
    //     {
    //       total_cases_per_pallet,
    //     },
    //     {
    //       shouldDirty: true,
    //     },
    //   );
    // }
    // if (el.key === 'total_units_per_pallet') {
    //   form.setValue(
    //     'pallet_configuration.total_units_per_pallet',
    //     {
    //       total_units_per_pallet,
    //     },
    //     {
    //       shouldDirty: true,
    //     },
    //   );
    // }
  };

  const tabErrors = () => {
    const errors = form?.formState?.errors?.palletisng_info;
    const status = {
      loaded_pallet_assembled: false,
      pallet_details: false,
      pallet_general_info: false,
    };
    if (errors !== undefined) {
      if (errors[0]?.loaded_pallet_assembled) {
        status.loaded_pallet_assembled = true;
      }
      if (errors[0]?.pallet_details) {
        status.pallet_details = true;
      }
      if (errors[0]?.pallet_general_info) {
        status.pallet_general_info = true;
      }
    }
    return `loaded_pallet_assembled-${status.loaded_pallet_assembled} pallet_details-${status.pallet_details} pallet_general_info-${status.pallet_general_info}`;
  };
  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <div className="flex flex-col mt-6">
          <div className="flex flex-row items-center justify-between mb-3">
            <h3 className="font-roboto text-base text-primary-light">
              Packaging Material Details
            </h3>

            <Button
              disabled={!urlParams.editMode}
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => addManufacturerDetailColumn()}
              startIcon={<AddIcon />}
              text="New Column"
            />
          </div>

          <VerticalTable
            loading={useProductWeightUtils.isLoading}
            columnLoading={loading}
            rowCount={CUSTOM_COLUMN.length || 0}
            columnCount={form.getValues()?.manufacture_details?.length || 0}
            HeaderCell={ManufactureDetailsTableHeadeCell}
            column={CUSTOM_COLUMN}
          />
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Weight control
          </h3>

          <div>
            <MiniTable
              className="border-r-4 border-white-600 w-1/2"
              headers={['Status', 'Value', 'Tolerance', 'Measuring unit']}
              rowKeys={[
                'unit_net_weight',
                'unit_drained_weight',
                'unit_gross_weight',
              ]}
              RowComp={WeightRowComp}
            />

            <MiniTable
              className="border-l-4 border-white-600 w-1/2"
              headers={['Status', 'Value', 'Tolerance', 'Measuring unit']}
              rowKeys={['case_gross_weight', 'case_net_weight']}
              RowComp={WeightRowComp}
            />

            <MiniTable
              className="mt-4 w-full"
              headers={
                isAverageWeightControlChosen
                  ? ['Status', 'Method', 'T1 Weight', 'T2 Weight']
                  : ['Status', 'Method', ' ', ' ']
              }
              RowComp={
                isAverageWeightControlChosen
                  ? MethodWeightRowComp
                  : MethodWeightRowCompwithoutTWeight
              }
            />

            <MiniTable
              className="mt-4 border-r-4 border-white-600 w-1/2"
              headers={['Status', 'Value']}
              RowComp={NumberUnitsOuterCaseRowComp}
            />

            <MiniTable
              className="mt-4 border-l-4 border-white-600 w-1/2"
              headers={['Status', 'Value', 'Measuring Unit']}
              RowComp={MentionSpecificGravityRowComp}
            />
          </div>
        </div>

        <div className={`flex flex-col mt-6 ${tabErrors()}`}>
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Palletising information
          </h3>

          <TabRow
            canExpand={urlParams?.editMode}
            expanded={
              form.watch('expanded_state.product_sold_by_pallet') || false
            }
            onExpandChange={onExpandChange}
            expandedForm={
              <FormSwitch
                disabled={!urlParams?.editMode}
                name="expanded_state.product_sold_by_pallet"
              />
            }
            data={{
              pallet_general_info: [
                {
                  header: 'Method of pallet security',
                  jsx: (
                    <FormAutoComplete
                      dense={false}
                      options={
                        allStatus?.data
                          .packaging_method_of_pallet_security_dropdown || []
                      }
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_general_info.method_of_pallet_security"
                    />
                  ),
                },
                {
                  header: 'Is there a pallet label?',
                  jsx: (
                    <FormSwitch
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_general_info.is_there_a_pallet_label"
                    />
                  ),
                },
                {
                  header: 'Pallet material',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_general_info.pallet_material"
                    />
                  ),
                },
              ],
              pallet_details: [
                {
                  header: 'Pallet Type',
                  jsx: (
                    <FormAutoComplete
                      dense={false}
                      options={
                        allStatus?.data.packaging_pallet_type_dropdown || []
                      }
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_details.pallet_type"
                    />
                  ),
                },
                {
                  header: 'Length (mm)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_details.length_mm"
                      type="number"
                    />
                  ),
                },
                {
                  header: 'Width (mm)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_details.width_mm"
                      type="number"
                    />
                  ),
                },
                {
                  header: 'Height (mm)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_details.height_mm"
                      type="number"
                    />
                  ),
                },
                {
                  header: 'Weight (g)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.pallet_details.weight_g"
                      type="number"
                    />
                  ),
                },
              ],
              loaded_pallet_assembled: [
                {
                  header: 'Pallet Height (cm)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.loaded_pallet_assembled.assembled_pallet_height_mm"
                      type="number"
                    />
                  ),
                },
                {
                  header: 'Pallet Weight (Kg)',
                  jsx: (
                    <FormInput
                      dense={false}
                      disabled={!urlParams?.editMode}
                      name="palletisng_info.0.loaded_pallet_assembled.max_pallet_weight_kg"
                      type="number"
                    />
                  ),
                },
              ],
            }}
          />
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Pallet Configuration
          </h3>

          <div className="grid grid-cols-2 gap-x-4 gap-y-1">
            {(
              [
                {
                  label: 'Number of Units per Pack',
                  key: 'number_of_units_per_pack',
                  tooltip:
                    'Define Number of units in the product that is taken home by the consumer',
                },
                {
                  label: 'Number of Cases (Outer box) per Layer',
                  key: 'number_of_cases_per_layer',
                  tooltip: 'Define Number of cases in one layer of the pallet',
                },
                {
                  label: 'Number of Packs per Case (Outer box)',
                  key: 'number_of_packs_per_case',
                  tooltip: 'Define number of units in one case (outer box)',
                },
                {
                  label: 'Number of Layers per Pallet',
                  key: 'number_of_layers_per_pallet',
                  tooltip: 'Define Number of layers of cases in the pallet',
                },
                {
                  label: 'Total Cases Per Pallet',
                  key: 'total_cases_per_pallet',
                },
                {
                  label: 'Total Units Per Pallet',
                  key: 'total_units_per_pallet',
                },
              ] as const
            ).map(el => (
              <div
                key={el.key}
                className="flex flex-row h-10 justify-between items-center pl-4 pr-2 w-full bg-white"
              >
                <div className="flex flex-row items-center w-2/3">
                  <p className="font-roboto text-primary-light text-sm">
                    {el.label}
                  </p>

                  {'tooltip' in el ? <Tooltip title={el.tooltip} /> : null}
                </div>

                <div className="w-1/3">
                  <Controller
                    name={`pallet_configuration.${el.key}`}
                    control={form.control}
                    render={({
                      field: { ref, value, onChange },
                      fieldState,
                    }) => (
                      <Input
                        error={!!fieldState.error}
                        disabled={!urlParams.editMode}
                        className={clsx('w-full')}
                        inputClassName="text-sm px-2 font-roboto"
                        dense
                        innerRef={ref}
                        type="number"
                        value={value}
                        onChange={e => {
                          if (e.target.value === '') {
                            onChange(null);
                          } else {
                            onChange(Number(e.target.value));
                          }

                          totalHandeler();
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            Recycling Icons details
          </h3>

          <Table
            className="mt-2"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 px-4 h-10',
            )}
            bodyRowClassName="rounded bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary border-0 px-3"
            columns={recyclingIconsDetailsColumns}
            data={form.getValues()?.recycling_icon_details || []}
          />

          <div className="flex flex-row justify-end mt-3">
            <Button
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => onRecylingIconsAddRow()}
              startIcon={<AddIcon />}
              text="New Row"
              disabled={!urlParams.editMode}
            />
          </div>
        </div>

        <FormButtonGroup
          share={share}
          pageName="packaging"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default WeightAndPackagingComp;
