import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  GetRecycleBinParams,
  RestoreRecycleBinParams,
  UseRecycleBinResponseType,
  DeleteRecycleBinParams,
} from '@hooks/api/recycleBin/types';

const getRecycleBinList = async (params: GetRecycleBinParams) => {
  let rote = params?.modules;
  let type;
  if (params?.modules === 'Supplier_user') {
    rote = 'user';
    type = 'Supplier_user';
  } else if (params?.modules === 'Customer_user') {
    rote = 'user';
    type = 'Customer_user';
  }

  const res: AxiosResponse<UseRecycleBinResponseType> = await axios({
    method: 'get',
    url: `/${rote}/trash?user_type=${type}`,
    params: {
      page: params.page,
      q: params.q,
      from_deleted_at: params?.from_deleted_at,
      to_deleted_at: params?.to_deleted_at,
    },
  });
  return res.data;
};

const restoreRecycleBin = async (params: RestoreRecycleBinParams) => {
  let rote = params?.type;
  if (params?.type === 'Supplier_user') {
    rote = 'user';
  } else if (params?.type === 'Customer_user') {
    rote = 'user';
  }
  if (params.type === 'products') {
    const res: AxiosResponse = await axios({
      method: 'put',
      url: `/products/${params.code}/version/${params.version}/trash/restore`,
    });
    return res.data;
  }
  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/${rote}/${params.code}/trash/restore`,
  });

  return res.data;
};

const deleteRecycleBin = async (params: DeleteRecycleBinParams) => {
  let rote = params?.type;
  if (params?.type === 'Supplier_user') {
    rote = 'user';
  } else if (params?.type === 'Customer_user') {
    rote = 'user';
  }
  if (params.type === 'products') {
    const res: AxiosResponse = await axios({
      method: 'delete',
      url: `/products/${params.code}/version/${params.version}/soft`,
    });
    return res.data;
  }
  const res: AxiosResponse = await axios({
    method: 'delete',
    url: `/${rote}/${params.code}/soft`,
  });

  return res.data;
};

export default {
  getRecycleBinList,
  restoreRecycleBin,
  deleteRecycleBin,
};
