import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  productsHook as ProductHooks,
  suppliersHook as SupplierHooks,
} from '@hooks';

import {
  Modal,
  Input,
  AutoComplete,
  Switch,
  Button,
  AutoCompleteItem,
} from '@components/Common';

interface FormValues {
  productName: string;
  productCode: string;
  supplier: AutoCompleteItem;
  specification: 'New' | 'Existing';
  in_charge: 'Customer' | 'Supplier';
  selectedProduct: AutoCompleteItem;
}

type P = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(57),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const validationSchema = yup.object({
  productName: yup.string().required('Product Name is required'),
  productCode: yup.string().required('Product Code is required'),
  supplier: yup
    .mixed()
    .test(
      'supplier',
      'Supplier is required',
      val => val && val.name && val.code,
    ),
  selectedProduct: yup
    .mixed()
    .test('selectedProduct', 'Product is required', (val, context) => {
      if (context.parent.specification === 'New') return true;

      return val.name && val.code;
    }),
});

const NewProductModal: React.FC<P> = ({ open, setOpen }) => {
  const classes = useStyles();

  const { data: supplierData } = SupplierHooks.useSuppliers(undefined, {
    staleTime: 1000 * 60 * 60,
  });
  const { data: productData } = ProductHooks.useProducts({
    paginated: false,
  });
  const addProductMutation = ProductHooks.useAddProductMutation();

  const initialValues: FormValues = {
    productName: '',
    productCode: '',
    supplier: {
      name: '',
      code: '',
    },
    specification: 'New',
    in_charge: 'Customer',
    selectedProduct: {
      name: '',
      code: '',
    },
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">New Product</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async values => {
            try {
              if (values?.supplier?.code)
                await addProductMutation.mutateAsync({
                  name: values.productName,
                  item_code: values.productCode,
                  supplier_code: values.supplier.code.toString(),
                  in_charge: values.in_charge,
                  ...(values?.selectedProduct?.code && {
                    existing_code: values.selectedProduct.code.toString(),
                  }),
                });

              setOpen(false);
            } catch (error) {}
          }}
        >
          {formik => (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center p-4 min-w-full"
            >
              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Product Name</p>

                <div className="flex flex-col">
                  <Input
                    className="w-56"
                    value={formik.values.productName}
                    onChange={formik.handleChange}
                    id="productName"
                    name="productName"
                    placeholder="Product Name"
                  />

                  {formik.touched.productName && formik.errors.productName ? (
                    <p className="text-red mb-1">{formik.errors.productName}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Product Code</p>

                <div className="flex flex-col">
                  <Input
                    className="w-56"
                    value={formik.values.productCode}
                    onChange={formik.handleChange}
                    id="productCode"
                    name="productCode"
                    placeholder="Product Code"
                  />

                  {formik.touched.productCode && formik.errors.productCode ? (
                    <p className="text-red mb-1">{formik.errors.productCode}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-2">
                <p className="font-roboto">Supplier</p>

                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    id="supplier"
                    items={
                      supplierData?.data.map(el => ({
                        name: el.name,
                        code: el.supplier_code,
                      })) || []
                    }
                    value={formik.values.supplier}
                    onChange={newVal =>
                      formik.setFieldValue('supplier', newVal)
                    }
                    className="w-56"
                  />

                  {formik.touched.supplier && formik.errors.supplier ? (
                    <p className="text-red mb-1">{formik.errors.supplier}</p>
                  ) : null}
                </div>
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-4">
                <p className="font-roboto">Specification</p>

                <Switch
                  leftButtonText="New"
                  rightButtonText="Existing"
                  value={formik.values.specification}
                  onChange={value =>
                    formik.setFieldValue('specification', value)
                  }
                />
              </div>

              <div className="flex flex-row justify-between items-center min-w-full mb-4">
                <p className="font-roboto">Product Manager</p>

                <Switch
                  leftButtonText="Customer"
                  rightButtonText="Supplier"
                  value={formik.values.in_charge}
                  onChange={value => formik.setFieldValue('in_charge', value)}
                />
              </div>

              {formik.values.specification === 'Existing' ? (
                <div className="flex flex-row justify-between items-center min-w-full mb-4">
                  <p className="font-roboto">Product to select from</p>

                  <div className="flex flex-col">
                    <AutoComplete
                      freeSolo={false}
                      canAddCustomItem={false}
                      id="selectedProduct"
                      items={
                        productData?.data
                          ? productData.data.map(el => ({
                              name: `${el.name}-${el.item_code}`,
                              code: el.code,
                            }))
                          : []
                      }
                      value={formik.values.selectedProduct}
                      onChange={newVal =>
                        formik.setFieldValue('selectedProduct', newVal)
                      }
                      className="w-56"
                    />

                    {formik.touched.selectedProduct &&
                    formik.errors.selectedProduct ? (
                      <p className="text-red mb-1">
                        {formik.errors.selectedProduct}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="flex flex-row justify-end items-center min-w-full">
                <Button
                  type="submit"
                  className="text-green border-green"
                  variant="outlined"
                  startIcon={<Check />}
                  text="Done"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default NewProductModal;
