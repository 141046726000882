/* eslint-disable no-unneeded-ternary */
import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import {
  ChevronLeft,
  ChevronRight,
  Save,
  Add as AddIcon,
} from '@material-ui/icons';

import { map } from '@routes';

import { Button, ExportButton, RejectModal } from '@components/Common';
import SubmitModal from '@components/Bussines/Products/ProductForm/submitModal';

import WizardHooks from '@hooks/api/wizard';
import { useUrlParams, productsHook as ProductHooks } from '@hooks';

import { useUser } from '@contexts';

interface P {
  pageName?: string;
  share?: boolean;
  className?: string;
  onValidate?: () => void;
  onSave?: (link: string) => void;
}

const Menu: React.FC<P> = ({
  share,
  className,
  pageName,
  onValidate,
  onSave,
}) => {
  const history = useHistory();
  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);
  const [submitModalOpen, setSubmitModalOpen] = React.useState(false);
  const [user] = useUser();

  const [urlParams, , setUrlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  let FormSteps;

  if (share) {
    FormSteps = Object.values(map.share);
  } else {
    FormSteps = Object.values(map.bussines.productForm);
  }

  const currentIndex = FormSteps.findIndex(el =>
    el.includes(history.location.pathname),
  );

  const statusProductMutation = WizardHooks.useStatusProductMutation();
  const newVersionproduct = ProductHooks.useNewVersionProductMutation();

  const prevPageLink = FormSteps[currentIndex - 1]
    ? `${FormSteps[currentIndex - 1]}${history.location.search}`
    : '';
  const nextPageLink = FormSteps[currentIndex + 1]
    ? `${FormSteps[currentIndex + 1]}${history.location.search}`
    : '';

  const { data: productSpecTabs } = WizardHooks.useProductSpecTabs(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
    },
  );

  // const updateValidationsMutation = WizardHooks.useUpdateValidationsMutation();
  // const onSubmit = async () => {
  //   try {
  //     updateValidationsMutation.mutate({
  //       code: urlParams.code,
  //       version: urlParams.version,
  //     });

  //     // history.push(
  //     //   `${RoutesMap.bussines.productForm.validation}${history.location.search}`,
  //     // );
  //   } catch (e) {
  //     console.log('🚀 ~ file: Stepper.tsx ~ line 262 ~ onSubmit ~ e', e);
  //   }
  // };

  return (
    <div
      className={clsx(
        'flex flex-row items-end justify-between mt-12 mb-10',
        className,
      )}
    >
      <RejectModal
        header="Reject Message"
        open={rejectModalOpen}
        setOpen={setRejectModalOpen}
        submit={data =>
          statusProductMutation.mutate({
            code: urlParams.code,
            version: urlParams.version,
            status: 'pending_supplier',
            message: data.message,
          })
        }
      />

      <SubmitModal
        header="Acknowledge & Submit"
        open={submitModalOpen}
        setOpen={setSubmitModalOpen}
        warnings={productSpecTabs?.data.tabs_statuses}
        submit={() =>
          statusProductMutation.mutate(
            {
              code: urlParams.code,
              version: urlParams.version,
              status: 'submit',
            },
            {
              onSuccess: async () => {
                setUrlParams({ editMode: false });
              },
            },
          )
        }
      />
      <ExportButton pageName={pageName} />

      {productSpecTabs?.data.cardbox_status !== 'Archive' && (
        <div className="flex flex-col">
          {productSpecTabs?.data.cardbox_status === 'Approved' ? (
            <>
              {user?.access_levels.product_new_version && (
                <Button
                  variant="outlined"
                  size="small"
                  className="border-1 border-primary mb-2"
                  onClick={() => {
                    newVersionproduct.mutateAsync(
                      {
                        code: urlParams.code,
                        version: urlParams.version,
                      },
                      {
                        onSuccess: async data => {
                          setUrlParams({
                            code: data.data.code,
                            version: data.data.version,
                          });
                        },
                      },
                    );
                  }}
                  startIcon={<AddIcon />}
                  text="New Version"
                />
              )}
            </>
          ) : (
            <>
              {(productSpecTabs?.data.tabs_statuses[0].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[0].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[1].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[1].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[2].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[2].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[3].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[3].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[4].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[4].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[5].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[5].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[6].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[6].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[7].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[7].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[8].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[8].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[9].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[9].color === 'warning') &&
              (productSpecTabs?.data.tabs_statuses[10].color === 'success' ||
                productSpecTabs?.data.tabs_statuses[10].color === 'warning') ? (
                <>
                  {productSpecTabs?.data.cardbox_status ===
                  'Pending Approval' ? (
                    <>
                      {user?.access_levels.product_approve && (
                        <div className="flex flex-row mb-2">
                          <Button
                            className={clsx(
                              'bg-gray-600 text-red-dark mr-2 text-sm min-w-22 w-22',
                            )}
                            variant="text"
                            color="inherit"
                            onClick={() => {
                              setRejectModalOpen(true);
                            }}
                          >
                            Reject
                          </Button>

                          <Button
                            className={clsx(
                              'min-w-22 w-22 bg-green mr-2 text-sm items-center',
                            )}
                            onClick={() => {
                              statusProductMutation.mutate({
                                code: urlParams.code,
                                version: urlParams.version,
                                status: 'approve',
                              });
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {user?.access_levels.product_submit && (
                        <Button
                          className={clsx('min-w-50 w-50 bg-green mb-2')}
                          onClick={() => {
                            if (
                              productSpecTabs?.data.tabs_statuses[1].color ===
                                'warning' ||
                              productSpecTabs?.data.tabs_statuses[2].color ===
                                'warning' ||
                              productSpecTabs?.data.tabs_statuses[3].color ===
                                'warning' ||
                              productSpecTabs?.data.tabs_statuses[4].color ===
                                'warning'
                            ) {
                              setSubmitModalOpen(true);
                            } else {
                              statusProductMutation.mutate(
                                {
                                  code: urlParams.code,
                                  version: urlParams.version,
                                  status: 'submit',
                                },
                                {
                                  onSuccess: async () => {
                                    setUrlParams({ editMode: false });
                                  },
                                },
                              );
                            }
                          }}
                        >
                          Acknowledge & Submit
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {user?.access_levels.product_submit && (
                    <Button
                      disabled
                      className={clsx('min-w-50 w-50 mb-2')}
                      variant="outlined"
                      onClick={() => {
                        statusProductMutation.mutate({
                          code: urlParams.code,
                          version: urlParams.version,
                          status: 'submit',
                        });
                      }}
                    >
                      Acknowledge & Submit
                    </Button>
                  )}
                </>
              )}

              {urlParams.editMode && (
                <div className="flex flex-row mb-2">
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={clsx(
                      'min-w-22 w-22',
                      'border-green text-green mr-2 text-sm min-w-22 w-22',
                    )}
                    onClick={() => onValidate?.()}
                  >
                    Validate
                  </Button>
                </div>
              )}
            </>
          )}

          <div className="flex flex-row">
            {productSpecTabs?.data.cardbox_status === 'Approved' &&
            (productSpecTabs?.data.tabs_statuses[0].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[0].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[1].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[1].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[2].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[2].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[3].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[3].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[4].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[4].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[5].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[5].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[6].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[6].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[7].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[7].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[8].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[8].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[9].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[9].color === 'warning') &&
            (productSpecTabs?.data.tabs_statuses[10].color === 'success' ||
              productSpecTabs?.data.tabs_statuses[10].color === 'warning') ? (
              <>
                <Button
                  disabled={!prevPageLink}
                  className={clsx(
                    'min-w-22 w-22',
                    'mr-2 text-base font-normal font-poppins',
                  )}
                  variant="outlined"
                  startIcon={<ChevronLeft />}
                  href={prevPageLink}
                >
                  Back
                </Button>

                <Button
                  disabled={!nextPageLink}
                  className={clsx(
                    'min-w-22 w-22',
                    'text-base font-normal font-poppins',
                  )}
                  variant="outlined"
                  endIcon={<ChevronRight />}
                  href={nextPageLink}
                >
                  Next
                </Button>
              </>
            ) : (
              <>
                {urlParams.editMode ? (
                  <Button
                    className={clsx(
                      'min-w-22 w-22',
                      'text-base font-normal font-poppins',
                    )}
                    variant="outlined"
                    onClick={() => onSave && onSave(prevPageLink)}
                    startIcon={prevPageLink ? <ChevronLeft /> : ''}
                  >
                    {prevPageLink ? 'Back' : 'Save'}
                  </Button>
                ) : (
                  <Button
                    disabled={!prevPageLink}
                    className={clsx(
                      'min-w-22 w-22',
                      'mr-2 text-base font-normal font-poppins',
                    )}
                    variant="outlined"
                    startIcon={<ChevronLeft />}
                    href={nextPageLink}
                  >
                    Back
                  </Button>
                )}

                {urlParams.editMode ? (
                  <Button
                    className={clsx(
                      'min-w-22 w-22',
                      'text-base font-normal font-poppins',
                    )}
                    variant="outlined"
                    onClick={() => onSave && onSave(nextPageLink)}
                    endIcon={nextPageLink ? <ChevronRight /> : ''}
                  >
                    {nextPageLink ? 'Next' : 'Save'}
                  </Button>
                ) : (
                  <Button
                    href={nextPageLink}
                    className={clsx(
                      'min-w-22 w-22',
                      'text-base font-normal font-poppins',
                    )}
                    variant="outlined"
                    disabled={!nextPageLink}
                    endIcon={<ChevronRight />}
                  >
                    Next
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
