import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import supplierApis from '@hooks/api/suppliers/api';
import {
  UseSuppliersParams,
  UseDetailedSupplierParams,
  UseSuppliersResponseType,
  UseDetailedSupplierResponseType,
  GetFilteredSuppliersParams,
  UseFilteredSuppliersResponseType,
} from '@hooks/api/suppliers/types';

import errorHandler from '@utils/errorHandler';

const KEYS = {
  suppliers: ['suppliers'],
  detailedSuppliers: ['suppliers', 'details'],
  filteredSuppliers: ['suppliers', 'filter'],
} as const;

const useSuppliers = (
  params?: UseSuppliersParams,
  options?: UseQueryOptions<UseSuppliersResponseType, AxiosError>,
) => {
  const query = useQuery<UseSuppliersResponseType, AxiosError>(
    [...KEYS.suppliers, params],
    () => supplierApis.getSuppliers(params),
    {
      keepPreviousData: params?.paginated,
      ...options,
    },
  );

  return query;
};

const useDetailedSupplier = (
  params: UseDetailedSupplierParams,
  options?: UseQueryOptions<UseDetailedSupplierResponseType, AxiosError>,
) => {
  const query = useQuery<UseDetailedSupplierResponseType, AxiosError>(
    [...KEYS.detailedSuppliers, params.code],
    () => supplierApis.getDetailedSupplier(params),
    options,
  );

  return query;
};

const useFilteredSuppliers = (
  params?: GetFilteredSuppliersParams,
  options?: UseQueryOptions<UseFilteredSuppliersResponseType, AxiosError>,
) => {
  const query = useQuery<UseFilteredSuppliersResponseType, AxiosError>(
    [...KEYS.filteredSuppliers, params],
    () => supplierApis.getFilteredSuppliers(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );

  return query;
};

const useSaveSupplierMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(supplierApis.saveSupplier, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.suppliers);
    },
  });

  return mutation;
};

const useSubmitSupplierMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(supplierApis.submitSupplier, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.suppliers);
    },
  });

  return mutation;
};

const useApproveOrRejectSupplierMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(supplierApis.approveOrRejectSupplier, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.suppliers);
    },
  });

  return mutation;
};

const useUpdateSupplierMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(supplierApis.updateSupplier, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.suppliers);
    },
  });

  return mutation;
};

const useDeleteSupplierMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(supplierApis.deleteSupplier, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.suppliers);
    },
  });

  return mutation;
};

export default {
  useSuppliers,
  useDetailedSupplier,
  useFilteredSuppliers,
  useSaveSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useSubmitSupplierMutation,
  useApproveOrRejectSupplierMutation,
};
