import { InputBase, IconButton, InputBaseProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

interface P {
  className?: string;
  onSearch: (arg: string) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(12),
  },
  input: {
    color: theme.palette.primary.main,
    boxSizing: 'border-box',
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    fontSize: 'inherit',
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,

    '&::placeholder': {
      color: theme.palette.gray.main,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

const Searchbox: React.FC<P & Omit<InputBaseProps, keyof P>> = ({
  className,
  onSearch,
  value,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <InputBase
      classes={{
        root: classes.root,
        input: classes.input,
      }}
      className={className}
      placeholder="Search..."
      fullWidth
      id="standard-search"
      type="search"
      endAdornment={
        <IconButton onClick={() => onSearch(value as string)}>
          <Search className="text-gray" />
        </IconButton>
      }
      {...rest}
    />
  );
};

export default Searchbox;
