/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Cell, Row } from 'react-table';

import {
  useForm,
  Controller,
  UnpackNestedValue,
  FieldPath,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Add, Edit, Close } from '@material-ui/icons';

import { phoneRegex, isApproved, isPendigForApproval } from '@utils';
import RouteMap from '@routes/map';
import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  AutoComplete,
  AutoCompleteItem,
  FooterButtonGroup,
  Table,
  CountryAutoComplete,
  DeletePromptModal,
  RejectModal,
} from '@components/Common';

import Menu, {
  MenuItemTypes,
} from '@components/Suppliers/Manufacturers/ManufacturersList/Menu';

import { useUrlParams, stringify } from '@hooks/utils';

import CertificateHooks from '@hooks/api/certificates';
import { Certificate } from '@hooks/api/certificates/types';

type P = {
  supplier_code?: string;
  supplier_name?: string;
};

const SuppliersInfoComp: React.FC<P> = ({ supplier_code, supplier_name }) => {
  const history = useHistory();

  const [urlParams] = useUrlParams<{ editMode?: boolean }>();
  const routeParams = useRouteMatch<{ supplierCode?: string }>();
  const [user] = useUser();

  const [selectedCertificateCode, setSelectedCertificateCode] =
    React.useState('');
  const [
    openCertificateTableActionMenuId,
    setOpenCertificateTableActionMenuId,
  ] = React.useState('');

  const [certificatesTableCurrentPage, setCertificatesTableCurrentPage] =
    React.useState(1);

  const { data: certificatesData, ...useCertificatesUtils } =
    CertificateHooks.useCertificates(
      {
        page: certificatesTableCurrentPage,
        supplier_code: supplier_code || '',
      },
      {
        enabled: !!supplier_code,
      },
    );

  const deleteCertificateMutation =
    CertificateHooks.useDeleteCertificateMutation();

  const onCertificateTableMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<Certificate>) => {
      if (actionType === 'View') {
        history.push(
          `${RouteMap.certificates.certificateInfo.replace(
            ':certificateCode?',
            row.original.certificate_code,
          )}`,
        );
      }

      if (actionType === 'Delete') {
        setSelectedCertificateCode(row.original.certificate_code);
      }
    },
    [],
  );

  const certificatesTableColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<Certificate> | 'action';
      id: FieldPath<Certificate> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<Certificate>) => JSX.Element);
    }> => [
      {
        Header: 'Certificate Type',
        accessor: 'certificate_type.name',
        id: 'certificate_type.name',
        width: 210,
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacture.manufacture_name',
        id: 'manufacture.manufacture_name',
        width: 260,
      },
      {
        Header: 'Status',
        accessor: 'cardbox_status',
        id: 'cardbox_status',
        width: 120,
      },
      {
        Header: 'Supplier',
        accessor: 'supplier.supplier_name',
        id: 'supplier.supplier_name',
        width: 300,
      },
      {
        Header: 'Expiry Date',
        accessor: 'expiry_date',
        id: 'expiry_date',
        width: 130,
      },
      {
        width: 30,
        Header: 'Action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={
              user?.access_levels.certificate_trash_restore
                ? ['View', 'Delete']
                : ['View']
            }
            open={openCertificateTableActionMenuId === cell.row.id}
            onTriggerClick={() =>
              setOpenCertificateTableActionMenuId(cell.row.id)
            }
            onClose={() => setOpenCertificateTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onCertificateTableMenuItemClick(actionType, cell.row);

              setOpenCertificateTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [
      useCertificatesUtils.dataUpdatedAt,
      openCertificateTableActionMenuId,
      onCertificateTableMenuItemClick,
    ],
  );

  return (
    <>
      <DeletePromptModal
        open={!!selectedCertificateCode}
        setOpen={() => setSelectedCertificateCode('')}
        onConfirm={async () => {
          try {
            await deleteCertificateMutation.mutateAsync({
              certificateCode: selectedCertificateCode,
            });

            setSelectedCertificateCode('');
          } catch (error) {}
        }}
      />

      <div className="flex flex-col mt-8">
        <div className="flex flex-row items-center justify-between mb-3">
          <h3 className="font-roboto text-base text-primary-light">
            Certificates
          </h3>
          {user?.access_levels.certificate_create_update && (
            <Button
              disabled={
                !urlParams.editMode || !routeParams?.params?.supplierCode
              }
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() =>
                history.push(
                  `${RouteMap.certificates.certificateInfo.replace(
                    ':certificateCode?',
                    '',
                  )}?${stringify('', {
                    editMode: true,
                    supplier_name,
                    supplier_code,
                  })}`,
                )
              }
              startIcon={<Add />}
              text="Add New"
            />
          )}
        </div>

        <Table
          loading={useCertificatesUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={certificatesTableColumns}
          data={certificatesData?.data || []}
          getRowProps={row => ({
            onClick: () =>
              history.push(
                `${RouteMap.certificates.certificateInfo.replace(
                  ':certificateCode?',
                  row.original.certificate_code,
                )}`,
              ),
          })}
          paginationProps={{
            page: certificatesTableCurrentPage,
            onPageChange: setCertificatesTableCurrentPage,
            pageCount: certificatesData?.meta?.last_page,
          }}
        />
      </div>
    </>
  );
};

export default SuppliersInfoComp;
