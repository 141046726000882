import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import WizardApis from '@hooks/api/wizard/api';
import {
  WizardApiBaseParams,
  UpdateMainInfoParams,
  UseProductMainInfo,
  UseProductIngredients,
  UseENumbersType,
  UseCustomDeclaration,
  UpdateIngredientsParams,
  UseProductIntegrity,
  UpdateIntegrityParams,
  UseProductAllergens,
  UseProductCCP,
  UseProductNutrition,
  UseProductStandards,
  UseProductAttachments,
  UseProductWeight,
  UseProductStorage,
  UseProductQas,
  UseAllergenListType,
  UseProductValidations,
  UseProductSpecTabs,
} from '@hooks/api/wizard/types';

import errorHandler from '@utils/errorHandler';

const KEYS = {
  mainInfo: ['wizard', 'main-info'],
  customDeclaration: ['wizard', 'ingredients', 'customDeclaration'],
  ingredients: ['wizard', 'ingredients'],
  integrity: ['wizard', 'integrity'],
  allergens: ['wizard', 'allergens'],
  ccp: ['wizard', 'ccp'],
  weight: ['wizard', 'weight'],
  standards: ['wizard', 'standards'],
  nutrition: ['wizard', 'nutrition'],
  storage: ['wizard', 'storage'],
  qas: ['wizard', 'qas'],
  attachments: ['wizard', 'attachments'],
  validations: ['wizard', 'validations'],
  tabs: ['wizard', 'tabs'],
} as const;

const useProductMainInfo = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductMainInfo, AxiosError>,
) => {
  const query = useQuery<UseProductMainInfo, AxiosError>(
    [...KEYS.mainInfo, params],
    () => WizardApis.getMainInfo(params),
    options,
  );

  return query;
};

const useUpdateMainInfoMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateMainInfo, {
    onMutate: async (variables: UpdateMainInfoParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.mainInfo);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateMainInfoMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateMainInfo, {
    onMutate: async (variables: UpdateMainInfoParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.mainInfo);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useENumbers = (
  options?: UseQueryOptions<UseENumbersType, AxiosError>,
) => {
  const query = useQuery<UseENumbersType, AxiosError>(
    ['ENumber'],
    () => WizardApis.getENumbers(),
    { cacheTime: Infinity, staleTime: Infinity, ...options },
  );
  return query;
};

const useAllergenList = (
  options?: UseQueryOptions<UseAllergenListType, AxiosError>,
) => {
  const query = useQuery<UseAllergenListType, AxiosError>(
    ['AllergenList'],
    () => WizardApis.getAllergenList(),
    { cacheTime: Infinity, staleTime: Infinity, ...options },
  );
  return query;
};

const useCustomDeclaration = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseCustomDeclaration, AxiosError>,
) => {
  const query = useQuery<UseCustomDeclaration, AxiosError>(
    [...KEYS.customDeclaration, params],
    () => WizardApis.getCustomDeclaration(params),
    options,
  );
  return query;
};

const useProductIngredients = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductIngredients, AxiosError>,
) => {
  const query = useQuery<UseProductIngredients, AxiosError>(
    [...KEYS.ingredients, params],
    () => WizardApis.getIngredients(params),
    options,
  );

  return query;
};

const useUpdateIngredientsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateIngredients, {
    onMutate: async (variables: UpdateIngredientsParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.ingredients);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateIngredientsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateIngredients, {
    onMutate: async (variables: UpdateIngredientsParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.ingredients);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useIntegrity = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductIntegrity, AxiosError>,
) => {
  const query = useQuery<UseProductIntegrity, AxiosError>(
    [...KEYS.integrity, params],
    () => WizardApis.getIntegrity(params),
    options,
  );
  return query;
};

const useUpdateIntegrityMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateIntegrity, {
    onMutate: async (variables: UpdateIntegrityParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.integrity);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateIntegrityMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateIntegrity, {
    onMutate: async (variables: UpdateIntegrityParams) => {
      return { variables };
    },
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.integrity);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useAllergens = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductAllergens, AxiosError>,
) => {
  const query = useQuery<UseProductAllergens, AxiosError>(
    [...KEYS.allergens, params],
    () => WizardApis.getAllergens(params),
    options,
  );

  return query;
};

const useUpdateAllergensMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateAllergens, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.allergens);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateAllergensMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateAllergens, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.allergens);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useCCP = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductCCP, AxiosError>,
) => {
  const query = useQuery<UseProductCCP, AxiosError>(
    [...KEYS.ccp, params],
    () => WizardApis.getCCP(params),
    options,
  );

  return query;
};

const useUpdateCCPMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateCCP, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.ccp);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateCCPMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateCCP, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.ccp);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useAttachments = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductAttachments, AxiosError>,
) => {
  const query = useQuery<UseProductAttachments, AxiosError>(
    [...KEYS.attachments, params],
    () => WizardApis.getAttachments(params),
    options,
  );

  return query;
};

const useUpdateAttachmentsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateAttachments, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.attachments);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateAttachmentsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateAttachments, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.attachments);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useNutrition = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductNutrition, AxiosError>,
) => {
  const query = useQuery<UseProductNutrition, AxiosError>(
    [...KEYS.nutrition, params],
    () => WizardApis.getNutrition(params),
    options,
  );

  return query;
};

const useUpdateNutritionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateNutrition, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.nutrition);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateNutritionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateNutrition, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.nutrition);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useStandards = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductStandards, AxiosError>,
) => {
  const query = useQuery<UseProductStandards, AxiosError>(
    [...KEYS.standards, params],
    () => WizardApis.getStandards(params),
    options,
  );

  return query;
};

const useUpdateStandardsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateStandards, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.standards);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateStandardsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateStandards, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.standards);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useWeight = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductWeight, AxiosError>,
) => {
  const query = useQuery<UseProductWeight, AxiosError>(
    [...KEYS.weight, params],
    () => WizardApis.getWeight(params),
    options,
  );

  return query;
};

const useUpdateWeightMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateWeight, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.weight);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateWeightMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateWeight, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.weight);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useStorage = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductStorage, AxiosError>,
) => {
  const query = useQuery<UseProductStorage, AxiosError>(
    [...KEYS.storage, params],
    () => WizardApis.getStorage(params),
    options,
  );

  return query;
};

const useSaltTargets = () => {
  const query = useQuery('saltTargets', WizardApis.getSaltTargets);

  return query;
};

const useUpdateStorageMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateStorage, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.storage);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateStorageMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateStorage, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.storage);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useQas = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductQas, AxiosError>,
) => {
  const query = useQuery<UseProductQas, AxiosError>(
    [...KEYS.qas, params],
    () => WizardApis.getQas(params),
    options,
  );

  return query;
};

const useUpdateQasMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateQas, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.qas);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidateQasMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.validateQas, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.qas);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useValidations = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductValidations, AxiosError>,
) => {
  const query = useQuery<UseProductValidations, AxiosError>(
    [...KEYS.validations, params],
    () => WizardApis.getValidations(params),
    options,
  );

  return query;
};

const useUpdateValidationsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.updateValidations, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.validations);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

const useProductSpecTabs = (
  params: WizardApiBaseParams,
  options?: UseQueryOptions<UseProductSpecTabs, AxiosError>,
) => {
  const query = useQuery<UseProductSpecTabs, AxiosError>(
    [...KEYS.tabs, params],
    () => WizardApis.getProductSpecTabs(params),
    options,
  );

  return query;
};

const useStatusProductMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(WizardApis.statusProduct, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
      queryClient.invalidateQueries(KEYS.tabs);
    },
  });

  return mutation;
};

export default {
  useProductMainInfo,
  useUpdateMainInfoMutation,
  useValidateMainInfoMutation,
  useENumbers,
  useAllergenList,
  useCustomDeclaration,
  useProductIngredients,
  useUpdateIngredientsMutation,
  useValidateIngredientsMutation,
  useIntegrity,
  useUpdateIntegrityMutation,
  useAllergens,
  useUpdateAllergensMutation,
  useNutrition,
  useUpdateNutritionMutation,
  useStandards,
  useUpdateStandardsMutation,
  useAttachments,
  useUpdateAttachmentsMutation,
  useCCP,
  useUpdateCCPMutation,
  useWeight,
  useUpdateWeightMutation,
  useStorage,
  useUpdateStorageMutation,
  useSaltTargets,
  useQas,
  useUpdateQasMutation,
  useValidations,
  useUpdateValidationsMutation,
  useProductSpecTabs,
  useValidateIntegrityMutation,
  useValidateAllergensMutation,
  useValidateCCPMutation,
  useValidateAttachmentsMutation,
  useValidateNutritionMutation,
  useValidateStandardsMutation,
  useValidateWeightMutation,
  useValidateStorageMutation,
  useValidateQasMutation,
  useStatusProductMutation,
};
