/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
import React from 'react';
import { FieldPath } from 'react-hook-form';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
  AutoComplete,
} from '@components/Common';

import Menu, {
  MenuItemTypes,
} from '@components/Configurations/RecycleBin/Menu';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Configurations/RecycleBin/AttributeFiltersModal';

import RecycleBinHooks from '@hooks/api/recycleBin';

import { GetRecycleBinParams, recycleBin } from '@hooks/api/recycleBin/types';

import { usePrevious, useDebounce } from '@hooks/utils';

import { formatDate } from '@utils';
import { useUser } from '@contexts';

import { Link } from '@material-ui/core';

const selectedRecycleBinInitial = {
  code: '',
  type: '',
  version: '',
};

const RecycleBin = () => {
  const userTypes = [];

  const [user] = useUser();

  if (user?.access_levels.certificate_trash_restore) {
    userTypes.push({ name: 'Certificates', code: 'certificates' });
  }
  if (user?.access_levels.policy_trash_restore) {
    userTypes.push({ name: 'Policies', code: 'policies' });
  }
  if (user?.access_levels.product_trash_restore) {
    userTypes.push({ name: 'Products', code: 'products' });
  }
  if (user?.access_levels.user_trash_restore_customer) {
    userTypes.push({ name: 'Customer User', code: 'Customer_user' });
  }
  if (user?.access_levels.user_trash_restore_supplier) {
    userTypes.push({ name: 'Supplier User', code: 'Supplier_user' });
  }

  const history = useHistory();

  const [selectedRecycleBin, setSelectedRecycleBin] = React.useState<{
    code: string | number;
    type: string;
    version?: string;
  }>(selectedRecycleBinInitial);

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [userFilters, setUserFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [selectedUserType, setSelectedUserType] = React.useState<null | {
    name: string;
    code: string | number;
  }>(userTypes[0]);

  const formatFilterAttributes = (
    filterValues: FilterAttributesValues,
  ): GetRecycleBinParams => {
    const formattedValues = {
      ...(filterValues?.deleted_at?.from && {
        from_deleted_at: formatDate(filterValues.deleted_at.from),
      }),

      ...(filterValues?.deleted_at?.to && {
        to_deleted_at: formatDate(filterValues.deleted_at.to),
      }),

      modules: selectedUserType?.code || 'certifigates',
    };

    return formattedValues;
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  const [selectedUserUsername, setSelectedUserUsername] = React.useState('');
  const restoreRecycleBin = RecycleBinHooks.useRestoreRecycleBinMutations();
  const deleteRecycleBin = RecycleBinHooks.useDeleteRecycleBinMutations();

  const restore = React.useCallback(async (row: Row<recycleBin>) => {
    if (row.original.type === 'products') {
      await restoreRecycleBin.mutateAsync({
        code: row.original.code,
        version: row.original.version,
        type: row.original.type,
      });
    } else {
      await restoreRecycleBin.mutateAsync({
        code: row.original.code,
        type: row.original.type,
      });
    }
  }, []);

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<recycleBin>) => {
      if (actionType === 'Restore') {
        restore(row);
      }

      if (actionType === 'Delete') {
        setSelectedRecycleBin({
          code: row.original.code,
          type: row.original.type,
          version: row.original.version,
        });
      }
    },
    [],
  );

  const { data: usersList, ...useUsersListUtils } =
    RecycleBinHooks.useRecycleBinList({
      page: currentPage,
      q: debouncedSearch,
      modules: String(selectedUserType?.code),
      ...(userFilters ? formatFilterAttributes(userFilters) : {}),
    });

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<recycleBin> | 'action';
      id: FieldPath<recycleBin> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<recycleBin>) => JSX.Element);
    }> => [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        width: 320,
      },
      {
        Header: 'Deleted By',
        accessor: 'deleted_by',
        id: 'deleted_by',
        width: 220,
      },
      {
        Header: 'Deleted At',
        accessor: 'deleted_at',
        id: 'deleted_at',
        width: 280,
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <>
            {cell.row.original.type === 'Supplier_user' && (
              <Menu
                menuItems={
                  user?.access_levels.user_soft_delete_supplier
                    ? ['Restore', 'Delete']
                    : ['Restore']
                }
                open={openTableActionMenuId === cell.row.id}
                onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
                onClose={() => setOpenTableActionMenuId('')}
                onMenuItemClick={async actionType => {
                  await onMenuItemClick(actionType, cell.row);

                  setOpenTableActionMenuId('');
                }}
              />
            )}
            {cell.row.original.type === 'Customer_user' && (
              <Menu
                menuItems={
                  user?.access_levels.user_soft_delete_customer
                    ? ['Restore', 'Delete']
                    : ['Restore']
                }
                open={openTableActionMenuId === cell.row.id}
                onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
                onClose={() => setOpenTableActionMenuId('')}
                onMenuItemClick={async actionType => {
                  await onMenuItemClick(actionType, cell.row);

                  setOpenTableActionMenuId('');
                }}
              />
            )}
            {cell.row.original.type === 'products' && (
              <Menu
                menuItems={
                  user?.access_levels.product_soft_delete
                    ? ['Restore', 'Delete']
                    : ['Restore']
                }
                open={openTableActionMenuId === cell.row.id}
                onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
                onClose={() => setOpenTableActionMenuId('')}
                onMenuItemClick={async actionType => {
                  await onMenuItemClick(actionType, cell.row);

                  setOpenTableActionMenuId('');
                }}
              />
            )}
            {cell.row.original.type === 'certificates' && (
              <Menu
                menuItems={
                  user?.access_levels.certificate_soft_delete
                    ? ['Restore', 'Delete']
                    : ['Restore']
                }
                open={openTableActionMenuId === cell.row.id}
                onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
                onClose={() => setOpenTableActionMenuId('')}
                onMenuItemClick={async actionType => {
                  await onMenuItemClick(actionType, cell.row);

                  setOpenTableActionMenuId('');
                }}
              />
            )}
            {cell.row.original.type === 'policies' && (
              <Menu
                menuItems={
                  user?.access_levels.policy_soft_delete
                    ? ['Restore', 'Delete']
                    : ['Restore']
                }
                open={openTableActionMenuId === cell.row.id}
                onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
                onClose={() => setOpenTableActionMenuId('')}
                onMenuItemClick={async actionType => {
                  await onMenuItemClick(actionType, cell.row);
                  setOpenTableActionMenuId('');
                }}
              />
            )}
          </>
        ),
      },
    ],
    [openTableActionMenuId, useUsersListUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={!!selectedRecycleBin.code}
        setOpen={() => setSelectedRecycleBin(selectedRecycleBinInitial)}
        onConfirm={async () => {
          try {
            await deleteRecycleBin.mutateAsync({
              code: selectedRecycleBin.code,
              type: selectedRecycleBin.type,
              version: selectedRecycleBin.version,
            });
          } catch (error) {
          } finally {
            setSelectedRecycleBin(selectedRecycleBinInitial);
          }
        }}
      />
      <AttributeFiltersModal
        values={userFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setUserFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Configurations' },
            { href: '#', text: 'Users' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {userFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setUserFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <AutoComplete
            disableClearable
            className="w-80"
            items={userTypes}
            onChange={setSelectedUserType}
            value={selectedUserType}
          />
        </div>

        <Table
          loading={useUsersListUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto last-item-center"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={usersList?.data || []}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: usersList?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default RecycleBin;
