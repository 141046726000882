/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Cell, Row } from 'react-table';

import { Add } from '@material-ui/icons';

import { phoneRegex, isApproved, isPendigForApproval } from '@utils';
import RouteMap from '@routes/map';
import { useUser } from '@contexts';

import { Button, Table, DeletePromptModal } from '@components/Common';

import Menu, {
  MenuItemTypes,
} from '@components/Suppliers/Manufacturers/ManufacturersList/Menu';

import { useUrlParams, stringify } from '@hooks/utils';

import ManufacturerHooks from '@hooks/api/manufacturers';
import { DetailedManufacturer } from '@hooks/api/manufacturers/types';
import { CardBoxStatus } from '@app/hooks/api/generalTypes';

type P = {
  supplier_code?: string;
  supplier_name?: string;
  cardbox_status?: CardBoxStatus | null;
};

const SuppliersInfoComp: React.FC<P> = ({
  supplier_code,
  supplier_name,
  cardbox_status,
}) => {
  const history = useHistory();

  const [urlParams] = useUrlParams<{ editMode?: boolean }>();
  const routeParams = useRouteMatch<{ supplierCode?: string }>();
  const [user] = useUser();

  const [selectedManufacturerCode, setSelectedManufacturerCode] =
    React.useState('');

  const [
    openManufacturerTableActionMenuId,
    setOpenManufacturerTableActionMenuId,
  ] = React.useState('');

  const [manufacturersTableCurrentPage, setManufacturersTableCurrentPage] =
    React.useState(1);

  const { data: manufacturersList, ...useFilteredManufacturersUtils } =
    ManufacturerHooks.useFilteredManufacturers(
      {
        page: manufacturersTableCurrentPage,
        supplier_code: supplier_code || '',
      },
      {
        enabled: !!supplier_code,
      },
    );

  const deleteManufacturerMutation =
    ManufacturerHooks.useDeleteManufacturerMutation();

  const onManufacturerTableMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<DetailedManufacturer>) => {
      if (actionType === 'View') {
        history.push(
          `${RouteMap.suppliers.manufacturerInfo.replace(
            ':manufacturerCode?',
            row.original.manufacture_code,
          )}`,
        );
      }

      if (actionType === 'Delete') {
        setSelectedManufacturerCode(row.original.manufacture_code);
      }
    },
    [],
  );

  const manufacturersTableColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof DetailedManufacturer | 'action';
      id: keyof DetailedManufacturer | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<DetailedManufacturer>) => JSX.Element);
    }> => [
      {
        Header: 'Site Name',
        accessor: 'site_name',
        id: 'site_name',
        width: 590,
      },
      {
        Header: 'Status',
        accessor: 'cardbox_status',
        id: 'cardbox_status',
        width: 200,
      },
      {
        Header: 'Approval Date',
        accessor: 'approval_date',
        id: 'approval_date',
        width: 200,
      },
      {
        width: 30,
        Header: 'Action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={
              user?.access_levels.manufacture_soft_delete
                ? ['View', 'Delete']
                : ['View']
            }
            open={openManufacturerTableActionMenuId === cell.row.id}
            onTriggerClick={() =>
              setOpenManufacturerTableActionMenuId(cell.row.id)
            }
            onClose={() => setOpenManufacturerTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onManufacturerTableMenuItemClick(actionType, cell.row);

              setOpenManufacturerTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [
      useFilteredManufacturersUtils.dataUpdatedAt,
      openManufacturerTableActionMenuId,
      onManufacturerTableMenuItemClick,
    ],
  );

  const isStatusApproved = isApproved(cardbox_status);

  return (
    <>
      <DeletePromptModal
        open={!!selectedManufacturerCode}
        setOpen={() => setSelectedManufacturerCode('')}
        onConfirm={async () => {
          try {
            await deleteManufacturerMutation.mutateAsync({
              manufacture_code: selectedManufacturerCode,
            });
          } catch (error) {
          } finally {
            setSelectedManufacturerCode('');
          }
        }}
      />

      {user?.access_levels.manufacture_index_single && (
        <div className="flex flex-col mt-8">
          <div className="flex flex-row items-center justify-between mb-3">
            <h3 className="font-roboto text-base text-primary-light">
              Manufacturer
            </h3>

            {user?.access_levels.manufacture_create_update && (
              <Button
                disabled={
                  !urlParams.editMode ||
                  !routeParams?.params?.supplierCode ||
                  !isStatusApproved
                }
                variant="outlined"
                className="border-1 border-primary ml-2"
                onClick={() =>
                  history.push(
                    `${RouteMap.suppliers.manufacturerInfo.replace(
                      ':manufacturerCode?',
                      '',
                    )}?${stringify('', {
                      editMode: true,
                      supplier_name,
                      supplier_code,
                    })}`,
                  )
                }
                startIcon={<Add />}
                text="Add New"
              />
            )}
          </div>

          <Table
            loading={useFilteredManufacturersUtils.isLoading}
            className="mt-2"
            bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
            headerCellClassName="text-primary-light font-normal font-roboto"
            bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
            columns={manufacturersTableColumns}
            data={manufacturersList?.data || []}
            getRowProps={row => ({
              onClick: () =>
                history.push(
                  `${RouteMap.suppliers.manufacturerInfo.replace(
                    ':manufacturerCode?',
                    row.original.manufacture_code,
                  )}`,
                ),
            })}
            paginationProps={{
              page: manufacturersTableCurrentPage,
              onPageChange: setManufacturersTableCurrentPage,
              pageCount: manufacturersList?.meta?.last_page,
            }}
          />
        </div>
      )}
    </>
  );
};

export default SuppliersInfoComp;
