/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button as MuiButton, IconButton } from '@material-ui/core';
import { Add, Loop, Delete, Create, Visibility } from '@material-ui/icons';

interface Props {
  id: string;
  text?: string;
  wrapperClassName?: string;
  className?: string;
  textClassName?: string;
  imgClassName?: string;
  onChange: (arg: File | string) => void;
  value: string | File;
  showPreview?: boolean;
  showAddIcon?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  button: {
    width: theme.spacing(13),
    height: theme.spacing(3),

    '& .MuiButton-label': {
      width: theme.spacing(13),
      height: theme.spacing(3),
    },
  },
  activated: {
    border: '1px solid',
    borderColor: `${theme.palette.gray.main} !important`,
  },
  icon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    padding: 0,

    '& svg': {
      fontSize: 'inherit',
    },
  },
}));

const getFileName = (file: Props['value']) => {
  if (typeof file === 'string') return file.split('/').slice(-1)[0];

  return file.name;
};

const isLink = (file: Props['value']) => {
  if (typeof file !== 'string') return false;

  const regex = new RegExp(
    /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi,
  );

  return regex.test(file);
};

const UploadButton: React.FC<Props> = ({
  disabled,
  id,
  value,
  onChange,
  text = 'Choose File',
  wrapperClassName,
  className,
  textClassName,
  imgClassName,
  showPreview = false,
  showAddIcon = false,
}) => {
  const [preview, setPreview] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const originalVal = React.useRef<Props['value'] | null>(value);

  const classes = useStyles({ preview });

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e?.target?.files?.[0];

    if (!selectedFile) return;

    if (
      selectedFile?.type !== 'application/pdf' &&
      !selectedFile?.type.includes('image/')
    ) {
      // only images and pdf files are accepted
      toast.error('only image and pdf document are allowed');

      return;
    }

    const reader = new FileReader();

    reader.addEventListener('loadstart', () => setLoading(true));
    reader.addEventListener('loadend', () => setLoading(false));

    reader.onload = res => {
      setPreview(res?.target?.result as string);
      onChange(selectedFile);
    };

    reader.readAsDataURL(selectedFile as Blob); // convert to base64 string
  };

  const onViewClick = () => {
    const url =
      typeof value === 'string' && isLink(value)
        ? value
        : window.URL.createObjectURL(new Blob([value]));

    const fileName = getFileName(value);

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  };

  const PreviewBtnComp = () => {
    if (value) {
      return (
        <>
          <IconButton
            disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              // this should reset preview if upload has been done preveiously
              // and it should fall back on origianl value coming from API
              // if preview hasn't been set (value === original value) then the original value will
              // be cleared
              if (preview && originalVal.current) {
                setPreview('');
                onChange(originalVal.current);
              } else {
                onChange('');
                originalVal.current = null;
              }
            }}
          >
            <Delete />
          </IconButton>

          <IconButton
            disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={() => {
              if (inputRef?.current) {
                inputRef.current.click();
              }
            }}
          >
            <Create />
          </IconButton>

          <IconButton
            disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={() => onViewClick()}
          >
            <Visibility />
          </IconButton>
        </>
      );
    }

    if (loading) {
      return <Loop />;
    }

    if (showAddIcon) {
      return (
        <div className="flex flex-row items-center justify-between">
          <Add className="text-lg mr-1" />

          <p className={textClassName}>{text}</p>
        </div>
      );
    }

    return <p>{text || ''}</p>;
  };

  return (
    <div className={clsx(classes.root, wrapperClassName)}>
      <input
        disabled={disabled}
        ref={inputRef}
        accept="image/*,application/pdf"
        className={classes.input}
        id={id}
        type="file"
        onChange={onFileSelect}
      />

      {showPreview && preview ? (
        <img className={imgClassName} src={preview} alt="preview" />
      ) : (
        <label htmlFor={id}>
          <MuiButton
            disabled={disabled}
            disableRipple
            className={clsx(
              'px-0',
              classes.button,
              preview && classes.activated,
              className,
            )}
            classes={{
              label: 'flex flex-row items-center justify-evenly',
            }}
            variant="outlined"
            color="primary"
            component="span"
          >
            <PreviewBtnComp />
          </MuiButton>
        </label>
      )}
    </div>
  );
};

export default UploadButton;
