import React from 'react';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import { Modal, Button, AutoComplete, DatePicker } from '@components/Common';

import GeneralHooks from '@hooks/api/general';
import { Status } from '@hooks/api/generalTypes';

export interface FilterAttributesValues {
  deleted_at: {
    from: Date | null;
    to: Date | null;
  };
}

type P = {
  values: FilterAttributesValues | null;
  open: boolean;
  setOpen: (value: boolean) => void;
  onFormSubmit: (args: FilterAttributesValues) => void;
};

const AttributeFilterModal: React.FC<P> = ({
  values,
  open,
  setOpen,
  onFormSubmit,
}) => {
  const form = useForm<FilterAttributesValues>({
    defaultValues: {
      deleted_at: {
        from: null,
        to: null,
      },
    },
  });

  React.useEffect(() => {
    form.reset({
      deleted_at: {
        from: values?.deleted_at.from ?? null,
        to: values?.deleted_at.to ?? null,
      },
    });
  }, [JSON.stringify(values)]);

  const onSubmit: SubmitHandler<FilterAttributesValues> = data => {
    onFormSubmit(data);
  };

  const { data: allStatuses } = GeneralHooks.useAllStatus();

  const isAtLeastOneFieldCompleted = (obj: FilterAttributesValues) => {
    const formValues = [obj.deleted_at];

    return !!formValues.find(el => el !== null);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center',
          'bg-white-600 shadow-lg min-w-132',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'h-10 pl-4 min-w-full bg-white-400',
          )}
        >
          <h3 className="font-roboto text-base">Attribute Filters</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Deleted At</p>

            <div className="flex flex-row items-center">
              <Controller
                name="deleted_at.from"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="from"
                      className="w-32"
                      wrapperClassName="mr-2"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />

              <Controller
                name="deleted_at.to"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="to"
                      className="w-32"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-end items-center min-w-full">
            {isAtLeastOneFieldCompleted(form.watch()) ? (
              <Button
                type="submit"
                onClick={form.handleSubmit(onSubmit)}
                className="text-green border-green"
                variant="outlined"
                startIcon={<Check />}
                text="Done"
              />
            ) : null}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AttributeFilterModal;
