/* eslint-disable object-shorthand */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  Stepper as MuiStepper,
  StepperProps,
  Step,
  StepLabel,
  StepConnector,
  IconButton,
} from '@material-ui/core';

import { Button } from '@components/Common';

import { Edit, Close } from '@material-ui/icons';

import { useUrlParams } from '@hooks';

import WizardHooks from '@hooks/api/wizard';
import { ProductSpecTabsInstance } from '@hooks/api/wizard/types';

import BaseIcon from '@assets/images/icons/stepper-base.svg';
import SuccessIcon from '@assets/images/icons/stepper-success.svg';
import WarningIcon from '@assets/images/icons/stepper-warning.svg';
import ErrorIcon from '@assets/images/icons/stepper-error.svg';

import { map as RouteMap, ProductFormKeys } from '@routes';
import user from '@app/hooks/api/user';
import { useUser } from '@app/contexts';

const ICON_DIMENSIONS = 24;
const STEP_LINE_HEIGHT = 3;
const STEP_ITEM_WIDTH = 120;
const STEPPER_HEIGHT = 100;
const TEXT_OFFSET = 30;

interface P {
  className?: string;
  share?: boolean;
  children?: never;
}

type StepsType = Readonly<
  Array<{
    label: string;
    key: ProductFormKeys;
  }>
>;

const steps: StepsType = [
  {
    label: 'Main Information',
    key: 'main_info',
  },
  {
    label: 'Recipe & Ingredients',
    key: 'ingredients',
  },
  {
    label: 'Product Integrity',
    key: 'integrity',
  },
  {
    label: 'Allergens',
    key: 'allergens',
  },
  {
    label: 'Nutritional',
    key: 'nutritional',
  },
  {
    label: 'Process Flow & CCPs',
    key: 'ccps',
  },
  {
    label: 'Storage',
    key: 'storage',
  },
  {
    label: 'Weight & Packaging',
    key: 'packaging',
  },
  {
    label: 'Product Standards',
    key: 'standards',
  },
  {
    label: 'QAS',
    key: 'qas',
  },
  {
    label: 'Attachments',
    key: 'attachments',
  },
];

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    padding: 0,
    alignItems: 'center',
    height: STEPPER_HEIGHT,
    overflowX: 'auto',

    // display: 'block',
    // 'max-width': '100%',
    'overflow-x': 'auto',

    paddingBottom: 12,

    '&::-webkit-scrollbar': {
      height: 5,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.palette.gray.main,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.error.light,
      borderRadius: 10,
    },
  },
  stepsConnector: {
    height: STEP_LINE_HEIGHT,
    border: 0,
    display: 'flex',
    backgroundColor: theme.palette.gray.dark,
    borderRadius: 1,

    '&.MuiStepConnector-alternativeLabel': {
      top: ICON_DIMENSIONS / 2 - STEP_LINE_HEIGHT / 2,
      left: `calc(-50% + ${ICON_DIMENSIONS / 2}px)`,
      right: `calc(50% + ${ICON_DIMENSIONS / 2}px)`,
    },
  },
  stepRoot: {
    boxSizing: 'border-box',
    padding: 0,
    minWidth: STEP_ITEM_WIDTH,

    '& .MuiStepLabel-labelContainer': {
      minWidth: 'fit-content',
      width: STEP_ITEM_WIDTH,
      whiteSpace: 'nowrap',
      position: 'absolute',
    },

    '&:nth-child(even) .MuiStepLabel-labelContainer': {
      top: -TEXT_OFFSET,
    },

    '&:nth-child(odd) .MuiStepLabel-labelContainer': {
      top: TEXT_OFFSET,
    },
  },
  labelRoot: {
    '& .MuiStepLabel-label': {
      margin: 0,
      color: `${theme.palette.gray.dark} !important`,
    },

    '& .MuiStepLabel-active': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
}));

const useColorlibStepIconStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.gray.dark,
    zIndex: 1,
    width: ICON_DIMENSIONS,
    height: ICON_DIMENSIONS,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.text.primary,

    '& .MuiIconButton-root': {
      // display: 'none',
      // filter: 'brightness(0.5) invert(1)',
    },
  },
}));

function ColorlibStepIcon({
  key,
  active,
  status,
  share,
}: {
  key: ProductFormKeys;
  active: boolean;
  status?: ProductSpecTabsInstance['color'];
  share?: boolean;
}) {
  const history = useHistory();
  const classes = useColorlibStepIconStyles();

  const icons: { [index: string]: React.ReactElement } = {
    base: <img src={BaseIcon} alt="stepper base" />,
    success: <img src={SuccessIcon} alt="stepper success" />,
    warning: <img src={WarningIcon} alt="stepper warning" />,
    error: <img src={ErrorIcon} alt="stepper error" />,
  };

  const params = history.location.search;

  return (
    <div className={clsx(classes.root, active && classes.active)}>
      <IconButton
        onClick={() => {
          if (share) {
            return history.push(`${RouteMap.share[key]}${params}`);
          }
          return history.push(`${RouteMap.bussines.productForm[key]}${params}`);
        }}
      >
        {status === 'success'
          ? icons.success
          : status === 'warning'
          ? icons.warning
          : status === 'error'
          ? icons.error
          : icons.base}
      </IconButton>
    </div>
  );
}

const Stepper: React.FC<P & Omit<StepperProps, keyof P>> = ({
  className,
  share,
}) => {
  const location = useLocation();
  const stepperRef = React.useRef<null | HTMLDivElement>(null);
  const classes = useStyles();
  const [userData] = useUser();
  const [urlParams, , setUrlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const { data: productSpecTabs } = WizardHooks.useProductSpecTabs(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
    },
  );

  const statusProductMutation = WizardHooks.useStatusProductMutation();

  React.useEffect(() => {
    const stepIndex = steps.findIndex(
      step => RouteMap.bussines.productForm[step.key] === location.pathname,
    );

    const targetOffsetLeft = (stepIndex - 3) * STEP_ITEM_WIDTH;

    stepperRef?.current?.scrollTo({
      left: targetOffsetLeft,
      top: 0,
    });
  }, [location.pathname]);

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <h3 className="text-primary text-2xl mr-3">
            {productSpecTabs?.data.product_name}
          </h3>
          <p className="text-gray-200 text-sm">
            {productSpecTabs?.data.cardbox_status}
          </p>
        </div>
        <div>
          <>
            {productSpecTabs?.data.cardbox_status === 'Archived' ? (
              <Button
                className={clsx('min-w-50 w-50 mr-2')}
                variant="outlined"
                onClick={() => {
                  statusProductMutation.mutate({
                    code: urlParams.code,
                    version: urlParams.version,
                    status: 'draft',
                  });
                }}
              >
                Active
              </Button>
            ) : (
              <>
                {!share && (
                  <>
                    {userData?.access_levels.product_approve && (
                      <Button
                        className={clsx('min-w-50 w-50 mr-2')}
                        variant="outlined"
                        onClick={() => {
                          statusProductMutation.mutate(
                            {
                              code: urlParams.code,
                              version: urlParams.version,
                              status: 'archive',
                            },
                            {
                              onSuccess: () => {
                                setUrlParams({ editMode: false });
                              },
                            },
                          );
                        }}
                      >
                        Archive {share}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
          {(productSpecTabs?.data.cardbox_status === 'Draft' ||
            productSpecTabs?.data.cardbox_status === 'Pending Supplier') && (
            <>
              {urlParams.editMode ? (
                <>
                  <Button
                    variant="outlined"
                    className="w-28"
                    onClick={() => setUrlParams({ editMode: false })}
                    size="medium"
                    startIcon={<Close />}
                    text="Cancel"
                  />
                </>
              ) : (
                <Button
                  className="w-28"
                  onClick={() => setUrlParams({ editMode: true })}
                  size="medium"
                  startIcon={<Edit />}
                  text="Edit"
                />
              )}
            </>
          )}
        </div>
      </div>

      <MuiStepper
        innerRef={stepperRef}
        classes={{ root: classes.root }}
        className={className}
        alternativeLabel
        nonLinear
        connector={<StepConnector classes={{ root: classes.stepsConnector }} />}
        activeStep={
          share
            ? steps.findIndex(
                step => RouteMap.share[step.key] === location.pathname,
              )
            : steps.findIndex(
                step => RouteMap.share[step.key] === location.pathname,
              )
        }
      >
        {steps.map(el => (
          <Step
            classes={{
              root: classes.stepRoot,
            }}
            key={el.key}
            id={el.key}
          >
            <StepLabel
              classes={{
                root: classes.labelRoot,
              }}
              StepIconComponent={() => {
                if (share) {
                  return ColorlibStepIcon({
                    share: share,
                    key: el.key,
                    active: RouteMap.share[el.key] === location.pathname,
                    status: productSpecTabs?.data.tabs_statuses.find(
                      tab => tab.page === el.key,
                    )?.color,
                  });
                }

                return ColorlibStepIcon({
                  share: share,
                  key: el.key,
                  active:
                    RouteMap.bussines.productForm[el.key] === location.pathname,
                  status: productSpecTabs?.data.tabs_statuses.find(
                    tab => tab.page === el.key,
                  )?.color,
                });
              }}
            >
              {el.label}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </>
  );
};

export default Stepper;
