import React from 'react';
import { TextField, TextFieldProps, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';

interface P {
  type?: string;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    color: 'red',

    '& .MuiInputBase-root': {
      paddingRight: 0,
    },

    '& .MuiInputBase-input': {
      color: theme.palette.secondary.dark,
      fontFamily: theme.fonts.secondary,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  label: {
    color: theme.palette.gray.dark,
    fontFamily: theme.fonts.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  notchedOutline: {
    borderColor: theme.palette.gray.dark,
  },
}));

const Input: React.FC<P & Omit<TextFieldProps, keyof P>> = ({
  type = 'text',
  ...rest
}) => {
  const classes = useStyles();
  const [internalType, setType] = React.useState(type);

  return (
    <TextField
      classes={{ root: classes.root }}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
      }}
      fullWidth
      size="small"
      InputProps={{
        ...(type === 'password'
          ? {
              endAdornment: (
                <IconButton
                  className="py-0"
                  onClick={() =>
                    setType(internalType === 'text' ? 'password' : 'text')
                  }
                >
                  <Visibility className="text-gray-900" />
                </IconButton>
              ),
            }
          : {}),
      }}
      type={internalType}
      {...rest}
    />
  );
};

export default Input;
