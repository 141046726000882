import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import errorHandler from '@utils/errorHandler';

import activityLogApis from '@hooks/api/activity/api';

import {
  GetActivityLogParams,
  UseActivityLogResponseType,
} from '@hooks/api/activity/types';

const KEYS = {
  activityLogList: ['activityLog', 'list'],
} as const;

const useActivityLogList = (
  params: GetActivityLogParams,
  options?: UseQueryOptions<UseActivityLogResponseType, AxiosError>,
) => {
  const query = useQuery<UseActivityLogResponseType, AxiosError>(
    [...KEYS.activityLogList, params],
    () => activityLogApis.getActivityLogList(params),
    options,
  );

  return query;
};

export default {
  useActivityLogList,
};
