import { Route, Switch } from 'react-router-dom';
import map from '@routes/map';
import PrivateRoute from '@routes/privateRoute';
import ShareRoute from '@routes/shareRoute';

import Login from '@components/Auth/login';
import ForgotPassword from '@components/Auth/ForgotPassword';
import ResetPassword from '@components/Auth/ResetPassword';
import TermsAndConditions from '@components/Auth/termsAndConditions';
import AuthPolicies from '@components/Auth/policies';
import AuthSupplier from '@components/Auth/supplierAndCertificate';

import Dashboard from '@components/Dashboard';
import Subscription from '@components/Configurations/Subscription';

import SuppliersList from '@components/Suppliers/SuppliersList';
import SupplierInfo from '@components/Suppliers/SupplierInfo';

import ManufacturersList from '@components/Suppliers/Manufacturers/ManufacturersList';
import ManufacturerInfo from '@components/Suppliers/Manufacturers/ManufacturerInfo';

import CertificatesList from '@components/Certificates/CertificatesList';
import CertificateInfo from '@components/Certificates/CertificateInfo';

import BussinesProducts from '@components/Bussines/Products';

import Policies from '@components/Policies';

import CompanyProfile from '@components/Configurations/CompanyProfile';

import UsersList from '@components/Configurations/Users/UsersList';
import UserInfo from '@components/Configurations/Users/UserInfo';

import ActivityLogs from '@components/Configurations/security/activityLog';
import Notification from '@components/Notification';
import SharedLinks from '@components/Configurations/security/sharedLinks';

import PublicLogin from '@app/components/Auth/PublicLogin';
import LoginAs from '@app/components/Auth/LoginAs';

import {
  MainInfo,
  Ingredients,
  Integrity,
  Allergens,
  Nutrition,
  Flow,
  Storage,
  Weight,
  Standards,
  Qas,
  Attachments,
  Validation,
} from '@components/Bussines/Products/ProductForm';

import Profile from '@components/Configurations/Profile';

import ProfileChangePassword from '@components/Configurations/ProfileChangePassword';

import Setting from '@components/Configurations/Setting';

import RecycleBin from '@app/components/Configurations/RecycleBin';

import NotFound from '@components/NotFound';

const Router = () => (
  <Switch>
    <Route exact path={map.auth.share} component={PublicLogin} />
    <Route exact path={map.auth.loginAs} component={LoginAs} />

    <Route exact path={map.auth.login} component={Login} />
    <Route exact path={map.auth.forgotPassword} component={ForgotPassword} />
    <Route exact path={map.auth.resetPassword} component={ResetPassword} />

    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.main_info}
      render={props => <MainInfo share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.ingredients}
      render={props => <Ingredients share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.integrity}
      render={props => <Integrity share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.allergens}
      render={props => <Allergens share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.nutritional}
      render={props => <Nutrition share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.ccps}
      render={props => <Flow share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.storage}
      render={props => <Storage share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.packaging}
      render={props => <Weight share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.standards}
      render={props => <Standards share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.qas}
      render={props => <Qas share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.attachments}
      render={props => <Attachments share {...props} />}
    />
    <ShareRoute
      exact
      authenticationRoute={false}
      path={map.share.validation}
      render={props => <Validation share {...props} />}
    />

    <PrivateRoute
      exact
      authenticationRoute
      path={map.auth.termsAndConditions}
      component={TermsAndConditions}
    />
    <PrivateRoute
      authenticationRoute
      exact
      path={map.auth.policies}
      component={AuthPolicies}
    />
    <PrivateRoute
      authenticationRoute
      exact
      path={map.auth.supplierAndCertificates}
      component={AuthSupplier}
    />

    <PrivateRoute exact path={map.dashboard} component={Dashboard} />
    <PrivateRoute
      exact
      path={map.configurations.subscription}
      component={Subscription}
    />

    <PrivateRoute
      exact
      path={map.suppliers.suppliersList}
      component={SuppliersList}
    />
    <PrivateRoute
      exact
      path={map.suppliers.supplierInfo}
      component={SupplierInfo}
    />

    <PrivateRoute
      exact
      path={map.suppliers.manufacturersList}
      component={ManufacturersList}
    />
    <PrivateRoute
      exact
      path={map.suppliers.manufacturerInfo}
      component={ManufacturerInfo}
    />

    <PrivateRoute
      exact
      path={map.bussines.productForm.main_info}
      component={MainInfo}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.ingredients}
      component={Ingredients}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.integrity}
      component={Integrity}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.allergens}
      component={Allergens}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.nutritional}
      component={Nutrition}
    />
    <PrivateRoute exact path={map.bussines.productForm.ccps} component={Flow} />
    <PrivateRoute
      exact
      path={map.bussines.productForm.storage}
      component={Storage}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.packaging}
      component={Weight}
    />
    <PrivateRoute
      exact
      path={map.bussines.productForm.standards}
      component={Standards}
    />
    <PrivateRoute exact path={map.bussines.productForm.qas} component={Qas} />
    <PrivateRoute
      exact
      path={map.bussines.productForm.attachments}
      component={Attachments}
    />

    <PrivateRoute
      exact
      path={map.bussines.products}
      component={BussinesProducts}
    />

    <PrivateRoute
      exact
      path={map.certificates.certificatesList}
      component={CertificatesList}
    />
    <PrivateRoute
      exact
      path={map.certificates.certificateInfo}
      component={CertificateInfo}
    />

    <PrivateRoute exact path={map.policies} component={Policies} />

    <PrivateRoute exact path={map.configurations.profile} component={Profile} />

    <PrivateRoute
      exact
      path={map.configurations.profileChangePassword}
      component={ProfileChangePassword}
    />

    <PrivateRoute exact path={map.configurations.setting} component={Setting} />

    <PrivateRoute
      exact
      path={map.configurations.recyclebin}
      component={RecycleBin}
    />

    <PrivateRoute
      exact
      path={map.configurations.companyProfile}
      component={CompanyProfile}
    />

    <PrivateRoute
      exact
      path={map.configurations.users.usersList}
      component={UsersList}
    />
    <PrivateRoute
      exact
      path={map.configurations.users.usersInfo}
      component={UserInfo}
    />
    <PrivateRoute
      exact
      path={map.configurations.security.activityLogs}
      component={ActivityLogs}
    />
    <PrivateRoute exact path={map.notification} component={Notification} />
    <PrivateRoute
      exact
      path={map.configurations.security.sharedLinks}
      component={SharedLinks}
    />

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Router;
