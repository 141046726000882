/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { Cell } from 'react-table';
import { useForm, Controller, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Collapse, InputAdornment } from '@material-ui/core';
import { Delete, Add, Check, Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import {
  Nutrition,
  NutritionInstance,
  NutritionTypicalValuesSchema,
  SaltTargetSubCategories,
  NutrientTargetsSchema,
  NutrientTargetsSubCategory,
} from '@hooks/api/wizard/types';

import { wizardHook, generalHook, useUrlParams } from '@hooks';

import { deepClone, numberValidator } from '@utils';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import {
  Input,
  Switch,
  Table,
  AutoComplete,
  AutoCompleteItem,
  Button,
  CheckBox,
} from '@components/Common';

import Egg from '@components/Bussines/Products/ProductForm/Nutrition/Egg';

import {
  eggColorCalculator,
  rowColor,
  handleBgColorTailwind,
  allWatchHandler,
  sampleMineralRow,
  sampleVitaminsRow,
} from '@components/Bussines/Products/ProductForm/Nutrition/helper';

import {
  EggType,
  NutritionType,
  CustomCell,
  SubRowInstance,
  TableInputProps,
  TableCellTextProps,
  TableAutoCompleteProps,
} from '@components/Bussines/Products/ProductForm/Nutrition/types';

const validationSchema = yup.object({
  serving_size: yup.mixed().test('serving_size', 'error', val => {
    if (val === 0) return false;

    if (val === null || val === undefined || val === '') return false;

    return Number.isFinite(Number(val));
  }),

  typical_values_consumed_or_sold: yup
    .mixed()
    .test('typical_values_consumed_or_sold', 'error', val => {
      if (val?.name) {
        return true;
      }
      return false;
    }),
  typical_values_per: yup.mixed().test('typical_values_per', 'error', val => {
    if (val?.name) {
      return true;
    }
    return false;
  }),
  typical_values_per_serving: yup
    .mixed()
    .test('typical_values_per_serving', 'error', val => {
      if (val?.name) {
        return true;
      }
      return false;
    }),

  food_traffic_light: yup.object({
    serving_suggestion: yup
      .mixed()
      .test('serving_suggestion', 'error', val => !!val),
  }),

  unit: yup.mixed().test('unit', 'error', val => {
    if (val === null) return false;
    if (val.name === '') return false;
    return true;
  }),

  serving_per_pack: yup.mixed().test('serving_per_pack', 'error', val => {
    if (val === 0) return false;

    if (val === null || val === undefined || val === '') return false;

    return Number.isFinite(Number(val));
  }),

  typical_values: yup.array(
    yup.object({
      per_100g: yup.mixed().test('per_100g', 'error', numberValidator),
    }),
  ),
  nutrient_targets: yup.array(
    yup.object({
      main_category: yup.mixed().test('main_category', 'error', val => {
        if (val === null) return false;
        if (val.title === '') return false;
        return true;
      }),
      sub_category: yup.mixed().test('sub_category', 'error', val => {
        if (val === null) return false;
        if (val.title === '') return false;
        return true;
      }),
    }),
  ),
});

type P = {
  share?: boolean;
};
const Products: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const history = useHistory();

  const form = useForm<Nutrition>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });
  const formWatch = form.watch([
    `typical_values.${0}.per_100g_rounded`,
    `typical_values.${1}.per_100g_rounded`,
  ]);

  const {
    useNutrition,
    useUpdateNutritionMutation,
    useSaltTargets,
    useValidateNutritionMutation,
  } = wizardHook;
  const { data: allStatus } = generalHook.useAllStatus();

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const [areMineralsRelevant, setAreMineralsRelevant] = React.useState('No');
  const [areVitaminsRelevant, setAreVitaminsRelevant] = React.useState('No');
  const [expanded, setExpanded] = React.useState('No');
  const [isEnergyReadOnly, setIsEnergyReadOnly] = React.useState(true);

  const fatWatch = form.watch('typical_values.2.per_100g');
  const saturatesWatch = form.watch('typical_values.3.per_100g');
  const sugarsWatch = form.watch('typical_values.5.per_100g');
  const saltWatch = form.watch('typical_values.8.per_100g');
  const nutritionType = form.watch('nutrition_type');

  const eggNutritionType =
    nutritionType?.name === NutritionType.Drink
      ? NutritionType.Drink
      : NutritionType.Food;

  const portionSize = form.watch('serving_size') ?? 0;

  const eggColors = {
    fat: eggColorCalculator(
      fatWatch,
      EggType.Fat,
      eggNutritionType,
      +portionSize,
    ),
    saturates: eggColorCalculator(
      saturatesWatch,
      EggType.Saturates,
      eggNutritionType,
      +portionSize,
    ),
    sugars: eggColorCalculator(
      sugarsWatch,
      EggType.Sugars,
      eggNutritionType,
      +portionSize,
    ),
    salt: eggColorCalculator(
      saltWatch,
      EggType.Salt,
      eggNutritionType,
      +portionSize,
    ),
  };

  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (type && name) {
        allWatchHandler({
          form,
          type,
          name,
          value,
          setIsEnergyReadOnly,
        });
      }

      return null;
    });

    return () => subscription.unsubscribe();
  }, [form.watch]);

  const { data: productNutrition, ...useProductNutritionUtils } = useNutrition(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => form.reset(data.data),
    },
  );

  const updateNutritionMutation = useUpdateNutritionMutation();
  const validateNutritionMutation = useValidateNutritionMutation();

  const { data: saltTargets, ...useSaltTargetsUtils } = useSaltTargets();

  const onSave = (link: string, data: Nutrition) => {
    form.clearErrors();
    updateNutritionMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate = async (data: Nutrition) => {
    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      validateNutritionMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      });

      // history.push(
      //   `${RoutesMap.bussines.productForm.validation}${history.location.search}`,
      // );
    } catch (e) {}
  };

  const getSubRows = React.useCallback(
    (row: NutritionTypicalValuesSchema) => {
      return row.subrows?.length > 0 ? row.subrows : [];
    },
    [productNutrition?.timeStamp],
  );

  const fetchUnitFromFieldName = React.useCallback(
    (name: TableCellTextProps['name']) => {
      // const [key, index] = name.split('.');

      // const unitKey = `${key}.${index}.unit_text` as typeof name;
      const unitKey = name.split('.').slice(0, -1).join('.');

      const fetchUnitText = form.getValues(
        `${unitKey}.unit_text` as typeof name,
      );

      return fetchUnitText ? fetchUnitText.toString() : '';
    },
    [],
  );

  const TableInput = React.useCallback(
    ({
      name,
      dense = true,
      className,
      inputClassName,
      type,
      showUnit,
      disabled,
      showError,
      ...inputProps
    }: TableInputProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { ref, value, onChange, ...rest }, fieldState }) => (
          <Input
            error={!!fieldState.error?.message && !disabled}
            dense={dense}
            className={className ?? 'w-full h-6'}
            inputClassName={inputClassName ?? 'text-xs px-2 font-roboto'}
            innerRef={ref}
            value={value ?? ''}
            onChange={e => {
              let newVal = e.target.value;

              if (type === 'number') {
                // this is to prevent negative values
                newVal = newVal.replace(/-/g, '');
              }

              onChange(newVal);
            }}
            endAdornment={
              showUnit && fetchUnitFromFieldName(name) ? (
                <InputAdornment
                  disableTypography
                  className="text-gray text-sm px-2 font-roboto"
                  position="end"
                >
                  {fetchUnitFromFieldName(name)}
                </InputAdornment>
              ) : null
            }
            type={type}
            inputProps={{
              min: 0,
              step: 'any',
            }}
            {...rest}
            {...inputProps}
          />
        )}
      />
    ),
    [productNutrition?.timeStamp],
  );

  const TableAutoComplete = React.useCallback(
    (props: TableAutoCompleteProps) => {
      const {
        name,
        disabled,
        dense,
        normalizer,
        className,
        inputRootClassName,
        itemDropDown,
        onChange: changeHandle,
        freeSolo,
        canAddCustomItem,
      } = props;

      let dropDownItem: NonNullable<AutoCompleteItem>[] | undefined;
      if (itemDropDown?.length && normalizer) {
        dropDownItem = itemDropDown.map(
          (value: any): NonNullable<AutoCompleteItem> => {
            return {
              code: value.id,
              name: value?.title,
            };
          },
        );
      }

      return (
        <Controller
          name={name}
          control={form.control}
          render={({ field, fieldState }) => {
            const { ref, value, onChange, ...rest } = field;

            // TODO
            const narrowedValue = value as any;

            if (narrowedValue && !narrowedValue.code && narrowedValue.id) {
              narrowedValue.code = narrowedValue.id;
            }

            if (narrowedValue && !narrowedValue.name && narrowedValue.title) {
              narrowedValue.name = narrowedValue.title;
            }

            return (
              <AutoComplete
                dense={dense}
                error={!!fieldState?.error}
                className={clsx('flex justify-center', className)}
                inputRootClassName={inputRootClassName}
                items={dropDownItem || itemDropDown || []}
                value={narrowedValue}
                disableClearable
                disabled={disabled}
                onChange={data => {
                  if (changeHandle) changeHandle(data);

                  const normalizeredData =
                    normalizer && data
                      ? { id: data.code, title: data.name }
                      : data;

                  onChange(normalizeredData);
                }}
                freeSolo={freeSolo}
                canAddCustomItem={canAddCustomItem}
                {...rest}
              />
            );
          }}
        />
      );
    },
    [productNutrition?.timeStamp],
  );

  const generateInputNameTypicalValues = React.useCallback(
    (cell: Cell): FieldPath<Nutrition> => {
      const [substanceIndex, subRowIndex] = cell.row.id.split('.');

      if (cell.row.depth === 0)
        return `typical_values.${Number(substanceIndex)}.${
          cell.column.id as keyof NutritionTypicalValuesSchema
        }`;

      return `typical_values.${Number(substanceIndex)}.subrows.${Number(
        subRowIndex,
      )}.${cell.column.id as keyof SubRowInstance}`;
    },
    [productNutrition?.timeStamp],
  );

  const hasReadOnlyEnergy = React.useCallback(
    (index: number) => {
      if (index >= 2) return false;

      return !!form.getValues('auto_calculate_kj_kcal');
    },
    [isEnergyReadOnly],
  );

  const TextCell = ({
    value,
    unit,
    className,
    colorReset = false,
    preventZero,
    preventZeroLimit,
  }: {
    value: any;
    unit?: string;
    colorReset?: boolean;
    preventZero?: boolean;
    preventZeroLimit?: number;
    className?: string;
  }) => {
    const isNumber = (val: any) => {
      if (val === '' || val === null || val === undefined) return false;

      return typeof +val === 'number' && Number.isFinite(+val);
    };

    let formattedValue = value;

    // 'preventZero' props is used when we do not want to show the value '0' or 'preventZeroLimit'
    // it is mostly used in % fields
    if (
      preventZero &&
      isNumber(+formattedValue) &&
      (formattedValue === 0 ||
        (preventZeroLimit && formattedValue < preventZeroLimit))
    ) {
      formattedValue =
        formattedValue === null
          ? formattedValue
          : preventZeroLimit
          ? `<${preventZeroLimit}`
          : '<1';
    }

    return (
      <p
        className={overrideTailwindClasses(
          clsx(
            'text-sm font-roboto px-2',
            colorReset ? '' : 'text-primary',
            className,
          ),
        )}
      >
        {formattedValue}

        {unit && formattedValue ? ` ${unit}` : ''}
      </p>
    );
  };

  const TableCellText = React.useCallback(
    ({
      name,
      unit,
      showUnit,
      className,
      colorReset,
      preventZero,
      preventZeroLimit,
    }: TableCellTextProps) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value } }) => (
          <TextCell
            preventZero={preventZero}
            preventZeroLimit={preventZeroLimit}
            colorReset={colorReset}
            value={value}
            unit={showUnit ? fetchUnitFromFieldName(name) : unit}
            className={className}
          />
        )}
      />
    ),
    [productNutrition?.timeStamp],
  );

  const NutritionInformationTableColumns = React.useMemo(
    (): {
      Header?: string | (() => JSX.Element);
      accessor?: keyof NutritionInstance;
      id: keyof NutritionInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Typical values',
        id: 'title',
        accessor: 'title',
        className: 'typical_values_title',
        width: 150,
        Cell: cell => (
          <TableCellText name={generateInputNameTypicalValues(cell)} />
        ),
      },
      {
        id: 'per_100g',
        accessor: 'per_100g',
        width: 110,
        Header: () => (
          <div className="flex flex-col">
            <TableAutoComplete
              dense
              className="mb-2"
              inputRootClassName="text-xs text-gray"
              name="typical_values_per"
              disabled={!urlParams?.editMode}
              itemDropDown={allStatus?.data?.typical_values_per_drop_down || []}
              canAddCustomItem={false}
              freeSolo={false}
            />

            <TableAutoComplete
              dense
              inputRootClassName="text-xs text-gray"
              name="typical_values_consumed_or_sold"
              disabled={!urlParams?.editMode}
              itemDropDown={allStatus?.data?.consumed_or_sold || []}
              canAddCustomItem={false}
              freeSolo={false}
            />
          </div>
        ),
        Cell: cell => (
          <TableInput
            showError
            showUnit
            type="number"
            name={generateInputNameTypicalValues(cell)}
            disabled={
              hasReadOnlyEnergy(Number(cell.row.id)) || !urlParams?.editMode
            }
          />
        ),
      },
      {
        Header: () => <div className="text-center"> Per 100g/ml rounded </div>,
        id: 'per_100g_rounded',
        accessor: 'per_100g_rounded',
        width: 80,
        Cell: cell => (
          <TableCellText
            className="text-center"
            showUnit
            name={generateInputNameTypicalValues(cell)}
          />
        ),
      },
      {
        id: 'per_serving',
        accessor: 'per_serving',
        width: 110,
        Header: () => (
          <div className="flex flex-col">
            <p className="mb-2 text-center">Per serving</p>

            <TableAutoComplete
              dense
              inputRootClassName="text-xs text-gray"
              name="typical_values_per_serving"
              disabled={!urlParams?.editMode}
              itemDropDown={allStatus?.data?.consumed_or_sold || []}
              canAddCustomItem={false}
              freeSolo={false}
            />
          </div>
        ),
        Cell: cell => (
          <TableCellText
            className="text-center"
            preventZero
            preventZeroLimit={0.1}
            showUnit
            name={generateInputNameTypicalValues(cell)}
          />
        ),
      },
      {
        Header: '%RI per 100g/ml (Adult)',
        id: 'ri_per_100',
        accessor: 'ri_per_100',
        width: 70,
        Cell: cell => (
          <TableCellText
            preventZero
            className="text-center"
            unit="%"
            name={generateInputNameTypicalValues(cell)}
          />
        ),
      },
      {
        Header: '%RI per Serving (Adult)',
        id: 'ri_per_serving',
        accessor: 'ri_per_serving',
        width: 50,
        Cell: cell => (
          <TableCellText
            preventZero
            className="text-center"
            unit="%"
            name={generateInputNameTypicalValues(cell)}
          />
        ),
      },
      {
        Header: 'Reference Intake (Adult)',
        id: 'reference_intake',
        accessor: 'reference_intake',
        width: 70,
        Cell: cell => (
          <TableCellText
            className="text-center"
            showUnit
            name={generateInputNameTypicalValues(cell)}
          />
        ),
      },
      {
        Header: 'Source of information',
        id: 'source_of_information',
        accessor: 'source_of_information',
        width: 120,
        Cell: (cell: CustomCell) => (
          <TableAutoComplete
            dense
            inputRootClassName="text-xs text-gray"
            name={generateInputNameTypicalValues(cell)}
            disabled={!urlParams?.editMode}
            itemDropDown={allStatus?.data?.source_of_informations || []}
            canAddCustomItem={false}
            freeSolo={false}
          />
        ),
      },
      {
        Header: 'Declared',
        id: 'declared',
        accessor: 'declared',
        className: 'declare text-center',
        width: 55,
        Cell: cell => (
          <Controller
            name={generateInputNameTypicalValues(cell)}
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <CheckBox
                checked={!!value}
                onChange={onChange}
                disabled={!urlParams?.editMode}
              />
            )}
          />
        ),
      },
    ],
    [urlParams?.editMode, allStatus?.timeStamp],
  );

  const setSubCategoryAndDepencies = (
    subCategory: undefined | SaltTargetSubCategories,
  ) => {
    const currentSaltValue = form.getValues('typical_values.8.per_100g');

    if (subCategory) {
      const salt_level_percent_threshold = Math.round(
        (Number(currentSaltValue) / subCategory.threshold_per_hundred_grams) *
          100,
      );

      form.setValue(
        `nutrient_targets.0.sub_category`,
        {
          ...subCategory,
          salt_level_percent_threshold,
        },
        { shouldDirty: true },
      );

      form.setValue(
        `nutrient_targets.0.target_achieved`,
        salt_level_percent_threshold <= 100,
        { shouldDirty: true },
      );
    } else {
      form.setValue(`nutrient_targets.0.sub_category`, null, {
        shouldDirty: true,
      });

      form.setValue(`nutrient_targets.0.target_achieved`, null, {
        shouldDirty: true,
      });
    }

    form.reset(undefined, {
      keepValues: true,
      keepTouched: true,
      keepDirty: true,
      keepErrors: true,
    });
  };

  React.useEffect(() => {
    const subCategory = form.getValues('nutrient_targets.0.sub_category');

    // the `Salt level % of threshold` field in nutrient target table
    // is dependent on the salt value from the nutrient information table
    if (subCategory) setSubCategoryAndDepencies(subCategory);
  }, [form.watch('typical_values.8.per_100g')]);

  const onNutritionalTargetMainCategoryChange = React.useCallback(
    (newData: AutoCompleteItem, rowIndex: number) => {
      const targetElement = saltTargets?.data.find(
        el => newData && el.id === newData.code,
      );
      // if (targetElement) {
      form.setValue(
        `nutrient_targets.${rowIndex}.main_category`,
        targetElement || null,
      );

      setSubCategoryAndDepencies(undefined);
      // }
    },
    [useSaltTargetsUtils.dataUpdatedAt, setSubCategoryAndDepencies],
  );

  const fetchSubCategoriesFromCategoryId = React.useCallback(
    (categoryId: number) => {
      return (
        saltTargets?.data.find(el => el.id === categoryId)?.subcategories || []
      );
    },
    [useSaltTargetsUtils.dataUpdatedAt],
  );

  const onNutritionalTargetSubCategoryChange = React.useCallback(
    (newData: AutoCompleteItem, rowIndex: number, categoryId: number) => {
      const targetSubCategory = fetchSubCategoriesFromCategoryId(
        categoryId,
      )?.find(el => newData && el.id === newData.code);
      console.log(
        '🚀 ~ file: index.tsx ~ line 821 ~ targetSubCategory',
        targetSubCategory,
      );
      // if (targetSubCategory) {
      setSubCategoryAndDepencies(targetSubCategory);
      // }
    },
    [useSaltTargetsUtils.dataUpdatedAt, setSubCategoryAndDepencies],
  );

  const formatAutoCompleteItem = (el: { id: number; title: string }) => ({
    name: el.title,
    code: el.id,
  });

  const NutritionTargetsTableColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof (NutrientTargetsSchema & NutrientTargetsSubCategory);
      id: keyof (NutrientTargetsSchema & NutrientTargetsSubCategory) | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Main product category',
        id: 'main_category',
        accessor: 'main_category',
        width: 190,
        Cell: (cell: CustomCell) => (
          <Controller
            name="nutrient_targets.0.main_category"
            control={form.control}
            render={({ field: { value }, fieldState }) => (
              <AutoComplete
                error={!!fieldState?.error}
                className="flex justify-center"
                items={(saltTargets?.data || [])?.map(formatAutoCompleteItem)}
                value={value ? formatAutoCompleteItem(value) : null}
                disabled={!urlParams?.editMode}
                // disableClearable
                onChange={data =>
                  onNutritionalTargetMainCategoryChange(
                    data,
                    Number(cell.row.id),
                  )
                }
                freeSolo={false}
                canAddCustomItem={false}
              />
            )}
          />
        ),
      },
      {
        Header: 'Sub-categories',
        id: 'sub_category',
        accessor: 'sub_category',
        width: 355,
        Cell: (cell: CustomCell) => (
          <Controller
            name="nutrient_targets.0.sub_category"
            control={form.control}
            render={({ field: { value }, fieldState }) => (
              <>
                {console.log('🚀 ~ file: index.tsx ~ line 881 ~ value', value)}
                <AutoComplete
                  dense
                  error={!!fieldState?.error}
                  className="flex justify-center"
                  items={(form.getValues('nutrient_targets.0.main_category.id')
                    ? fetchSubCategoriesFromCategoryId(
                        form.getValues('nutrient_targets.0.main_category.id'),
                      )
                    : []
                  )?.map(formatAutoCompleteItem)}
                  value={value ? formatAutoCompleteItem(value) : null}
                  disabled={!urlParams?.editMode}
                  // disableClearable
                  onChange={data => {
                    console.log('🚀 ~ file: index.tsx ~ line 898 ~ data', data);
                    // if (data === null) {
                    //   onNutritionalTargetSubCategoryChange(null);
                    // }
                    // if (form.getValues('nutrient_targets.0.main_category.id')) {
                    onNutritionalTargetSubCategoryChange(
                      data,
                      Number(cell.row.id),
                      form.getValues('nutrient_targets.0.main_category.id'),
                    );
                    // }
                  }}
                  freeSolo
                  canAddCustomItem={false}
                />
              </>
            )}
          />
        ),
      },
      {
        Header: 'Nutrient',
        id: 'nutrient',
        accessor: 'nutrient',
        width: 120,
        Cell: () => (
          <Controller
            name="nutrient_targets.0.nutrient"
            control={form.control}
            render={({ field: { value } }) => (
              <TextCell
                value={value}
                className="text-xs font-roboto text-gray py-2 px-4"
              />
            )}
          />
        ),
      },
      {
        Header: 'Target value per 100g/100ml',
        accessor: 'salt_target_value',
        id: 'salt_target_value',
        width: 120,
        Cell: () => (
          <Controller
            name="nutrient_targets.0.sub_category"
            control={form.control}
            render={({ field: { value } }) => (
              <TextCell
                value={value?.salt_target_value}
                className="text-xs font-roboto text-gray py-2 px-4"
              />
            )}
          />
        ),
      },
      {
        Header: 'Salt level % of threshold',
        accessor: 'salt_level_percent_threshold',
        id: 'salt_level_percent_threshold',
        width: 50,
        Cell: () => (
          <Controller
            name="nutrient_targets.0.sub_category"
            control={form.control}
            render={({ field: { value } }) => (
              <TextCell
                value={value?.salt_level_percent_threshold}
                unit="%"
                className="text-xs font-roboto text-gray py-2 px-4"
              />
            )}
          />
        ),
      },
      {
        Header: 'Target achieved',
        id: 'target_achieved',
        accessor: 'target_achieved',
        width: 30,
        className: 'text-center',
        Cell: () => (
          <Controller
            name="nutrient_targets.0.target_achieved"
            control={form.control}
            render={({ field: { value } }) => {
              if (value === null || value === undefined) return <></>;

              if (value) {
                return <Check className=" text-green" />;
              }

              return <Close className=" text-red" />;
            }}
          />
        ),
      },
    ],
    [
      urlParams?.editMode,
      allStatus?.timeStamp,
      useSaltTargetsUtils.dataUpdatedAt,
    ],
  );

  const onAddRow = React.useCallback((rowKey: 'minerals' | 'vitamins') => {
    const newRow = deepClone({
      ...(rowKey === 'minerals' ? sampleMineralRow : sampleVitaminsRow),
      userAdded: true,
    });

    form.setValue(rowKey, [...form.getValues()[rowKey], newRow], {
      shouldDirty: true,
    });

    form.reset(undefined, {
      keepValues: true,
      keepDirty: true,
      keepTouched: true,
      keepErrors: true,
    });
  }, []);

  const onDeleteRow = React.useCallback(
    (rowKey: 'minerals' | 'vitamins', rowIndex: number) => {
      const newVals = form.getValues()[rowKey];

      newVals.splice(rowIndex, 1);

      form.setValue(rowKey, newVals, { shouldDirty: true });

      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepDirty: true,
        keepErrors: true,
      });
    },
    [],
  );

  const MineralTableColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof NutritionInstance;
      id: keyof NutritionInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Mineral',
        id: 'title',
        accessor: 'title',
        width: 220,
        Cell: cell => (
          <TableInput
            className="px-2"
            inputClassName="text-sm text-primary font-roboto"
            name={`minerals.${Number(cell.row.id)}.title`}
            disabled={!cell.row.original.userAdded || !urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Per 100g/ml',
        id: 'per_100g_ml',
        accessor: 'per_100g_ml',
        width: 110,
        Cell: cell => (
          <TableInput
            showUnit
            type="number"
            name={`minerals.${Number(cell.row.id)}.per_100g_ml`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Per  serving',
        id: 'per_serving',
        accessor: 'per_serving',
        width: 75,
        Cell: cell => (
          <TableCellText
            showUnit
            name={`minerals.${Number(cell.row.id)}.per_serving`}
          />
        ),
      },
      {
        Header: '% NRVs per 100g/ml',
        id: 'ri_per_100g_ml',
        accessor: 'ri_per_100g_ml',
        width: 110,
        Cell: cell => (
          <TableCellText
            preventZero
            unit="%"
            name={`minerals.${Number(cell.row.id)}.ri_per_100g_ml`}
          />
        ),
      },
      {
        Header: '% NRVs per serving',
        id: 'ri_per_serving',
        accessor: 'ri_per_serving',
        width: 120,
        Cell: cell => (
          <TableCellText
            preventZero
            unit="%"
            name={`minerals.${Number(cell.row.id)}.ri_per_serving`}
          />
        ),
      },
      {
        Header: 'NRVs (Adult)',
        id: 'ri_for_adult',
        accessor: 'ri_for_adult',
        width: 100,
        Cell: cell => {
          return !cell.row.original.userAdded || !urlParams?.editMode ? (
            <TableCellText
              name={`minerals.${Number(cell.row.id)}.ri_for_adult`}
            />
          ) : (
            <TableInput
              type="number"
              name={`minerals.${Number(cell.row.id)}.ri_for_adult`}
            />
          );
        },
      },
      {
        Header: 'Declared',
        id: 'declare',
        accessor: 'declare',
        className: 'text-center',
        width: 30,
        Cell: cell => (
          <Controller
            name={`minerals.${Number(cell.row.id)}.declare`}
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                disabled={!urlParams?.editMode}
              />
            )}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <Button
            disabled={!urlParams.editMode}
            className={clsx(
              'h-full bg-transparent shadow-none border-0 outline-none',
              cell.row.original.userAdded ? '' : 'invisible',
            )}
            size="small"
            startIcon={<Delete className="text-gray text-xl" />}
            onClick={() => onDeleteRow('minerals', Number(cell.row.id))}
          />
        ),
      },
    ],
    [urlParams?.editMode, allStatus?.timeStamp],
  );

  const VitaminsTableColumns = React.useMemo(
    (): {
      Header: string;
      accessor?: keyof NutritionInstance;
      id: keyof NutritionInstance | 'action';
      className?: string;
      width?: number;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }[] => [
      {
        Header: 'Vitamins',
        id: 'title',
        accessor: 'title',
        width: 220,
        Cell: cell => (
          <TableInput
            className="px-2"
            inputClassName="text-sm text-primary font-roboto"
            name={`vitamins.${Number(cell.row.id)}.title`}
            disabled={!cell.row.original.userAdded || !urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Per 100g/ml',
        id: 'per_100g_ml',
        accessor: 'per_100g_ml',
        width: 110,
        Cell: cell => (
          <TableInput
            showUnit
            type="number"
            name={`vitamins.${Number(cell.row.id)}.per_100g_ml`}
            disabled={!urlParams?.editMode}
          />
        ),
      },
      {
        Header: 'Per  serving',
        id: 'per_serving',
        accessor: 'per_serving',
        width: 75,
        Cell: cell => (
          <TableCellText
            showUnit
            name={`vitamins.${Number(cell.row.id)}.per_serving`}
          />
        ),
      },
      {
        Header: '% NRVs per 100g/ml',
        id: 'ri_per_100g_ml',
        accessor: 'ri_per_100g_ml',
        width: 110,
        Cell: cell => (
          <TableCellText
            preventZero
            unit="%"
            name={`vitamins.${Number(cell.row.id)}.ri_per_100g_ml`}
          />
        ),
      },
      {
        Header: '% NRVs per serving',
        id: 'ri_per_serving',
        accessor: 'ri_per_serving',
        width: 120,
        Cell: cell => (
          <TableCellText
            preventZero
            unit="%"
            name={`vitamins.${Number(cell.row.id)}.ri_per_serving`}
          />
        ),
      },
      {
        Header: 'NRVs (Adult)',
        id: 'ri_for_adult',
        accessor: 'ri_for_adult',
        width: 100,
        Cell: cell => {
          return !cell.row.original.userAdded || !urlParams?.editMode ? (
            <TableCellText
              name={`vitamins.${Number(cell.row.id)}.ri_for_adult`}
            />
          ) : (
            <TableInput
              type="number"
              name={`vitamins.${Number(cell.row.id)}.ri_for_adult`}
            />
          );
        },
      },
      {
        Header: 'Declared',
        id: 'declare',
        accessor: 'declare',
        className: 'text-center',
        width: 30,
        Cell: cell => (
          <Controller
            name={`vitamins.${Number(cell.row.id)}.declare`}
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <CheckBox
                checked={value}
                onChange={onChange}
                disabled={!urlParams?.editMode}
              />
            )}
          />
        ),
      },
      {
        width: 30,
        Header: '',
        id: 'action',
        className: 'text-center',
        Cell: cell => (
          <Button
            disabled={!urlParams.editMode}
            className={clsx(
              'h-full bg-transparent shadow-none border-0 outline-none',
              cell.row.original.userAdded ? '' : 'invisible',
            )}
            size="small"
            startIcon={<Delete className="text-gray text-xl" />}
            onClick={() => onDeleteRow('vitamins', Number(cell.row.id))}
          />
        ),
      },
    ],
    [urlParams?.editMode, allStatus?.timeStamp],
  );

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <div className="mt-6">
          <h3 className="font-roboto text-base text-primary-light mb-2">
            General Info
          </h3>

          <div className="flex flex-row">
            <table className="bg-white border-spacing-0 w-1/2 mr-4">
              <thead>
                <tr>
                  <td className="bg-white-400 py-3 px-3">Serving size</td>
                  <td className="bg-white-400 py-3 px-3">Unit</td>
                  <td className="bg-white-400 py-3 px-3">
                    Number of servings per pack
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <TableInput
                      type="number"
                      name="serving_size"
                      className="w-full"
                      disabled={!urlParams?.editMode}
                      dense={false}
                    />
                  </td>

                  <td>
                    <TableAutoComplete
                      name="unit"
                      disabled={!urlParams?.editMode}
                      itemDropDown={allStatus?.data?.nutrition_units || []}
                      canAddCustomItem={false}
                      freeSolo={false}
                    />
                  </td>

                  <td>
                    <TableInput
                      type="number"
                      name="serving_per_pack"
                      className="w-full"
                      disabled={!urlParams?.editMode}
                      dense={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="w-1/2">
              <div className="flex flex-col">
                <div className="bg-white-400 mb-1 py-3 px-3">
                  Laboratory(s) name and accreditation
                </div>

                <TableInput
                  name="lab_analysis"
                  className="w-full"
                  disabled={!urlParams?.editMode}
                  dense={false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <div className="flex flex-row  items-center justify-between">
            <h3 className="font-roboto text-base text-primary-light">
              Nutrition Information
            </h3>

            <div className="flex flex-row items-center justify-between">
              <Controller
                name="nutrition_type"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    buttonClassNames="w-16"
                    disabled={!urlParams?.editMode}
                    className="mr-2"
                    leftButtonText="Food"
                    rightButtonText="Drink"
                    value={value?.name === 'Food' ? 'Food' : 'Drink'}
                    onChange={newVal =>
                      onChange({
                        ...value,
                        name: newVal,
                      })
                    }
                  />
                )}
              />

              <div className="flex flex-row items-center px-4 py-2 bg-white rounded mr-2">
                <p className="text-sm font-roboto text-primary mr-8">
                  Auto Calculate KJ & Kcal
                </p>

                <Controller
                  name="auto_calculate_kj_kcal"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      disabled={!urlParams?.editMode}
                      className="mr-2"
                      type="secondary"
                      value={value ? 'Yes' : 'No'}
                      onChange={newVal => onChange(Number(newVal === 'Yes'))}
                    />
                  )}
                />
              </div>

              <div className="flex flex-row items-center px-4 py-2 bg-white rounded mr-2">
                <p className="text-sm font-roboto text-primary mr-8">
                  Expand Mode
                </p>

                <Switch
                  type="secondary"
                  value={expanded}
                  onChange={setExpanded}
                />
              </div>
            </div>
          </div>

          <div className="w-full min-h-120">
            <Table
              cellMaxWidthIsEqToCellWidth
              loading={useProductNutritionUtils.isLoading}
              dataUpdatedAt={useProductNutritionUtils.dataUpdatedAt}
              className="mt-2 border-collapse"
              size="small"
              headerCellClassName={clsx(
                'font-normal text-primary-light text-left',
                'bg-white-400 py-1 px-2',
              )}
              bodyCellClassName="px-1 py-0 h-10"
              headerFirstCellClassName="pl-4"
              rowFirstCellClassName="pl-2 pr-1"
              columns={NutritionInformationTableColumns}
              data={form.getValues()?.typical_values || []}
              getRowProps={row => {
                return {
                  className: `h-10 rounded border-t-4 border-b-4 border-white-600 
                ${handleBgColorTailwind(
                  rowColor(row.index, eggColors, row.depth),
                )}`,
                };
              }}
              getSubRows={getSubRows as any}
              expanded={expanded === 'Yes'}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light">
            Food Traffic light
          </h3>

          <div className="grid grid-cols-12 mt-2 mb-4">
            <div className="col-span-4 col-start-4 bg-white rounded">
              <TableInput
                name="food_traffic_light.serving_suggestion"
                className="w-full"
                placeholder="type serving suggestion..."
                disabled={!urlParams?.editMode}
                dense={false}
              />
            </div>

            <div className="col-span-2 bg-white rounded">
              <TableAutoComplete
                name="food_traffic_light.contain"
                disabled={!urlParams?.editMode}
                itemDropDown={
                  allStatus?.data?.serving_suggestion_drop_down || []
                }
              />
            </div>
          </div>

          <div className="flex flex-row justify-center">
            {[
              {
                header: 'Energy',
                color: 'white',
                headerComp: {
                  values: [
                    form.getValues(`typical_values.${0}.per_serving`),
                    form.getValues(`typical_values.${1}.per_serving`),
                  ],
                },
                footerComp: {
                  value: form.getValues(`typical_values.${0}.ri_per_serving`),
                },
              },
              {
                header: 'Fat',
                color: eggColors?.fat,
                headerComp: {
                  value: form.watch(`typical_values.${2}.per_serving`),
                },
                footerComp: {
                  value: form.getValues(`typical_values.${2}.ri_per_serving`),
                },
              },
              {
                header: 'Saturates',
                color: eggColors?.saturates,
                headerComp: {
                  value: form.watch(`typical_values.${3}.per_serving`),
                },
                footerComp: {
                  value: form.getValues(`typical_values.${3}.ri_per_serving`),
                },
              },
              {
                header: 'Sugars',
                color: eggColors?.sugars,
                headerComp: {
                  value: form.watch(`typical_values.${5}.per_serving`),
                },
                footerComp: {
                  value: form.getValues(`typical_values.${5}.ri_per_serving`),
                },
              },
              {
                header: 'Salt',
                color: eggColors?.salt,
                headerComp: {
                  value: form.watch(`typical_values.${8}.per_serving`),
                },
                footerComp: {
                  value: form.getValues(`typical_values.${8}.ri_per_serving`),
                },
              },
            ].map(({ color, header, headerComp, footerComp }) => (
              <Egg
                color={color}
                key={header}
                header={header}
                headerComp={headerComp}
                footerComp={footerComp}
              />
            ))}
          </div>

          <div className="grid grid-cols-4 mt-2 mb-4">
            <div className="col-span-2 col-start-2 bg-white mt-4 mb-px text-center">
              <p className="text-primary font-roboto h-10 leading-10 text-sm">
                % of an adult&apos;s Reference Intake
              </p>
            </div>

            <div className="col-span-2 col-start-2 bg-white text-center">
              <p className="text-primary font-roboto h-10 leading-10 text-sm">
                Typical Energy values{' '}
                {form.watch('typical_values_per')?.name?.toLowerCase()}:{' '}
                {formWatch[0]}kJ / {formWatch[1]}
                kcal
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light">
            Nutreint Targets
          </h3>

          <Table
            loading={useProductNutritionUtils.isLoading}
            className="mt-2 border-collapse"
            size="small"
            headerCellClassName={clsx(
              'font-normal text-primary-light text-left',
              'bg-white-400 py-1 px-4',
            )}
            bodyRowClassName="rounded h-10 bg-white border-t-2 border-b-2 border-white-600"
            bodyCellClassName="text-primary py-0 px-1 h-10"
            columns={NutritionTargetsTableColumns}
            data={form.getValues()?.nutrient_targets || []}
            expanded
          />
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light">Minerals</h3>

          <div className="flex flex-row items-center justify-between bg-white mt-2 py-3 px-4 w-1/2">
            <p className="font-roboto text-primary text-sm">
              Minerals - Is this relevant?
            </p>

            <Controller
              name="expanded_state.minerals"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <Switch
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={value ? 'Yes' : 'No'}
                  onChange={val => {
                    return val === 'Yes' ? onChange(true) : onChange(false);
                  }}
                />
              )}
            />
          </div>

          <Collapse
            in={form.watch('expanded_state.minerals')}
            timeout="auto"
            unmountOnExit
          >
            <Table
              loading={useProductNutritionUtils.isLoading}
              className="border-collapse"
              size="small"
              headerCellClassName={clsx(
                'font-normal text-primary-light text-left',
                'bg-white-400 py-1 px-2',
              )}
              bodyRowClassName="rounded h-10 bg-white border-t-4 border-b-4 border-white-600"
              bodyCellClassName="text-primary py-0 px-1 h-10"
              headerFirstCellClassName="pl-4"
              rowFirstCellClassName="pl-2 pr-1"
              columns={MineralTableColumns}
              data={form.getValues().minerals || []}
              expanded
            />

            <div className="flex flex-row justify-end mt-3">
              <Button
                variant="outlined"
                className="border-1 border-primary ml-2"
                onClick={() => onAddRow('minerals')}
                startIcon={<Add />}
                text="New Row"
                disabled={!urlParams.editMode}
              />
            </div>
          </Collapse>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="font-roboto text-base text-primary-light">Vitamins</h3>

          <div className="flex flex-row items-center justify-between bg-white mt-2 py-3 px-4 w-1/2">
            <p className="font-roboto text-primary text-sm">
              Vitamins - Is this relevant?
            </p>

            <Controller
              name="expanded_state.vitamins"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <Switch
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={value ? 'Yes' : 'No'}
                  onChange={val =>
                    val === 'Yes' ? onChange(true) : onChange(false)
                  }
                />
              )}
            />
          </div>

          <Collapse
            in={form.watch('expanded_state.vitamins')}
            timeout="auto"
            unmountOnExit
          >
            <Table
              loading={useProductNutritionUtils.isLoading}
              className="border-collapse"
              size="small"
              headerCellClassName={clsx(
                'font-normal text-primary-light text-left',
                'bg-white-400 py-1 px-2',
              )}
              bodyRowClassName="rounded h-10 bg-white border-t-4 border-b-4 border-white-600"
              bodyCellClassName="text-primary py-0 px-1 h-10"
              headerFirstCellClassName="pl-4"
              rowFirstCellClassName="pl-2 pr-1"
              columns={VitaminsTableColumns}
              data={form.getValues().vitamins || []}
              expanded
            />

            <div className="flex flex-row justify-end mt-3">
              <Button
                variant="outlined"
                className="border-1 border-primary ml-2"
                onClick={() => onAddRow('vitamins')}
                startIcon={<Add />}
                text="New Row"
                disabled={!urlParams.editMode}
              />
            </div>
          </Collapse>
        </div>

        <FormButtonGroup
          share={share}
          pageName="nutritions"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default Products;
