/* eslint-disable no-unreachable */
/* eslint-disable no-console */
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCksD2OFSTiwWmPWPCzSAidrVvHoVFMNEc',
  authDomain: 'laravel-notification-96fc9.firebaseapp.com',
  projectId: 'laravel-notification-96fc9',
  storageBucket: 'laravel-notification-96fc9.appspot.com',
  messagingSenderId: '480809151146',
  appId: '1:480809151146:web:d3884f23a5662c7db27ed3',
};

// Initialize Firebase

export const isWindowSupported = () => {
  return isSupported();
};

let firebaseApp;
let messaging;

(async () => {
  const arr = await isWindowSupported();

  if (arr) {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
  }
})();

export const fetchToken = setTokenFound => {
  return getToken(messaging, {
    vapidKey:
      'BF6c8H0ermIF7FMSoHoC_lTOXkHaaKkhMb4VYRP7LLbDfcpjVtZh3_al-PXRkUOlI544PTZiYTQtYgAf00ANTtA',
  })
    .then(currentToken => {
      if (currentToken) {
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () => {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};
