import { Cell } from 'react-table';
import { FieldPath } from 'react-hook-form';
import { Qas } from '@hooks/api/wizard/types';

export enum QasTables {
  ProductAttributes = 'product_attributes',
  Organoleptics = 'organoleptics',
  PhotoStandards = 'photo_standards',
}

export interface TableInputProps {
  name: FieldPath<Qas>;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  dense?: boolean;
}

export type CustomCell = Cell<{ userAdded?: boolean }>;

export type Expandables = Omit<Qas, 'cardbox_status' | 'errors' | 'warnings'>;

export type ExpandableKeys = keyof Expandables;
