import React from 'react';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';

export type Item = {
  name: string;
  code: string | number;
  [key: string]: string | number | boolean;
} | null;

interface P {
  id?: string;
  items: Array<Readonly<NonNullable<Item>>>;
  className?: string;
  inputRootClassName?: string;
  inputPlaceholder?: string;
  value: Item;
  onChange: (val: Item) => void;
  disabled?: boolean;
  freeSolo?: boolean;
  canAddCustomItem?: boolean;
  options?: never;
  renderInput?: never;
  inputProps?: never;
  dense?: boolean;
  error?: boolean;
}

type StyleRelatedProps = Pick<P, 'dense' | 'disabled' | 'error'>;

const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 0 : 1),

    '& .MuiInputBase-root': {
      backgroundColor: ({ disabled }: { disabled?: boolean }) =>
        disabled ? theme.palette.common.white : theme.palette.gray.light,

      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ error }: StyleRelatedProps) =>
        error ? theme.palette.error.dark : theme.palette.gray.light,

      color: 'inherit',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
    },
  },
  popper: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.light,
    },

    '& .MuiAutocomplete-noOptions': {
      color: theme.palette.primary.light,
    },
  },
  autoComplete: {
    backgroundColor: theme.palette.common.white,
    height: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 3 : 5),

    '& .MuiAutocomplete-inputRoot': {
      height: '100%',
    },

    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,

    '& input': {
      padding: `${theme.spacing(0, 1)} !important`,

      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',

      '&::placeholder': {
        color: theme.palette.gray.main,
      },
    },
  },
  groupLabel: {
    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,
  },
  option: {
    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const filter = createFilterOptions<Readonly<NonNullable<Item>>>();

type CustomAutoComplete = AutocompleteProps<
  Readonly<NonNullable<Item>>,
  false,
  boolean,
  true
>;

const Autocomplete: React.FC<P & Omit<CustomAutoComplete, keyof P>> = ({
  id,
  className,
  inputRootClassName,
  inputPlaceholder = 'Select...',
  items,
  value,
  onChange,
  disabled,
  freeSolo = true,
  canAddCustomItem = true,
  options,
  renderInput,
  dense = false,
  error = false,
  disableClearable,
  ...rest
}) => {
  const classes = useStyles({ disabled, dense, error });

  const [localValue, setLocalValue] = React.useState('');

  return (
    <MuiAutocomplete
      popupIcon={disabled ? null : <ArrowDropDown />}
      disabled={disabled}
      disableClearable={disableClearable}
      fullWidth
      forcePopupIcon
      size="small"
      getOptionSelected={(option, selectedVal) => {
        if (!option?.code || !selectedVal?.code) return false;

        return option?.code === selectedVal?.code;
      }}
      className={clsx(classes.autoComplete, 'transition-all', className)}
      value={value !== undefined ? value : null} // this ensures that the component is always `controlled`
      onChange={(_, newValue, reason) => {
        if (reason === 'clear') {
          onChange(null);
        }

        if (newValue && typeof newValue === 'object') onChange(newValue);

        if (newValue && typeof newValue === 'string') {
          // sometimes when onblur happen the component
          // only gives the item `name` as the new value
          const selectedOption = items.find(el => el && el.name === newValue);

          onChange({
            ...selectedOption,
            name: newValue,
            code:
              selectedOption?.code || selectedOption?.code === 0
                ? selectedOption?.code
                : -1,
          });
        }
      }}
      classes={{
        popper: clsx(classes.popper),
        groupLabel: classes.groupLabel,
        option: classes.option,
      }}
      filterOptions={(options2, params) => {
        const filtered = filter(options2, params);

        const doesItemAlreadyExist = !!filtered.find(
          el => el.name.toLowerCase() === (localValue || '').toLowerCase(),
        );

        // Suggest the creation of a new value
        if (canAddCustomItem && localValue !== '' && !doesItemAlreadyExist) {
          filtered.push({
            name: `${localValue}`,
            code: -1,
          });
        }

        return filtered;
      }}
      selectOnFocus
      autoSelect
      handleHomeEndKeys
      options={items}
      getOptionLabel={option => option?.name || ''}
      // in order to hide the endAdornment Icon when autocomplete is disabled
      // free solo gets enabled when disabled is active
      freeSolo={disabled ? true : freeSolo}
      clearOnBlur={!freeSolo}
      renderInput={params => (
        <TextField
          {...params}
          classes={{
            root: clsx(classes.inputRoot, inputRootClassName),
          }}
          placeholder={inputPlaceholder}
          inputProps={{
            ...params.inputProps,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              (params.inputProps as any)?.onChange(e);

              setLocalValue(e.target.value);
            },
            autoComplete: 'new-password',
          }}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
