import {
  useQuery,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import errorHandler from '@utils/errorHandler';

import { useUser } from '@contexts';

import userApis from '@hooks/api/user/api';
import {
  UseUserProfileResponseType,
  GetUsersListParams,
  UseUsersListResponseType,
  GetUserInfoParams,
  UseUserSettingResponseType,
  UseUserVerificationResponseType,
} from '@hooks/api/user/types';

const KEYS = {
  currentUser: ['currentUser'],
  setting: ['setting'],
  users: ['users'],
  usersList: ['users', 'list'],
  usersInfo: ['users', 'info'],
  verification: ['verification'],
} as const;

const useCurrentUserProfile = (
  options?: UseQueryOptions<UseUserProfileResponseType, AxiosError>,
) => {
  const query = useQuery<UseUserProfileResponseType, AxiosError>(
    KEYS.currentUser,
    () => userApis.fetchUserProfile(),
    options,
  );

  return query;
};

const useVerification = (
  options?: UseQueryOptions<UseUserVerificationResponseType, AxiosError>,
) => {
  const query = useQuery<UseUserVerificationResponseType, AxiosError>(
    KEYS.verification,
    () => userApis.getVerification(),
    options,
  );

  return query;
};

const useUpdateUserProfileMutation = () => {
  const queryClient = useQueryClient();
  const [user, setUser] = useUser();

  const mutation = useMutation(userApis.updateUserProfile, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      if (user)
        setUser({
          ...user,
          picture: data.data.picture,
        });

      queryClient.invalidateQueries(KEYS.currentUser);
    },
  });

  return mutation;
};

const useUpdateUserProfileChangePasswordMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userApis.updateUserProfileChangePassword, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.currentUser);
    },
  });

  return mutation;
};

const useCurrentUserSetting = (
  options?: UseQueryOptions<UseUserSettingResponseType, AxiosError>,
) => {
  const query = useQuery<UseUserSettingResponseType, AxiosError>(
    KEYS.setting,
    () => userApis.fetchUserSetting(),
    options,
  );

  return query;
};

const useUpdateUserSettingMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userApis.updateUserSetting, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
      queryClient.invalidateQueries(KEYS.setting);
    },
  });

  return mutation;
};

const useUsersList = (
  params?: GetUsersListParams,
  options?: UseQueryOptions<UseUsersListResponseType, AxiosError>,
) => {
  const query = useQuery<UseUsersListResponseType, AxiosError>(
    [...KEYS.usersList, params],
    () => userApis.getUsersList(params),
    options,
  );

  return query;
};

const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  const [user, , logout] = useUser();

  const mutation = useMutation(userApis.deleteUser, {
    onError: errorHandler,
    onSuccess: data => {
      if (user?.username === data.username) {
        logout();
      }

      queryClient.invalidateQueries(KEYS.usersList);
    },
  });

  return mutation;
};

const useUserInfo = (
  params: GetUserInfoParams,
  options?: UseQueryOptions<UseUserProfileResponseType, AxiosError>,
) => {
  const query = useQuery<UseUserProfileResponseType, AxiosError>(
    [...KEYS.usersList, params],
    () => userApis.getUserInfo(params),
    options,
  );

  return query;
};

const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userApis.updateUser, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
      queryClient.invalidateQueries(KEYS.users);
    },
  });

  return mutation;
};

const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userApis.createUser, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.users);
    },
  });

  return mutation;
};

const useResendWelcomeEmailMutation = () => {
  const mutation = useMutation(userApis.resendWelcomeEmail, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useChangeUserStatusMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(userApis.changeUserStatus, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.users);
    },
  });

  return mutation;
};

export default {
  useCurrentUserProfile,
  useUpdateUserProfileMutation,
  useUpdateUserProfileChangePasswordMutation,
  useCurrentUserSetting,
  useUpdateUserSettingMutation,
  useDeleteUserMutation,
  useUsersList,
  useUserInfo,
  useUpdateUserMutation,
  useCreateUserMutation,
  useResendWelcomeEmailMutation,
  useChangeUserStatusMutation,
  useVerification,
};
