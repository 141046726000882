import SubscriptionHooks from '@hooks/api/subscription';

import { Layout, Breadcrumbs } from '@components/Common';
import Box from '@components/Dashboard/Box';

const SubscriptionComp = () => {
  const { data: subscriptionData } = SubscriptionHooks.useSubscription();

  return (
    <Layout>
      <Breadcrumbs onClick={() => {}} items={[{ href: '/', text: 'home' }]} />

      <h3 className="text-base text-primary-light mb-2 mt-6">Statistics</h3>

      <div className="flex flex-col mb-4">
        <div className="flex flex-row items-center px-4 h-10 bg-white-400 rounded">
          <p className="text-base font-roboto text-primary-light">Service</p>
        </div>

        <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
          {(
            [
              {
                displayText: 'Service Type',
                key: 'service_type',
              },
              {
                displayText: 'Full Licence Customer',
                key: 'full_license_customer',
              },
              {
                displayText: 'Light Licence Customer',
                key: 'light_license_customer',
              },
              {
                displayText: 'Spec',
                key: 'spec',
              },
              {
                displayText: 'Trial Duration',
                key: 'trial_duration',
              },
              {
                displayText: 'Supplier',
                key: 'supplier',
              },
              {
                displayText: 'Supplier Users',
                key: 'supplier_user',
              },
              {
                displayText: 'Support',
                key: 'support',
              },
            ] as const
          ).map(el => (
            <div
              key={el.key}
              className=" col-span-1 flex flex-row items-center bg-white rounded shadow-sm py-2"
            >
              <div>
                <p className="font-roboto pl-4 w-52">{el.displayText}</p>
              </div>

              <div className="flex flex-col w-full">
                <>{subscriptionData?.data.service[el.key]}</>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-10">
        {(subscriptionData?.data.general || []).map(el => (
          <Box key={el.header} header={el.header} data={el.data} />
        ))}
      </div>
    </Layout>
  );
};

export default SubscriptionComp;
