import React from 'react';
import { Cell } from 'react-table';

import { Add } from '@material-ui/icons';

import PoclicyHooks from '@hooks/api/policies';
import { Policy } from '@hooks/api/policies/types';

import {
  Layout,
  Table,
  Breadcrumbs,
  UploadButtonUploadOnChange as UploadButton,
  TableTrashButton,
  Button,
} from '@components/Common';

import { useUser } from '@contexts';

import NewPolicyModal from '@components/Policies/NewPolicyModal';

const PoliciesListComp = () => {
  const [user] = useUser();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [isNewPolicyModalOpen, setIsNewPolicyModalOpen] = React.useState(false);

  const { data: policiyData, ...usePoliciesUtils } = PoclicyHooks.usePolicies({
    page: currentPage,
  });
  const tashPolicyMutation = PoclicyHooks.useTrashPolicyMutation();

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof Policy | 'action';
      id: keyof Policy | 'action';
      width?: number;
      className?: string;
      Cell?: undefined | ((cell: Cell<Policy>) => JSX.Element);
    }> => [
      {
        Header: 'Policy Title',
        accessor: 'title',
        id: 'title',
        width: 210,
      },
      {
        Header: 'Date Uploaded',
        accessor: 'date_uploaded',
        id: 'date_uploaded',
        width: 260,
      },
      {
        Header: 'Uploaded By',
        accessor: 'uploaded_by',
        id: 'uploaded_by',
        width: 120,
      },
      {
        Header: 'Acknowledgement Required',
        accessor: 'acknowledgement_required',
        id: 'acknowledgement_required',
        className: 'text-center',
        width: 220,
      },
      {
        Header: 'File',
        accessor: 'file',
        id: 'file',
        className: 'text-center',
        width: 30,
        Cell: cell => (
          <UploadButton
            disabled
            id={cell.row.index.toString()}
            url=""
            wrapperClassName="flex justify-center my-2"
            value={cell.value}
            onChange={() => {}}
          />
        ),
      },
      {
        Header: user?.access_levels.policy_soft_delete ? 'Action' : '',
        accessor: 'action',
        id: 'action',
        className: 'text-center',
        width: user?.access_levels.policy_soft_delete ? 30 : 0,
        Cell: cell => (
          <>
            {user?.access_levels.policy_soft_delete ? (
              <TableTrashButton
                onClick={() =>
                  cell.row?.original?.policy_code &&
                  tashPolicyMutation.mutate({
                    policy_code: cell.row.original.policy_code,
                  })
                }
              />
            ) : null}
          </>
        ),
      },
    ],
    [usePoliciesUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="pb-10">
      {isNewPolicyModalOpen ? (
        <NewPolicyModal
          open={isNewPolicyModalOpen}
          setOpen={setIsNewPolicyModalOpen}
        />
      ) : null}

      <Breadcrumbs
        onClick={() => {}}
        items={[{ href: '#', text: 'Policies' }]}
      />

      <div className="flex flex-row items-center justify-between mt-10">
        <p className="text-base text-primary-light font-roboto">Policies</p>

        {user?.access_levels.policy_create && (
          <Button
            variant="outlined"
            className="border-1 border-primary"
            onClick={() => setIsNewPolicyModalOpen(true)}
            startIcon={<Add />}
            text="Add New"
          />
        )}
      </div>

      <Table
        loading={usePoliciesUtils.isLoading}
        className="my-2"
        bodyRowClassName="rounded bg-white border-t-4 border-b-4 border-white-600"
        headerCellClassName="text-primary-light font-normal font-roboto"
        bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
        columns={columns}
        data={policiyData?.data || []}
        paginationProps={{
          page: currentPage,
          onPageChange: setCurrentPage,
          pageCount: policiyData?.meta?.last_page,
        }}
      />
    </Layout>
  );
};

export default PoliciesListComp;
