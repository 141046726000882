import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { useHistory } from 'react-router-dom';

import {
  IconButton,
  Popper,
  Grow,
  Divider,
  ClickAwayListener,
} from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  Settings,
  Person,
  ExitToApp,
} from '@material-ui/icons';

import RouteMap from '@routes/map';

import { useSidebarState, useUser } from '@contexts';

import { Sidebar, Button, Notification } from '@components/Common';
import Cookies from 'js-cookie';

type P = {
  children: React.ReactNode;
  mainClassNames?: string;
  share?: boolean;
};

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    height: theme.appBarHeight,
    zIndex: ({ isSidebarOpen }: { isSidebarOpen: boolean }) =>
      isSidebarOpen ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1,
  },
  appBarShift: {
    '& .MuiButtonBase-root': {
      zIndex: theme.zIndex.drawer - 10,
    },
  },
  content: {
    marginLeft: theme.closedDrawerWidth,
    marginTop: theme.appBarHeight,
  },
  contentShift: {
    marginLeft: theme.drawerWidth,
  },
}));

const Layout: React.FC<P> = ({ children, mainClassNames, share = false }) => {
  const history = useHistory();

  const [isSidebarOpen, setIsSidebarOpen] = useSidebarState();
  const [user, , logout] = useUser();

  const [open, setOpen] = React.useState(false);
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);

  const classes = useStyles({ isSidebarOpen });

  return (
    <>
      {share ? (
        <>
          <div
            className={clsx(
              'bg-primary transition-all fixed top-0 left-0 right-0',
              'py-6 pr-4 pl-2 text-right z-50',
            )}
          >
            <div className="flex flex-row items-center justify-end">
              <Button
                className=" px-4"
                onClick={e => {
                  Cookies.remove('public_token');
                  history.push(RouteMap.auth.login);
                }}
              >
                <ExitToApp className="text-white text-lg" />

                <span className="ml-4 text-sm font-roboto text-white">
                  Log out
                </span>
              </Button>
            </div>
          </div>

          <main className={overrideTailwindClasses(clsx('px-8 pt-24'))}>
            {children}
          </main>
        </>
      ) : (
        <>
          <div
            className={clsx(
              'bg-primary transition-all fixed top-0 left-0 right-0',
              'flex flex-row justify-between items-center',
              'py-2 pr-4 pl-2',
              classes.appBar,
              !isSidebarOpen && classes.appBarShift,
            )}
          >
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <KeyboardArrowRight className="text-white" />
            </IconButton>

            <div className="flex">
              <Notification />
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="text"
                  innerRef={menuAnchorRef}
                  onClick={() => setOpen(!open)}
                >
                  <p className="text-white-600">{user?.name}</p>

                  <KeyboardArrowDown className="text-white-600" />
                </Button>

                <Popper
                  open={open}
                  anchorEl={menuAnchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  placement="bottom-end"
                  className="z-20 bg-white shadow-lg rounded"
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'center top',
                      }}
                    >
                      <div>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                          <div className="flex flex-col py-4 shadow">
                            <div className="px-4 flex flex-row justify-center items-center">
                              <img
                                className="w-14 h-14 rounded-full mr-4"
                                src={user?.picture || ''}
                                alt="user profile"
                              />

                              <div className="flex flex-col">
                                <h3 className="text-sm font-roboto text-gray">
                                  {user?.name}
                                </h3>
                                <p className="text-xs font-roboto text-gray-800">
                                  {user?.email}
                                </p>
                              </div>
                            </div>

                            <Divider className="my-4" />

                            <a
                              className="flex flex-row items-center px-4"
                              href={RouteMap.configurations.setting}
                            >
                              <Settings className="text-gray text-lg" />

                              <p className="ml-4 text-sm font-roboto text-gray">
                                Setting
                              </p>
                            </a>

                            <a
                              className="flex flex-row items-center px-4 my-4"
                              href={RouteMap.configurations.profile}
                            >
                              <Person className="text-gray text-lg" />

                              <p className="ml-4 text-sm font-roboto text-gray">
                                Profile
                              </p>
                            </a>

                            <a
                              className="flex flex-row items-center px-4"
                              href="/#"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                logout();
                              }}
                            >
                              <ExitToApp className="text-gray text-lg" />

                              <p className="ml-4 text-sm font-roboto text-gray">
                                Log out
                              </p>
                            </a>
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>

          <Sidebar />
          <main
            className={overrideTailwindClasses(
              clsx(
                classes.content,
                'px-8 pt-4 transition-all',
                {
                  [classes.contentShift]: isSidebarOpen,
                },
                mainClassNames,
              ),
            )}
          >
            {children}
          </main>
        </>
      )}
    </>
  );
};

export default Layout;
