import {
  QasProductAttributes,
  QasPhotoStandards,
  QasOrganoleptics,
} from '@app/hooks/api/wizard/types';

const qasProductAttributesInitRow: QasProductAttributes = {
  status: '',
  green: {
    pic1: '',
    pic2: '',
    pic3: '',
    value: '',
  },
  amber: {
    pic1: '',
    pic2: '',
    pic3: '',
    value: '',
  },
  red: {
    pic1: '',
    pic2: '',
    pic3: '',
    value: '',
  },
  userAdded: true,
};

const qasOrganolepticsInitRow: QasOrganoleptics = {
  amber: '',
  green: '',
  red: '',
  status: '',
  userAdded: true,
};

const qasPhotoStandardsInitRow: QasPhotoStandards = {
  title: '',
  items: [
    {
      file: '',
      value: '',
    },
    {
      file: '',
      value: '',
    },
    {
      file: '',
      value: '',
    },
    {
      file: '',
      value: '',
    },
  ],
  userAdded: true,
};

export {
  qasProductAttributesInitRow,
  qasOrganolepticsInitRow,
  qasPhotoStandardsInitRow,
};
