import React from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';

import Config from '@constants/config';

interface P {
  wrapperClassName?: string;
  disabled?: boolean;
}

type StyleRelatedProps = Pick<P, 'disabled'>;

const useStyles = makeStyles((theme: any) => ({
  root: {
    margin: 0,

    '& .MuiInput-root': {
      backgroundColor: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.common.white : theme.palette.gray.light,
      boxSizing: 'border-box',
      height: theme.spacing(3),
      paddingLeft: theme.spacing(1),

      fontFamily: theme.fonts.secondary,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,

      // color: theme.palette.secondary.dark,
      color: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.gray.main : theme.palette.secondary.dark,
    },

    '& .MuiInputAdornment-root button': {
      padding: 0,
    },
  },
}));

const DatePicker: React.FC<P & Omit<KeyboardDatePickerProps, keyof P>> = ({
  wrapperClassName,
  className,
  onChange,
  disabled,
  ...rest
}) => {
  const classes = useStyles({ disabled });

  const [open, setOpen] = React.useState(false);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={clsx('bg-white p-2', wrapperClassName)}>
        <KeyboardDatePicker
          keyboardIcon={disabled ? null : <EventIcon />}
          disabled={disabled}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(date, value) => {
            onChange(date, value);

            setOpen(false);
          }}
          invalidDateMessage={false}
          disableToolbar
          className={clsx(classes.root, className)}
          variant="inline"
          format={Config.dateFormat}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...rest}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
