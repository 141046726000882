const { host } = window.location;

let env = 'production'; // Defaults to production
if (process.env.NODE_ENV === 'development' || host.includes('local'))
  env = 'development';

let dynamicApiUrl = '';
if (process.env.NODE_ENV === 'development') {
  dynamicApiUrl = 'https://app.specready.co.uk/api';
} else {
  dynamicApiUrl = 'https://app.specready.co.uk/api';
}

export default {
  // App Details
  appName: 'SpecReady',
  baseUrl: 'https://app.specready.co.uk',

  // Build Configuration - eg. Debug or Release?
  isDevEnv: env === 'development',
  ENV: env,

  // Date Format
  dateFormat: 'dd/MM/yyyy',
  // API
  apiBaseUrl: dynamicApiUrl,
  // apiBaseUrl: 'https://app.specready.co.uk/api',
};
