import * as DateUtils from 'date-fns';

import Config from '@constants/config';

import { CardBoxStatus } from '@hooks/api/generalTypes';

const getKeyValue =
  <T extends Record<string, string>, U extends keyof T>(key: U) =>
  (obj: T) =>
    obj[key];

function removeFalsyKeyFromObject(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (typeof v === 'number') return true;

      return !!v;
    }),
  );
}

const convertToIso = (date: string) => {
  const isFormatDesired = DateUtils.isMatch(date, Config.dateFormat);

  if (isFormatDesired)
    return DateUtils.parse(date, Config.dateFormat, new Date());

  return new Date(date);
};

function formatDate(date: string | number | Date, format?: string) {
  if (!date) return '';

  try {
    const validDate = typeof date === 'string' ? convertToIso(date) : date;

    return DateUtils.format(validDate, format || Config.dateFormat);
  } catch (error) {
    return '';
  }
}

function deepClone<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

function isPrimitive(value: unknown) {
  return Object(value) !== value;
}

type StatusDetectionParams =
  | null
  | undefined
  | CardBoxStatus
  | 'Pending Approval';

function isDraft(status: StatusDetectionParams) {
  return status === 'Draft';
}

function isApproved(status: StatusDetectionParams) {
  return status === 'Approved';
}

function isPendigForApproval(status: StatusDetectionParams) {
  return status === 'PendingApproval' || status === 'Pending Approval';
}

// this is DUMB , but check phone only for lenght (min 11 , max 20)
const phoneRegex = /^.{11,20}$/;
// const phoneRegex =
//   /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const pascalize = (text: string) => {
  const withNoUnderscore = text
    .trim()
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return withNoUnderscore;
};

const numberValidator = (val: unknown) => {
  if (val === 0) return true;

  if (val === null || val === undefined || val === '') return false;

  return Number.isFinite(Number(val));
};

const switchValidator = (val: unknown) => typeof val === 'boolean';

const arrayValidator = (val: unknown) => {
  if (val === null || val === undefined) return false;

  return Array.isArray(val) && !!val?.length;
};

export {
  getKeyValue,
  formatDate,
  removeFalsyKeyFromObject,
  deepClone,
  isPrimitive,
  phoneRegex,
  convertToIso,
  isDraft,
  isApproved,
  isPendigForApproval,
  pascalize,
  numberValidator,
  switchValidator,
  arrayValidator,
};
