import {
  ManufactureDetailInstance,
  RecycleIconDetailsInstance,
  PalletisngInfoInstance,
} from '@hooks/api/wizard/types';

const manufactureDetailInstance: ManufactureDetailInstance = {
  packaging_type: null,
  details: {
    comments: '',
    colour: '',
    component_description: '',
    component_of_weight_g: null,
    country_of_origin: [],
    diameter_mm: null,
    food_contact_certified: null,
    gauge_of_thickness_micro: null,
    height_mm: null,
    length_mm: null,
    material: null,
    method_of_closure: null,
    used_recycled_material_content_percent: null,
    component_recyclable: null,
    packaging_material_biodegradable: null,
    supplier_name: '',
    width_mm: null,
    upload_food_contact_certificate: '',
  },
};

const recyclingIconDetailInstance: RecycleIconDetailsInstance = {
  supporting_text: '',
  text: null,
  used_on_pack: null,
};

const palletisngInfoInstance: PalletisngInfoInstance = {
  pallet_general_info: {
    is_there_a_pallet_label: null,
    method_of_pallet_security: null,
    pallet_material: '',
  },
  pallet_details: {
    height_mm: null,
    length_mm: null,
    weight_g: null,
    width_mm: null,
    pallet_type: null,
  },
  loaded_pallet_assembled: {
    assembled_pallet_height_mm: null,
    max_pallet_weight_kg: null,
  },
};

export default {
  manufactureDetailInstance,
  recyclingIconDetailInstance,
  palletisngInfoInstance,
};
