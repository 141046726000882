import React from 'react';
import { useForm, Controller, FieldPath } from 'react-hook-form';
import { Cell } from 'react-table';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  removeElements,
  isNode,
  isEdge,
  Position,
  ConnectionMode,
  // types
  ReactFlowProps,
  NodeProps,
  EdgeProps,
  OnLoadParams as ReactFlowInstaceType,
} from 'react-flow-renderer';
import domtoimage from 'dom-to-image';
import dagre from 'dagre';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Add as AddIcon,
  ControlPoint as ControlPointIcon,
} from '@material-ui/icons';

import { useUrlParams } from '@hooks';

import GeneralHooks from '@hooks/api/general';

import WizardHooks from '@hooks/api/wizard';
import {
  CCP,
  CriticalControlInstance,
  QualityControlInstance,
} from '@hooks/api/wizard/types';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import CustomNode, {
  NODE_WIDTH_PX,
  NODE_HEIGHT_PX,
} from '@components/Bussines/Products/ProductForm/Flow/CustomNode';
import CustomEdge from '@components/Bussines/Products/ProductForm/Flow/CustomEdge';

import FlowModal, {
  FormValues,
} from '@components/Bussines/Products/ProductForm/Flow/FlowModal';
import {
  CustomNode as TCustomNode,
  CustomEdge as TCustomEdge,
  CustomElements,
} from '@components/Bussines/Products/ProductForm/Flow/types';

import {
  Input,
  TextEditor,
  Table,
  Button,
  UploadButtonUploadOnChange,
  TableTrashButton,
} from '@components/Common';

import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: any) => ({
  tableWrap: {
    display: 'block',
    'max-width': '100%',
    'overflow-x': 'auto',

    paddingBottom: 12,

    '&::-webkit-scrollbar': {
      height: 11,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.palette.gray.main,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.error.light,
      borderRadius: 10,
    },
  },
}));

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

type SortDirections = 'H' | 'V';
const DEFAULT_SORT_DIRECTION = 'H' as const;

const getLayoutedElements = (
  elements: CustomElements,
  direction: SortDirections = DEFAULT_SORT_DIRECTION,
) => {
  const isHorizontal = direction === 'H';

  const isThereAnyEdge = elements.find(isEdge);

  // eslint-disable-next-line no-nested-ternary
  const rankdir = isThereAnyEdge
    ? isHorizontal
      ? 'LR'
      : 'TB'
    : isHorizontal
    ? 'TB'
    : 'LR';
  // the above condition does not realy make sense but 'dagre'
  // provides diffrent result based off of node being connected or not
  // and it looks reversed for some reason , so we are switching the direction here
  // when there isn't any edge present in the elements , it's not realy perfect
  // but it's a good hack for graphs with lower number of elements

  dagreGraph.setGraph({ rankdir });

  elements.forEach(el => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: NODE_WIDTH_PX,
        height: NODE_HEIGHT_PX,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map(el => {
    const newEl = { ...el };

    if (isNode(newEl)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      newEl.targetPosition = isHorizontal ? Position.Left : Position.Top;
      newEl.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      newEl.position = {
        x: nodeWithPosition.x - NODE_WIDTH_PX / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - NODE_HEIGHT_PX / 2,
      };
    }

    return newEl;
  });
};

const validationSchema = yup.object({
  process_flow: yup.object({
    file: yup
      .mixed()
      .test('file', 'error message is irrelevant', (val, contxt) => {
        if (contxt.parent.text !== '<p><br></p>') {
          return true;
        }

        return !!val;
      }),
    text: yup
      .mixed()
      .test('text', 'error message is irrelevant', (val, contxt) => {
        // console.log('🚀 ~ file: index.tsx ~ line 173 ~ .test ~ val', val);
        // console.log('🚀.test ~ contxt.parent.file', contxt.parent.file);
        if (contxt.parent.file) {
          // console.log('🚀  ~ line 180 ~ .test ~ return true');
          return true;
        }
        if (val === '<p><br></p>') {
          // console.log('🚀  ~ line 183 ~ .test ~ return true');
          return false;
        }
        if (val) {
          // console.log('🚀  ~ line 187 ~ .test ~ return true');
          return true;
        }
        // console.log('🚀  ~ line 190 ~ .test ~ return false');
        return false;
      }),
  }),

  critical_control: yup.array(
    yup.object({
      ccp_no: yup.string().ensure().required('error'),
      control_measure: yup.string().ensure().required('error'),
      corrective_action: yup.string().ensure().required('error'),
      critical_limits: yup.string().ensure().required('error'),
      hazard: yup.string().ensure().required('error'),
      monitoring_frequency: yup.string().ensure().required('error'),
      process_step: yup.string().ensure().required('error'),
    }),
  ),
});

type P = {
  share?: boolean;
};
const CCPComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const history = useHistory();

  // const [isImageProccessing, setIsImageProccessing] = React.useState(false);

  const [elements, setElements] = React.useState<CustomElements>([]);

  const [isNodeModalOpen, setIsNodeModalOpen] = React.useState(false);
  const [editableNode, setEditableNode] = React.useState<TCustomNode>();

  const [flowInstance, setFlowInstance] =
    React.useState<ReactFlowInstaceType | null>(null);

  const classes = useStyles();

  const form = useForm<CCP>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    // reValidateMode: 'onSubmit',
  });
  const { data: produtctCCp, ...productCCpUtils } = WizardHooks.useCCP(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        form.reset(data.data);

        const layoutedElements =
          data.data?.process_flow?.flow?.json_value || [];

        setElements(layoutedElements);
      },
    },
  );
  const updateCCPMutatation = WizardHooks.useUpdateCCPMutation();
  const validateCCPMutatation = WizardHooks.useValidateCCPMutation();
  const updateFileMutatation = GeneralHooks.useUploadMutation();

  const generateFlowImageExport = async () => {
    const flowMiniMap: HTMLElement | null = document.querySelector(
      '.react-flow__minimap',
    );
    const flowControlls: HTMLElement | null = document.querySelector(
      '.react-flow__controls',
    );
    const flow: HTMLElement | null = document.querySelector('#my-flow-canvas');

    if (flow && flowMiniMap && flowControlls && flowInstance) {
      // capturing the last user focus coordinance
      const {
        zoom,
        position: [x, y],
      } = flowInstance.toObject();

      // setting the canvas focus to coordinance to the center before image capture
      flowInstance.fitView();

      // hide miniMap and controls from the image
      flowMiniMap.style.visibility = 'hidden';
      flowControlls.style.visibility = 'hidden';

      // applying the scale property will make the image quality much better
      const scale = 1; // subject to change
      flow.style.transform = `scale(${scale})`;
      flow.style.transformOrigin = 'top left';
      flow.style.width = `${flow.offsetWidth}px`;
      flow.style.height = `${flow.offsetHeight}px`;

      // setIsImageProccessing(true);

      try {
        const pngDataUrl = await domtoimage.toPng(flow, {
          height: flow.offsetHeight * scale,
          width: flow.offsetWidth * scale,
        });

        const blob = await (await fetch(pngDataUrl)).blob();

        // const link = document.createElement('a');
        // link.download = 'my-image-name.png';
        // link.href = pngDataUrl;
        // link.click();

        const uploadedPngFileUrl = await updateFileMutatation.mutateAsync({
          url: `/products/${urlParams.code}/version/${urlParams.version}/ccps/upload`,
          file: blob as File,
        });

        return uploadedPngFileUrl;
      } finally {
        // reseting the styles previously applied to the elements for image capture
        flowMiniMap.style.visibility = 'visible';
        flowControlls.style.visibility = 'visible';
        flow.style.transform = `scale(${scale / scale})`;

        // reseting the last coordinance and zoom value
        flowInstance.setTransform({ x, y, zoom });

        // setIsImageProccessing(false);
      }
    }

    return undefined;
  };

  const onSave = async (link: string, data: CCP) => {
    const newData = { ...data };

    newData.process_flow.flow.json_value = flowInstance?.getElements() || [];

    try {
      const uploadedPngFileUrl = await generateFlowImageExport();

      if (uploadedPngFileUrl) {
        newData.process_flow.flow.image = uploadedPngFileUrl;
      }

      updateCCPMutatation.mutate(
        {
          code: urlParams.code,
          version: urlParams.version,
          ...newData,
        },
        {
          onSuccess: () => {
            form.reset(undefined, {
              keepDirty: false,
            });
            history.push(link);
          },
        },
      );
    } catch (error) {}
  };

  const onValidate = async (data: CCP) => {
    const newData = { ...data };

    newData.process_flow.flow.json_value = flowInstance?.getElements() || [];

    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');

        return;
      }

      const uploadedPngFileUrl = await generateFlowImageExport();
      if (uploadedPngFileUrl) {
        newData.process_flow.flow.image = uploadedPngFileUrl;
      }
      validateCCPMutatation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...newData,
      });
    } catch (error) {}
  };

  const onLoad: ReactFlowProps['onLoad'] = reactFlowInstance => {
    setFlowInstance(reactFlowInstance);

    reactFlowInstance.fitView();
  };

  const onElementsRemove: ReactFlowProps['onElementsRemove'] =
    elementsToRemove =>
      setElements(els => removeElements(elementsToRemove, els));

  const onConnect: ReactFlowProps['onConnect'] = params => {
    setElements(els => {
      const newEdgeId = `e${params.source}-${params.target}`;
      const isEdgeNew = !els.find(el => el.id === newEdgeId);

      const newEls = [...els];

      if (isEdgeNew)
        newEls.push({
          id: newEdgeId,
          source: String(params.source),
          target: String(params.target),
          type: 'customEdge',
        });

      return newEls;
    });
  };

  const removeElementById = (targetId: string) => {
    setElements(els => {
      const targetElement = els.find(el => el.id === targetId);

      return targetElement ? removeElements([targetElement], els) : els;
    });
  };

  const TableInput = React.useCallback(
    ({ name, disabled }: { name: FieldPath<CCP>; disabled: boolean }) => (
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState }) => {
          const { ref, value, ...rest } = field;

          return (
            <Input
              error={!!fieldState?.error}
              disabled={disabled}
              className="w-full h-16"
              inputClassName="h-16 p-2"
              dense
              innerRef={ref}
              value={value || ''}
              {...rest}
            />
          );
        }}
      />
    ),
    [],
  );

  const onQualityControlDeleteRow = (rowIndex: string) => {
    const newRows = [...form.getValues().quality_control];

    newRows.splice(Number(rowIndex), 1);

    form.setValue('quality_control', newRows);

    form.reset(undefined, {
      // keepDirty: true,
      keepIsValid: true,
      keepTouched: true,
      keepValues: true,
      keepErrors: true,
    });
  };

  const onCriticalControlDeleteRow = (rowIndex: string) => {
    const newRows = [...form.getValues().critical_control];

    newRows.splice(Number(rowIndex), 1);

    form.setValue('critical_control', newRows);

    form.reset(undefined, {
      // keepDirty: true,
      keepIsValid: true,
      keepTouched: true,
      keepValues: true,
      keepErrors: true,
    });
  };

  const criticalControllColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof CriticalControlInstance;
      id: keyof CriticalControlInstance | 'action';
      className?: string;
      bodyCellClassName?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell) => JSX.Element);
    }> => [
      {
        Header: 'Process step',
        id: 'process_step',
        accessor: 'process_step',
        width: 175,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.process_step`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'CCP No.',
        id: 'ccp_no',
        accessor: 'ccp_no',
        width: 120,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.ccp_no`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Hazard',
        id: 'hazard',
        accessor: 'hazard',
        width: 135,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.hazard`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Control Measure',
        id: 'control_measure',
        accessor: 'control_measure',
        width: 265,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.control_measure`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Critical limits',
        id: 'critical_limits',
        accessor: 'critical_limits',
        width: 355,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.critical_limits`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Monitoring Frequency',
        id: 'monitoring_frequency',
        accessor: 'monitoring_frequency',
        width: 190,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(
              cell.row.id,
            )}.monitoring_frequency`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Corrective action',
        id: 'corrective_action',
        accessor: 'corrective_action',
        width: 255,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.corrective_action`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        width: 160,
        Cell: cell => (
          <TableInput
            name={`critical_control.${Number(cell.row.id)}.comments`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        width: 40,
        Header: '',
        id: 'action',
        className: 'text-center',
        bodyCellClassName: 'sticky right-0 border-l-4 border-white-600',
        Cell: (cell: any) => (
          <>
            {/* {cell.rows.length <= 1 ? (
              <TableTrashButton
                disabled
                className="opacity-50"
                onClick={() => onCriticalControlDeleteRow(cell.row.id)}
              />
            ) : (
              <TableTrashButton
                disabled={!urlParams.editMode}
                onClick={() => onCriticalControlDeleteRow(cell.row.id)}
              />
            )} */}
            <TableTrashButton
              disabled={!urlParams.editMode}
              onClick={() => onCriticalControlDeleteRow(cell.row.id)}
            />
          </>
        ),
      },
    ],
    [urlParams.editMode],
  );

  const qualityControllColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof QualityControlInstance;
      id: keyof QualityControlInstance | 'action';
      className?: string;
      bodyCellClassName?: string;
      width?: number;
      Cell?: undefined | ((cell: Cell) => JSX.Element);
    }> => [
      {
        Header: 'Process step',
        id: 'process_step',
        accessor: 'process_step',
        width: 175,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.process_step`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Quality/Legal Issue',
        id: 'quality_legal',
        accessor: 'quality_legal',
        width: 180,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.quality_legal`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Hazard',
        id: 'hazard',
        accessor: 'hazard',
        width: 135,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.hazard`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Control Measure',
        id: 'control_measure',
        accessor: 'control_measure',
        width: 265,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.control_measure`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Monitoring step and procedures',
        id: 'monitoring_step',
        accessor: 'monitoring_step',
        width: 355,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.monitoring_step`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Corrective action',
        id: 'correction_action',
        accessor: 'correction_action',
        width: 255,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.correction_action`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Tolerance',
        id: 'tolerance',
        accessor: 'tolerance',
        width: 160,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.tolerance`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        width: 160,
        Cell: cell => (
          <TableInput
            name={`quality_control.${Number(cell.row.id)}.comments`}
            disabled={!urlParams.editMode}
          />
        ),
      },
      {
        width: 40,
        Header: '',
        id: 'action',
        className: 'text-center',
        bodyCellClassName: 'sticky right-0 border-l-4 border-white-600',
        Cell: cell => (
          <TableTrashButton
            disabled={!urlParams.editMode}
            onClick={() => onQualityControlDeleteRow(cell.row.id)}
          />
        ),
      },
    ],
    [urlParams.editMode],
  );

  const onLayout = React.useCallback(
    (direction: SortDirections) => {
      const newLayoutedElements = getLayoutedElements(elements, direction);

      setElements(newLayoutedElements);
    },
    [elements],
  );

  const generateNodeId = (els: CustomElements) => {
    const nodeIds = els.filter(isNode).map(el => Number(el.id));

    if (nodeIds.length === 0) {
      nodeIds.push(-1);
    }

    const lastMaxId = Math.max(...nodeIds);

    return String(lastMaxId + 1);
  };

  const onNodeEditSelect = (nodeId: string) => {
    setElements(els => {
      const targetElement = els.find(
        el => isNode(el) && el.id === nodeId,
      ) as TCustomNode;

      if (targetElement) setEditableNode(targetElement);

      return els;
    });
  };

  const onNodeUpdate = React.useCallback(
    (data: FormValues) => {
      setElements(els => {
        const newEls = [...els];

        const targetNode = newEls.find(el => el.id === editableNode?.id);
        const targetNodeIndex = newEls.findIndex(
          el => el.id === editableNode?.id,
        );

        const newEl = {
          ...targetNode,
          data: {
            ...(targetNode?.data || {}),
            label: data?.content || '',
            nodeType: data?.nodeType.code || 'default',
          },
        } as TCustomNode;

        // severing the deleted edges when node type changes
        if (newEl.data?.nodeType === 'input') {
          const parentEdgeIndex = newEls.findIndex(
            el => isEdge(el) && (el as TCustomEdge)?.target === newEl.id,
          );

          if (parentEdgeIndex !== -1) {
            newEls.splice(parentEdgeIndex, 1);
          }
        }

        if (newEl.data?.nodeType === 'output') {
          const childEdgeIndex = newEls.findIndex(
            el => isEdge(el) && (el as TCustomEdge)?.source === newEl.id,
          );

          if (childEdgeIndex !== -1) {
            newEls.splice(childEdgeIndex, 1);
          }
        }

        newEls.splice(targetNodeIndex, 1, newEl);

        return newEls;
      });
    },
    [editableNode],
  );

  const onNodeAdd = React.useCallback(
    (data: FormValues) => {
      setElements(els => {
        const newEl = {
          id: generateNodeId(els),
          type: 'customNode',
          data: {
            label: data?.content || '',
            nodeType: data?.nodeType.code || 'default',
          },
          position: { x: 150, y: 0 },
        } as TCustomNode;

        // finding the first node element will determine the sortDirection of the flow
        // sorted locally or coming from server
        const firstNodeElement = els.find(isNode) as TCustomNode;

        newEl.targetPosition = firstNodeElement?.targetPosition;
        newEl.sourcePosition = firstNodeElement?.sourcePosition;

        if (newEl.data?.nodeType === 'input') {
          newEl.targetPosition = undefined;
        }

        if (newEl.data?.nodeType === 'output') {
          newEl.sourcePosition = undefined;
        }

        if (flowInstance) {
          const { position } = flowInstance.toObject();
          newEl.position = {
            x: position[0],
            y: position[1],
          };
        }

        const newEls = [...els, newEl];

        return newEls;
      });
    },
    [editableNode, flowInstance],
  );

  const onCriticalControlAddRow = React.useCallback(() => {
    console.log(
      '🚀 ~ file: index.tsx ~ line 850 ~ onCriticalControlAddRow ~ form.getValues().critical_control',
      form.getValues().critical_control,
    );
    const newRows = [...form.getValues().critical_control];

    const newRow: CriticalControlInstance = {
      ccp_no: null,
      comments: '',
      control_measure: '',
      corrective_action: '',
      critical_limits: '',
      hazard: '',
      monitoring_frequency: '',
      process_step: '',
    };

    newRows.push({ ...newRow });

    form.reset(
      { ...form.getValues(), critical_control: newRows },
      {
        // keepDirty: true,
        keepIsValid: true,
        keepTouched: true,
        keepErrors: true,
      },
    );
  }, [productCCpUtils.dataUpdatedAt]);

  const onQualityControlAddRow = React.useCallback(() => {
    const newRows = [...form.getValues().quality_control];

    const newRow: QualityControlInstance = {
      comments: '',
      control_measure: '',
      correction_action: '',
      hazard: '',
      monitoring_step: '',
      process_step: '',
      quality_legal: '',
      tolerance: '',
    };

    newRows.push({ ...newRow });

    form.reset(
      { ...form.getValues(), quality_control: newRows },
      {
        // keepDirty: true,
        keepIsValid: true,
        keepTouched: true,
        keepErrors: true,
      },
    );
  }, [productCCpUtils.dataUpdatedAt]);

  const hasFlowBeenEdited =
    elements && produtctCCp?.data?.process_flow?.flow?.json_value
      ? JSON.stringify(elements) !==
        JSON.stringify(produtctCCp.data.process_flow.flow.json_value)
      : false;

  return (
    <Layout
      shouldShowTransitionPrompt={form.formState.isDirty || hasFlowBeenEdited}
      share={share}
    >
      <FlowModal
        setOpen={() => {
          setEditableNode(undefined);
          setIsNodeModalOpen(false);
        }}
        open={!!editableNode || isNodeModalOpen}
        data={editableNode}
        onSubmit={data => {
          if (editableNode) onNodeUpdate(data);
          else onNodeAdd(data);

          setEditableNode(undefined);
          setIsNodeModalOpen(false);
        }}
      />

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        className={errorForm}
      >
        <div className="flex flex-col">
          <div className="w-full flex flex-row items-center justify-between mb-2 mt-7">
            <h3 className="font-roboto text-base text-primary-light">
              Process Flow
            </h3>

            <Controller
              name="process_flow.file"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <UploadButtonUploadOnChange
                  error={!!fieldState?.error}
                  text="Upload File"
                  id="process_flow.file"
                  disabled={!urlParams.editMode}
                  url={`/products/${urlParams.code}/version/${urlParams.version}/ccps/upload`}
                  wrapperClassName="flex justify-center min-w-min w-20"
                  value={value}
                  onChange={val => onChange(val)}
                />
              )}
            />
          </div>

          <Controller
            name="process_flow.text"
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <TextEditor
                error={!!fieldState?.error}
                placeholder="Description"
                readOnly={!urlParams.editMode}
                height={16}
                toolbar={['bold', 'italic', 'underline']}
                value={value}
                onChange={val => onChange(val)}
              />
            )}
          />

          <div className="h-96 w-full relative mt-4 shadow">
            {/* {isImageProccessing ? (
              <h3 className="flex flex-row justify-center items-center w-full h-full text-center text-lg">
                Proccessing image
                <CircularProgress className="w-6 h-6 ml-2" />
              </h3>
            ) : null} */}

            <ReactFlow
              // className={isImageProccessing ? 'invisible' : ''}
              edgeTypes={{
                customEdge: (props: EdgeProps) => (
                  <CustomEdge {...props} onDelete={removeElementById} />
                ),
              }}
              nodeTypes={{
                customNode: (props: NodeProps) => (
                  <CustomNode
                    {...props}
                    onNodeEdit={onNodeEditSelect}
                    onNodeDelete={removeElementById}
                  />
                ),
              }}
              id="my-flow-canvas"
              elements={elements}
              onElementsRemove={onElementsRemove}
              onConnect={onConnect}
              onLoad={onLoad}
              snapToGrid
              snapGrid={[15, 15]}
              connectionMode={ConnectionMode.Loose}
              elementsSelectable
              selectNodesOnDrag={false}
              zoomOnScroll={false}
              preventScrolling={false}
            >
              <MiniMap
                nodeClassName={(n: TCustomNode) => {
                  if (n.data?.nodeType === 'input')
                    return 'text-transparent fill-current stroke-gray';
                  if (n.data?.nodeType === 'output')
                    return 'text-transparent fill-current stroke-gray';
                  return 'text-transparent fill-current stroke-gray';
                }}
                nodeBorderRadius={2}
              />

              <Controls showInteractive={false} />

              <Background className="bg-white" color="#aaa" gap={16} />
            </ReactFlow>

            <Button
              disabled={!urlParams.editMode}
              className="w-8 h-8 absolute top-2 left-2 z-50 bg-white"
              variant="text"
              onClick={() => setIsNodeModalOpen(true)}
              endIcon={<ControlPointIcon />}
            />

            <Button
              disabled={!urlParams.editMode}
              text="Sort Horizontally"
              className="w-40 absolute top-2 right-2 z-50 bg-white"
              variant="outlined"
              onClick={() => onLayout('H')}
            />

            <Button
              disabled={!urlParams.editMode}
              text="Sort Vertically"
              className="w-40 absolute top-12 right-2 z-50 bg-white"
              variant="outlined"
              onClick={() => onLayout('V')}
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="text-base text-primary mb-2 mt-7">
            Critical Control Points details (CCPs)
          </h3>

          <div className={classes.tableWrap}>
            <Table
              loading={productCCpUtils.isLoading}
              className="mt-2 table-fixed border-separate"
              bodyCellClassName="bg-white border-0 p-2"
              headerCellClassName="font-poppins font-normal text-base"
              columns={criticalControllColumns}
              data={form.getValues().critical_control || []}
            />
          </div>

          <div className="flex flex-row justify-end mt-3">
            <Button
              disabled={!urlParams.editMode}
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => onCriticalControlAddRow()}
              startIcon={<AddIcon />}
              text="New Row"
            />
          </div>
        </div>

        <div className="flex flex-col mt-6">
          <h3 className="text-base text-primary mb-2 mt-7">
            Quality Control Points details (QCPs)
          </h3>

          <div className={classes.tableWrap}>
            <Table
              loading={productCCpUtils.isLoading}
              className="mt-2 table-fixed border-separate"
              bodyCellClassName="bg-white border-0 p-2"
              headerCellClassName="font-poppins font-normal text-base"
              columns={qualityControllColumns}
              data={form.getValues().quality_control || []}
            />
          </div>

          <div className="flex flex-row justify-end mt-3">
            <Button
              disabled={!urlParams.editMode}
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => onQualityControlAddRow()}
              startIcon={<AddIcon />}
              text="New Row"
            />
          </div>
        </div>

        <FormButtonGroup
          share={share}
          pageName="flow"
          onValidate={() => onValidate(form.getValues())}
          onSave={link => onSave(link, form.getValues())}
        />
      </form>
    </Layout>
  );
};

export default CCPComp;
