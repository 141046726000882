import React from 'react';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
} from '@components/Common';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Suppliers/SuppliersList/AttributeFiltersModal';
import Menu, { MenuItemTypes } from '@components/Suppliers/SuppliersList/Menu';

import SupplierHooks from '@hooks/api/suppliers';
import {
  GetFilteredSuppliersParams,
  Supplier,
} from '@hooks/api/suppliers/types';

import { usePrevious, useDebounce } from '@hooks/utils';

import { formatDate } from '@utils';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): GetFilteredSuppliersParams => {
  const formattedValues: GetFilteredSuppliersParams = {
    ...(filterValues?.supplier && {
      supplier_type: filterValues?.supplier?.code,
    }),

    ...(filterValues?.specificationStatus && {
      cardbox_status: filterValues?.specificationStatus?.code,
    }),

    ...(filterValues?.approveDate?.from && {
      from_approve_date: formatDate(filterValues.approveDate.from),
    }),

    ...(filterValues?.approveDate?.to && {
      to_approve_date: formatDate(filterValues.approveDate.to),
    }),
  };

  return formattedValues;
};

const SuppliersListComp = () => {
  const history = useHistory();
  const [user] = useUser();

  const [selectedSupplierCode, setSelectedSupplierCode] = React.useState('');

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [supplierFilters, setSupplierFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: suppliersData, ...useSuppliersUtils } =
    SupplierHooks.useFilteredSuppliers({
      page: currentPage,
      q: debouncedSearch,
      ...(supplierFilters ? formatFilterAttributes(supplierFilters) : {}),
    });

  const deleteSupplierMutation = SupplierHooks.useDeleteSupplierMutation();

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<Supplier>) => {
      if (actionType === 'View') {
        history.push(
          `${RouteMap.suppliers.supplierInfo.replace(
            ':supplierCode?',
            row.original.supplier_code,
          )}`,
        );
      }

      if (actionType === 'Delete') {
        setSelectedSupplierCode(row.original.supplier_code);
      }
    },
    [],
  );

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: keyof Supplier | 'counter' | 'action';
      id: keyof Supplier | 'counter' | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<Supplier>) => JSX.Element);
    }> => [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        width: 294,
      },
      {
        Header: 'Code',
        accessor: 'supplier_item_code',
        id: 'supplier_item_code',
        width: 40,
      },
      {
        Header: 'Country',
        accessor: 'country',
        id: 'country',
        width: 180,
        Cell: cell => <span>{cell.value?.name}</span>,
      },
      {
        Header: 'status',
        accessor: 'cardbox_status',
        id: 'cardbox_status',
        width: 120,
      },
      {
        Header: 'Type',
        accessor: 'supplier_type',
        id: 'supplier_type',
        width: 150,
        Cell: cell => <span>{cell.value?.name}</span>,
      },
      {
        Header: 'Approval Date',
        accessor: 'approval_date',
        id: 'approval_date',
        width: 110,
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={
              user?.access_levels.supplier_soft_delete
                ? ['View', 'Delete']
                : ['View']
            }
            open={openTableActionMenuId === cell.row.id}
            onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
            onClose={() => setOpenTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onMenuItemClick(actionType, cell.row);

              setOpenTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [onMenuItemClick, openTableActionMenuId, suppliersData?.timeStamp],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={!!selectedSupplierCode}
        setOpen={() => setSelectedSupplierCode('')}
        onConfirm={async () => {
          try {
            await deleteSupplierMutation.mutateAsync({
              supplier_code: selectedSupplierCode,
            });
          } catch (error) {
          } finally {
            setSelectedSupplierCode('');
          }
        }}
      />

      <AttributeFiltersModal
        values={supplierFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setSupplierFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Supplier' },
            { href: '#', text: 'Supplier List' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {supplierFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setSupplierFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <p className="text-base text-primary-light font-roboto">
            Suppliers List
          </p>
          {user?.access_levels.supplier_create_update && (
            <div className="flex flex-row items-center">
              <Button
                variant="outlined"
                className="border-1 border-primary"
                onClick={() =>
                  history.push(
                    `${RouteMap.suppliers.supplierInfo.replace(
                      ':supplierCode?',
                      '',
                    )}?editMode=true`,
                  )
                }
                startIcon={<AddIcon />}
                text="Add New"
              />
            </div>
          )}
        </div>

        <Table
          loading={useSuppliersUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto last-item-center"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={suppliersData?.data || []}
          getRowProps={row => ({
            onClick: () =>
              history.push(
                `${RouteMap.suppliers.supplierInfo.replace(
                  ':supplierCode?',
                  row.original.supplier_code,
                )}`,
              ),
          })}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: suppliersData?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default SuppliersListComp;
