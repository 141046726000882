/* eslint-disable no-nested-ternary */
import React from 'react';
import * as DateUtils from 'date-fns';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useForm, Controller, UnpackNestedValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUser } from '@contexts';

import { Edit, Close } from '@material-ui/icons';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  AutoComplete,
  TextArea,
  DatePicker,
  UploadButtonUploadOnChange,
  DeletePromptModal,
  RejectModal,
} from '@components/Common';

import RouteMap from '@routes/map';

import { useUrlParams } from '@hooks/utils';

import { formatDate, convertToIso, isPendigForApproval } from '@utils';

import GeneralHooks from '@hooks/api/general';

import ManufacturerHooks from '@hooks/api/manufacturers';

import SupplierHooks from '@hooks/api/suppliers';

import CertificateHooks from '@hooks/api/certificates';
import { DetailedCertificate } from '@hooks/api/certificates/types';

import SAQModal from '@components/Certificates/CertificateInfo/ShareSAQModal';

const initialValues: Omit<DetailedCertificate, 'certificate_code'> = {
  supplier: null,
  manufacture: null,
  cardbox_status: null,
  approved_by: '',
  approve_date: '',
  certificate_type: null,
  certification_number: '',
  certification_body: '',
  scope: '',
  extensions: '',
  issue_date: '',
  expiry_date: '',
  number_of_critical_nc: null,
  number_of_minor_nc: null,
  number_of_major_nc: null,
  number_of_observations: null,
  grade: null,
  upload_certificate: '',
};

const validationSchema = yup.object({
  supplier: yup
    .mixed()
    .required('Supplier is required')
    .test('supplier', 'Supplier is required', value => !!value),
  certificate_type: yup
    .mixed()
    .required('Certificate type is required')
    .test('certificate_type', 'Certificate type is required', val => !!val),
  expiry_date: yup
    .string()
    .test(
      'expiry_date',
      'Expiry date Cannot be set before issue date',
      (val, contxt) => {
        if (!val || !contxt.parent.issue_date) return true;

        return !DateUtils.isBefore(
          convertToIso(val),
          convertToIso(contxt.parent.issue_date),
        );
      },
    )
    .required('Expiry date is required'),
  upload_certificate: yup
    .string()
    .required('Certificate attachment is required'),
});

const CetificateInfoComp = () => {
  const history = useHistory();
  const [user] = useUser();
  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);

  const [isDeleteCertificateModalOpen, setIsDeleteCertificateModalOpen] =
    React.useState(false);
  const [isShareSAQModalOpen, setIsShareSAQModalOpen] = React.useState(false);

  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
    supplier_name?: string;
    supplier_code?: string;
    manufacturer_name?: string;
    manufacturer_code?: string;
  }>();
  const routeParams = useRouteMatch<{ certificateCode?: string }>();

  const form = useForm<DetailedCertificate>({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    const newVals = { ...initialValues };

    if (urlParams?.supplier_name && urlParams?.supplier_code) {
      newVals.supplier = {
        supplier_name: urlParams.supplier_name,
        supplier_code: urlParams.supplier_code,
        supplier_item_code: '',
      };
    }

    if (urlParams?.manufacturer_name && urlParams?.manufacturer_code) {
      newVals.manufacture = {
        manufacture_name: urlParams.manufacturer_name,
        manufacture_code: urlParams.manufacturer_code,
      };
    }

    form.reset(newVals);
  }, [
    urlParams?.supplier_name,
    urlParams?.supplier_code,
    urlParams?.manufacturer_name,
    urlParams?.manufacturer_code,
  ]);

  const selectedSupplier = form.watch('supplier');

  const { data: manufacturersList, ...useManufacturersUtils } =
    ManufacturerHooks.useManufacturers(
      {
        supplier_code:
          selectedSupplier?.supplier_code || urlParams?.supplier_code || '',
        paginated: false,
      },
      {
        enabled:
          !!selectedSupplier?.supplier_code || !!urlParams?.supplier_code,
      },
    );

  const { data: suppliersList, ...useSuppliersUtils } =
    SupplierHooks.useSuppliers({
      paginated: false,
      cardbox_status: true,
    });

  const { data: detailedCertificateData, ...useDetailedCertificateUtils } =
    CertificateHooks.useDetailedCertificate(
      {
        certificateCode: routeParams.params?.certificateCode || '',
      },
      {
        enabled: !!routeParams.params?.certificateCode,
        onSuccess: data => form.reset(data.data),
      },
    );

  const { data: allStatus } = GeneralHooks.useAllStatus();

  const updateCertificateMutation =
    CertificateHooks.useUpdateCertificateMutation();
  const saveCertificateMutation = CertificateHooks.useSaveCertificateMutation();
  const submitCertificateMutation =
    CertificateHooks.useSubmitCertificateMutation();
  const approveOrRejectCertificateMutation =
    CertificateHooks.useApproveOrRejectCertificateMutation();
  const deleteCertificateMutation =
    CertificateHooks.useDeleteCertificateMutation();

  const formatCertificateData = (
    data: UnpackNestedValue<DetailedCertificate>,
  ) => {
    const goodData = {
      certificateCode: routeParams.params.certificateCode || '',
      supplier_code: data.supplier?.supplier_code || null,
      manufacture_code: data.manufacture?.manufacture_code || null,
      certificate_type: data?.certificate_type || null,
      certification_number: data.certification_number,
      certification_body: data.certification_body,
      scope: data.scope,
      extensions: data.extensions,
      issue_date: formatDate(data.issue_date || ''),
      expiry_date: formatDate(data.expiry_date || ''),
      number_of_critical_nc: data.number_of_critical_nc,
      number_of_minor_nc: data.number_of_minor_nc,
      number_of_major_nc: data.number_of_major_nc,
      number_of_observations: data.number_of_observations,
      grade: data?.grade || null,
      upload_certificate: data.upload_certificate,
    };

    return goodData;
  };

  const onSave: (
    data: UnpackNestedValue<DetailedCertificate>,
  ) => void = async data => {
    form.clearErrors();

    try {
      const formattedData = formatCertificateData(data);

      if (routeParams.params?.certificateCode) {
        await updateCertificateMutation.mutateAsync({
          ...formattedData,
          certificateCode: routeParams.params.certificateCode,
        });
      } else {
        const newCertificate = await saveCertificateMutation.mutateAsync(
          formattedData,
        );

        if (newCertificate) {
          history.replace(
            `${RouteMap.certificates.certificateInfo.replace(
              ':certificateCode?',
              newCertificate.data.certificate_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (error) {}
  };

  const onSubmit: (
    data: UnpackNestedValue<DetailedCertificate>,
  ) => void = async data => {
    form.clearErrors();

    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) return;

      const formattedData = formatCertificateData(data);

      if (routeParams.params?.certificateCode) {
        await submitCertificateMutation.mutateAsync({
          ...formattedData,
          certificateCode: routeParams.params?.certificateCode,
          // supplier_code: routeParams.params.supplierCode,
        });
      } else {
        const newCertificate = await submitCertificateMutation.mutateAsync(
          formattedData,
        );

        if (newCertificate) {
          history.replace(
            `${RouteMap.certificates.certificateInfo.replace(
              ':certificateCode?',
              newCertificate.data.certificate_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (e) {}
  };
  const isSaqSelected = !!form.watch('certificate_type')?.is_saq;

  const isStatusPendigForApproval = isPendigForApproval(
    detailedCertificateData?.data.cardbox_status,
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      {isShareSAQModalOpen ? (
        <SAQModal open={isShareSAQModalOpen} setOpen={setIsShareSAQModalOpen} />
      ) : null}

      <DeletePromptModal
        open={isDeleteCertificateModalOpen}
        setOpen={() => setIsDeleteCertificateModalOpen(false)}
        onConfirm={async () => {
          try {
            if (routeParams?.params?.certificateCode) {
              await deleteCertificateMutation.mutateAsync({
                certificateCode: routeParams?.params?.certificateCode,
              });

              history.replace(RouteMap.certificates.certificatesList);
            }
          } catch (error) {
          } finally {
            setIsDeleteCertificateModalOpen(false);
          }
        }}
      />

      <RejectModal
        header="Reject Message"
        open={rejectModalOpen}
        setOpen={setRejectModalOpen}
        submit={data =>
          approveOrRejectCertificateMutation.mutate({
            certificateCode: routeParams.params?.certificateCode || '',
            status: 'reject',
            message: data.message,
          })
        }
      />

      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Certificates' },
              { href: '#', text: 'Certificate Info' },
            ]}
          />

          {user?.access_levels.certificate_create_update && (
            <div>
              {/* {urlParams.editMode && routeParams.params?.certificateCode ? (
                <Button
                  variant="outlined"
                  className="w-28 mr-2 text-red-dark border-red-dark"
                  onClick={() => setIsDeleteCertificateModalOpen(true)}
                  size="medium"
                  text="Delete"
                />
              ) : null} */}
              {user?.access_levels.certificate_approve && (
                <>
                  {detailedCertificateData?.data.cardbox_status ===
                  'Archived' ? (
                    <>
                      {routeParams.params.certificateCode && (
                        <Button
                          variant="outlined"
                          className="w-28 mr-2 text-red-dark border-red-dark"
                          onClick={() => {
                            approveOrRejectCertificateMutation.mutate({
                              certificateCode:
                                routeParams.params?.certificateCode || '',
                              status: 'draft',
                            });
                          }}
                          size="medium"
                          text="Active"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {routeParams.params.certificateCode && (
                        <Button
                          variant="outlined"
                          className="w-28 mr-2 text-red-dark border-red-dark"
                          onClick={() => {
                            approveOrRejectCertificateMutation.mutate(
                              {
                                certificateCode:
                                  routeParams.params?.certificateCode || '',
                                status: 'archive',
                              },
                              {
                                onSuccess: () => {
                                  setUrlParams({ editMode: false });
                                },
                              },
                            );
                          }}
                          size="medium"
                          text="Archive"
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {urlParams.editMode ? (
                <Button
                  variant="outlined"
                  className="w-28"
                  onClick={() => setUrlParams({ editMode: false })}
                  size="medium"
                  startIcon={<Close />}
                  text="Cancel"
                />
              ) : (
                <Button
                  className="w-28"
                  onClick={() => setUrlParams({ editMode: true })}
                  size="medium"
                  startIcon={<Edit />}
                  text="Edit"
                />
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              Certificate Info
            </h3>
          </div>

          <div className="grid grid-cols-2 gap-x-2 gap-y-1 mt-2">
            <Controller
              name="supplier"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Supplier*</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        noOptionsText="No matching supplier is found, please check the supplier record is created"
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={
                          useDetailedCertificateUtils.isLoading ||
                          useSuppliersUtils.isLoading
                        }
                        disabled={
                          !urlParams?.editMode ||
                          !!routeParams.params?.certificateCode ||
                          !!(urlParams.supplier_name && urlParams.supplier_code)
                        }
                        value={
                          value
                            ? {
                                name: value.supplier_name,
                                code: value.supplier_code,
                              }
                            : null
                        }
                        items={(suppliersList?.data || []).map(el => ({
                          name: el.name,
                          code: el.supplier_code,
                        }))}
                        onChange={newVal => {
                          if (value?.supplier_code !== newVal?.code) {
                            onChange(
                              newVal
                                ? {
                                    supplier_name: newVal.name,
                                    supplier_code: newVal.code,
                                  }
                                : null,
                            );

                            form.setValue('manufacture', null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="manufacture"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Manufacturer</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        noOptionsText="No matching manufacturer is found, please check the manufacturer record is created"
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={
                          useDetailedCertificateUtils.isLoading ||
                          useManufacturersUtils.isLoading
                        }
                        disabled={
                          !urlParams?.editMode ||
                          !!routeParams.params?.certificateCode ||
                          !!(
                            urlParams.manufacturer_name &&
                            urlParams.manufacturer_code
                          )
                        }
                        value={
                          value
                            ? {
                                name: value.manufacture_name,
                                code: value.manufacture_code,
                              }
                            : null
                        }
                        items={(manufacturersList?.data || []).map(el2 => ({
                          name: el2.site_name,
                          code: el2.manufacture_code,
                        }))}
                        onChange={newVal =>
                          onChange(
                            newVal
                              ? {
                                  manufacture_name: newVal.name,
                                  manufacture_code: newVal.code,
                                }
                              : null,
                          )
                        }
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Status',
                  key: 'cardbox_status',
                },
                {
                  displayText: 'Approved By',
                  key: 'approved_by',
                },
                {
                  displayText: 'Approved Date',
                  key: 'approve_date',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <div
                    key={el.key}
                    className="col-span-1 flex flex-row items-center bg-white rounded shadow-sm"
                  >
                    <div>
                      <p className="font-roboto pl-4 w-52">{el.displayText}</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <Input
                        loading={useDetailedCertificateUtils.isLoading}
                        disabled
                        className="flex-grow"
                        innerRef={ref}
                        {...rest}
                      />
                    </div>
                  </div>
                )}
              />
            ))}

            <Controller
              name="certificate_type"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Certificate Type*</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        loading={useDetailedCertificateUtils.isLoading}
                        disabled={!urlParams?.editMode}
                        value={value}
                        groupBy={option => option?.cert_type?.toString()}
                        items={
                          allStatus?.data?.certifications_category_dropdown ||
                          []
                        }
                        onChange={newVal => onChange(newVal)}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {(
              [
                {
                  displayText: 'Certificate number',
                  key: 'certification_number',
                },
                {
                  displayText: 'Certification body',
                  key: 'certification_body',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-1',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useDetailedCertificateUtils.isLoading}
                          disabled={!urlParams?.editMode}
                          className="flex-grow"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Scope',
                  key: 'scope',
                },
                {
                  displayText: 'Extensions',
                  key: 'extensions',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, value, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-2',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <TextArea
                          multiline
                          rows={2}
                          disabled={!urlParams?.editMode}
                          inputClassName="p-2"
                          whiteWrapper
                          value={value ? value.toString() : ''}
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Issue date',
                  key: 'issue_date',
                },
                {
                  displayText: 'Expiry date',
                  key: 'expiry_date',
                  required: true,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState,
                }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <DatePicker
                          disabled={!urlParams?.editMode}
                          className="w-full"
                          value={value ? convertToIso(value.toString()) : null}
                          onChange={val => {
                            onChange(val);

                            form.trigger(['issue_date', 'expiry_date']);
                          }}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            {(
              [
                {
                  displayText: 'Number of critical NC(s)',
                  key: 'number_of_critical_nc',
                },
                {
                  displayText: 'Number of minor NC(s)',
                  key: 'number_of_minor_nc',
                },
                {
                  displayText: 'Number of major NC(s)',
                  key: 'number_of_major_nc',
                },
                {
                  displayText: 'Number of observation(s)',
                  key: 'number_of_observations',
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div
                    key={el.key}
                    className={clsx(
                      'flex flex-col col-span-1',
                      isSaqSelected ? 'hidden' : '',
                    )}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          loading={useDetailedCertificateUtils.isLoading}
                          disabled={!urlParams?.editMode}
                          className="flex-grow"
                          innerRef={ref}
                          type="number"
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}

            <Controller
              name="grade"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div
                  className={clsx(
                    'flex flex-col col-span-1',
                    isSaqSelected ? 'hidden' : '',
                  )}
                >
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Grade</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        loading={useDetailedCertificateUtils.isLoading}
                        disabled={!urlParams?.editMode}
                        value={value}
                        items={
                          allStatus?.data?.certifications_grade_dropdown || []
                        }
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="upload_certificate"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div className="flex flex-col col-span-1">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">
                        Upload Certificate*
                      </p>
                    </div>

                    <div className="flex flex-col w-full">
                      <UploadButtonUploadOnChange
                        url="/certificates/upload"
                        disabled={!urlParams?.editMode}
                        id="upload_certificate"
                        wrapperClassName="flex justify-center my-2"
                        value={value ? String(value) : ''}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />
          </div>
        </div>

        {isSaqSelected ? (
          <div className="flex flex-row justify-end items-center mt-3">
            <Button
              size="large"
              disabled={!urlParams.editMode}
              variant="outlined"
              className="border-1 border-primary"
              onClick={() => setIsShareSAQModalOpen(true)}
              text="Share SAQ"
            />
          </div>
        ) : null}
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        canApprove={user?.access_levels.certificate_approve}
        canReject={user?.access_levels.certificate_approve}
        canSave={user?.access_levels.certificate_create_update}
        canSubmit={user?.access_levels.supplier_submit}
        isLoading={useDetailedCertificateUtils.isLoading}
        {...(isStatusPendigForApproval
          ? {
              onApprove: () =>
                approveOrRejectCertificateMutation.mutate(
                  {
                    certificateCode: routeParams.params?.certificateCode || '',
                    status: 'approve',
                  },
                  {
                    onSuccess: () => setUrlParams({ editMode: false }),
                  },
                ),
              onReject: () => {
                setRejectModalOpen(true);
              },
              onSave: () => onSave(form.getValues()),
            }
          : {
              onSubmit: () => onSubmit(form.getValues()),
              onSave: () => onSave(form.getValues()),
            })}
      />
    </Layout>
  );
};

export default CetificateInfoComp;
