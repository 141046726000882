/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

type P = {
  value: any;
  disabled?: boolean;
  items?: any;
  onChange: (val: any) => void;
};

const search: React.FC<P> = ({ value, onChange, disabled, items }) => {
  const [rerender, setRerender] = React.useState(1);
  const [val, setVal] = React.useState();
  const [suggestList, setSuggestList] = React.useState<any>([]);
  const [fcoced, setFcoced] = React.useState<any>(false);

  const inputRef = React.useRef<any>(null);

  const suggestClick = (name: any, code: any): any => {
    setFcoced(false);
    setSuggestList([]);
    onChange({
      name,
      code,
    });
    setVal(name);
    setRerender(rerender + 1);
  };

  React.useEffect(() => {
    setVal(value.name);
    setTimeout(() => {
      setSuggestList([]);
    }, 1000);
  }, [value, rerender]);

  const onChangeHandling = (event: any): any => {
    setFcoced(true);
    setVal(event.target.value);
    const newArray = [];
    if (event.target.value.length > 2) {
      for (let i = 0; i < items.length; i += 1) {
        if (
          RegExp(`\\b${event.target.value.toLowerCase()}`).test(
            items[i].name.toLowerCase(),
          )
        ) {
          // let Suggest = items[i].name;
          // for (let index = 0; index < event.target.value.length; index += 1) {
          //   Suggest = Suggest.replace(
          //     Suggest.charAt(index),
          //     event.target.value[index],
          //   );
          // }
          newArray.push({
            name: items[i].name,
            code: items[i].code,
          });
        }
      }
    }
    setSuggestList(newArray);
  };

  const onBlurHandling = (event: any): any => {
    console.log('onBlurHandling');

    const eventCurrentTarget: any = event.currentTarget;
    setTimeout(() => {
      // Give browser time to focus the next element
      requestAnimationFrame(() => {
        if (!eventCurrentTarget?.contains(document.activeElement)) {
          for (let i = 0; i < items.length; i += 1) {
            if (
              items[i].name.toLowerCase() === event.target.value.toLowerCase()
            ) {
              setFcoced(false);
              onChange({
                name: items[i].name,
                code: items[i].code,
              });
              return;
            }
          }
          setFcoced(false);
          onChange({
            name: event.target.value,
            code: -1,
          });
        } else if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      });
    }, 100);
  };

  return (
    <div className="suggest" onBlur={onBlurHandling}>
      <input
        className="suggest__input"
        disabled={disabled}
        onChange={onChangeHandling}
        ref={inputRef}
        value={val}
      />
      {suggestList.length > 0 && fcoced && (
        <a href="#" type="button" className="suggest__list" tabIndex={0}>
          {suggestList.map((suggest: any) => (
            <span
              key={suggest.code}
              onClick={() => {
                suggestClick(suggest.name, suggest.code);
              }}
              className="suggest__word"
            >
              {suggest.name}
            </span>
          ))}
        </a>
      )}
    </div>
  );
};

export default search;
