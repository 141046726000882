import React from 'react';
import clsx from 'clsx';

import { Delete } from '@material-ui/icons';

import { Button } from '@components/Common';

interface P {
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  tabIndex?: number;
}

const TableTrashButton: React.FC<P> = ({
  disabled = false,
  onClick,
  className,
  tabIndex,
}) => {
  return (
    <Button
      tabIndex={tabIndex}
      disabled={disabled}
      className={clsx(
        'h-full bg-transparent shadow-none border-0 outline-none px-0',
        disabled ? 'opacity-50' : '',
        className,
      )}
      size="large"
      startIcon={<Delete className="text-gray text-xl" />}
      onClick={onClick}
    />
  );
};

export default TableTrashButton;
