import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import errorHandler from '@utils/errorHandler';

import manufacturerApis from '@hooks/api/certificates/api';
import {
  GetCertificatesParams,
  UseCertificatesResponse,
  GetDetailedCertificateParams,
  UseDetailedCertificateResponse,
} from './types';

const KEYS = {
  certificates: ['certificates'],
  detailedCertificates: ['certificates', 'details'],
} as const;

const useCertificates = (
  params?: GetCertificatesParams,
  options?: UseQueryOptions<UseCertificatesResponse, AxiosError>,
) => {
  const query = useQuery<UseCertificatesResponse, AxiosError>(
    [...KEYS.certificates, params],
    () => manufacturerApis.getCertificates(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );

  return query;
};

const useDetailedCertificate = (
  params: GetDetailedCertificateParams,
  options?: UseQueryOptions<UseDetailedCertificateResponse, AxiosError>,
) => {
  const query = useQuery<UseDetailedCertificateResponse, AxiosError>(
    [...KEYS.detailedCertificates, params],
    () => manufacturerApis.getDetailedCertificate(params),
    options,
  );

  return query;
};

const useSaveCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.saveCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.certificates);
    },
  });

  return mutation;
};

const useSubmitCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.submitCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.certificates);
    },
  });

  return mutation;
};

const useApproveOrRejectCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.approveOrRejectCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.certificates);
    },
  });

  return mutation;
};

const useUpdateCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.updateCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.certificates);
    },
  });

  return mutation;
};

const useDeleteCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(manufacturerApis.deleteCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.certificates);
    },
  });

  return mutation;
};

export default {
  useCertificates,
  useDetailedCertificate,
  useSaveCertificateMutation,
  useUpdateCertificateMutation,
  useDeleteCertificateMutation,
  useSubmitCertificateMutation,
  useApproveOrRejectCertificateMutation,
};
