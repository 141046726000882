/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import {
  Collapse,
  Divider,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Delete, Add, Help } from '@material-ui/icons';

import { Switch, Button, Dialog } from '@components/Common';

type DataType = {
  header?: string;
  className?: string;
  tooltip?: string;
  error?: boolean;
  jsx: React.ReactNode;
};

type DataWithTabs = Array<
  Array<{
    meat_type: Array<DataType>;
    farm_details: Array<DataType>;
    packing_site_details: Array<DataType>;
    slaughterhouse_details: Array<DataType>;
  }>
>; // this is way to specific , might need a rework later

type DataWithoutTabs = Array<Array<DataType>>;

type P = {
  className?: string;
  expanded: boolean | null;
  onExpandChange?: (arg: boolean) => void;
  onAddRow?: () => void;
  onDeleteRow: (arg?: number) => void;
  headerTooltip?: string;
  columnClassName?: string;
  columnDividerClassName?: string;
  columnHeaderClassName?: string;
  headerWrapperClassName?: string;
  headerRowClassName?: string;
  question?: string;
  customCollapseComponent?: React.ReactNode;
  customHeaderComponent?: React.ReactNode;
  tabs?: boolean;
  canExpand?: boolean;
  canAddRow?: boolean;
  canDeleteRow: boolean;
  rowGridCount?: number;
  error?: boolean;
  tabsError?: any;
  data?: null | DataWithoutTabs | DataWithTabs;
};

const Column = ({
  column,
  isFirstRow,
  hidden = false,
  columnDividerClassName = '',
  columnHeaderClassName = '',
  columnClassName = '',
  isLastRow,
  ...rest
}: {
  column: DataType;
  columnHeaderClassName?: string;
  columnDividerClassName?: string;
  columnClassName?: string;
  hidden?: boolean;
  isFirstRow: boolean;
  isLastRow: boolean;
}) => (
  <div
    {...rest}
    className={overrideTailwindClasses(
      clsx(
        'flex flex-col bg-white shadow px-2 pb-2',
        hidden && 'hidden',
        isFirstRow ? 'rounded-t' : 'rounded-b',
        isFirstRow && 'pt-3',
        columnClassName,
        column.className,
      ),
    )}
  >
    {isFirstRow ? null : (
      <Divider
        className={overrideTailwindClasses(
          clsx('mb-2', columnDividerClassName),
        )}
      />
    )}

    {column?.header ? (
      <div className="items-center mb-3">
        <h4
          className={clsx(
            'font-roboto break-words inline-flex',
            columnHeaderClassName,
          )}
        >
          {column.header}
        </h4>

        {column?.tooltip ? (
          <Tooltip title={column.tooltip}>
            <IconButton className="p-1 h-6">
              <Help className="text-sm" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    ) : null}

    {column?.jsx || ''}
  </div>
);

type TabsKeys =
  | 'meat_type'
  | 'farm_details'
  | 'packing_site_details'
  | 'slaughterhouse_details';

const Row: React.FC<P> = ({
  question,
  tabs = false,
  data,
  headerTooltip,
  customHeaderComponent,
  columnDividerClassName,
  customCollapseComponent,
  columnClassName,
  columnHeaderClassName,
  headerWrapperClassName,
  headerRowClassName,
  className,
  expanded,
  onExpandChange,
  onAddRow,
  onDeleteRow,
  canExpand,
  canAddRow,
  canDeleteRow,
  rowGridCount,
  error,
  tabsError,
}) => {
  const tabError = {
    meat_type: false,
    packing_site_details: false,
    slaughterhouse_details: false,
    farm_details: false,
  };

  const TABS: Readonly<
    Array<{
      key: TabsKeys;
      label: string;
      tooltip?: string;
      error?: boolean;
      gridColsCount: 4 | 5;
    }>
  > = [
    {
      key: 'meat_type',
      label: 'Meat type & Quantity',
      error: false,
      tooltip: `If Product contains more than one meat species, 
      this section must be completed for each species of meat by adding extra rows`,
      gridColsCount: 4,
    },
    {
      key: 'packing_site_details',
      label: 'Processing & Packing site details',
      error: false,
      gridColsCount: 5,
    },
    {
      key: 'slaughterhouse_details',
      label: 'Slaughterhouse details',
      error: false,
      gridColsCount: 5,
    },
    {
      key: 'farm_details',
      error: false,
      label: 'Farm details',
      gridColsCount: 5,
    },
  ];

  if (tabsError?.length > 0) {
    if (tabsError[0]?.meat_type) {
      TABS[0].error = true;
    }
    if (tabsError[0]?.packing_site_details) {
      TABS[1].error = true;
    }
    if (tabsError[0]?.slaughterhouse_details) {
      TABS[2].error = true;
    }
    if (tabsError[0]?.farm_details) {
      TABS[3].error = true;
    }
  }
  if (tabsError?.length > 1) {
    if (tabsError[1]?.meat_type) {
      TABS[0].error = true;
    }
    if (tabsError[1]?.packing_site_details) {
      TABS[1].error = true;
    }
    if (tabsError[1]?.slaughterhouse_details) {
      TABS[2].error = true;
    }
    if (tabsError[1]?.farm_details) {
      TABS[3].error = true;
    }
  }
  if (tabsError?.length > 2) {
    if (tabsError[2]?.meat_type) {
      TABS[0].error = true;
    }
    if (tabsError[2]?.packing_site_details) {
      TABS[1].error = true;
    }
    if (tabsError[2]?.slaughterhouse_details) {
      TABS[2].error = true;
    }
    if (tabsError[2]?.farm_details) {
      TABS[3].error = true;
    }
  }
  if (tabsError?.length > 3) {
    if (tabsError[3]?.meat_type) {
      TABS[0].error = true;
    }
    if (tabsError[3]?.packing_site_details) {
      TABS[1].error = true;
    }
    if (tabsError[3]?.slaughterhouse_details) {
      TABS[2].error = true;
    }
    if (tabsError[3]?.farm_details) {
      TABS[3].error = true;
    }
  }

  const [tabValue, setTabValue] = React.useState<TabsKeys>(TABS[0].key);

  const DeleteButton = ({
    rowIndex,
    deletable,
  }: {
    rowIndex?: number;
    deletable?: any;
  }) => {
    return (
      <div className="flex flex-col justify-between ml-2 bg-white shadow">
        {deletable <= 1 ? (
          <Button
            disabled
            className={clsx(
              'h-full bg-transparent shadow-none border-0 outline-none',
              'opacity-50',
            )}
            size="small"
            startIcon={<Delete className="text-gray text-xl" />}
            onClick={() => onDeleteRow(rowIndex)}
          />
        ) : (
          <Button
            disabled={!canDeleteRow}
            className={clsx(
              'h-full bg-transparent shadow-none border-0 outline-none',
              canDeleteRow ? '' : 'opacity-50',
            )}
            size="small"
            startIcon={<Delete className="text-gray text-xl" />}
            onClick={() => onDeleteRow(rowIndex)}
          />
        )}

        <Divider className={clsx(columnDividerClassName)} />
      </div>
    );
  };

  return (
    <div className={className}>
      <div
        className={overrideTailwindClasses(
          clsx('flex flex-col mb-2', headerWrapperClassName),
        )}
      >
        <div
          className={overrideTailwindClasses(
            clsx(
              'flex flex-row items-center rounded bg-white-400 py-2 px-4',
              headerRowClassName,
            ),
          )}
        >
          {customHeaderComponent || (
            <>
              <div className="flex flex-row items-center w-6/12">
                <h3 className="text-sm font-roboto">{question}</h3>

                {headerTooltip ? (
                  <Tooltip title={headerTooltip}>
                    <IconButton className="p-1 h-6">
                      <Help className="text-sm" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>

              <div
                className={`flex flex-row items-center flex-grow justify-between ${
                  typeof expanded === 'boolean'
                    ? expanded
                      ? 'Yes'
                      : 'No'
                    : null
                }`}
              >
                {onExpandChange ? (
                  <Switch
                    error={error}
                    disabled={!canExpand}
                    type="secondary"
                    value={
                      typeof expanded === 'boolean'
                        ? expanded
                          ? 'Yes'
                          : 'No'
                        : null
                    }
                    onChange={expandedStr => {
                      const nextExpandState = expandedStr === 'Yes';
                      onExpandChange(nextExpandState);
                    }}
                  />
                ) : null}

                {onAddRow ? (
                  <Button
                    disabled={!canAddRow}
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    text="Add Row"
                    variant="outlined"
                    onClick={() => onAddRow()}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>

        {tabs ? (
          <Tabs
            classes={{
              root: 'min-h-unset h-10',
              flexContainer: 'min-h-unset h-10',
              indicator: 'bg-primary',
            }}
            value={tabValue}
            onChange={(_, newVal) => setTabValue(newVal)}
            aria-label="simple tabs example"
          >
            {TABS.map((el, i) => (
              <Tab
                key={`simple-tab-${i}`}
                disabled={!expanded}
                classes={{
                  root: `transition-all min-h-unset h-10 normal-case font-roboto text-sm text-gray ${
                    el.error && 'bg-red-200'
                  }`,
                  selected:
                    'text-primary-light border-b-1 border-primary-light bg-white-400',
                }}
                value={el.key}
                label={
                  el?.tooltip ? (
                    <div className="flex flex-row items-center">
                      <span>{el.label}</span>

                      <Tooltip title={el.tooltip}>
                        <IconButton className="p-1 h-6">
                          <Help className="text-sm" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : (
                    <span>{el.label}</span>
                  )
                }
                id={`simple-tab-${i}`}
                aria-controls={`simple-tabpanel-${i}`}
                className="font-roboto px-4 min-w-min max-w-full"
              />
            ))}
          </Tabs>
        ) : null}
      </div>

      <Collapse in={!!expanded} timeout="auto" unmountOnExit>
        {customCollapseComponent ? (
          <div className="flex flex-row items-center justify-between">
            {customCollapseComponent}

            <DeleteButton deletable={data?.length} />
          </div>
        ) : null}

        {!customCollapseComponent && (!data || data?.length === 0) ? (
          <div className="flex flex-row justify-center items-center my-4">
            <p>No Content</p>
          </div>
        ) : null}

        {!tabs && data && data.length > 0
          ? (data as DataWithoutTabs).map((row, rowIndex, rowArr) => (
              <div
                key={`row-${row[0]?.header}-${rowIndex}`}
                className="flex flex-row items-stretch"
              >
                <div
                  className={clsx(
                    `grid grid-cols-${
                      rowGridCount || row.length
                    } w-full gap-x-2`,
                  )}
                >
                  {row.map(column => (
                    <Column
                      columnClassName={columnClassName}
                      columnDividerClassName={columnDividerClassName}
                      key={`column-${rowIndex}-${column?.header}`}
                      columnHeaderClassName={columnHeaderClassName}
                      column={column}
                      isFirstRow={rowIndex === 0}
                      isLastRow={rowIndex === rowArr.length - 1}
                    />
                  ))}
                </div>

                <DeleteButton rowIndex={rowIndex} deletable={data?.length} />
              </div>
            ))
          : null}

        {tabs && data && data.length > 0
          ? (data as DataWithTabs).map((row, rowIndex, rowArr) => {
              const currentTab = TABS.find(tab => tab.key === tabValue);

              return (
                <div key={`row-${currentTab?.key}-${rowIndex}`}>
                  <Divider className="mb-2 bg-gray-400" />

                  <div className="flex flex-row items-stretch">
                    <div
                      className={clsx(
                        'grid gap-x-2 w-full',
                        `grid-cols-${currentTab?.gridColsCount}`,
                      )}
                    >
                      {row[0].meat_type.map(column => (
                        <Column
                          columnClassName={columnClassName}
                          columnDividerClassName={columnDividerClassName}
                          key={`column-meat_type-${rowIndex}-${column?.header}`}
                          hidden={tabValue !== TABS[0].key}
                          columnHeaderClassName={columnHeaderClassName}
                          column={column}
                          isFirstRow={rowIndex === 0}
                          isLastRow={rowIndex === rowArr.length - 1}
                        />
                      ))}

                      {row[0].packing_site_details.map(column => (
                        <Column
                          columnClassName={columnClassName}
                          columnDividerClassName={columnDividerClassName}
                          key={`column-packing_site_details-${rowIndex}-${column?.header}`}
                          hidden={tabValue !== TABS[1].key}
                          columnHeaderClassName={columnHeaderClassName}
                          column={column}
                          isFirstRow={rowIndex === 0}
                          isLastRow={rowIndex === rowArr.length - 1}
                        />
                      ))}

                      {row[0].slaughterhouse_details.map(column => (
                        <Column
                          columnClassName={columnClassName}
                          columnDividerClassName={columnDividerClassName}
                          key={`column-slaughterhouse_details-${rowIndex}-${column?.header}`}
                          hidden={tabValue !== TABS[2].key}
                          columnHeaderClassName={columnHeaderClassName}
                          column={column}
                          isFirstRow={rowIndex === 0}
                          isLastRow={rowIndex === rowArr.length - 1}
                        />
                      ))}

                      {row[0].farm_details.map(column => (
                        <Column
                          columnClassName={columnClassName}
                          columnDividerClassName={columnDividerClassName}
                          key={`column-farm_details-${rowIndex}-${column?.header}`}
                          hidden={tabValue !== TABS[3].key}
                          columnHeaderClassName={columnHeaderClassName}
                          column={column}
                          isFirstRow={rowIndex === 0}
                          isLastRow={rowIndex === rowArr.length - 1}
                        />
                      ))}
                    </div>

                    <DeleteButton
                      rowIndex={rowIndex}
                      deletable={data?.length}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </Collapse>
    </div>
  );
};

export default React.memo(Row);
