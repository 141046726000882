/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Cell } from 'react-table';

import {
  useForm,
  Controller,
  SubmitHandler,
  FieldPath,
  UseFormStateReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useUrlParams, wizardHook as WizardHooks } from '@hooks';
import { deepClone, switchValidator } from '@utils';

import {
  Allergens,
  Allergens_Substences,
  Allergens_Requirements,
} from '@hooks/api/wizard/types';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import Table from '@components/Bussines/Products/ProductForm/Allergens/Table';

import {
  Switch,
  Input,
  UploadButtonUploadOnChange as UploadButton,
} from '@components/Common';

type SubRowInstance = Omit<Allergens_Substences, 'sub_rows'>;
type CustomCell = Cell<{ userAdded?: boolean }>;

const { ...substanceSubRowSample } = {
  userAdded: true,
  allergen_category_id: null,
  allergen_category_name: '',
  comments: '',
  contains: null,
  may_contain: null,
  on_same_manufacturing_area: null,
  on_same_production_line: null,
};

const isSubRowNewAndEmpty = (subRow: SubRowInstance) => {
  const areNullableValuesNull = [
    subRow.contains,
    subRow.may_contain,
    subRow.on_same_manufacturing_area,
    subRow.on_same_production_line,
  ].every(val => val === null);

  const isSubRowTouched = !areNullableValuesNull || subRow.comments;

  return isSubRowTouched && subRow.userAdded;
};

const validationSchema = yup.object({
  substances: yup.array(
    yup.object({
      contains: yup
        .mixed()
        .test('contains', 'error message is irrelevant', switchValidator),
      may_contain: yup
        .mixed()
        .test('may_contain', 'error message is irrelevant', switchValidator),
      on_same_manufacturing_area: yup
        .mixed()
        .test(
          'on_same_manufacturing_area',
          'error message is irrelevant',
          switchValidator,
        ),
      on_same_production_line: yup
        .mixed()
        .test(
          'on_same_production_line',
          'error message is irrelevant',
          switchValidator,
        ),
      sub_rows: yup.array(
        yup.object({
          allergen_category_name: yup
            .string()
            .test(
              'allergen_category_name',
              'Allergen Category Name is Required',
              (val, context: any) => {
                // if there is any NonNull value , then 'allergen_category_name' should be filled
                // false means ERROR

                if (val === '') {
                  if (
                    typeof context.parent.contains === 'boolean' ||
                    typeof context.parent.may_contain === 'boolean' ||
                    typeof context.parent.on_same_manufacturing_area ===
                      'boolean' ||
                    typeof context.parent.on_same_production_line === 'boolean'
                  ) {
                    return false;
                  }
                }
                return true;
              },
            ),
          contains: yup
            .mixed()
            .test(
              'contains',
              'error message is irrelevant',
              (val, context: any) => {
                if (
                  context.from[1].value.allergen_category_name === 'Nuts' &&
                  context.from[1].value.contains === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }
                if (
                  context.from[1].value.allergen_category_name ===
                    'Cereals containing glutent and products thereof' &&
                  context.from[1].value.contains === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }

                if (val !== null) {
                  return true;
                }
                if (
                  context.parent.allergen_category_name === '' ||
                  context.parent.allergen_category_name === null
                ) {
                  return true;
                }

                return false;
              },
            ),
          may_contain: yup
            .mixed()
            .test(
              'may_contain',
              'error message is irrelevant',
              (val, context: any) => {
                if (
                  context.from[1].value.allergen_category_name === 'Nuts' &&
                  context.from[1].value.may_contain === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }
                if (
                  context.from[1].value.allergen_category_name ===
                    'Cereals containing glutent and products thereof' &&
                  context.from[1].value.may_contain === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }

                if (val !== null) {
                  return true;
                }
                if (
                  context.parent.allergen_category_name === '' ||
                  context.parent.allergen_category_name === null
                ) {
                  return true;
                }

                return false;
              },
            ),

          on_same_manufacturing_area: yup
            .mixed()
            .test(
              'on_same_manufacturing_area',
              'error message is irrelevant',
              (val, context: any) => {
                if (
                  context.from[1].value.allergen_category_name === 'Nuts' &&
                  context.from[1].value.on_same_manufacturing_area === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }
                if (
                  context.from[1].value.allergen_category_name ===
                    'Cereals containing glutent and products thereof' &&
                  context.from[1].value.on_same_manufacturing_area === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }

                if (val !== null) {
                  return true;
                }
                if (
                  context.parent.allergen_category_name === '' ||
                  context.parent.allergen_category_name === null
                ) {
                  return true;
                }

                return false;
                // if (context.parent.allergen_category_name) return false;
                // return true;
              },
            ),
          on_same_production_line: yup
            .mixed()
            .test(
              'on_same_production_line',
              'error message is irrelevant',
              (val, context: any) => {
                if (
                  context.from[1].value.allergen_category_name === 'Nuts' &&
                  context.from[1].value.on_same_production_line === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }
                if (
                  context.from[1].value.allergen_category_name ===
                    'Cereals containing glutent and products thereof' &&
                  context.from[1].value.on_same_production_line === false
                ) {
                  if (val === null) {
                    return true;
                  }
                }

                if (val !== null) {
                  return true;
                }
                if (
                  context.parent.allergen_category_name === '' ||
                  context.parent.allergen_category_name === null
                ) {
                  return true;
                }

                return false;
                // if (context.parent.allergen_category_name) return false;
                // return true;
              },
            ),
        }),
      ),
    }),
  ),
  requirements: yup.array(
    yup.object({
      fulfilled: yup
        .mixed()
        .test('fulfilled', 'error message is irrelevant', switchValidator),
    }),
  ),
});

type P = {
  share?: boolean;
};
const AllergensComp: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');
  const history = useHistory();
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const form = useForm<Allergens>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    // reValidateMode: 'onSubmit',
  });

  const { data: productAllergens, ...productAllergenUtils } =
    WizardHooks.useAllergens(
      {
        code: urlParams.code,
        version: urlParams.version,
      },
      {
        enabled: !!urlParams.code && !!urlParams.version,
        onSuccess: data => {
          const newData = deepClone(data);

          newData.data.substances = [...(newData?.data.substances || [])].map(
            (el, i) => {
              if (!el.sub_rows || !el.sub_rows?.length) return el;

              if (
                el.sub_rows.length + 1 ===
                data?.data.substances[i].sub_rows.length
              )
                return el;

              const newEl = deepClone(el);

              newEl.sub_rows = [
                ...newEl.sub_rows,
                { ...substanceSubRowSample },
              ];

              return newEl;
            },
          );

          for (let index = 0; index < 14; index++) {
            if (
              newData.data.substances[index].contains === null &&
              newData.data.substances_automatic[index].contains !== null
            ) {
              newData.data.substances[index].contains =
                newData.data.substances_automatic[index].contains;
            }

            if (
              newData.data.substances[index].sub_rows !== undefined &&
              newData.data.substances_automatic[index].sub_rows !== undefined
            ) {
              for (
                let i = 0;
                i < newData.data.substances[index].sub_rows.length;
                i++
              ) {
                if (
                  newData.data.substances[index].sub_rows[i].contains ===
                    null &&
                  newData.data.substances_automatic[index].sub_rows[i]
                    ?.contains !== null
                ) {
                  newData.data.substances[index].sub_rows[i].contains =
                    newData.data.substances_automatic[index].sub_rows[
                      i
                    ]?.contains;
                }
              }
            }
          }
          form.reset(newData.data);
        },
      },
    );
  const updateAllergensMutation = WizardHooks.useUpdateAllergensMutation();
  const validateAllergensMutation = WizardHooks.useValidateAllergensMutation();

  const removeEmptySubstanceSubRows = (data: Allergens) => {
    const cloned = deepClone(data);

    cloned.substances = cloned.substances.map(substance => {
      const clonedSubstance = deepClone(substance);

      if (!clonedSubstance?.sub_rows) return clonedSubstance;

      clonedSubstance.sub_rows = clonedSubstance.sub_rows.filter(
        subRow => subRow.allergen_category_name,
      );

      return clonedSubstance;
    });

    return cloned;
  };

  const onSave = async (link: string, data: Allergens) => {
    const cleanData = removeEmptySubstanceSubRows(data);

    updateAllergensMutation.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...cleanData,
      },
      {
        onSuccess: () => {
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  const onValidate = async (data: Allergens) => {
    try {
      const isFormValid = await form.trigger();
      if (!isFormValid) {
        setErrorForm('inValid');
        toast.error('Form is not valid');
        return;
      }
      const cleanData = removeEmptySubstanceSubRows(data);
      validateAllergensMutation.mutate({
        code: urlParams.code,
        version: urlParams.version,
        ...cleanData,
      });
    } catch (e) {}
  };

  const generateInputName = React.useCallback(
    (cell: Cell): FieldPath<Allergens> => {
      const [substanceIndex, subRowIndex] = cell.row.id.split('.');

      if (cell.row.depth === 0)
        return `substances.${Number(substanceIndex)}.${
          cell.column.id as keyof Allergens_Substences
        }`;

      return `substances.${Number(substanceIndex)}.sub_rows.${Number(
        subRowIndex,
      )}.${cell.column.id as keyof SubRowInstance}`;
    },
    [],
  );

  const generateNumber = React.useCallback((cell: Cell): boolean | null => {
    const [substanceIndex, subRowIndex] = cell.row.id.split('.');

    if (cell.row.depth === 0) {
      const old = form.watch(`substances.${Number(substanceIndex)}.contains`);
      const sys = form.watch(
        `substances_automatic.${Number(substanceIndex)}.contains`,
      );

      if (sys === undefined || sys === null) {
        return false;
      }

      return old !== sys;
    }

    const old = form.watch(
      `substances.${Number(substanceIndex)}.sub_rows.${Number(
        subRowIndex,
      )}.contains`,
    );
    if (old === undefined) {
      return false;
    }
    const sys = form.watch(
      `substances_automatic.${Number(substanceIndex)}.sub_rows.${Number(
        subRowIndex,
      )}.contains`,
    );
    if (sys === undefined || sys === null) {
      return false;
    }
    return old !== sys;
  }, []);

  const onSubstancesRowChange = React.useCallback(
    (newVal: any, cell: Cell) => {
      const inputName = generateInputName(cell);

      // this is for top level table row update (meaning row is not a sub_row)
      if (cell.row.depth === 0) {
        form.setValue(inputName, newVal, {
          shouldDirty: true,
        });

        // changing the column value for
        // 'contains' | 'may_contain' | 'on_same_production_line' | 'on_same_manufacturing_area'
        // value should trigger a rerender
        // in order to show the sub_rows section
        if (
          [
            'may_contain',
            'contains',
            'on_same_production_line',
            'on_same_manufacturing_area',
          ].includes(cell.column.id)
        ) {
          form.reset(undefined, {
            keepValues: true,
            keepDirty: true,
            keepIsValid: true,
            keepTouched: true,
            keepErrors: true,
          });
        }

        return;
      }

      const [substanceIndex] = cell.row.id.split('.');

      form.setValue(inputName, newVal, {
        shouldDirty: true,
      });

      // 'allergen_category_name' update in sub_rows could trigger a sub_row addition
      // and therefore a form rerender
      if (cell.column.id === 'allergen_category_name') {
        const subRows =
          form.getValues().substances[Number(substanceIndex)].sub_rows;
        const isThereARowWithNoCategoryName = subRows.find(
          subRow => !subRow.allergen_category_name,
        );

        // if there is no sub_rows with empty 'allergen_category_name'
        // a new sub_row should be added
        if (!isThereARowWithNoCategoryName) {
          form.setValue(
            `substances.${Number(substanceIndex)}.sub_rows`,
            [...subRows, { ...substanceSubRowSample }],
            { shouldDirty: true, shouldValidate: true },
          );

          form.reset(undefined, {
            keepValues: true,
            keepDirty: true,
            keepIsValid: true,
            keepTouched: true,
            keepErrors: true,
          });
        }
      }
    },
    [productAllergens?.timeStamp],
  );

  const removeSubRow = React.useCallback(
    (cell: Cell) => {
      if (!form.getValues().substances) return;

      const [substanceIndex, subRowIndex] = cell.row.id.split('.');

      const newSubRows = [
        ...form.getValues().substances[Number(substanceIndex)].sub_rows,
      ];

      newSubRows.splice(Number(subRowIndex), 1);

      if (!newSubRows.find(subRow => subRow.userAdded)) {
        newSubRows.push({ ...substanceSubRowSample });
      }

      form.setValue(
        `substances.${Number(substanceIndex)}.sub_rows`,
        newSubRows,
        { shouldDirty: true },
      );

      form.reset(undefined, {
        keepValues: true,
        keepDirty: true,
        keepIsValid: true,
        keepTouched: true,
        keepErrors: true,
      });
    },
    [productAllergens?.timeStamp],
  );

  const isSubRowTouched = React.useCallback(
    (cell: Cell, formState: UseFormStateReturn<Allergens>) => {
      const [substanceIndex, subRowIndex] = cell.row.id.split('.');

      const {
        contains,
        may_contain,
        on_same_production_line,
        on_same_manufacturing_area,
        comments,
      } =
        formState?.dirtyFields?.substances?.[Number(substanceIndex)]
          ?.sub_rows?.[Number(subRowIndex)] || {};

      return (
        contains ||
        may_contain ||
        on_same_production_line ||
        on_same_manufacturing_area ||
        comments
      );
    },
    [],
  );

  const substancesColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor: keyof Allergens_Substences;
      id: keyof Allergens_Substences;
      className: string;
      tooltip?: string;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }> => [
      {
        Header: 'Substance',
        accessor: 'allergen_category_name',
        id: 'allergen_category_name',
        className: 'col-span-4 px-0',
        Cell: cell => (
          <div className="flex flex-row bg-white w-full justify-between items-center">
            <Controller
              name={generateInputName(cell)}
              control={form.control}
              render={({ field: { value }, fieldState, formState }) => (
                <div className="flex flex-col w-full pr-4 py-2">
                  <Input
                    disableDisabledColor
                    error={!!fieldState.error?.message}
                    disabled={
                      !cell.row.original.userAdded || !urlParams?.editMode
                    }
                    onChange={e => onSubstancesRowChange(e.target.value, cell)}
                    placeholder="Other..."
                    dense
                    className="w-full h-6 text-gray px-2"
                    inputClassName="text-xs px-2 font-roboto"
                    value={value || ''}
                  />

                  {isSubRowTouched(cell, formState) &&
                  fieldState?.error?.message ? (
                    <p className="text-sm z-50 px-4 pt-1 pb-0 bg-white text-red">
                      {fieldState.error.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {urlParams?.editMode && cell.row.original?.userAdded ? (
              <IconButton onClick={() => removeSubRow(cell)}>
                <Close className="text-red" />
              </IconButton>
            ) : null}
          </div>
        ),
      },
      {
        Header: 'Contains',
        id: 'contains',
        accessor: 'contains',
        className: 'col-span-1',
        tooltip: `System will automatically select Yes based on ingredients declaration tab. If allergen does 
          not exist in product No should be selected manually`,
        Cell: cell => (
          <div className={`contains-${generateInputName(cell)}`}>
            <Controller
              name={generateInputName(cell)}
              control={form.control}
              render={({ field: { value }, fieldState }) => (
                <Switch
                  error={!!fieldState.error?.message}
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={
                    typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null
                  }
                  warning={generateNumber(cell)}
                  onChange={newVal =>
                    onSubstancesRowChange(newVal === 'Yes', cell)
                  }
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'May Contain',
        id: 'may_contain',
        accessor: 'may_contain',
        className: 'col-span-1',
        tooltip: `Is there a risk of cross contamination due to handling of allergen
          which needs to be declared on label?`,
        Cell: cell => (
          <div className={`may_contain-${generateInputName(cell)}`}>
            <Controller
              name={generateInputName(cell)}
              control={form.control}
              render={({ field: { value }, fieldState }) => (
                <Switch
                  error={!!fieldState.error?.message}
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={
                    typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null
                  }
                  onChange={newVal =>
                    onSubstancesRowChange(newVal === 'Yes', cell)
                  }
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'On same production line',
        id: 'on_same_production_line',
        accessor: 'on_same_production_line',
        tooltip: `Is Allergan used on same production line or same machineries?`,
        className: 'col-span-1',
        Cell: cell => (
          <div className={`on_same_production_line-${generateInputName(cell)}`}>
            <Controller
              name={generateInputName(cell)}
              control={form.control}
              render={({ field: { value }, fieldState }) => (
                <Switch
                  error={!!fieldState.error?.message}
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={
                    typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null
                  }
                  onChange={newVal =>
                    onSubstancesRowChange(newVal === 'Yes', cell)
                  }
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'On same manufacturing area',
        id: 'on_same_manufacturing_area',
        accessor: 'on_same_manufacturing_area',
        tooltip: 'Is Allergan used or handled on same site/production area ?',
        className: 'col-span-1',
        Cell: cell => (
          <div
            className={`on_same_manufacturing_area-${generateInputName(cell)}`}
          >
            <Controller
              name={generateInputName(cell)}
              control={form.control}
              render={({ field: { value }, fieldState }) => (
                <Switch
                  error={!!fieldState.error?.message}
                  disabled={!urlParams?.editMode}
                  type="secondary"
                  value={
                    typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null
                  }
                  onChange={newVal =>
                    onSubstancesRowChange(newVal === 'Yes', cell)
                  }
                />
              )}
            />
          </div>
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        tooltip: `Explain control measures in place to avoide cross contamiantion risk`,
        className: 'col-span-3 text-center justify-center',
        Cell: cell => (
          <Controller
            name={generateInputName(cell)}
            control={form.control}
            render={({ field: { ref, ...rest } }) => (
              <Input
                disabled={!urlParams?.editMode}
                className="w-full"
                innerRef={ref}
                {...rest}
              />
            )}
          />
        ),
      },
    ],
    [productAllergens?.timeStamp, urlParams?.editMode],
  );

  const dietryRequirementsColumns = React.useMemo(
    (): Array<{
      Header: string;
      accessor: keyof Allergens_Requirements;
      id: keyof Allergens_Requirements;
      className: string;
      Cell?: undefined | ((cell: CustomCell) => JSX.Element);
    }> => [
      {
        Header: 'Requirements',
        accessor: 'dietary_requirement_category_name',
        id: 'dietary_requirement_category_name',
        className: 'col-span-4',
      },
      {
        Header: 'Requirement Fulfilled',
        id: 'fulfilled',
        accessor: 'fulfilled',
        className: 'col-span-2 text-center justify-center',
        Cell: (cell: Cell) => (
          <Controller
            name={`requirements.${Number(cell.row.id)}.fulfilled`}
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Switch
                error={!!fieldState.error?.message}
                disabled={!urlParams?.editMode}
                type="secondary"
                className="justify-center"
                value={
                  typeof value === 'boolean' ? (value ? 'Yes' : 'No') : null
                }
                onChange={newVal => onChange(newVal.toLowerCase() === 'yes')}
              />
            )}
          />
        ),
      },
      {
        Header: 'Attachments',
        id: 'attachment',
        accessor: 'attachment',
        className: 'col-span-2 text-center justify-center',
        Cell: (cell: Cell) => (
          <Controller
            name={`requirements.${Number(cell.row.id)}.attachment`}
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <UploadButton
                disabled={!urlParams?.editMode}
                url={`/products/${urlParams.code}/version/${urlParams.version}/allergens/upload`}
                id={`requirements[${cell.row.index}][${cell.column.id}]`}
                wrapperClassName="flex justify-center"
                value={value}
                onChange={onChange}
              />
            )}
          />
        ),
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: 'comments',
        className: 'col-span-3',
        Cell: cell => (
          <Controller
            name={`requirements.${Number(cell.row.id)}.comments`}
            control={form.control}
            render={({ field: { ref, ...rest } }) => (
              <Input
                disabled={!urlParams?.editMode}
                className="w-full"
                innerRef={ref}
                {...rest}
              />
            )}
          />
        ),
      },
    ],
    [urlParams?.editMode],
  );

  const getSubRows = React.useCallback(
    (row: Allergens_Substences) => {
      return row.sub_rows?.length ? row.sub_rows : [];
    },
    [productAllergens?.timeStamp],
  );

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form className={errorForm}>
        <h3 className="font-roboto text-base text-primary-light mb-2 mt-7">
          Allergens
        </h3>
        <div
          className={`
            nuts-contains-${form.watch('substances.0.contains')}
            nuts-may_contain-${form.watch('substances.0.may_contain')} 
            nuts-on_same_manufacturing_area-${form.watch(
              'substances.0.on_same_manufacturing_area',
            )}
            nuts-on_same_production_line-${form.watch(
              'substances.0.on_same_production_line',
            )}
            glutent-contains-${form.watch('substances.1.contains')}
            glutent-may_contain-${form.watch('substances.1.may_contain')} 
            glutent-on_same_manufacturing_area-${form.watch(
              'substances.1.on_same_manufacturing_area',
            )}
            glutent-on_same_production_line-${form.watch(
              'substances.1.on_same_production_line',
            )}

       
           `}
        >
          <Table
            loading={productAllergenUtils.isLoading}
            getSubRows={getSubRows}
            columns={substancesColumns}
            data={
              form.getValues()?.substances
                ? [...form.getValues()?.substances]
                : []
            }
          />

          <h3 className="font-roboto text-base text-primary-light mb-2 mt-7">
            Dietry Requirements
          </h3>

          <Table
            loading={productAllergenUtils.isLoading}
            columns={dietryRequirementsColumns}
            data={form.getValues().requirements || []}
          />

          <FormButtonGroup
            share={share}
            pageName="allergens"
            onValidate={() => onValidate(form.getValues())}
            onSave={link => onSave(link, form.getValues())}
          />
        </div>
      </form>
    </Layout>
  );
};

export default AllergensComp;
