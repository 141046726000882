/* eslint-disable react/no-array-index-key */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';

interface P {
  RowComp: React.ElementType;
  headers: Array<string>;
  rowKeys?: Array<string>;
  className?: string;
}

const MiniTable: React.FC<P> = ({ RowComp, headers, rowKeys, className }) => {
  return (
    <table
      className={overrideTailwindClasses(
        clsx('inline-table table-auto', className),
      )}
    >
      <thead>
        <tr className="bg-white-400 table-row h-10 border-t-4 border-b-4 border-white-600">
          {headers.map((headerText, i) => (
            <td
              key={`header-cell-${i}`}
              className={clsx(
                'table-cell align-middle py-2 font-roboto text-primary-light text-sm',
                i === 0 ? 'px-4' : 'px-2',
              )}
            >
              {headerText}
            </td>
          ))}
        </tr>
      </thead>

      <tbody>
        {rowKeys ? (
          rowKeys.map((rowKey, rowIndex) => (
            <tr
              key={`body-row-${rowKey}-${rowIndex}`}
              className="bg-white h-10 table-row border-t-4 border-b-4 border-white-600"
            >
              <RowComp keyStringBase={rowKey} />
            </tr>
          ))
        ) : (
          <tr
            key="body-row"
            className="bg-white h-10 table-row border-t-4 border-b-4 border-white-600"
          >
            <RowComp />
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default MiniTable;
