/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable object-shorthand */
/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect } from 'react';

import Input from '../../../../Common/Input';

const InputController = ({
  onChange,
  type = 'text',
  value,
  disabled,
  role,
}) => {
  const [val, setVal] = React.useState();

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <>
      <Input
        className={`${val ? 'not-empty' : 'empty'} input-role ${
          role ? 'required' : ''
        }`}
        onBlur={event => onChange(event)}
        onChange={event => {
          setVal(event.target.value);
        }}
        disabled={disabled}
        type={type}
        value={val}
      />
    </>
  );
};
export default InputController;
