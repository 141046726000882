import React from 'react';

type SidebarStateProviderProps = { children: React.ReactNode };

// const SidebarStateContext = React.createContext<
//   | {
//       isSidebarOpen: boolean;
//       setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
//     }
//   | undefined
// >(undefined);
const SidebarStateContext = React.createContext<
  readonly [boolean, React.Dispatch<React.SetStateAction<boolean>>] | undefined
>(undefined);

function SidebarStateProvider({ children }: SidebarStateProviderProps) {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  const value = [isSidebarOpen, setIsSidebarOpen] as const;

  return (
    <SidebarStateContext.Provider value={value}>
      {children}
    </SidebarStateContext.Provider>
  );
}

function useSidebarState() {
  const context = React.useContext(SidebarStateContext);

  if (context === undefined) {
    throw new Error(
      'useSidebarState must be used within a SidebarStateProvider',
    );
  }

  return context;
}

export { SidebarStateProvider, useSidebarState };
