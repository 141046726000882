import React from 'react';
import clsx from 'clsx';
import { Select as MuiSelect, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

interface P {
  value: string;
  onChange: (e: string) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: theme.spacing(18),
  },
}));

const Select: React.FC<P> = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <MuiSelect
      classes={{
        root: clsx(classes.root),
      }}
      IconComponent={KeyboardArrowDown}
      labelId="user-select-label"
      id="user-select"
      value={value}
      onChange={e => onChange(String(e.target.value))}
    >
      <MenuItem value="Babak Arghavan1">Babak Arghavan1</MenuItem>
      <MenuItem value="Babak Arghavan2">Babak Arghavan2</MenuItem>
    </MuiSelect>
  );
};

export default Select;
