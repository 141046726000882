import React from 'react';
import clsx from 'clsx';

import { useForm, Controller } from 'react-hook-form';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  CustomNode,
  CustomNodeTypes,
} from '@components/Bussines/Products/ProductForm/Flow/types';

import {
  Modal,
  Input,
  AutoComplete,
  Button,
  Tooltip,
} from '@components/Common';

type NodeListItem = {
  name: 'Input' | 'Output' | 'Default';
  code: CustomNodeTypes;
};

export interface FormValues {
  nodeType: NodeListItem;
  content: string;
}

type P = {
  open: boolean;
  setOpen: (value?: boolean) => void;
  onSubmit: (data: FormValues) => void;
  data?: CustomNode;
};

const useStyles = makeStyles((theme: any) => ({
  container: {
    minWidth: theme.spacing(57),
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(5),
  },
}));

const nodeTypesList: Array<NodeListItem> = [
  {
    name: 'Default',
    code: 'default',
  },
  {
    name: 'Input',
    code: 'input',
  },
  {
    name: 'Output',
    code: 'output',
  },
];

const NewProductModal: React.FC<P> = ({ open, setOpen, data, onSubmit }) => {
  const classes = useStyles();

  const form = useForm<FormValues>();

  React.useEffect(() => {
    form.reset({
      content: data?.data?.label || '',
      nodeType: {
        name:
          nodeTypesList.find(
            el => el.name.toLowerCase() === data?.data?.nodeType || '',
          )?.name || nodeTypesList[0].name,
        code: data?.data?.nodeType || nodeTypesList[0].code,
      },
    });
  }, [JSON.stringify(data)]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center bg-white-600 shadow-lg',
          classes.container,
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'pl-4 min-w-full',
            classes.header,
          )}
        >
          <h3 className="font-roboto text-base">Modify Node</h3>

          <IconButton onClick={() => setOpen()}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full">
            <p className="font-roboto">
              Box Type
              <Tooltip
                title={`the 'Input' type only has one exit Point 
              while 'Output' type only has one entry point
               and type 'Default' has both entry and exit points`}
              />
            </p>

            <div className="flex flex-col">
              <Controller
                name="nodeType"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col w-full pr-4 py-1">
                    <AutoComplete
                      freeSolo={false}
                      canAddCustomItem={false}
                      disableClearable
                      items={nodeTypesList}
                      value={value}
                      onChange={onChange}
                      className="w-56"
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full">
            <p className="font-roboto">
              Content
              <Tooltip title="The text content of the related box" />
            </p>

            <div className="flex flex-col">
              <Controller
                name="content"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <div className="flex flex-col w-full pr-4 py-1">
                    <Input
                      placeholder="Content"
                      className="w-56"
                      innerRef={ref}
                      {...rest}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-end items-center min-w-full">
            <Button
              type="button"
              className="text-green border-green"
              variant="outlined"
              startIcon={<Check />}
              text="Done"
              onClick={() => onSubmit(form.getValues())}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewProductModal;
