import {
  Integrity,
  Palm,
  Soya,
  Egg,
  Gm,
  Meat,
  Seafood,
  Colours,
  Flavourings,
  Preservatives,
  Additives,
  Irradiation,
  Additional_tables,
} from '@hooks/api/wizard/types';

const DEFAULT_COUNTRY = { id: -1, name: '' };
const DEFAULT_AUTOCOMPLETE_VALUE = { code: -1, name: '' };
const DEFAULT_WELFARE = DEFAULT_AUTOCOMPLETE_VALUE;
const DEFAULT_ACCREDITATION_STATUS = DEFAULT_AUTOCOMPLETE_VALUE;
const DEFAULT_MEAT_SPECIES = DEFAULT_AUTOCOMPLETE_VALUE;
const DEFAULT_CAPTURE_METHOD = DEFAULT_AUTOCOMPLETE_VALUE;

const palm: Palm = {
  accreditation_status: DEFAULT_ACCREDITATION_STATUS,
  certified: null,
  certificate: '',
  ingredient_name: '',
  country_of_origin: [],
  ingredient_id: null,
  percent: null,
};

const soya: Soya = {
  accreditation_status: DEFAULT_ACCREDITATION_STATUS,
  certified: null,
  certificate: '',
  ingredient_name: '',
  country_of_origin: [],
  ingredient_id: null,
  percent: null,
};

const egg: Egg = {
  comments: '',
  welfare: '',
  is_pasteurized: null,
  certificate: '',
  ingredient_name: '',
  country_of_origin: [],
  ingredient_id: null,
};

const gm: Gm = {
  comments: '',
  data_and_details: '',
  percent: null,
  certificate: '',
  ingredient_name: '',
  country_of_origin: [],
  ingredient_id: null,
};

const meat: Meat = {
  farm_details: {
    comments: '',
    country: [],
    health_mark: '',
    name_and_address: '',
    welfare: [],
  },
  meat_type: {
    meat_content: '',
    meat_species: DEFAULT_MEAT_SPECIES,
    min_meat_percent: '',
    min_visual_lean: '',
  },
  packing_site_details: {
    comments: '',
    country: [],
    health_mark: '',
    name_and_address: '',
    welfare: [],
  },
  slaughterhouse_details: {
    comments: '',
    country: [],
    health_mark: '',
    name_and_address: '',
    welfare: [],
  },
};

const seafood: Seafood = {
  capture_method: DEFAULT_CAPTURE_METHOD,
  caught_area_faq_number: '',
  caught_method: '',
  comments: '',
  free_of_parasite: '',
  health_mark: '',
  species_latin_name: '',
  welfare: '',
  min_fish_content: null,
};

const colours: Colours = {
  comments: '',
  country_of_origin: [],
  e_number_id: '',
  e_number_name: '',
  name: '',
  percent: null,
  source_of_material: '',
};

const flavourings: Flavourings = {
  comments: '',
  country_of_origin: [],
  e_number_id: '',
  e_number_name: '',
  name: '',
  percent: null,
  source_of_material: '',
};

const preservatives: Preservatives = {
  comments: '',
  country_of_origin: [],
  e_number_id: '',
  e_number_name: '',
  name: '',
  percent: null,
  source_of_material: '',
};

const additives: Additives = {
  comments: '',
  country_of_origin: [],
  e_number_id: '',
  e_number_name: '',
  name: '',
  percent: null,
  source_of_material: '',
};

const irradiation: Irradiation = {
  explain: '',
};

const additional_tables: Additional_tables = {
  title: '',
  value: '',
};

const ROW_SAMPLES = {
  palm,
  soya,
  egg,
  gm,
  meat,
  seafood,
  colours,
  flavourings,
  preservatives,
  additives,
  irradiation,
  additional_tables,
};

export type Expandables = Omit<
  Integrity,
  | 'cardbox_status'
  | 'errors'
  | 'warnings'
  | 'expanded_state'
  | 'additional_tables'
>;

export type ExpandableKeys = keyof Expandables;
export type ExpandableValuesUnion = Integrity[keyof Expandables];

const DEFAULT_EXPAND_STATE: { [key in keyof Expandables]: boolean | null } = {
  palm: null,
  soya: null,
  egg: null,
  gm: null,
  meat: null,
  seafood: null,
  colours: null,
  flavourings: null,
  preservatives: null,
  additives: null,
  irradiation: null,
};

export { ROW_SAMPLES, DEFAULT_EXPAND_STATE };
