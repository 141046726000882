import { useIsFetching, useIsMutating } from 'react-query';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const Loader = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const theme = useTheme();

  return (
    <div
      className={clsx(
        'fixed right-0 p-2 rounded-5 shadow-xl',
        isFetching || isMutating ? 'block' : 'hidden',
      )}
      style={{
        top: theme.appBarHeight + 5,
        zIndex: theme.zIndex.modal + 100,
      }}
    >
      <CircularProgress className="w-6 h-6" />
    </div>
  );
};

export default Loader;
