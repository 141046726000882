import React from 'react';
import clsx from 'clsx';
import { PieChart } from 'react-minimal-pie-chart';

interface P {
  header: string;
  data: Array<{
    value: number;
    title: string;
  }>;
}

const backgroundColorClassNamesInOrder = [
  'bg-primary-light',
  'bg-red-dark',
  'bg-yellow-light',
  'bg-green',
  'bg-gray-400',
];

const isTotalSumOfValuesEqToZero = (values: P['data']) => {
  let sum = 0;

  values.forEach(el => {
    sum += el.value;
  });

  return sum === 0;
};

const Box: React.FC<P> = ({ header, data }) => {
  const [hoverElementIndex, setHoveredElementIndex] = React.useState<
    number | null
  >(null);

  // this is to prevent duplicate sequences of colors in charColor array
  // so that no item in chart is the same color as the next or previous item
  const formattedChartColors = React.useMemo(() => {
    const chartColors = ['#162641', '#E45572', '#FFCA28', '#208D9A', '#C4C4C4'];
    const colorCount = chartColors.length;

    const colorCountArray = Array(data.length)
      .fill('')
      .map((_, i) => chartColors[i % colorCount]);

    const uniqueSequenceColorArray: typeof chartColors = [];

    colorCountArray.forEach((color, i, fullColorArray) => {
      const prevColor = fullColorArray[i - 1] || '';
      const nextColor = fullColorArray[i + 1] || '';

      const narrowedCharColorArr = [...chartColors];

      if (
        color === prevColor ||
        color === nextColor ||
        (fullColorArray.length === i + 1 && color === fullColorArray[0])
      ) {
        if (color === prevColor) {
          narrowedCharColorArr.splice(i - 1, 1);
        }

        if (color === nextColor) {
          narrowedCharColorArr.splice(i + 1, 1);
        }

        const uniqueColor = narrowedCharColorArr[Math.floor(Math.random() * 3)];

        uniqueSequenceColorArray.push(uniqueColor);
      } else {
        uniqueSequenceColorArray.push(color);
      }
    });

    return uniqueSequenceColorArray;
  }, [JSON.stringify(data)]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center px-4 h-10 bg-white-400 rounded">
        <p className="text-base font-roboto text-primary-light">{header}</p>
      </div>

      <div className="flex flex-row items-center justify-between bg-white px-4 py-6 shadow mt-1 rounded h-full">
        <div className="flex flex-col justify-center">
          {data.map((el, i) => (
            <div key={el.title} className="flex flex-row items-center my-3">
              <div
                className={clsx(
                  'w-3 h-3 rounded-full mr-4',
                  backgroundColorClassNamesInOrder[i],
                )}
              />

              <p className="text-sm font-roboto text-primary-light">
                {el.title}
              </p>
            </div>
          ))}
        </div>

        <div className="h-60 w-60 px-2 flex flex-row items-center justify-center">
          {isTotalSumOfValuesEqToZero(data) ? (
            <p className="text-2xl font-roboto text-primary">
              No Data Available
            </p>
          ) : (
            <PieChart
              animate
              onMouseOver={(_, i) => setHoveredElementIndex(i)}
              onMouseOut={() => setHoveredElementIndex(null)}
              lineWidth={36}
              labelPosition={0}
              labelStyle={{
                fill: '#000',
                fontSize: '0.875rem',
                fontFamily: 'roboto',
              }}
              label={x => {
                if (x.dataIndex === hoverElementIndex) return x.dataEntry.value;

                return '';
              }}
              data={data.map((el, i) => ({
                ...el,
                color: formattedChartColors[i],
              }))}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Box;
