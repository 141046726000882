import React from 'react';
import clsx from 'clsx';
import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

interface P {
  anchorText: string;
  items: Array<{
    text: string;
    onClick: () => void;
  }>;
}

const useStyles = makeStyles((theme: any) => ({
  menuItem: {
    ...theme.typography.caption,
    fontFamily: theme.fonts.secondary,
  },
}));

const Menu: React.FC<P> = ({ items, anchorText }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const menuAnchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="text"
        innerRef={menuAnchorRef}
        onClick={() => setOpen(!open)}
      >
        <p className="text-white-600">{anchorText}</p>

        <KeyboardArrowDown className="text-white-600" />
      </Button>

      <Popper
        open={open}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className="z-20 bg-white shadow-lg rounded"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <div>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {items.map(el => (
                    <MenuItem
                      key={el.text}
                      className={clsx(classes.menuItem)}
                      onClick={el.onClick}
                    >
                      {el.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Menu;
