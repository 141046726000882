import React from 'react';
import clsx from 'clsx';
import { Checkbox, CheckboxProps } from '@material-ui/core';

import CheckedIcon from '@assets/images/icons/checkbox-checked.svg';
import UnCheckedIcon from '@assets/images/icons/checkbox.svg';

interface P {
  onChange: (arg: boolean) => void;
}

const CheckBox: React.FC<P & Omit<CheckboxProps, keyof P>> = ({
  className,
  checked,
  onChange,
  disabled,
  ...rest
}) => (
  <Checkbox
    disabled={disabled}
    disableRipple
    className={clsx(
      'p-0 text-3xl',
      checked ? 'text-green-600' : 'text-gray-800',
      disabled ? 'pointer-events-none cursor-not-allowed' : '',
      className,
    )}
    checked={checked}
    onChange={(_, isChecked) => onChange(isChecked)}
    icon={<img alt="UnChecked Icon" src={UnCheckedIcon} />}
    checkedIcon={<img alt="Checked Icon" src={CheckedIcon} />}
    {...rest}
  />
);

export default CheckBox;
