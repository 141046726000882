import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';

import { toast } from 'react-toastify';

import notificationApis from '@hooks/api/notification/api';
import errorHandler from '@utils/errorHandler';

import {
  GetNotificationParams,
  UseNotificationResponseType,
  DeleteNotificationParams,
} from '@hooks/api/notification/types';

const KEYS = {
  notificationList: ['notification', 'list'],
} as const;

const useNotificationList = (
  params: GetNotificationParams,
  options?: UseQueryOptions<UseNotificationResponseType, AxiosError>,
) => {
  const query = useQuery<UseNotificationResponseType, AxiosError>(
    [...KEYS.notificationList, params],
    () => notificationApis.getNotificationList(params),
    options,
  );

  return query;
};

const useDeleteNotificationMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(notificationApis.deleteNotification, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('notification');
    },
  });

  return mutation;
};

export default {
  useNotificationList,
  useDeleteNotificationMutations,
};
