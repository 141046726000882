import React from 'react';
import Cookies from 'js-cookie';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useUser } from '@contexts';

import map from '@routes/map';

type P = RouteProps & { authenticationRoute?: boolean };

const PrivateRoute: React.FC<P> = props => {
  const token = Cookies.get('token');
  const [user] = useUser();

  // if token doesn't exist , user is not authenticated
  if (!token) {
    return <Redirect to={map.auth.login} />;
  }

  // in order to access the website , user needs to be verified
  // but being verified is different for 'supplier' users & 'customer' users
  const isUserVerified =
    user?.user_type === 'supplier'
      ? !!user?.is_supplier_sign
      : !!user?.is_policy_sign && !!user?.is_term_sign;

  // to access 'authenticationRoute's , full verification is not required , user alone will suffice
  if (!(props.authenticationRoute ? !!user : isUserVerified)) {
    return <Redirect to={map.auth.login} />;
  }

  // Logged in and verified
  return <Route exact {...props} />;
};

export default PrivateRoute;
