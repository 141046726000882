/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button as MuiButton, IconButton } from '@material-ui/core';
import { Add, Loop, Delete, Create, Visibility } from '@material-ui/icons';

import GeneralHooks from '@hooks/api/general';

interface Props {
  id: string;
  text?: string;
  wrapperClassName?: string;
  className?: string;
  textClassName?: string;
  imgClassName?: string;
  onChange: (arg: string) => void;
  value: string;
  showPreview?: boolean;
  showAddIcon?: boolean;
  disabled?: boolean;
  url: string;
  acceptType?: string;
  yupError?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  button: {
    width: theme.spacing(13),
    height: theme.spacing(3),

    '& .MuiButton-label': {
      width: theme.spacing(13),
      height: theme.spacing(3),
    },
  },
  activated: {
    border: '1px solid',
    borderColor: `${theme.palette.gray.main} !important`,
  },
  icon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    padding: 0,

    '& svg': {
      fontSize: 'inherit',
    },
  },
}));

const getFileName = (file: Props['value']) => file.split('/').slice(-1)[0];

const UploadButtonAdvance: React.FC<Props> = ({
  disabled,
  id,
  value,
  onChange,
  text = 'Choose File',
  wrapperClassName,
  className,
  textClassName,
  imgClassName,
  showPreview = false,
  showAddIcon = false,
  url,
  acceptType,
  yupError,
}) => {
  const [preview, setPreview] = React.useState(value);
  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const originalVal = React.useRef<Props['value'] | null>(value);

  const uploadFileMutation = GeneralHooks.useUploadMutation();

  const classes = useStyles({ preview });

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e?.target?.files?.[0];

    if (!selectedFile) return;

    if (inputRef?.current?.value) inputRef.current.value = '';

    if (
      selectedFile?.type !== 'application/pdf' &&
      !selectedFile?.type.includes('image/')
    ) {
      // only images and pdf files are accepted
      toast.error('only image and pdf document are allowed');

      return;
    }

    const maxSizeInMB = 10; // 10mb
    const sizeInMB = selectedFile.size / (1024 * 1024);
    // if (selectedFile.size > maxSize) {
    if (sizeInMB > maxSizeInMB) {
      // only images and pdf files are accepted
      toast.error(
        `File if Too big , Files should be under 
         ${maxSizeInMB} Megabytes`,
      );

      return;
    }

    const reader = new FileReader();

    reader.addEventListener('loadstart', () => setLoading(true));

    reader.onload = async res => {
      setPreview(res?.target?.result as string);

      try {
        const uploadAddress = await uploadFileMutation.mutateAsync({
          url,
          file: selectedFile,
        });

        onChange(uploadAddress);
      } catch (error) {
        setPreview('');
      } finally {
        setLoading(false);
      }
    };

    reader.readAsDataURL(selectedFile as Blob); // convert to base64 string
  };

  const onViewClick = () => {
    const fileName = getFileName(value);

    const link = document.createElement('a');
    link.href = value;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }, 100);
  };

  const PreviewBtnComp = () => {
    if (value) {
      return (
        <>
          <IconButton
            disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              setPreview('');
              onChange('');

              // if (preview && originalVal.current) {
              //   setPreview('');
              //   onChange(originalVal.current);
              // } else {
              //   onChange('');
              //   originalVal.current = null;
              // }
            }}
          >
            <Delete />
          </IconButton>

          <IconButton
            disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={() => {
              if (inputRef?.current) {
                inputRef.current.click();
              }
            }}
          >
            <Create />
          </IconButton>

          <IconButton
            // disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={() => onViewClick()}
          >
            <Visibility />
          </IconButton>
        </>
      );
    }

    if (showAddIcon) {
      return (
        <div className="flex flex-row items-center justify-between">
          <Add className="text-lg mr-1" />

          <p className={textClassName}>{text}</p>
        </div>
      );
    }

    return <p>{text || ''}</p>;
  };

  return (
    <div
      className={clsx(
        classes.root,
        wrapperClassName,
        'relative',
        'overflow-hidden',
      )}
    >
      <input
        disabled={disabled}
        ref={inputRef}
        accept={acceptType ?? 'image/*,application/pdf'}
        className={classes.input}
        id={id}
        type="file"
        onChange={onFileSelect}
      />

      {loading ? <Loop /> : null}

      {!loading ? (
        showPreview && preview ? (
          <>
            <img className={imgClassName} src={preview} alt="preview" />
            <MuiButton
              id="test2"
              disabled={disabled}
              disableRipple
              className={clsx(
                'px-0',
                !disabled && 'flex',
                classes.button,
                preview && classes.activated,
                yupError ? 'error-border' : '',
                className,
                'absolute',
                'bg-white',
                'h-5/6 w-5/6',
                'opacity-0',
                'transition-opacity',
                'duration-1000',
                'hover:opacity-100',
              )}
              classes={{
                label: 'flex flex-row items-center justify-evenly',
              }}
              variant="outlined"
              color="primary"
              component="span"
            >
              <PreviewBtnComp />
            </MuiButton>
          </>
        ) : (
          <label htmlFor={id}>
            <MuiButton
              disabled={disabled}
              disableRipple
              className={clsx(
                'px-0',
                classes.button,
                preview && classes.activated,
                className,
                yupError ? 'error-border' : '',
              )}
              classes={{
                label: 'flex flex-row items-center justify-evenly',
              }}
              variant="outlined"
              color="primary"
              component="span"
            >
              <PreviewBtnComp />
            </MuiButton>
          </label>
        )
      ) : null}
    </div>
  );
};

export default UploadButtonAdvance;
