import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { generalHook as GeneralApis } from '@hooks';
import { Country } from '@hooks/api/generalTypes';

type P = {
  renderInput?: never;
  options?: [];
  inputPlaceholder?: string;
  inputRootClassName?: string;
  multiple?: boolean;
  value: Array<Country> | Country | null;
  onChange: (arg: Country | Array<Country>) => void;
  dense?: boolean;
  disabled?: boolean;
  error?: boolean;
};

type StyleRelatedProps = Pick<P, 'dense' | 'disabled' | 'error'>;

const useStyles = makeStyles((theme: any) => ({
  inputRoot: {
    height: 'fit-content',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 0 : 1),

    '& .MuiInputBase-root': {
      backgroundColor: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.common.white : theme.palette.gray.light,

      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ error }: StyleRelatedProps) =>
        error ? theme.palette.error.dark : theme.palette.gray.light,
    },
  },
  autoComplete: {
    backgroundColor: theme.palette.common.white,

    // : theme.spacing(5),

    minHeight: ({ dense }: StyleRelatedProps) => theme.spacing(dense ? 3 : 5),

    '& .MuiAutocomplete-inputRoot': {
      minHeight: theme.spacing(3),
      height: '100%',
    },

    '& input': {
      ...theme.typography.body2,
      fontFamily: theme.fonts.secondary,
      fontWeight: theme.typography.fontWeightRegular,

      color: ({ disabled }: StyleRelatedProps) =>
        disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

      padding: `${theme.spacing(0, 1)} !important`,

      '&::placeholder': {
        color: theme.palette.gray.main,
      },
    },
  },
  popper: {
    '& .MuiPaper-root': {
      color: theme.palette.primary.light,
    },

    '& .MuiAutocomplete-noOptions': {
      color: theme.palette.primary.light,
    },
  },
  option: {
    color: ({ disabled }: StyleRelatedProps) =>
      disabled ? theme.palette.gray.main : theme.palette.secondary.dark,

    ...theme.typography.body2,
    fontFamily: theme.fonts.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Autocomplete: React.FC<
  P & Omit<AutocompleteProps<any, true, boolean, false>, keyof P>
> = ({
  multiple = false,
  id,
  className,
  inputRootClassName,
  value,
  onChange,
  disabled,
  options,
  renderInput,
  inputPlaceholder = 'Search ...',
  dense = false,
  error = false,
  ...rest
}) => {
  const classes = useStyles({ disabled, dense, error });

  const { data: countries } = GeneralApis.useCountries();

  return (
    <MuiAutocomplete
      disabled={disabled}
      multiple={multiple}
      options={countries?.data || []}
      autoHighlight
      getOptionLabel={option => option?.name || ''}
      className={clsx(classes.autoComplete, 'transition-all', className)}
      getOptionSelected={(option, currentValue) => {
        if (!currentValue) return false;

        if (!currentValue.name || !currentValue.id) return false;

        if (multiple && Array.isArray(currentValue)) {
          return !!(currentValue as Array<Country>).filter(
            val => val.id === option.id,
          );
        }

        return (currentValue as Country).id === option.id;
      }}
      value={value || []}
      classes={{
        popper: classes.popper,
        option: classes.option,
      }}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={params => (
        <TextField
          {...params}
          classes={{
            root: clsx(classes.inputRoot, inputRootClassName),
          }}
          placeholder={inputPlaceholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
