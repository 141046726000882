const map = {
  auth: {
    loginAs: '/auth/loginAs/:token',
    share: '/auth/share/:public_token',
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    termsAndConditions: '/auth/terms-and-conditions',
    policies: '/auth/policies',
    supplierAndCertificates: '/auth/supplier-certificate',
  },

  dashboard: '/',

  suppliers: {
    suppliersList: '/suppliers/supplierList',
    supplierInfo: '/suppliers/supplierList/supplierInfo/:supplierCode?',

    manufacturersList: '/suppliers/manufacturersList',
    manufacturerInfo:
      '/suppliers/manufacturersList/manufacturerInfo/:manufacturerCode?',
  },

  certificates: {
    certificatesList: '/certificates/certificateList',
    certificateInfo:
      '/certificates/certificateList/certificateInfo/:certificateCode?',
  },

  bussines: {
    products: '/bussines/products',

    // form steps
    productForm: {
      main_info: '/bussines/products/main-info',
      ingredients: '/bussines/products/ingredients',
      integrity: '/bussines/products/integrity',
      allergens: '/bussines/products/allergens',
      nutritional: '/bussines/products/nutrition',
      ccps: '/bussines/products/flow',
      storage: '/bussines/products/storage',
      packaging: '/bussines/products/weight',
      standards: '/bussines/products/standards',
      qas: '/bussines/products/qas',
      attachments: '/bussines/products/attachments',
      // validation: '/bussines/products/validation',
    },
  },

  policies: '/policies',

  configurations: {
    profile: '/profile',
    profileChangePassword: '/profile/change-password',
    setting: '/setting',

    companyProfile: '/configurations/company-profile',

    users: {
      usersList: '/configurations/users/usersList',
      usersInfo: '/configurations/users/usersList/usersInfo/:username?',
    },

    subscription: '/configurations/subscription',
    policyFiles: '/configurations/policy-files',
    security: {
      sharedLinks: '/configurations/shared-links',
      activityLogs: '/configurations/activity-logs',
    },
    recyclebin: '/configurations/recyclebin',
  },
  notification: '/notification',
  share: {
    main_info: '/share/products/main-info',
    ingredients: '/share/products/ingredients',
    integrity: '/share/products/integrity',
    allergens: '/share/products/allergens',
    nutritional: '/share/products/nutrition',
    ccps: '/share/products/flow',
    storage: '/share/products/storage',
    packaging: '/share/products/weight',
    standards: '/share/products/standards',
    qas: '/share/products/qas',
    attachments: '/share/products/attachments',
    validation: '/share/products/validation',
  },
} as const;

const mapWithStartingPoint = {
  ...map,
  startingPoing: map.dashboard,
} as const;

export type ProductFormKeys = keyof typeof map.bussines.productForm;

export type MapType = typeof map;

export default mapWithStartingPoint;
