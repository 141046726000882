import React from 'react';
import { FieldPath } from 'react-hook-form';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import { useUser } from '@contexts';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
  FooterButtonGroup,
  DeletePromptModal,
  AutoComplete,
} from '@components/Common';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Configurations/Users/UsersList/AttributeFiltersModal';
import Menu, {
  MenuItemTypes,
} from '@components/Configurations/Users/UsersList/Menu';

import UserHooks from '@hooks/api/user';
import { GetUsersListParams, UserProfile } from '@hooks/api/user/types';

import { usePrevious, useDebounce } from '@hooks/utils';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): GetUsersListParams => {
  const formattedValues = {
    status: filterValues?.specificationStatus?.code,
  };

  return formattedValues;
};

const UsersListComp = () => {
  const [user] = useUser();

  const history = useHistory();

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [userFilters, setUserFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const userTypes: {
    name: string;
    code: string;
  }[] = [];

  if (user?.access_levels.user_create_update_customer) {
    userTypes.push({ name: 'Company Users', code: 'Company_user' });
  }
  if (user?.access_levels.user_create_update_supplier) {
    userTypes.push({ name: 'Supplier Users', code: 'Supplier_User' });
  }
  const [selectedUserType, setSelectedUserType] = React.useState<null | {
    name: string;
    code: string | number;
  }>(userTypes[0]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  const [selectedUserUsername, setSelectedUserUsername] = React.useState('');

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: usersList, ...useUsersListUtils } = UserHooks.useUsersList({
    page: currentPage,
    q: debouncedSearch,
    user_type: String(selectedUserType?.code),
    ...(userFilters ? formatFilterAttributes(userFilters) : {}),
  });

  const deleteUserMutation = UserHooks.useDeleteUserMutation();

  const onMenuItemClick = React.useCallback(
    async (actionType: MenuItemTypes, row: Row<UserProfile>) => {
      if (actionType === 'View') {
        history.push(
          `${RouteMap.configurations.users.usersInfo.replace(
            ':username?',
            row.original.username,
          )}`,
        );
      }

      if (actionType === 'Delete') {
        setSelectedUserUsername(row.original.username);
      }
    },
    [],
  );

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<UserProfile> | 'action';
      id: FieldPath<UserProfile> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<UserProfile>) => JSX.Element);
    }> => [
      {
        Header: 'Full Name',
        accessor: 'name',
        id: 'name',
        width: 320,
      },
      {
        Header: 'Email',
        accessor: 'email',
        id: 'email',
        width: 220,
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        width: 280,
      },
      {
        width: 30,
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        Cell: cell => (
          <Menu
            menuItems={
              user?.access_levels.user_trash_restore_customer ||
              user?.access_levels.user_trash_restore_supplier
                ? ['View', 'Delete']
                : ['View']
            }
            open={openTableActionMenuId === cell.row.id}
            onTriggerClick={() => setOpenTableActionMenuId(cell.row.id)}
            onClose={() => setOpenTableActionMenuId('')}
            onMenuItemClick={async actionType => {
              await onMenuItemClick(actionType, cell.row);

              setOpenTableActionMenuId('');
            }}
          />
        ),
      },
    ],
    [onMenuItemClick, openTableActionMenuId, useUsersListUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={!!selectedUserUsername}
        setOpen={() => setSelectedUserUsername('')}
        onConfirm={async () => {
          try {
            await deleteUserMutation.mutateAsync({
              username: selectedUserUsername,
            });
          } catch (error) {
          } finally {
            setSelectedUserUsername('');
          }
        }}
      />

      <AttributeFiltersModal
        values={userFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setUserFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Configurations' },
            { href: '#', text: 'Users' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {userFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setUserFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <div className="flex flex-row items-center justify-between mt-14">
          <AutoComplete
            disableClearable
            className="w-80"
            items={userTypes}
            onChange={setSelectedUserType}
            value={selectedUserType}
          />
          {/* <p className="text-base text-primary-light font-roboto">
            Certificates List
          </p> */}

          {(user?.access_levels.user_create_update_supplier ||
            user?.access_levels.user_create_update_customer) && (
            <div className="flex flex-row items-center">
              <Button
                variant="outlined"
                className="border-1 border-primary"
                onClick={() =>
                  history.push(
                    `${RouteMap.configurations.users.usersInfo.replace(
                      ':username?',
                      '',
                    )}?editMode=true&type=${selectedUserType?.code}`,
                  )
                }
                startIcon={<AddIcon />}
                text="Add New"
              />
            </div>
          )}
        </div>

        <Table
          loading={useUsersListUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto last-item-center"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={usersList?.data || []}
          getRowProps={row => ({
            onClick: () =>
              history.push(
                `${RouteMap.configurations.users.usersInfo.replace(
                  ':username?',
                  row.original.username,
                )}`,
              ),
          })}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: usersList?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default UsersListComp;
