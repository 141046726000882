import { AxiosResponse } from 'axios';
import axios from '@lib/api';

import {
  DeleteNotificationParams,
  GetNotificationParams,
  UseNotificationResponseType,
} from '@hooks/api/notification/types';

const getNotificationList = async (params: GetNotificationParams) => {
  const res: AxiosResponse<UseNotificationResponseType> = await axios({
    method: 'get',
    url: `/notifications`,
    params,
  });
  return res.data;
};

const deleteNotification = async (params: DeleteNotificationParams) => {
  const res: AxiosResponse = await axios({
    method: 'get',
    url: `/notifications/${params.notification_code}`,
  });
  return res.data;
};

export default {
  getNotificationList,
  deleteNotification,
};
