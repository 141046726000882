/* eslint-disable no-nested-ternary */
import React from 'react';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  DeletePromptModal,
  Switch,
  UploadButtonUploadOnChange,
} from '@components/Common';

import { useUrlParams } from '@hooks/utils';

import UserHooks from '@hooks/api/user';
import { UserProfileChangePassword } from '@hooks/api/user/types';

const initialValues: UserProfileChangePassword = {
  password_old: '',
  password: '',
  password_confirmation: '',
};

const validationSchema = yup.object({
  password_old: yup
    .string()
    .ensure()
    .required('Old Password is required')
    .min(8, 'Password must be at least 8 characters long'),

  password: yup
    .string()
    .ensure()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  password_confirmation: yup
    .string()
    .ensure()
    .required('Password Confirmation is required')
    .min(8, 'Password must be at least 8 characters long'),
});

const ProfileComp = () => {
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] =
    React.useState(false);

  const [verification, setVerification] = React.useState('');
  const [verificationRequested, SetVerificationRequested] =
    React.useState(false);

  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
  }>();

  const form = useForm<UserProfileChangePassword>({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const updateUserProfileChangePasswordMutation =
    UserHooks.useUpdateUserProfileChangePasswordMutation();

  const onSubmit: SubmitHandler<UserProfileChangePassword> = data => {
    updateUserProfileChangePasswordMutation.mutate(data);
  };

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Certificates' },
              { href: '#', text: 'Profile' },
              { href: '#', text: 'Change Password' },
            ]}
          />

          <div>
            {urlParams.editMode ? (
              <Button
                variant="outlined"
                className="w-28"
                onClick={() => setUrlParams({ editMode: false })}
                size="medium"
                startIcon={<Close />}
                text="Cancel"
              />
            ) : (
              <Button
                className="w-28"
                onClick={() => setUrlParams({ editMode: true })}
                size="medium"
                startIcon={<Edit />}
                text="Edit"
              />
            )}
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              Change Password
            </h3>
          </div>

          <div className="grid grid-cols-3 gap-x-2 gap-y-1 mt-2">
            {(
              [
                {
                  displayText: 'Old Password',
                  key: 'password_old',
                  disabled: false,
                },
                {
                  displayText: 'New Password',
                  key: 'password',
                  disabled: false,
                },
                {
                  displayText: 'Conferm Password',
                  key: 'password_confirmation',
                  disabled: false,
                },
              ] as const
            ).map(el => (
              <Controller
                key={el.key}
                name={el.key}
                control={form.control}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <div key={el.key} className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        <Input
                          disabled={el?.disabled || !urlParams?.editMode}
                          className="flex-grow"
                          type="password"
                          innerRef={ref}
                          {...rest}
                        />
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}
          </div>
        </div>
      </div>

      <FooterButtonGroup
        className="my-10"
        editMode={urlParams.editMode || false}
        canSave={urlParams.editMode}
        onSave={form.handleSubmit(onSubmit)}
      />
    </Layout>
  );
};

export default ProfileComp;
