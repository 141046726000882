/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
import React from 'react';
import { FieldPath } from 'react-hook-form';
import { Cell, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';

import FilterIcon from '@assets/images/icons/filter-cone.svg';

import { map as RouteMap } from '@routes';

import {
  Layout,
  Breadcrumbs,
  Searchbox,
  Button,
  Table,
} from '@components/Common';

import AttributeFiltersModal, {
  FilterAttributesValues,
} from '@components/Configurations/security/activityLog/AttributeFiltersModal';

import ActivityLogHooks from '@hooks/api/activity';

import { GetActivityLogParams } from '@hooks/api/activity/types';

import { usePrevious, useDebounce } from '@hooks/utils';
import { Link } from '@material-ui/core';
import { activityLog } from '@app/hooks/api/activity/types';

import { removeFalsyKeyFromObject, formatDate } from '@utils';

const formatFilterAttributes = (
  filterValues: FilterAttributesValues,
): GetActivityLogParams => {
  const dateFields = ['from', 'to'];

  const formattedValues = {
    action: filterValues?.action?.code,
    entity: filterValues?.entity?.code,
    from: filterValues?.expiry_date?.from,
    to: filterValues?.expiry_date?.to,
  };

  const truthyValues = removeFalsyKeyFromObject(formattedValues);

  const reFormattedValues = Object.entries(truthyValues).map(([key, value]) => {
    if (dateFields.includes(key)) {
      return [key, formatDate(value as string)];
    }

    return [key, value];
  });

  return Object.fromEntries(reFormattedValues);
};

const ActivityLog = () => {
  const history = useHistory();

  const [isAttributeFiltersModalOpen, setIsAttributeFiltersModalOpen] =
    React.useState(false);
  const [openTableActionMenuId, setOpenTableActionMenuId] = React.useState('');

  const [userFilters, setUserFilters] =
    React.useState<FilterAttributesValues | null>(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = useDebounce(searchValue);

  const prevSearchValue = usePrevious(searchValue);

  React.useEffect(() => {
    // when user starts to type in search bar pagination gets reset
    if (
      prevSearchValue === '' &&
      searchValue !== '' &&
      searchValue !== prevSearchValue
    ) {
      setCurrentPage(1);
    }
  }, [searchValue, prevSearchValue]);

  const { data: usersList, ...useUsersListUtils } =
    ActivityLogHooks.useActivityLogList({
      page: currentPage,
      q: debouncedSearch,
      ...(userFilters ? formatFilterAttributes(userFilters) : {}),
    });

  const columns = React.useMemo(
    (): Array<{
      Header: string;
      accessor?: FieldPath<activityLog> | 'action';
      id: FieldPath<activityLog> | 'action';
      width?: number;
      Cell?: undefined | ((cell: Cell<activityLog>) => JSX.Element);
    }> => [
      {
        Header: 'Detail',
        accessor: 'detail',
        id: 'detail',
        width: 580,
        Cell: cell => (
          <div
            className={`h-full flex items-center p-4 rounded ${
              cell.row.original.login_as ? 'bg-red-200' : ''
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: cell.value }} />
            {cell.row.original.login_as && (
              <small className="ml-1">({cell.row.original.login_as})</small>
            )}
          </div>
        ),
      },
      {
        Header: 'Date and Time',
        accessor: 'created_at',
        id: 'created_at',
        width: 220,
      },
      {
        Header: 'User',
        accessor: 'user',
        id: 'user',
        width: 220,
      },
      {
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        width: 120,
      },
      {
        Header: 'Entity',
        accessor: 'entity',
        id: 'entity',
        width: 120,
      },
    ],
    [openTableActionMenuId, useUsersListUtils.dataUpdatedAt],
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <AttributeFiltersModal
        values={userFilters}
        open={isAttributeFiltersModalOpen}
        setOpen={setIsAttributeFiltersModalOpen}
        onFormSubmit={filterValues => {
          setUserFilters(filterValues);

          setIsAttributeFiltersModalOpen(false);
        }}
      />

      <div>
        <Breadcrumbs
          onClick={() => {}}
          items={[
            { href: '#', text: 'Configurations' },
            { href: '#', text: 'Activity Log' },
          ]}
        />

        <div className="flex flex-row items-center mt-6">
          <Searchbox
            value={searchValue}
            onSearch={value => setSearchValue(value)}
            onChange={e => setSearchValue(e.target.value)}
            className="mr-4 w-120"
          />

          <Button
            className="mr-3 text-white-600"
            onClick={() => setIsAttributeFiltersModalOpen(true)}
            startIcon={<img src={FilterIcon} alt="filter" />}
            text="Attribute Filters"
          />

          {userFilters ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setUserFilters(null);
              }}
              text="Clear Filters"
            />
          ) : null}
        </div>

        <Table
          loading={useUsersListUtils.isLoading}
          className="mt-2"
          bodyRowClassName="cursor-pointer rounded bg-white border-t-4 border-b-4 border-white-600"
          headerCellClassName="text-primary-light font-normal font-roboto"
          bodyCellClassName="h-10 border-0 text-gray font-poppins text-xs2"
          columns={columns}
          data={usersList?.data || []}
          paginationProps={{
            page: currentPage,
            onPageChange: setCurrentPage,
            pageCount: usersList?.meta?.last_page,
          }}
        />
      </div>

      {/* <FooterButtonGroup className="mt-2 mb-10" /> */}
    </Layout>
  );
};

export default ActivityLog;
