/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-object-spread */
/* eslint-disable consistent-return */
import React from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useForm, Controller, FieldPath } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { deepClone } from '@utils';
import { useHistory } from 'react-router-dom';
import { map } from '@routes';
import { toast } from 'react-toastify';

import { useUser } from '@contexts';

import { useUrlParams } from '@hooks';

import {
  FormLayout as Layout,
  FormButtonGroup,
} from '@components/Bussines/Products/ProductForm';

import CauseChangeModal from '@components/Bussines/Products/ProductForm/Ingredients/CauseChangeModal';

import { function_of_additivesList } from '@components/Bussines/Products/ProductForm/Ingredients/utils';
import { CauseChangeModalValues } from '@components/Bussines/Products/ProductForm/Ingredients/types';

import { TextEditor } from '@components/Common';

import WizardHooks from '@hooks/api/wizard';
import {
  Ingredients,
  ingredients_materials,
  CustomDeclaration,
} from '@hooks/api/wizard/types';

import Layer from './Layer';

interface InitialValuesType {
  auto_declaration: string;
  custom_declaration: string;
  cause_of_change: string;
}

const useStyles = makeStyles((theme: any) => ({
  searchbox: {
    marginRight: theme.spacing(2),
    width: theme.spacing(63),
  },
}));

type P = {
  share?: boolean;
};
const Products: React.FC<P> = ({ share }) => {
  const [errorForm, setErrorForm] = React.useState('valid');
  const [errorCauseChange, setErrorCauseChange] = React.useState(false);

  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const validationSchema = yup.object({
    materials: yup.array().of(
      yup.object().shape({
        product_grams: yup
          .mixed()
          .test('product_grams', 'error', (val, contxt) => {
            if (contxt.parent.name === '' || contxt.parent.name === null) {
              return true;
            }
            if (val === '' || val === null) {
              return false;
            }
            return true;
          }),
        product_percentage: yup
          .mixed()
          .test('product_percentage', 'error', (val, contxt) => {
            if (contxt.parent.name === '' || contxt.parent.name === null) {
              return true;
            }
            if (val === '' || val === null) {
              return false;
            }
            return true;
          }),
        tree_data: yup.array().of(
          yup.object().shape({
            ingredient_name: yup
              .mixed()
              .test('product_percentage', 'error', val => {
                if (val === '' || val === null) {
                  return false;
                }
                if (val?.name === '' || val?.name === null) {
                  return false;
                }
                return true;
              }),
            // qty_in_mixing_bowl: yup
            //   .string()
            //   .required('qty_in_mixing_bowl is required'),
            qty_in_final_product: yup
              .string()
              .required('qty_in_final_product is required'),
            gmo_status: yup.mixed().test('product_percentage', 'error', val => {
              if (val === '' || val === null) {
                return false;
              }
              if (val?.name === '' || val?.name === null) {
                return false;
              }
              return true;
            }),

            country_of_origin: yup
              .mixed()
              .test('product_percentage', 'error', val => {
                if (val === '' || val === null) {
                  return false;
                }
                if (val?.name === '' || val?.name === null) {
                  return false;
                }
                return true;
              }),
          }),
        ),
      }),
    ),
    // cause_of_change: yup
    //   .mixed()
    //   .test('cause_of_change', 'error', (val, contxt) => {
    //     if (
    //       contxt.parent.custom_declaration === '' ||
    //       contxt.parent.custom_declaration === null
    //     ) {
    //       return true;
    //     }
    //     if (val !== '' || val !== null) {
    //       return true;
    //     }
    //     return false;
    //   }),
  });

  const form = useForm<Ingredients>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const [user] = useUser();

  const history = useHistory();

  const [isCauseChangeModalOpen, setIsCauseChangeModalOpen] =
    React.useState(false);

  const declarationForm = useForm<CustomDeclaration[]>();

  const watchMaterials = form.watch('materials');

  const { ...updateIngredients } = WizardHooks.useUpdateIngredientsMutation();
  const { ...validateIngredients } =
    WizardHooks.useValidateIngredientsMutation();

  const { data: ENumber } = WizardHooks.useENumbers();

  const ingredientNameList = ENumber?.data.map(el => ({
    ...el,
    code: el.id,
    name: el.additives,
  }));

  const { ...ingredientsData } = WizardHooks.useProductIngredients(
    {
      code: urlParams.code,
      version: urlParams.version,
    },
    {
      enabled: !!urlParams.code && !!urlParams.version,
      onSuccess: data => {
        form.reset(data.data);
      },
      onError: error => {},
    },
  );

  const pluralFunction = (word: string): string => {
    const plural: { [key: string]: string } = {
      '(quiz)$': '$1zes',
      '^(ox)$': '$1en',
      '([m|l])ouse$': '$1ice',
      '(matr|vert|ind)ix|ex$': '$1ices',
      '(x|ch|ss|sh)$': '$1es',
      '([^aeiouy]|qu)y$': '$1ies',
      '(hive)$': '$1s',
      '(?:([^f])fe|([lr])f)$': '$1$2ves',
      '(shea|lea|loa|thie)f$': '$1ves',
      sis$: 'ses',
      '([ti])um$': '$1a',
      '(tomat|potat|ech|her|vet)o$': '$1oes',
      '(bu)s$': '$1ses',
      '(alias)$': '$1es',
      '(octop)us$': '$1i',
      '(ax|test)is$': '$1es',
      '(us)$': '$1es',
      '([^s]+)$': '$1s',
    };
    const irregular: { [key: string]: string } = {
      move: 'moves',
      foot: 'feet',
      goose: 'geese',
      sex: 'sexes',
      child: 'children',
      man: 'men',
      tooth: 'teeth',
      person: 'people',
    };
    const uncountable: string[] = [
      'sheep',
      'fish',
      'deer',
      'moose',
      'series',
      'species',
      'money',
      'rice',
      'information',
      'equipment',
      'bison',
      'cod',
      'offspring',
      'pike',
      'salmon',
      'shrimp',
      'swine',
      'trout',
      'aircraft',
      'hovercraft',
      'spacecraft',
      'sugar',
      'tuna',
      'you',
      'wood',
    ];
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
      return word;
    }
    // check for irregular forms
    for (const w in irregular) {
      const pattern = new RegExp(`${w}$`, 'i');
      const replace = irregular[w];
      if (pattern.test(word)) {
        return word.replace(pattern, replace);
      }
    }
    // check for matches using regular expressions
    for (const reg in plural) {
      const pattern = new RegExp(reg, 'i');
      if (pattern.test(word)) {
        return word.replace(pattern, plural[reg]);
      }
    }
    return word;
  };

  const autoFill = () => {
    form.setValue('custom_declaration', form.getValues('auto_declaration'));
  };

  const CauseChangeHandel = () => {
    const newData = form.getValues('custom_declaration');
    if (ingredientsData?.data?.data.custom_declaration !== newData) {
      if (form.watch('cause_of_change')) {
        setErrorCauseChange(false);
        return false;
      }
      setErrorCauseChange(true);
      return true;
    }
    setErrorCauseChange(false);
    return false;
  };

  //  form onSave
  const onSave = async (link: string, data: Ingredients) => {
    if (CauseChangeHandel()) return;

    updateIngredients.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.setValue('cause_of_change', '');
          form.reset(undefined, {
            keepDirty: false,
          });
          history.push(link);
        },
      },
    );
  };

  //  form onValidate
  const onValidate = async (data: Ingredients) => {
    const isFormValid = await form.trigger();

    if (CauseChangeHandel()) return;
    if (!isFormValid) {
      setErrorForm('inValid');
      toast.error('Form is not valid');

      return;
    }
    if (errorCauseChange) return;
    validateIngredients.mutate(
      {
        code: urlParams.code,
        version: urlParams.version,
        ...data,
      },
      {
        onSuccess: () => {
          form.setValue('cause_of_change', '');
        },
      },
    );
  };

  React.useEffect(() => {
    const test = form.watch('materials');
    let newData: any = [];
    if (test !== undefined) {
      // newData = [...test];
      // const newData: any = Object.assign([], test);
      newData = JSON.parse(JSON.stringify(test));
    }

    if (newData.length > 0) {
      newData?.forEach((table: any, tableIndex: any) => {
        table.tree_data.forEach((row: any, rowIndex: any) => {
          newData[tableIndex].tree_data[rowIndex].qty_in_final_product = Number(
            row.qty_in_final_product,
          );
          if (row.declared !== true) {
            newData[tableIndex].tree_data.splice(rowIndex, 1);
          }
        });
      });

      newData?.forEach((table: any, tableIndex: any) => {
        table.tree_data.sort((a: any, b: any) => {
          return b.qty_in_final_product - a.qty_in_final_product;
        });
      });

      const isFirstFunction = (arr: any, rowIndex: number) =>
        arr[rowIndex].function_of_additives?.code !==
          arr[rowIndex - 1]?.function_of_additives?.code || rowIndex === 0;

      const isNextFunction = (arr: any, rowIndex: number) => {
        return (
          arr[rowIndex].function_of_additives?.code !==
          arr[rowIndex + 1]?.function_of_additives?.code
        );
      };

      const isLastFunction = (arr: any, rowIndex: number) =>
        arr[rowIndex].function_of_additives?.code !==
        arr[rowIndex + 2]?.function_of_additives?.code;

      const isNextName = (arr: any, rowIndex: number) => {
        // console.log('🚀 ~ file: index.tsx ~ line 182 ~ isNextName ~ arr', arr);
        // console.log(
        //   '🚀 ~ file: index.tsx ~ line 188 ~ isNextName ~ newData',
        //   newData,
        // );
        // console.log(
        //   '🚀 ~ file: index.tsx ~ line 192 ~ isNextName ~ newData[tableIndex].tree_data[rowIndex]',
        //   arr[rowIndex +].ingredient_name.name,
        // );
        // console.log(
        //   '🚀 ~ file: index.tsx ~ line 191 ~ isNextName ~ newData[tableIndex].tree_data[rowIndex + 1]',
        //   newData[tableIndex].tree_data[rowIndex + 1],
        // );
        // return undefined;
        return arr[rowIndex + 1] !== undefined;
      };

      const checkAllChild = (arr: any) => {
        let num = 0;
        arr.forEach((children: any) => {
          if (children?.children?.length <= 0) {
            num = 1;
          } else {
            children?.children?.forEach((children2: any) => {
              if (children2?.children?.length <= 0) {
                num = 2;
              }
            });
          }
        });
        return num;
      };

      let auto_declaration = '<p>';

      const warning_note: string[] = [];
      if (newData !== undefined && ingredientNameList !== undefined) {
        newData.forEach((table: any, tableIndex: any) => {
          if (newData.length > 1) {
            if (table.name) {
              auto_declaration += `${table.name.toUpperCase()}`;
            }
            if (table.product_percentage) {
              auto_declaration += `(${table.product_percentage}%)`;
            }
          }
          auto_declaration += `<br />`;
          // let clidrenLevel = 0;
          const generate = (row: any, rowIndex: number, parent: any) => {
            // check declared is checked
            if (row.declared === true) {
              // check declared is checked
              if (row.function_of_additives?.name) {
                if (isFirstFunction(parent, rowIndex)) {
                  // console.log(
                  //   '🚀 ~ file: index.tsx ~ line 227 ~ generate ~ isLastFunction(parent, rowIndex)',
                  //   row.ingredient_name.name,
                  //   !isLastFunction(parent, rowIndex),
                  // );
                  // console.log(
                  //   '🚀 ~ file: index.tsx ~ line 227 ~ generate ~ isNextFunction(parent, rowIndex)',
                  //   row.ingredient_name.name,
                  //   !isNextFunction(parent, rowIndex),
                  // );
                  if (
                    // !isLastFunction(parent, rowIndex) &&
                    !isNextFunction(parent, rowIndex)
                  ) {
                    auto_declaration += `${pluralFunction(
                      row.function_of_additives?.name,
                    )} (`;
                  } else {
                    auto_declaration += `${row.function_of_additives?.name} (`;
                  }
                }
              }

              // handeling note
              // console.log(
              //   '🚀 ~ file: index.tsx ~ line 246 ~ generate ~ row.e_number.code',
              //   row.e_number.code,
              // );
              // console.log(
              //   '🚀 ~ file: index.tsx ~ line 246 ~ generate ~ row.e_number.code',
              //   row.e_number.name,
              // );
              const warning_noteText =
                ingredientNameList[Number(row.e_number?.code) - 1]
                  ?.warning_note;
              if (warning_noteText) {
                if (!warning_note.includes(warning_noteText)) {
                  warning_note.push(warning_noteText);
                }
                // console.log(
                //   '🚀 ~ file: index.tsx ~ line 251 ~ generate ~ warning_note',
                //   warning_note,
                // );
                const num = warning_note.findIndex(element => {
                  return element === warning_noteText;
                });
                // console.log(
                //   '🚀 ~ file: index.tsx ~ line 253 ~ generate ~ num',
                //   num,
                // );
                for (let i = 0; i < num + 1; i += 1) {
                  auto_declaration += `*`;
                }
              }
              // handeling allergen
              // console.log(
              //   '🚀 ~ file: index.tsx ~ line 507 ~ generate ~ row.ingredient_name',
              //   row,
              // );
              if (row.allergen.allergen_id === null) {
                if (row.ingredient_name?.name !== null) {
                  auto_declaration += `${row.ingredient_name?.name}`;
                }
              } else {
                auto_declaration += `${row.allergen.render}`;
              }
              // handeling quid
              if (row.quid === true) {
                if (row.percentage_in_final_product) {
                  auto_declaration += `(${Number(
                    row.percentage_in_final_product,
                  ).toFixed(1)}%) `;
                } else if (row.percentage_in_final_product_manual) {
                  auto_declaration += `(${Number(
                    row.percentage_in_final_product_manual,
                  ).toFixed(1)}%) `;
                }
              }
              if (row.children?.length > 0) {
                // clidrenLevel += 1;
                // if (clidrenLevel === 1) {
                //   auto_declaration += ` (`;
                // } else {
                //   auto_declaration += ` [`;
                // }

                if (checkAllChild(row.children) === 1) {
                  auto_declaration += ` (`;
                } else {
                  auto_declaration += ` [`;
                }

                row.children?.forEach(
                  (children: any, childrenIndex: number) => {
                    generate(children, childrenIndex, row.children);
                  },
                );

                if (checkAllChild(row.children) === 1) {
                  auto_declaration += `)`;
                } else {
                  auto_declaration += `]`;
                }

                // if (clidrenLevel === 1) {
                //   auto_declaration += `)`;
                // } else {
                //   auto_declaration += `]`;
                // }
                // clidrenLevel -= 1;
              }

              if (row.function_of_additives?.name) {
                if (isNextFunction(parent, rowIndex)) auto_declaration += `)`;
              }

              if (isNextName(parent, rowIndex)) auto_declaration += ', ';
            }
          };

          table.tree_data.forEach((row: any, rowIndex: number) => {
            generate(row, rowIndex, table.tree_data);
          });
          auto_declaration += `</p>`;
        });
        warning_note.forEach((n, warning_noteIndex) => {
          for (let i = 0; i < warning_noteIndex + 1; i += 1) {
            auto_declaration += `*`;
          }
          auto_declaration += `${n} <br />`;
        });
        form.setValue('auto_declaration', auto_declaration);
      }
    }
  }, [watchMaterials]);

  return (
    <Layout shouldShowTransitionPrompt={form.formState.isDirty} share={share}>
      <form
        className={errorForm}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        {form.getValues().materials ? (
          <Controller
            name="materials"
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Layer
                error={fieldState}
                value={value}
                onChange={(val: Array<ingredients_materials>) => onChange(val)}
              />
            )}
          />
        ) : (
          <> loading </>
        )}

        <div className="">
          <h3 className="col-span-4 text-base text-primary mb-2 mt-7">
            Decleration
          </h3>
          <Controller
            name="auto_declaration"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                header="Auto Decleration"
                readOnly
                height={23}
                value={value}
                onChange={val => onChange(val)}
              />
            )}
          />
        </div>
        <div className="mt-4">
          <Controller
            name="custom_declaration"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                header="Custom Decleration"
                height={23}
                readOnly={!urlParams.editMode}
                whiteWrapper
                toolbar={[
                  'autofill',
                  // 'save',
                  'bold',
                  'italic',
                  'underline',
                  // 'clear',
                ]}
                value={value}
                // onSave={() => setIsCauseChangeModalOpen(true)}
                onUndo={() => autoFill()}
                onChange={val => onChange(val)}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 mt-4 gap-x-1">
          <div className="col-span-1">
            <Controller
              name="cause_of_change"
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextEditor
                  error={errorCauseChange}
                  whiteWrapper
                  header="Cause of change"
                  height={23}
                  value={value}
                  readOnly={
                    form.getValues('custom_declaration') !==
                      ingredientsData?.data?.data.custom_declaration &&
                    !urlParams.editMode
                  }
                  onChange={val => {
                    setErrorCauseChange(false);
                    onChange(val);
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-1">
            <Controller
              name="custom_declarations"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <TextEditor
                  header="History"
                  height={23}
                  readOnly
                  value={value}
                  onChange={val => onChange(val)}
                />
              )}
            />
          </div>
        </div>
      </form>

      <FormButtonGroup
        share={share}
        pageName="ingredients"
        onValidate={() => onValidate(form.getValues())}
        onSave={link => onSave(link, form.getValues())}
      />
    </Layout>
  );
};

export default Products;
