import React from 'react';

import { Dialog } from '@components/Common';

type P = {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: () => void;
  title?: string;
  contentText?: string;
};

const DeletePromptModal: React.FC<P> = ({
  open,
  setOpen,
  onConfirm,
  title,
  contentText = `Please note that deleting records will remove all linked objects as well.
   Are you sure you would like to delete this record?`,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onReject={() => setOpen(false)}
      onConfirm={onConfirm}
      title={title}
      contentText={contentText}
    />
  );
};

export default DeletePromptModal;
