import React from 'react';
import {
  Table as MiuTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTable, Column, useExpanded, Cell } from 'react-table';

interface Data {
  name: string;
  age: number;
  counter?: string;
}

interface TableProps {
  columns: Array<Column<Data>>;
  data: Array<Data>;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: theme.spacing(18),
  },
}));

const Table: React.FC<TableProps> = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
    toggleAllRowsExpanded,
  } = useTable<Data>(
    {
      columns,
      data,
    },
    useExpanded,
  );

  return (
    <>
      <MiuTable style={{ tableLayout: 'auto' }} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell variant="head" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell
                      style={{ width: cell.column.width }}
                      variant="body"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MiuTable>

      <button type="button" onClick={() => toggleAllRowsExpanded(true)}>
        EXPAND ALL
      </button>

      <br />
      <div>Showing the first 20 results of {rows.length} rows</div>

      <pre>
        <code>{JSON.stringify({ expanded }, null, 2)}</code>
      </pre>
    </>
  );
};

export default Table;

// const MyTable: React.FC<TableProps> = ({ columns, data }) => {
// const columns: Column<Data>[] = [
//   {
//     Header: '#',
//     id: 'counter',
//     width: 30,
//     Cell: ({ row }: Cell) => (
//       <span {...row.getToggleRowExpandedProps()}>{row.index + 1}</span>
//     ),
//   },
//   {
//     Header: 'Name',
//     accessor: 'name',
//   },
//   {
//     Header: 'Age',
//     accessor: 'age',
//   },
// ];

//   const data: Data[] = [
//     {
//       name: 'John',
//       age: 23,
//     },
//     {
//       name: 'John',
//       age: 23,
//     },
//     {
//       name: 'John',
//       age: 23,
//     },
//   ];

//   const classes = useStyles();

//   return <Table columns={columns} data={data} />;
// };
