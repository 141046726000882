import React from 'react';
import clsx from 'clsx';
import { useTable, useExpanded, TableOptions } from 'react-table';
import { Tooltip, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';

import { TableLoading } from '@components/Common';

// numbers are their their px value divided by 8
const FIRST_CELL_INSET = 16;
const EXPANDED_ROW_FIRST_CELL_INSET = 16;
const TABLE_CELL_MIN_HEIGHT = 40;
const TABLE_ROW_VERTICAL_MARGIN = 8;

const useStyles = makeStyles((theme: any) => ({
  row: {
    marginTop: TABLE_ROW_VERTICAL_MARGIN,
    marginBottom: TABLE_ROW_VERTICAL_MARGIN,
  },
  expandedRow: {
    position: 'relative',

    '&::before': {
      position: 'absolute',
      content: '""',
      height: TABLE_CELL_MIN_HEIGHT + TABLE_ROW_VERTICAL_MARGIN + 8,
      width: 1,

      backgroundColor: theme.palette.secondary.dark,

      left: EXPANDED_ROW_FIRST_CELL_INSET / 2,
      top: -(TABLE_ROW_VERTICAL_MARGIN * 3) - 12,
      transform: 'translate(-50%, 0%)',

      zIndex: 5,
    },

    '&::after': {
      position: 'absolute',
      content: '""',
      height: 1 / 2,
      width: EXPANDED_ROW_FIRST_CELL_INSET / 2,

      backgroundColor: theme.palette.secondary.dark,

      left: EXPANDED_ROW_FIRST_CELL_INSET / 2,
      top: TABLE_CELL_MIN_HEIGHT / 2,
      transform: 'translate(0%, -50%)',

      zIndex: 4,
    },
  },
  firstCellInset: {
    paddingLeft: FIRST_CELL_INSET,
  },
  expandedRowFirstCellInset: {
    marginLeft: EXPANDED_ROW_FIRST_CELL_INSET,
  },
  tableCell: {
    minHeight: TABLE_CELL_MIN_HEIGHT,
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.common.white,

    zIndex: 6,
  },
}));

const Table: React.FC<TableOptions<any>> = ({
  columns,
  data,
  getSubRows,
  loading,
}) => {
  const classes = useStyles();

  const TABLE = useTable(
    {
      columns,
      data,
      getSubRows,
    },
    useExpanded,
  );

  if (loading) {
    return <TableLoading />;
  }

  return (
    <>
      <div className="grid grid-cols-11 rounded bg-white-400 items-center py-2">
        {TABLE.headers.map((column, i) => (
          <div
            className={clsx(
              'text-primary font-roboto px-2',
              'flex items-center min-w-min',
              i === 0 && classes.firstCellInset,
              (column as any).className,
            )}
            key={column.id}
          >
            {column.render('Header')}

            {(column as any).tooltip ? (
              <Tooltip title={(column as any).tooltip}>
                <IconButton disableRipple className="p-1 h-6">
                  <Help className="text-sm" />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        ))}
      </div>

      <div>
        {TABLE.rows.map(row => {
          TABLE.prepareRow(row);

          if (
            [
              'may_contain',
              'contains',
              'on_same_production_line',
              'on_same_manufacturing_area',
            ].find(key => !!row.values[key]) &&
            !row.isExpanded &&
            row.canExpand
          ) {
            row.toggleRowExpanded(true);
          }

          return (
            <div
              className={clsx(
                'rounded',
                classes.row,
                row.depth > 0 ? classes.expandedRow : '',
              )}
              {...row.getRowProps()}
            >
              <div className="grid grid-cols-11">
                {row.cells.map((cell, cellIndex: number) => (
                  <div
                    className={clsx(
                      'flex items-center font-roboto h-full',
                      (cell.column as any).className,
                      classes.tableCell,
                      cellIndex === 0 && classes.firstCellInset,
                      cellIndex !== 0 && 'px-2',
                      cellIndex === 0 && row.depth > 0
                        ? classes.expandedRowFirstCellInset
                        : '',
                    )}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Table;
