/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useForm, Controller, UnpackNestedValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Edit, Close } from '@material-ui/icons';

import {
  Layout,
  Breadcrumbs,
  Button,
  Input,
  FooterButtonGroup,
  AutoComplete,
  CountryAutoComplete,
  DeletePromptModal,
  RejectModal,
} from '@components/Common';

import { useUrlParams, stringify } from '@hooks/utils';

import { phoneRegex, isPendigForApproval } from '@utils';
import RouteMap from '@routes/map';

import { Country } from '@hooks/api/generalTypes';

import CertificateHooks from '@hooks/api/certificates';

import SupplierHooks from '@hooks/api/suppliers';

import ManufacturerHooks from '@hooks/api/manufacturers';
import { DetailedManufacturer } from '@hooks/api/manufacturers/types';

import { useUser } from '@contexts';

import CertificateTable from '@components/Suppliers/Manufacturers/ManufacturerInfo/CertificateTable';

type ManufacturerInfoFields = Omit<
  DetailedManufacturer,
  | 'manufacture_code'
  | 'customer_name'
  | 'customer_code'
  | 'supplier_name'
  | 'supplier_code'
> & {
  supplier: null | {
    name: DetailedManufacturer['supplier_name'];
    code: DetailedManufacturer['supplier_code'];
  };
};

type FieldRefType = {
  displayText: string;
  key: keyof ManufacturerInfoFields;
  gridSize: string;
  disabled?: boolean;
  type?: 'input' | 'autocomplete';
  inputType?: string;
  required?: boolean;
};

const validationSchema = yup.object({
  supplier: yup
    .mixed()
    .required('Supplier is required')
    .test('supplier', 'Supplier is required', value => !!value),
  site_name: yup.string().ensure().required('Site name is required'),
  country: yup
    .mixed()
    .required('Country is required')
    .test('country', 'Country is required', val => !!val),
  city: yup.string().ensure().required('City is required'),
  site_address: yup.string().ensure().required('Site Address is required'),
  emergency_contact_name: yup
    .string()
    .ensure()
    .required('Emergency contact name is required'),
  emergency_contact_number: yup
    .string()
    .ensure()
    .required('Emergency contact phone is required')
    .matches(phoneRegex, 'Phone number is not valid'),
  emergency_contact_email: yup
    .string()
    .ensure()
    .required('Emergency contact email is required')
    .email('Emergency contact email is not valid'),
});

const initialValues: ManufacturerInfoFields = {
  supplier: null,
  site_name: '',
  cardbox_status: null,
  approved_by: '',
  approval_date: '',
  country: null,
  city: '',
  site_address: '',
  emergency_contact_name: '',
  emergency_contact_email: '',
  emergency_contact_number: '',
  health_mark: '',
};

const ManufacturerInfoComp = () => {
  const [urlParams, , setUrlParams] = useUrlParams<{
    editMode?: boolean;
    supplier_name?: string;
    supplier_code?: string;
  }>();

  const [user] = useUser();

  const routeParams = useRouteMatch<{ manufacturerCode?: string }>();
  const history = useHistory();

  const productDetailsFields: Readonly<Array<FieldRefType>> = React.useMemo(
    () => [
      {
        displayText: 'Site Name',
        key: 'site_name',
        gridSize: 'col-span-3',
        required: true,
      },
      {
        displayText: 'Status',
        key: 'cardbox_status',
        disabled: true,
        gridSize: 'col-span-2',
      },
      {
        displayText: 'Approved By',
        key: 'approved_by',
        disabled: true,
        gridSize: 'col-span-2',
      },
      {
        displayText: 'Approval Date',
        key: 'approval_date',
        disabled: true,
        gridSize: 'col-span-2',
      },
      {
        displayText: 'Country',
        key: 'country',
        gridSize: 'col-span-3',
        type: 'autocomplete',
        required: true,
      },
      {
        displayText: 'City',
        key: 'city',
        gridSize: 'col-span-3',
        required: true,
      },
      {
        displayText: 'Site Address',
        key: 'site_address',
        gridSize: 'col-span-6',
        required: true,
      },
      {
        displayText: 'Emergency contact Name',
        key: 'emergency_contact_name',
        gridSize: 'col-span-3',
        required: true,
      },
      {
        displayText: 'Emergency contact Email',
        key: 'emergency_contact_email',
        gridSize: 'col-span-3',
        required: true,
      },
      {
        displayText: 'Emergency contact Phone',
        key: 'emergency_contact_number',
        gridSize: 'col-span-3',
        inputType: 'number',
        required: true,
      },
      {
        displayText: 'Health Mark/EU Approval num',
        key: 'health_mark',
        gridSize: 'col-span-3',
      },
    ],
    [routeParams.params?.manufacturerCode],
  );

  const [isDeleteManufacturerModalOpen, setIsDeleteManufacturerModalOpen] =
    React.useState(false);

  const form = useForm<ManufacturerInfoFields>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [rejectModalOpen, setRejectModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (urlParams?.supplier_name && urlParams?.supplier_code) {
      form.reset({
        ...initialValues,

        supplier: {
          name: urlParams.supplier_name,
          code: urlParams.supplier_code,
        },
      });
    }
  }, [urlParams?.supplier_name, urlParams?.supplier_code]);

  const { data: suppliersList, ...useSuppliersUtils } =
    SupplierHooks.useSuppliers(
      {
        paginated: false,
      },
      {
        enabled: !routeParams?.params?.manufacturerCode,
      },
    );

  const { data: detailedManufacturerData, ...useDetailedManufacturerUtils } =
    ManufacturerHooks.useDetailedManufacturer(
      {
        manufactureCode: routeParams.params.manufacturerCode || '',
      },
      {
        enabled: !!routeParams?.params?.manufacturerCode,
        onSuccess: data =>
          form.reset({
            ...data.data,
            supplier: {
              name: data.data.supplier_name,
              code: data.data.supplier_code,
            },
          }),
      },
    );

  const {
    data: supplierAsManufacturerData,
    ...useSupplierAsManufacturerSupplierUtils
  } = ManufacturerHooks.useSupplierAsManufacturer(
    {
      supplierCode:
        form.watch('supplier')?.code ||
        detailedManufacturerData?.data.supplier_code ||
        '',
    },
    {
      enabled: false,
      onSuccess: data =>
        form.reset({
          ...data.data,
          supplier: {
            name: data.data.supplier_name,
            code: data.data.supplier_code,
          },
        }),
    },
  );

  const updateManufacturerMutation =
    ManufacturerHooks.useUpdateManufacturerMutation();
  const saveManufacturerMutation =
    ManufacturerHooks.useSaveManufacturerMutation();
  const submitManufacturerMutation =
    ManufacturerHooks.useSubmitManufacturerMutation();
  const approveOrRejectManufacturerMutation =
    ManufacturerHooks.useApproveOrRejectManufacturerMutation();
  const deleteManufacturerMutation =
    ManufacturerHooks.useDeleteManufacturerMutation();

  const formatSupplierData = (
    data: UnpackNestedValue<ManufacturerInfoFields>,
  ) => {
    const { country, supplier, ...goodData } = data;

    return {
      ...goodData,
      country_id: country?.id || null,
      supplier_code: supplier?.code || '',
    };
  };

  const onSave: (
    data: UnpackNestedValue<ManufacturerInfoFields>,
  ) => void = async data => {
    form.clearErrors();

    try {
      const formattedData = formatSupplierData(data);

      if (routeParams.params?.manufacturerCode) {
        await updateManufacturerMutation.mutateAsync({
          ...formattedData,
          manufacture_code: routeParams.params.manufacturerCode,
        });
      } else {
        const newManufacturer = await saveManufacturerMutation.mutateAsync(
          formattedData,
        );

        if (newManufacturer) {
          history.replace(
            `${RouteMap.suppliers.manufacturerInfo.replace(
              ':manufacturerCode?',
              newManufacturer.data.manufacture_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (error) {}
  };

  const onSubmit: (
    data: UnpackNestedValue<ManufacturerInfoFields>,
  ) => void = async data => {
    form.clearErrors();

    try {
      const isFormValid = await form.trigger();

      if (!isFormValid) return;

      const formattedData = formatSupplierData(data);

      if (routeParams.params?.manufacturerCode) {
        await submitManufacturerMutation.mutateAsync({
          ...formattedData,
          manufacture_code: routeParams.params.manufacturerCode,
        });
      } else {
        const newManufacturer = await submitManufacturerMutation.mutateAsync(
          formattedData,
        );

        if (newManufacturer) {
          history.replace(
            `${RouteMap.suppliers.manufacturerInfo.replace(
              ':manufacturerCode?',
              newManufacturer.data.manufacture_code,
            )}?editMode=true`,
          );
        }
      }
    } catch (e) {}
  };

  const isLoading =
    useDetailedManufacturerUtils.isLoading ||
    useSupplierAsManufacturerSupplierUtils.isLoading ||
    useSupplierAsManufacturerSupplierUtils.isFetching;

  const isStatusPendigForApproval = isPendigForApproval(
    detailedManufacturerData?.data.cardbox_status,
  );

  return (
    <Layout mainClassNames="min-h-screen-with-appbar flex flex-col justify-between">
      <DeletePromptModal
        open={isDeleteManufacturerModalOpen}
        setOpen={() => setIsDeleteManufacturerModalOpen(false)}
        onConfirm={async () => {
          try {
            if (routeParams.params.manufacturerCode) {
              await deleteManufacturerMutation.mutateAsync({
                manufacture_code: routeParams.params.manufacturerCode,
              });

              history.replace(RouteMap.suppliers?.manufacturersList);
            }
          } catch (error) {
          } finally {
            setIsDeleteManufacturerModalOpen(false);
          }
        }}
      />

      <RejectModal
        header="Reject Message"
        open={rejectModalOpen}
        setOpen={setRejectModalOpen}
        submit={data =>
          approveOrRejectManufacturerMutation.mutate({
            manufacture_code: routeParams.params?.manufacturerCode || '',
            status: 'reject',
            message: data.message,
          })
        }
      />

      <div>
        <div className="flex flex-row justify-between items-center">
          <Breadcrumbs
            onClick={() => {}}
            items={[
              { href: '#', text: 'Supplier' },
              { href: '#', text: 'Manufacturers' },
              { href: '#', text: 'Manufacturer Info' },
            ]}
          />
          {(user?.access_levels.manufacture_create_update ||
            user?.access_levels.manufacture_soft_delete ||
            user?.access_levels.manufacture_approve) && (
            <div>
              {/* {user?.access_levels.manufacture_soft_delete && (
                <>
                  {urlParams.editMode &&
                  routeParams.params?.manufacturerCode ? (
                    <Button
                      variant="outlined"
                      className="w-28 mr-2 text-red-dark border-red-dark"
                      onClick={() => setIsDeleteManufacturerModalOpen(true)}
                      size="medium"
                      text="Delete"
                    />
                  ) : null}
                </>
              )} */}

              {detailedManufacturerData?.data.cardbox_status === 'Archived' ? (
                <>
                  {user?.access_levels.manufacture_approve && (
                    <Button
                      variant="outlined"
                      className="w-28 mr-2 text-red-dark border-red-dark"
                      onClick={() => {
                        approveOrRejectManufacturerMutation.mutate({
                          manufacture_code:
                            routeParams.params?.manufacturerCode || '',
                          status: 'draft',
                        });
                      }}
                      size="medium"
                      text="Active"
                    />
                  )}
                </>
              ) : (
                <>
                  {routeParams.params.manufacturerCode && (
                    <Button
                      variant="outlined"
                      className="w-28 mr-2 text-red-dark border-red-dark"
                      onClick={() => {
                        approveOrRejectManufacturerMutation.mutate(
                          {
                            manufacture_code:
                              routeParams.params?.manufacturerCode || '',
                            status: 'archive',
                          },
                          {
                            onSuccess: () => {
                              setUrlParams({ editMode: false });
                            },
                          },
                        );
                      }}
                      size="medium"
                      text="Archive"
                    />
                  )}
                </>
              )}
              {urlParams.editMode ? (
                <>
                  <Button
                    variant="outlined"
                    className="w-28"
                    onClick={() => setUrlParams({ editMode: false })}
                    size="medium"
                    startIcon={<Close />}
                    text="Cancel"
                  />
                </>
              ) : (
                <Button
                  className="w-28"
                  onClick={() => setUrlParams({ editMode: true })}
                  size="medium"
                  startIcon={<Edit />}
                  text="Edit"
                />
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-base text-primary-light font-roboto">
              Manufacturer Info
            </h3>

            <Button
              disabled={
                !urlParams.editMode ||
                (!form.watch('supplier.code') &&
                  !routeParams.params?.manufacturerCode)
              }
              variant="outlined"
              className="border-1 border-primary ml-2"
              onClick={() => {
                useSupplierAsManufacturerSupplierUtils.refetch();
              }}
              text="Same as Supplier"
            />
          </div>

          <div className="grid grid-cols-6 gap-x-2 gap-y-1 mt-2">
            <Controller
              name="supplier"
              control={form.control}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState,
              }) => (
                <div className="flex flex-col col-span-3">
                  <div className="flex flex-row items-center bg-white rounded shadow-sm">
                    <div>
                      <p className="font-roboto pl-4 w-52">Supplier Name*</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <AutoComplete
                        noOptionsText="No matching supplier is found, please check the supplier record is created"
                        freeSolo={false}
                        canAddCustomItem={false}
                        loading={useSuppliersUtils.isLoading}
                        disabled={
                          !urlParams?.editMode ||
                          !!routeParams.params?.manufacturerCode ||
                          !!(urlParams.supplier_name && urlParams.supplier_code)
                        }
                        value={value}
                        items={(suppliersList?.data || []).map(el2 => ({
                          name: el2.name,
                          code: el2.supplier_code,
                        }))}
                        onChange={onChange}
                        {...rest}
                      />
                    </div>
                  </div>

                  {fieldState.error?.message ? (
                    <p className="text-red pl-2 my-1">
                      {fieldState.error?.message}
                    </p>
                  ) : null}
                </div>
              )}
            />

            {productDetailsFields.map(el => (
              <Controller
                key={el.displayText}
                name={el.key}
                control={form.control}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState,
                }) => (
                  <div
                    key={el.displayText}
                    className={clsx('flex flex-col', el.gridSize)}
                  >
                    <div className="flex flex-row items-center bg-white rounded shadow-sm">
                      <div>
                        <p className="font-roboto pl-4 w-52">
                          {el.displayText}
                          {'required' in el && el.required ? '*' : ''}
                        </p>
                      </div>

                      <div className="flex flex-col w-full">
                        {el.type === 'autocomplete' ? (
                          <CountryAutoComplete
                            disableClearable
                            loading={isLoading}
                            disabled={!urlParams?.editMode}
                            value={(value as Country) || null}
                            onChange={onChange}
                          />
                        ) : (
                          <Input
                            loading={isLoading}
                            disabled={!urlParams?.editMode || el.disabled}
                            className="flex-grow"
                            value={value || ''}
                            innerRef={ref}
                            onChange={onChange}
                            type={el.inputType || 'text'}
                            {...rest}
                          />
                        )}
                      </div>
                    </div>

                    {fieldState.error?.message ? (
                      <p className="text-red pl-2 my-1">
                        {fieldState.error?.message}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            ))}
          </div>
        </div>

        {user?.access_levels.certificate_index_single && (
          <CertificateTable
            supplier_code={detailedManufacturerData?.data.supplier_code}
            supplier_name={detailedManufacturerData?.data.supplier_name}
            manufacturer_code={detailedManufacturerData?.data.manufacture_code}
            manufacturer_name={detailedManufacturerData?.data.site_name}
          />
        )}
      </div>

      {detailedManufacturerData?.data.cardbox_status === 'Archived' ? (
        <FooterButtonGroup className="my-10" editMode={false} />
      ) : (
        <FooterButtonGroup
          className="my-10"
          isLoading={isLoading}
          editMode={urlParams.editMode || false}
          canApprove={user?.access_levels.manufacture_approve}
          canReject={user?.access_levels.manufacture_approve}
          canSave={user?.access_levels.manufacture_create_update}
          canSubmit={user?.access_levels.manufacture_submit}
          {...(urlParams.editMode
            ? isStatusPendigForApproval
              ? {
                  onApprove: () =>
                    approveOrRejectManufacturerMutation.mutate(
                      {
                        manufacture_code:
                          routeParams.params?.manufacturerCode || '',
                        status: 'approve',
                      },
                      {
                        onSuccess: () => setUrlParams({ editMode: false }),
                      },
                    ),
                  onReject: () => {
                    setRejectModalOpen(true);
                  },

                  onSave: () => onSave(form.getValues()),
                }
              : {
                  onSubmit: () => onSubmit(form.getValues()),
                  onSave: () => onSave(form.getValues()),
                }
            : {})}
        />
      )}
    </Layout>
  );
};

export default ManufacturerInfoComp;
