import React from 'react';
import clsx from 'clsx';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';

interface Props {
  text?: string;
  className?: string;
  loading?: boolean;
}

const Button: React.FC<Props & Omit<ButtonProps, keyof Props>> = ({
  text,
  className,
  children,
  loading = false,
  ...other
}) => {
  return (
    <MuiButton
      variant="contained"
      color="primary"
      size="medium"
      aria-disabled={loading}
      classes={{
        startIcon: text ? '' : 'mx-0',
        endIcon: text ? '' : 'mx-0',
      }}
      className={clsx(
        className,
        'min-w-min font-roboto font-medium',
        loading && 'pointer-events-none',
      )}
      {...(loading
        ? {
            startIcon: '',
            endIcon: <CircularProgress className="w-5 h-5 text-current" />,
          }
        : {})}
      {...other}
    >
      {loading ? 'Loading' : text || children}
    </MuiButton>
  );
};

export default Button;
