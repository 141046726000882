import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import policyApis from '@hooks/api/policies/api';
import { UsePoliciesType, GetPoliciesParams } from '@hooks/api/policies/types';

import errorHandler from '@utils/errorHandler';

const KEYS = {
  policies: ['policies'],
} as const;

const usePolicies = (
  params?: GetPoliciesParams,
  options?: UseQueryOptions<UsePoliciesType, AxiosError>,
) => {
  const query = useQuery<UsePoliciesType, AxiosError>(
    [...KEYS.policies, params],
    () => policyApis.getPolicies(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );

  return query;
};

const useAddPolicyMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(policyApis.addPolicy, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.policies);
    },
  });

  return mutation;
};

const useTrashPolicyMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(policyApis.trashPolicy, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.policies);
    },
  });

  return mutation;
};

export default {
  usePolicies,
  useAddPolicyMutation,
  useTrashPolicyMutation,
};
