import { AxiosResponse } from 'axios';

import axios from '@lib/api';

import {
  UseSuppliersParams,
  UseDetailedSupplierParams,
  UseSuppliersResponseType,
  UseDetailedSupplierResponseType,
  GetFilteredSuppliersParams,
  UseFilteredSuppliersResponseType,
  SaveSupplierParams,
  UpdateSupplierParams,
  DeleteSupplierParams,
  ApproveOrRejectSupplierParams,
  SubmitSupplierParams,
} from '@hooks/api/suppliers/types';

const getSuppliers = async (params?: UseSuppliersParams) => {
  const res: AxiosResponse<UseSuppliersResponseType> = await axios({
    method: 'get',
    url: `/suppliers`,
    params,
  });

  return res.data;
};

const getDetailedSupplier = async (params: UseDetailedSupplierParams) => {
  const res: AxiosResponse<UseDetailedSupplierResponseType> = await axios({
    method: 'get',
    url: `/suppliers/${params.code}/show`,
  });

  return res.data;
};

const getFilteredSuppliers = async (params?: GetFilteredSuppliersParams) => {
  const res: AxiosResponse<UseFilteredSuppliersResponseType> = await axios({
    method: 'get',
    url: `/suppliers/searchfilter`,
    params,
  });

  return res.data;
};

const saveSupplier = async (data: SaveSupplierParams) => {
  const res: AxiosResponse<UseDetailedSupplierResponseType> = await axios({
    method: 'post',
    url: `/suppliers`,
    data,
  });

  return res.data;
};

const submitSupplier = async (data: SubmitSupplierParams) => {
  const res: AxiosResponse<UseDetailedSupplierResponseType> = await axios({
    method: 'post',
    url: '/suppliers/submit',
    data,
  });

  return res.data;
};

const approveOrRejectSupplier = async (
  params: ApproveOrRejectSupplierParams,
) => {
  const { supplier_code, ...data } = params;
  const res: AxiosResponse<UseDetailedSupplierResponseType> = await axios({
    method: 'put',
    url: `/suppliers/${params.supplier_code}/status`,
    data,
  });

  return res.data;
};

const updateSupplier = async (params: UpdateSupplierParams) => {
  const { supplier_code, ...data } = params;

  const res: AxiosResponse = await axios({
    method: 'put',
    url: `/suppliers/${supplier_code}`,
    data,
  });

  return res.data;
};

const deleteSupplier = async (params?: DeleteSupplierParams) => {
  const res: AxiosResponse = await axios({
    method: 'delete',
    url: `/suppliers/${params?.supplier_code}/soft`,
  });

  return res.data;
};

export default {
  getSuppliers,
  getDetailedSupplier,
  getFilteredSuppliers,
  saveSupplier,
  updateSupplier,
  deleteSupplier,
  submitSupplier,
  approveOrRejectSupplier,
};
