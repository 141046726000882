import { UseFormReturn, FieldPath, UnpackNestedValue } from 'react-hook-form';
import { Cell } from 'react-table';

import {
  Nutrition,
  NutritionTypicalValuesSchema,
} from '@hooks/api/wizard/types';
import { AutoCompleteItem } from '@components/Common';

import { InputProps } from '@components/Common/Input';

enum EggType {
  Energy,
  Fat,
  Saturates,
  Sugars,
  Salt,
}

enum EggColor {
  White = 'white',
  Green = 'green',
  Amber = 'yellow',
  Red = 'red',
}

enum NutritionType {
  Food = 'Food',
  Drink = 'Drink',
}

type CustomCell = Cell<{ userAdded?: boolean }>;

type SubRowInstance = Omit<NutritionTypicalValuesSchema, 'subrows'>;

interface TableInputProps extends Partial<InputProps> {
  name: FieldPath<Nutrition>;
  showUnit?: boolean;
  showError?: boolean;
}

interface TableCellTextProps {
  name: FieldPath<Nutrition>;
  unit?: string;
  colorReset?: boolean;
  showUnit?: boolean;
  className?: string;
  preventZero?: boolean;
  preventZeroLimit?: number;
}

interface TableAutoCompleteProps {
  className?: string;
  inputRootClassName?: string;
  name: FieldPath<Nutrition>;
  disabled?: boolean;
  dense?: boolean;
  itemDropDown: NonNullable<AutoCompleteItem>[];
  normalizer?: boolean;
  onChange?: (data: any) => void;
  freeSolo?: boolean;
  canAddCustomItem?: boolean;
}

interface AllWatchHandlerParams {
  form: UseFormReturn<Nutrition>;
  type: string;
  name: any;
  value: any;
  setIsEnergyReadOnly: (value: boolean) => void;
}

export { EggType, EggColor, NutritionType };

export type {
  CustomCell,
  SubRowInstance,
  TableInputProps,
  TableCellTextProps,
  TableAutoCompleteProps,
  AllWatchHandlerParams,
};
