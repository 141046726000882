/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import { overrideTailwindClasses } from 'tailwind-override';
import { toast } from 'react-toastify';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button as MuiButton, IconButton } from '@material-ui/core';
import { Add, Loop, Delete, Create, Visibility } from '@material-ui/icons';

import GeneralHooks from '@hooks/api/general';

interface Props {
  id: string;
  text?: string;
  wrapperClassName?: string;
  className?: string;
  textClassName?: string;
  labelClassNames?: string;
  imgClassName?: string;
  onChange: (arg: string) => void;
  value: string | null;
  showPreview?: boolean;
  showAddIcon?: boolean;
  disabled?: boolean;
  showViewIconOnly?: boolean;
  url: string;
  tabIndex?: number;
  error?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  button: {
    width: theme.spacing(13),
    height: theme.spacing(3),

    '& .MuiButton-label': {
      width: theme.spacing(13),
      height: theme.spacing(3),
    },
  },
  activated: {
    border: '1px solid',
    borderColor: `${theme.palette.gray.main} !important`,
  },
  icon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
    padding: 0,

    '& svg': {
      fontSize: 'inherit',
    },
  },
}));

const getFileName = (file: Props['value']) =>
  file ? file.split('/').slice(-1)[0] : '';

const UploadButton: React.FC<Props> = ({
  disabled,
  id,
  value,
  onChange,
  text = 'Choose File',
  wrapperClassName,
  className,
  textClassName,
  imgClassName,
  showPreview = false,
  showAddIcon = false,
  showViewIconOnly = false,
  error = false,
  url,
  labelClassNames,
  tabIndex,
}) => {
  const [preview, setPreview] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const uploadFileMutation = GeneralHooks.useUploadMutation();

  const classes = useStyles({ preview, disabled });

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e?.target?.files?.[0];

    if (!selectedFile) return;

    if (inputRef?.current?.value) inputRef.current.value = '';

    // not sure how reliable this is , so commenting it for now
    // if (
    //   selectedFile?.type !== 'application/pdf' &&
    //   !selectedFile?.type.includes('image/')
    // ) {
    //   // only images and pdf files are accepted
    //   toast.error('only image and pdf document are allowed');

    //   return;
    // }

    const maxSizeInMB = 10; // 10mb
    const sizeInMB = selectedFile.size / (1024 * 1024);
    // if (selectedFile.size > maxSize) {
    if (sizeInMB > maxSizeInMB) {
      // only images and pdf files are accepted
      toast.error(
        `File if Too big , Files should be under 
         ${maxSizeInMB} Megabytes`,
      );

      return;
    }

    const reader = new FileReader();

    reader.addEventListener('loadstart', () => setLoading(true));

    reader.onload = async res => {
      setPreview(res?.target?.result as string);

      try {
        const uploadAddress = await uploadFileMutation.mutateAsync({
          url,
          file: selectedFile,
        });
        // const uploadAddress = await WizardApis.uploadFile({
        //   url,
        //   file: selectedFile,
        // });

        onChange(uploadAddress);
      } finally {
        setLoading(false);
      }
    };

    reader.readAsDataURL(selectedFile as Blob); // convert to base64 string
  };

  const onViewClick = () => {
    const fileName = getFileName(value);

    const link = document.createElement('a');
    link.href = value || '';
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);

    link.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }, 100);
  };

  const PreviewBtnComp = () => {
    if (value) {
      return (
        <>
          {showViewIconOnly ? null : (
            <>
              <IconButton
                disabled={disabled}
                classes={{
                  root: classes.icon,
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  onChange('');
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                disabled={disabled}
                classes={{
                  root: classes.icon,
                }}
                onClick={() => {
                  if (inputRef?.current) {
                    inputRef.current.click();
                  }
                }}
              >
                <Create />
              </IconButton>{' '}
            </>
          )}

          <IconButton
            // disabled={disabled}
            classes={{
              root: classes.icon,
            }}
            onClick={() => onViewClick()}
          >
            <Visibility />
          </IconButton>
        </>
      );
    }

    if (showAddIcon) {
      return (
        <div className="flex flex-row items-center justify-between">
          <Add className="text-lg mr-1" />

          <p className={textClassName}>{text}</p>
        </div>
      );
    }

    return <p>{text || ''}</p>;
  };

  return (
    <div tabIndex={tabIndex} className={clsx(classes.root, wrapperClassName)}>
      <input
        disabled={disabled}
        ref={inputRef}
        accept="image/*,application/pdf"
        className={classes.input}
        id={id}
        type="file"
        onChange={onFileSelect}
      />

      {loading ? <Loop /> : null}

      {!loading ? (
        showPreview && preview ? (
          <img className={imgClassName} src={preview} alt="preview" />
        ) : (
          <label htmlFor={id}>
            <MuiButton
              // disabled={disabled}
              disableRipple
              className={overrideTailwindClasses(
                clsx(
                  'px-0',
                  classes.button,
                  preview && classes.activated,
                  disabled ? 'border-gray-400 text-gray-400' : '',
                  error ? 'border-2 border-red-light bg-red-lighter' : '',
                  className,
                ),
              )}
              classes={{
                label: overrideTailwindClasses(
                  clsx(
                    'flex flex-row items-center justify-evenly',
                    labelClassNames,
                  ),
                ),
              }}
              variant="outlined"
              color={disabled ? 'default' : 'primary'}
              component="span"
            >
              <PreviewBtnComp />
            </MuiButton>
          </label>
        )
      ) : null}
    </div>
  );
};

export default UploadButton;
