import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryOptions,
} from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import errorHandler from '@utils/errorHandler';

import recycleBinApis from '@hooks/api/recycleBin/api';
import {
  GetRecycleBinParams,
  RestoreRecycleBinParams,
  UseRecycleBinResponseType,
} from '@hooks/api/recycleBin/types';

const KEYS = {
  recycleBinList: ['recycleBin', 'list'],
  restoreRecycleBin: ['restoreRecycleBin', 'restore'],
} as const;

const useRecycleBinList = (
  params: GetRecycleBinParams,
  options?: UseQueryOptions<UseRecycleBinResponseType, AxiosError>,
) => {
  const query = useQuery<UseRecycleBinResponseType, AxiosError>(
    [...KEYS.recycleBinList, params],
    () => recycleBinApis.getRecycleBinList(params),
    options,
  );

  return query;
};

const useRestoreRecycleBinMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(recycleBinApis.restoreRecycleBin, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('recycleBin');
    },
  });

  return mutation;
};

const useDeleteRecycleBinMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(recycleBinApis.deleteRecycleBin, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries('recycleBin');
    },
  });

  return mutation;
};

export default {
  useRecycleBinList,
  useRestoreRecycleBinMutations,
  useDeleteRecycleBinMutations,
};
