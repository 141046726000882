import React from 'react';
import Cookies from 'js-cookie';
import { useLocalStorage } from '@hooks';

import { LoginResponse } from '@hooks/api/auth/types';

export type User = null | Omit<LoginResponse['data'], 'api_token'>;

type UserStateProviderProps = { children: React.ReactNode };

const UserContext = React.createContext<
  | readonly [User, React.Dispatch<React.SetStateAction<User>>, () => void]
  | undefined
>(undefined);

function UserProvider({ children }: UserStateProviderProps) {
  const [user, setUser] = useLocalStorage<User>('user', null);

  const logout = () => {
    Cookies.remove('token');
    setUser(null);
  };

  const value = [user, setUser, logout] as const;

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
