import React from 'react';

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Button from '@components/Common/Button';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onReject: () => void;
  title?: string;
  contentText: string;
  confirmButtonText?: string;
  rejectButtonText?: string;
}

const Dialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  onReject,
  title,
  contentText,
  confirmButtonText = 'Yes',
  rejectButtonText = 'No',
}) => (
  <MuiDialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}

    {contentText ? (
      <DialogContent>
        <DialogContentText
          className="text-primary"
          id="alert-dialog-description"
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
    ) : null}

    <DialogActions>
      <Button onClick={() => onConfirm()} autoFocus>
        {confirmButtonText}
      </Button>
      <Button onClick={() => onReject()}>{rejectButtonText}</Button>
    </DialogActions>
  </MuiDialog>
);

export default Dialog;
