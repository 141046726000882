/* eslint-disable no-lonely-if */
import { useHistory } from 'react-router-dom';
import {
  useQuery,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { map } from '@routes';

import errorHandler from '@utils/errorHandler';

import authApis from '@hooks/api/auth/api';

import {
  UserUserPoliciesResponse,
  LoginResponse,
  GetUserSupplierAndCertificateResponse,
} from '@hooks/api/auth/types';

import { useUser } from '@contexts';

const KEYS = {
  userPolicies: ['user-policies'],
  userSupplierAndCertificate: ['user-supplier-and-certificate'],
} as const;

// LoginResponse['data']
const TransitionToNextRoute = (
  data: any,
  history: ReturnType<typeof useHistory>,
) => {
  console.log('🚀 ~ file: index.tsx ~ line 36 ~ data', data);
  if (data.user_type === 'supplier') {
    if (!data?.is_term_sign) {
      history.replace(map.auth.termsAndConditions);
    } else if (!data?.is_policy_sign) {
      history.replace(map.auth.policies);
    } else if (!data?.is_supplier_sign) {
      history.replace(map.auth.supplierAndCertificates);
    } else {
      if (data?.access_levels.dashboard_index) {
        history.replace(map.startingPoing);
      } else {
        history.replace(map.bussines.products);
      }
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!data?.is_term_sign) {
      history.replace(map.auth.termsAndConditions);
    } else if (!data?.is_policy_sign) {
      history.replace(map.auth.policies);
    } else {
      if (data?.access_levels.dashboard_index) {
        history.replace(map.startingPoing);
      } else {
        history.replace(map.bussines.products);
      }
    }
  }
};

const useLoginMutation = () => {
  const [, setUser] = useUser();
  const history = useHistory();

  const mutation = useMutation(authApis.login, {
    onError: errorHandler,
    onSuccess: data => {
      const { api_token, ...rest } = data.data;

      Cookies.remove('public_token');
      Cookies.set('token', api_token, { expires: 7 });
      console.log(
        '🚀 ~ file: index.tsx ~ line 79 ~ useLoginMutation ~ Cookies.set',
        Cookies.get('public_token'),
      );
      setUser(rest);

      TransitionToNextRoute(data.data, history);
    },
  });

  return mutation;
};

const usePublicLoginMutation = () => {
  const mutation = useMutation(authApis.publicLogin, {
    onError: errorHandler,
  });

  return mutation;
};

// const useLoginAsMutation = () => {
//   const mutation = useMutation(authApis.loginAs, {
//     onError: errorHandler,
//   });

//   return mutation;
// };
const useForgotPasswordMutation = () => {
  const mutation = useMutation(authApis.forgotPassword, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useResetPasswordMutation = () => {
  const mutation = useMutation(authApis.resetPassword, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);
    },
  });

  return mutation;
};

const useAcceptTermsAndConditionsMutation = () => {
  const [user, setUser] = useUser();
  const history = useHistory();

  const mutation = useMutation(authApis.acceptTermsAndConditions, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      if (user)
        setUser({
          ...user,
          is_term_sign: !!data?.data?.is_term_sign,
          is_policy_sign: !!data?.data?.is_policy_sign,
          is_supplier_sign: !!data?.data?.is_supplier_sign,
        });

      const newData = {
        ...data.data,
        access_levels: user?.access_levels,
      };

      TransitionToNextRoute(newData, history);
    },
  });

  return mutation;
};

const useUserPolicies = (
  options?: UseQueryOptions<UserUserPoliciesResponse, AxiosError>,
) => {
  const query = useQuery<UserUserPoliciesResponse, AxiosError>(
    KEYS.userPolicies,
    () => authApis.getUserPolicies(),
    options,
  );

  return query;
};

const useAcceptUserPoliciesMutation = () => {
  const [user, setUser] = useUser();
  const history = useHistory();
  const queryClient = useQueryClient();

  const mutation = useMutation(authApis.acceptPolicies, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.userPolicies);

      if (user)
        setUser({
          ...user,
          is_term_sign: !!data?.data?.is_term_sign,
          is_policy_sign: !!data?.data?.is_policy_sign,
          is_supplier_sign: !!data?.data?.is_supplier_sign,
        });
      const newData = {
        ...data.data,
        access_levels: user?.access_levels,
      };
      TransitionToNextRoute(newData, history);
    },
  });

  return mutation;
};

const useUserSupplierAndCertificate = (
  options?: UseQueryOptions<GetUserSupplierAndCertificateResponse, AxiosError>,
) => {
  const query = useQuery<GetUserSupplierAndCertificateResponse, AxiosError>(
    KEYS.userSupplierAndCertificate,
    () => authApis.getUserSupplierAndCertificate(),
    options,
  );

  return query;
};

const useSubmitUserSupplierAndCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(authApis.submitUserSupplierAndCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.userSupplierAndCertificate);
    },
  });

  return mutation;
};

const useSaveUserSupplierAndCertificateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(authApis.saveUserSupplierAndCertificate, {
    onError: errorHandler,
    onSuccess: data => {
      toast.success(data.message);

      queryClient.invalidateQueries(KEYS.userSupplierAndCertificate);
    },
  });

  return mutation;
};

export default {
  useLoginMutation,
  // useLoginAsMutation,
  usePublicLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useAcceptTermsAndConditionsMutation,
  useAcceptUserPoliciesMutation,
  useUserPolicies,
  useUserSupplierAndCertificate,
  useSubmitUserSupplierAndCertificateMutation,
  useSaveUserSupplierAndCertificateMutation,
};
