/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
  insertNode,
  isDescendant,
  getVisibleNodeCount,
  GetNodeKeyFunction,
} from '@nosferatu500/react-sortable-tree';
import { IconButton } from '@material-ui/core';

import { useSidebarState } from '@contexts';

import { useUrlParams } from '@hooks';

import WizardHooks from '@hooks/api/wizard';

import { Add as AddIcon, Delete } from '@material-ui/icons';

import {
  Units,
  ingredients_materials,
  Ingredients_Item,
} from '@hooks/api/wizard/types';

import {
  Input,
  Button,
  Tooltip,
  CountryAutoComplete,
  AutoCompleteMultiple,
  CheckBox,
} from '@components/Common';

import AutoComplete from '@components/Bussines/Products/ProductForm/Ingredients/AutoComplete';

import {
  treeRow,
  treeCarouselItems,
} from '@components/Bussines/Products/ProductForm/Ingredients/utils';

import InputController from '@components/Bussines/Products/ProductForm/Ingredients/InputController';

import Search from '@components/Bussines/Products/ProductForm/Ingredients/Search';
import { Country } from '@app/hooks/api/generalTypes';

type P = {
  treesData: Array<ingredients_materials>;
  setTreesData: (value: Array<ingredients_materials>) => void;
  index: number;
};

type Item = {
  name: string;
  code: number;
};

const useActiveElement = () => {
  const [active, setActive] = React.useState(document.activeElement);

  const handleFocusIn = () => {
    setActive(document.activeElement);
  };

  React.useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  return active;
};

const Tree: React.FC<P> = ({ treesData, setTreesData, index }) => {
  const [urlParams] = useUrlParams<{
    code: string;
    version: string;
    editMode: boolean;
  }>();

  const { data: ENumber } = WizardHooks.useENumbers();
  const { data: allergenList } = WizardHooks.useAllergenList();

  const ingredientNameList = ENumber?.data.map(el => ({
    ...el,
    code: el.id,
    name: el.additives,
  }));

  const e_numberList = ingredientNameList?.map(option => {
    return {
      name: option.e_number,
      code: option.code,
    };
  });

  const [mouseClickOffset, setMouseClickOffset] = React.useState(0);

  const treeCarousel = React.useRef<HTMLDivElement | null>(null);
  const treeCarouselWrap = React.useRef<HTMLDivElement | null>(null);

  const scrollBar = React.useRef<HTMLDivElement | null>(null);
  const scrollBarTrigger = React.useRef<HTMLDivElement | null>(null);

  const gettotal_qty_in_mixing_bowl = (arr: Array<Ingredients_Item>) => {
    let total_qty_in_mixing_bowl = 0;
    arr.forEach(row => {
      total_qty_in_mixing_bowl += Number(row.qty_in_mixing_bowl);
    });
    return total_qty_in_mixing_bowl;
  };

  const gettotal_qty_in_final_product = (arr: Array<Ingredients_Item>) => {
    let total_qty_in_final_product = 0;
    arr.forEach(row => {
      total_qty_in_final_product += Number(row.qty_in_final_product);
    });

    return total_qty_in_final_product;
  };

  // const getTotalpercentage_in_final_product = () => {
  //   let percentage_in_final_product = 0;
  //   for (let i = 0; i < treesData[index].tree_data.length; i+= 1) {
  //     percentage_in_final_product += Number(
  //       treesData[index].tree_data[i].percentage_in_final_product,
  //     );
  //   }
  //   return percentage_in_final_product;
  // };

  const gettotal_percentage_in_final_product_manual = (
    arr: Array<Ingredients_Item>,
  ) => {
    let percentage_in_final_product_manual = 0;
    for (let i = 0; i < arr.length; i += 1) {
      percentage_in_final_product_manual += Number(
        arr[i].percentage_in_final_product_manual,
      );
    }
    return percentage_in_final_product_manual;
  };

  const getpercentage_in_final_product = (table: ingredients_materials) => {
    const newArr = table;
    if (Number(newArr.total_qty_in_final_product) === 0) {
      for (let i = 0; i < newArr.tree_data.length; i += 1) {
        newArr.tree_data[i].percentage_in_final_product = null;
      }
    } else {
      for (let i = 0; i < newArr.tree_data.length; i += 1) {
        newArr.tree_data[i].percentage_in_final_product = Number(
          (Number(newArr.tree_data[i].qty_in_final_product) * 100) /
            Number(newArr.total_qty_in_final_product),
        );
      }
    }
    return newArr;
  };

  const getNodeKey: GetNodeKeyFunction = ({ treeIndex }) => treeIndex;

  // -----------------------------------------------------------------------

  // calculate relative position to the mouse and set dragging=true

  const focusedElement = useActiveElement();

  React.useEffect(() => {
    const scrollLeft = focusedElement?.closest('.tree-carousel')?.scrollLeft;
    const carouselParent = document.getElementsByClassName(
      'tree-carousel',
    ) as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < carouselParent?.length; i += 1) {
      carouselParent[i].scrollLeft = Number(scrollLeft);
    }
  }, [focusedElement]);

  const onMouseMove = (e: MouseEvent) => {
    if (
      treeCarousel?.current &&
      treeCarouselWrap?.current &&
      scrollBar?.current &&
      scrollBarTrigger?.current
    ) {
      const offset = scrollBar?.current?.getBoundingClientRect().left;
      const scrollBarWidth = scrollBar.current.getBoundingClientRect().width;
      const mousePosition = e?.pageX;
      const treeCarouselWidth =
        treeCarousel?.current?.getBoundingClientRect().width;
      let treeCarouselWrapWidth = 0;

      for (let i = 0; i < treeCarouselWrap?.current?.children.length; i += 1) {
        treeCarouselWrapWidth +=
          treeCarouselWrap.current.children[i].getBoundingClientRect().width;
      }

      const position = mousePosition - offset - mouseClickOffset;

      const end = scrollBarWidth;

      const carousel = document.getElementsByClassName(
        'tree-carousel-wrap',
      ) as HTMLCollectionOf<HTMLElement>;

      const carouselParent = document.getElementsByClassName(
        'tree-carousel',
      ) as HTMLCollectionOf<HTMLElement>;

      for (let i = 0; i < carouselParent?.length; i += 1) {
        carouselParent[i].scrollLeft = 0;
      }
      const scrollBarPosition = (position * 100) / end;

      if (scrollBarPosition > 0) {
        if (scrollBarPosition < 100) {
          scrollBarTrigger.current.style.left = `${scrollBarPosition}%`;

          for (let i = 0; i < carousel?.length; i += 1) {
            carousel[i].style.transform = `translateX(-${
              (scrollBarPosition *
                (treeCarouselWrapWidth - treeCarouselWidth)) /
              100
            }px)`;
          }
        } else {
          scrollBarTrigger.current.style.left = `${100}%`;
          for (let i = 0; i < carousel.length; i += 1) {
            carousel[i].style.transform = `translateX(-${
              treeCarouselWrapWidth - treeCarouselWidth
            }px)`;
          }
        }
      } else {
        scrollBarTrigger.current.style.left = `0%`;
        for (let i = 0; i < carousel.length; i += 1) {
          carousel[i].style.transform = `translateX(-${0}px)`;
        }
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mousemove', onMouseMove);
    e?.stopPropagation();
    e?.preventDefault();
  };

  const onMouseDown:
    | React.MouseEventHandler<HTMLDivElement>
    | undefined = e => {
    // only left mouse button
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    if (scrollBarTrigger.current) {
      setMouseClickOffset(
        e?.pageX - scrollBarTrigger?.current?.getBoundingClientRect().left,
      );
    }
    e?.stopPropagation();
    e?.preventDefault();
  };
  // -----------------------------------------------------------------------
  const onChange = (
    data: Array<Ingredients_Item>,
    name: string | null = null,
  ) => {
    const newArr = [...treesData];
    newArr[index].tree_data = data;

    if (name !== 'SupplierName' && name !== 'gmo_status') {
      newArr[index].total_qty_in_mixing_bowl = gettotal_qty_in_mixing_bowl(
        newArr[index].tree_data,
      );
      newArr[index].total_qty_in_final_product = gettotal_qty_in_final_product(
        newArr[index].tree_data,
      );
      newArr[index].total_percentage_in_final_product_manual =
        gettotal_percentage_in_final_product_manual(newArr[index].tree_data);

      newArr[index] = getpercentage_in_final_product(newArr[index]);
    }

    setTreesData(newArr);
  };

  const search = (nameKey: any, myArray: any): any => {
    for (let i = 0; i < myArray.length; i += 1) {
      if (myArray[i].is_exception) {
        if (
          RegExp(`\\b${myArray[i].name.toLowerCase()}\\b`).test(
            nameKey.toLowerCase(),
          )
        ) {
          return myArray[i];
        }
      }
    }
    for (let i = 0; i < myArray.length; i += 1) {
      if (
        RegExp(`\\b${myArray[i].name.toLowerCase()}\\b`).test(
          nameKey.toLowerCase(),
        )
      ) {
        return myArray[i];
      }
    }
    return false;
  };

  const renderAlergen = (ingredient_name: any, allergen: any): any => {
    let render = ingredient_name
      .toLowerCase()
      .replace(allergen.name.toLowerCase(), allergen.render);

    if (allergen.parent_render) {
      render = `${render} ${allergen.parent_render}`;
    }

    return render;
  };

  const changeInput = ({
    name,
    path,
    node,
    value,
    event,
    checkBox,
    country,
    suppliers,
  }: {
    name: string;
    path: number[];
    node: any;
    value?: any;
    event?: React.ChangeEvent<HTMLInputElement>;
    checkBox?: boolean;
    country?: Country | Country[];
    suppliers?: any;
  }) => {
    let val: any;

    // console.log('🚀 ~ file: Table.tsx ~ line 353 ~ value', value);
    if (value !== undefined) {
      if (value === null) {
        val = {
          name: '',
          code: -1,
        };
      } else {
        val = {
          name: value.name,
          code: value.code,
        };
      }
    }

    if (event !== undefined) {
      val = event.target.value;
    }
    if (checkBox !== undefined) {
      val = checkBox;
    }

    if (suppliers !== undefined) {
      val = suppliers;
    }

    if (country !== undefined) {
      val = country;
    }

    const newNode = {
      ...node,
      [name]: val,
    };

    // console.log('🚀 ~ file: Table.tsx ~ line 383 ~ newNode', newNode);

    if (name === 'ingredient_name' && ingredientNameList !== undefined) {
      if (val?.code !== -1) {
        newNode.e_number = {
          name: ingredientNameList[val?.code - 1]?.e_number,
          code: val?.code,
        };
      } else {
        newNode.e_number = {
          name: '',
          code: '',
        };
      }

      const allergen = search(val.name, allergenList?.data || []);

      if (allergen) {
        newNode.allergen = {
          is_allergen: true,
          allergen_id: allergen.id,
          allergen_parent_id: allergen.parent_id,
          render: renderAlergen(val.name, allergen),
          is_exception_allergen: allergen.is_exception,
        };
      } else {
        newNode.allergen = {
          allergen_id: null,
          allergen_parent_id: null,
          is_allergen: false,
          is_exception_allergen: false,
          render: '',
        };
      }
    }

    if (name === 'function_of_additives') {
      if (newNode.function_of_additives.name === 'Processing Aid') {
        newNode.declared = false;
      } else {
        newNode.declared = true;
      }
    }

    const data = changeNodeAtPath({
      treeData: treesData[index].tree_data,
      path,
      getNodeKey,
      newNode,
    }) as Array<Ingredients_Item>;
    onChange(data, name);
  };

  const removeRow = (path: number[]) => {
    const data = removeNodeAtPath({
      treeData: treesData[index].tree_data,
      path,
      getNodeKey,
    }) as Array<Ingredients_Item>;
    // console.log('🚀 ~ file: Table.tsx ~ line 436 ~ removeRow ~ data', data);
    onChange(data);
  };

  const addRow = () => {
    const newArr = [...treesData];
    const newTreeRow = { ...treeRow };

    // newTreeRow.ingredient_name = {
    //   name: `name-${getVisibleNodeCount({
    //     treeData: treesData[index].tree_data,
    //   })}`,
    //   code: newArr[index].tree_data.length,
    // };

    const data = insertNode({
      treeData: newArr[index].tree_data,
      depth: 0,
      minimumTreeIndex: getVisibleNodeCount({
        treeData: treesData[index].tree_data,
      }),
      newNode: { ...newTreeRow },
      expandParent: true,
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).treeData as Array<Ingredients_Item>;

    const carousel = document.getElementsByClassName(
      'tree-carousel-wrap',
    ) as HTMLCollectionOf<HTMLElement>;

    const carouselParent = document.getElementsByClassName(
      'tree-carousel',
    ) as HTMLCollectionOf<HTMLElement>;

    if (scrollBarTrigger?.current) {
      scrollBarTrigger.current.style.left = `0`;
    }
    for (let i = 0; i < carousel?.length; i += 1) {
      carousel[i].style.transform = 'translateX(0)';
    }
    for (let i = 0; i < carouselParent?.length; i += 1) {
      carouselParent[i].scrollLeft = 0;
    }

    onChange(data);
  };

  return (
    <>
      <div
        className={`SortableTree-parent editMode-${!urlParams.editMode}`}
        style={{
          height: `${
            (getVisibleNodeCount({ treeData: treesData[index].tree_data }) +
              3) *
              44 +
            74
          }px`,
        }}
      >
        <div className="SortableTree-header">
          <div className="SortableTree-column first-column">
            Ingredient Name
          </div>
          <div className="tree-carousel" ref={treeCarousel}>
            <div className="tree-carousel-wrap" ref={treeCarouselWrap}>
              {treeCarouselItems.map(item => (
                <div
                  className={`SortableTree-column ${item.slug}`}
                  key={`header-${item.key}`}
                >
                  <span>
                    {item.name}
                    {item.tooltip && <Tooltip title={item.tooltip} />}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="SortableTree-column last-column">Actions</div>
        </div>
        <SortableTree
          scaffoldBlockPxWidth={16}
          treeData={treesData[index].tree_data}
          // rowHeight={44}
          maxDepth={4}
          onChange={data => onChange(data)}
          generateNodeProps={({ node, path }) => ({
            title: (
              <div className={`tree-row tree-row-${path}`}>
                <div className="SortableTree-column first-column">
                  {ingredientNameList ? (
                    <div
                      className={`
                        required w-full
                        ${
                          node.ingredient_name?.name !== '' &&
                          node.ingredient_name?.name !== null
                            ? 'not-empty'
                            : 'empty'
                        }
                    `}
                    >
                      <Search
                        disabled={!urlParams.editMode}
                        onChange={value => {
                          // console.log(
                          //   '🚀 ~ file: Table.tsx ~ line 541 ~ value',
                          //   value,
                          // );
                          changeInput({
                            value,
                            name: 'ingredient_name',
                            path,
                            node,
                          });
                        }}
                        items={ingredientNameList}
                        value={
                          node.ingredient_name === null
                            ? ''
                            : node.ingredient_name
                        }
                      />
                    </div>
                  ) : (
                    <> loading </>
                  )}
                </div>
                <div className="tree-carousel">
                  <div className="tree-carousel-wrap">
                    {treeCarouselItems.map(item => (
                      <div
                        key={`table-${index}-SortableTree-${item.slug}`}
                        className={`SortableTree-column ${item.slug} ${
                          path.length !== 1 &&
                          item.disableInSubRow &&
                          'disabled'
                        }
                        ${item.required && 'required'}
                        ${
                          node[item.slug] !== '' && node[item.slug] !== null
                            ? 'not-empty'
                            : 'empty'
                        }
                        `}
                      >
                        {item.type === 'autoComplete' && (
                          <AutoComplete
                            onChange={value =>
                              changeInput({
                                value,
                                name: item.slug,
                                path,
                                node,
                              })
                            }
                            disabled={!urlParams.editMode}
                            items={item.data || []}
                            canAddCustomItem={item.canAddCustomItem}
                            freeSolo={item.canAddCustomItem}
                            value={node[item.slug]}
                          />
                        )}
                        {item.type === 'AutoCompleteMultiple' && (
                          <AutoCompleteMultiple
                            limitTags={2}
                            onChange={value =>
                              changeInput({
                                suppliers: value,
                                name: item.slug,
                                path,
                                node,
                              })
                            }
                            className="w-full"
                            disabled={!urlParams.editMode}
                            items={item.data || []}
                            canAddCustomItem={item.canAddCustomItem}
                            freeSolo={item.canAddCustomItem}
                            value={node[item.slug]}
                          />
                        )}
                        {item.type === 'e_number' && (
                          <AutoComplete
                            onChange={value =>
                              changeInput({
                                value,
                                name: item.slug,
                                path,
                                node,
                              })
                            }
                            className={
                              node[item.slug]?.code === -1 &&
                              node[item.slug]?.name !== '' &&
                              node[item.slug]?.name !== null
                                ? 'warning'
                                : ''
                            }
                            disabled={!urlParams.editMode}
                            items={e_numberList || []}
                            canAddCustomItem={item.canAddCustomItem}
                            freeSolo={item.canAddCustomItem}
                            value={node[item.slug]}
                          />
                        )}
                        {item.type === 'countryAutoComplete' && (
                          <CountryAutoComplete
                            limitTags={2}
                            fullWidth
                            onChange={country =>
                              changeInput({
                                country,
                                name: item.slug,
                                path,
                                node,
                              })
                            }
                            disabled={!urlParams.editMode}
                            inputPlaceholder="select ..."
                            multiple={item.multiple}
                            value={node[item.slug]}
                          />
                        )}
                        {(item.type === 'number' || item.type === 'text') && (
                          <InputController
                            onChange={(event: any) =>
                              changeInput({
                                event,
                                name: item.slug,
                                path,
                                node,
                              })
                            }
                            disabled={!urlParams.editMode}
                            type={item.type}
                            role={item.required}
                            value={node[item.slug]}
                          />
                        )}

                        {item.type === 'checkBox' && (
                          <CheckBox
                            disabled={!urlParams.editMode}
                            checked={node[item.slug]}
                            onChange={newVal => {
                              changeInput({
                                checkBox: newVal,
                                name: item.slug,
                                path,
                                node,
                              });
                            }}
                          />
                        )}

                        {item.type === 'declared' && (
                          <CheckBox
                            checked={node[item.slug]}
                            // disabled={
                            //   !urlParams.editMode ||
                            //   node.function_of_additives?.name ===
                            //     'Processing Aid'
                            // }
                            disabled={!urlParams.editMode}
                            onChange={newVal => {
                              changeInput({
                                checkBox: newVal,
                                name: item.slug,
                                path,
                                node,
                              });
                            }}
                          />
                        )}

                        {item.type === 'auto' && (
                          <div className="bg-white">
                            {node[item.slug]?.toFixed(2)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tree-row-remove last-column">
                  <IconButton
                    disabled={!urlParams.editMode}
                    className="btn"
                    type="button"
                    aria-label="remove row"
                    onClick={() => removeRow(path)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ),
          })}
        />
        <div className="SortableTree-end">
          <div className="SortableTree-footer">
            <div className="SortableTree-column first-column"> </div>
            <div className="tree-carousel">
              <div className="tree-carousel-wrap">
                <div className="SortableTree-column qty_in_mixing_bowl">
                  <div className="input">
                    {treesData[index].total_qty_in_mixing_bowl.toFixed(2)}
                  </div>
                </div>
                <div className="SortableTree-column qty_in_final_product">
                  <div className="input">
                    {treesData[index].total_qty_in_final_product.toFixed(2)}
                  </div>
                </div>
                <div className="SortableTree-column percentage_in_final_product" />

                <div className="SortableTree-column percentage_in_final_product_manual">
                  <div className="input">
                    {treesData[
                      index
                    ].total_percentage_in_final_product_manual.toFixed(2)}
                  </div>
                </div>
                <div className="SortableTree-column e_number" />
                <div className="SortableTree-column function_of_additives" />
                <div className="SortableTree-column gmo_status" />
                <div className="SortableTree-column country_of_origin" />
                <div className="SortableTree-column SupplierName" />
                <div className="SortableTree-column quid" />
                <div className="SortableTree-column declared" />
                <div className="SortableTree-column comments_and_extra_information" />
              </div>
            </div>
            <div className="SortableTree-column last-column">
              <Button
                variant="outlined"
                className="border-1 border-primary"
                onClick={addRow}
                startIcon={<AddIcon />}
                disabled={!urlParams.editMode}
                text="New Row"
              />
            </div>
          </div>
          <div className="scrollBar" ref={scrollBar}>
            <div
              onMouseDown={onMouseDown}
              ref={scrollBarTrigger}
              className="scrollBar-trigger"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Tree;
