import React from 'react';
import clsx from 'clsx';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { IconButton } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import {
  Modal,
  Button,
  DatePicker,
  AutoComplete,
  Input,
} from '@components/Common';

export interface FilterAttributesValues {
  expiry_date: {
    from: Date | null;
    to: Date | null;
  };
  action: {
    name: string;
    code: string;
  } | null;
  entity: {
    name: string;
    code: string;
  } | null;
  username: string;
}

type P = {
  values: FilterAttributesValues | null;
  open: boolean;
  setOpen: (code: boolean) => void;
  onFormSubmit: (args: FilterAttributesValues) => void;
};

const AttributeFilterModal: React.FC<P> = ({
  values,
  open,
  setOpen,
  onFormSubmit,
}) => {
  const form = useForm<FilterAttributesValues>({
    defaultValues: {
      expiry_date: {
        from: null,
        to: null,
      },
      action: null,
      entity: null,
    },
  });

  React.useEffect(() => {
    form.reset({
      expiry_date: {
        from: values?.expiry_date.from ?? null,
        to: values?.expiry_date.to ?? null,
      },
      action: values?.action
        ? {
            name: values?.action.name ?? '',
            code: values?.action.code ?? '',
          }
        : null,
      entity: values?.entity
        ? {
            name: values?.entity.name ?? '',
            code: values?.entity.code ?? '',
          }
        : null,
    });
  }, [JSON.stringify(values)]);

  const onSubmit: SubmitHandler<FilterAttributesValues> = data => {
    onFormSubmit(data);
  };

  const isAtLeastOneFieldCompleted = (obj: FilterAttributesValues) => {
    const formValues = [
      obj.action,
      obj.entity,
      obj.expiry_date.from,
      obj.expiry_date.to,
    ];

    return !!formValues.find(el => el !== null);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div
        className={clsx(
          'flex flex-col justify-between items-center',
          'bg-white-600 shadow-lg min-w-132',
        )}
      >
        <div
          className={clsx(
            'flex flex-row items-center justify-between',
            'h-10 pl-4 min-w-full bg-white-400',
          )}
        >
          <h3 className="font-roboto text-base">Attribute Filters</h3>

          <IconButton onClick={() => setOpen(false)}>
            <Close className="text-4xl text-red" />
          </IconButton>
        </div>

        <form className="flex flex-col items-center p-4 min-w-full">
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Expiry Date</p>

            <div className="flex flex-row items-center">
              <Controller
                name="expiry_date.from"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="from"
                      className="w-32"
                      wrapperClassName="mr-2"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />

              <Controller
                name="expiry_date.to"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col">
                    <DatePicker
                      placeholder="to"
                      className="w-32"
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Action</p>

            <Controller
              name="action"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className="w-74"
                    items={[
                      {
                        name: 'create',
                        code: 'create',
                      },
                      {
                        name: 'update',
                        code: 'update',
                      },
                      {
                        name: 'submit',
                        code: 'submit',
                      },
                      {
                        name: 'delete',
                        code: 'delete',
                      },
                      {
                        name: 'trash',
                        code: 'trash',
                      },
                      {
                        name: 'restore',
                        code: 'restore',
                      },
                      {
                        name: 'system',
                        code: 'system',
                      },
                      {
                        name: 'login',
                        code: 'login',
                      },
                      {
                        name: 'accept',
                        code: 'accept',
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto">Entity</p>

            <Controller
              name="entity"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col">
                  <AutoComplete
                    freeSolo={false}
                    canAddCustomItem={false}
                    className="w-74"
                    items={[
                      {
                        name: 'Policy',
                        code: 'Policy',
                      },
                      {
                        name: 'User',
                        code: 'User',
                      },
                      {
                        name: 'Certificate',
                        code: 'Certificate',
                      },
                      {
                        name: 'Manufacture',
                        code: 'Manufacture',
                      },
                      {
                        name: 'Supplier',
                        code: 'Supplier',
                      },
                      {
                        name: 'Customer',
                        code: 'Customer',
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>

          {/* <div className="flex flex-row justify-between items-center min-w-full mb-2">
            <p className="font-roboto"> َUsername</p>

            <Controller
              name="username"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col">
                  <Input className="w-74" value={value} onChange={onChange} />
                </div>
              )}
            />
          </div> */}

          <div className="flex flex-row justify-end items-center min-w-full">
            {isAtLeastOneFieldCompleted(form.watch()) ? (
              <Button
                type="submit"
                onClick={form.handleSubmit(onSubmit)}
                className="text-green border-green"
                variant="outlined"
                startIcon={<Check />}
                text="Done"
              />
            ) : null}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AttributeFilterModal;
