import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

import dashboardApis from '@hooks/api/dashboard/api';

import { UseDashboardType } from '@hooks/api/dashboard/types';
import { UseProductType } from '../products/types';

const useDashboard = (
  options?: UseQueryOptions<UseProductType, AxiosError>,
) => {
  const query = useQuery<UseDashboardType, AxiosError>(
    ['dashboard'],
    dashboardApis.getProducts,
  );

  return query;
};

export default {
  useDashboard,
};
