import DashboardHooks from '@hooks/api/dashboard';
import { useHistory } from 'react-router-dom';

import { Layout, Breadcrumbs } from '@components/Common';
import Box from '@components/Dashboard/Box';
import { useUser } from '@contexts';
import { map } from '@routes';

const DashboardComp = () => {
  const [user] = useUser();
  const history = useHistory();

  if (!user?.access_levels.dashboard_index) {
    history.replace(map.bussines.products);
    return null;
  }
  const { data: dashboardData } = DashboardHooks.useDashboard({
    enabled: false,
  });

  return (
    <Layout>
      <Breadcrumbs onClick={() => {}} items={[{ href: '/', text: 'home' }]} />

      <h3 className="text-base text-primary-light mb-2 mt-6">Statistics</h3>

      <div className="grid grid-cols-2 gap-4 mb-10">
        {(dashboardData?.data || []).map(el => (
          <Box key={el.header} header={el.header} data={el.data} />
        ))}
      </div>
    </Layout>
  );
};

export default DashboardComp;
