import React, { useEffect } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import RouteMap from '@routes/map';

import authHooks from '@hooks/api/auth';

import Cookies from 'js-cookie';

const PublicLogin = () => {
  const history = useHistory();
  const routeParams = useRouteMatch<{ public_token: string }>();

  const publicLogniMutation = authHooks.usePublicLoginMutation();

  const onSubmit = async () => {
    try {
      publicLogniMutation.mutate(
        {
          token: routeParams.params.public_token,
        },
        {
          onSuccess: () => {
            Cookies.set('public_token', routeParams.params.public_token, {
              expires: 7,
            });
            Cookies.remove('token');
            history.push('/share/products/main-info?code=1&version=1');
          },
        },
      );
    } catch (e) {
      history.push(RouteMap.auth.login);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-between bg-white-600">
      {/* <Logo className="mt-24" />

      <h3 className="font-roboto mt-16 text-2xl text-center">
        Welcome to SpecReady
      </h3>

      <div className="px-8 py-6 w-1/3">
        <div className="flex flex-col justify-center items-center">
          <Button
            onClick={() => onSubmit()}
            className="bg-green mt-5 mb-4 w-60"
            text="Login"
          />
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-start p-8">
        <Button
          variant="outlined"
          className="font-roboto text-sm text-gray"
          text="Need Help?"
          href="https://support.specready.co.uk"
        />
      </div> */}
    </div>
  );
};

export default PublicLogin;
