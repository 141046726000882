import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { Delete, DeleteOutline } from '@material-ui/icons';

interface Props {
  active?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 'fit-content',

    height: theme.spacing(4),
    width: theme.spacing(4),

    padding: 0,
  },
  label: {
    display: 'contents',
  },
}));

const Button: React.FC<Props & Omit<ButtonProps, keyof Props>> = ({
  className,
  onClick,
  active = false,
  ...other
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant="outlined"
      color="default"
      size="medium"
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      className={clsx(
        className,
        active
          ? 'bg-transparent shadow-none text-white bg-red border-red'
          : 'text-red border-red',
      )}
      startIcon={
        active ? (
          <Delete className="text-2xl" />
        ) : (
          <DeleteOutline className="text-2xl" />
        )
      }
      onClick={onClick}
      {...other}
    />
  );
};

export default Button;
